import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Redirect } from "react-router-dom";
import axios from "axios";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"
import AWS from 'aws-sdk';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { get_current_project } from '../api/projectApi';
const spacesEndpoint = new AWS.Endpoint('fra1.digitaloceanspaces.com');
const s3 = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: "DO00LNJYNYND379L4PQH",
  secretAccessKey: "twsFji6eSRe1V9KpcHnJbLBd90lem6nvI2myiUlrQRg"
});
const configDig = {
  // "Access-Control-Allow-Origin": "*",
  // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  digitalOceanSpaces: 'https://staging-space-dev.fra1.digitaloceanspaces.com/',
  bucketName: 'documents-propertyappz'
};
const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
    //'Content-Type': 'application/json',
    'Accept': 'application/json',
    Authorization: `${token}`,
  }
};

import constant from '../../../constants.json';
const baseURL = process.env.REACT_APP_API_URL;

class EditProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      optionGroupCountry: [],
      optionGroupCity: [],
      optionProjectType: [],
      project_name: '',
      project_phone_primary: '',
      project_phone_secondary: '',
      project_address_line_1: '',
      project_address_line_2: '',
      project_email: '',
      project_postcode: '',
      project_country_id: '',
      project_city_id: '',
      project_city_title: '',
      project_country_title: '',
      project_website: '',
      project_prefix: '',
      project_late_fee: '',
      project_project_type_id: '',
      project_project_type_title: '',
      project_organisation_id: '',
      project_picture: '',
      project_icon: null,
      project_picture: null,
      project_logo: null,
      show_picture: null,
      show_logo: null,
      show_icon: null,
      redirect: null,
      titlesArray: [
        {
          "value": 'Mr',
          "label": 'Mr'
        },
        {
          "value": 'Mrs',
          "label": 'Mrs'
        }
      ],
      id: "",
      name: '',
      hasFloors: false,
      hasPhasesBlocks: false,
      hasSizes: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
  }
  handleChange(event) {
  }

  async getOptionsProjectTypes() {
    const res = await axios.get(baseURL + "/api/project-type-listing", config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d.id,
      "label": d.name
    }))
    this.setState({ optionProjectType: options })
  }
  async getOptionsCountry() {
    const res = await axios.get(baseURL + "/api/countries-listing", config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d.id,
      "label": d.title
    }))
    this.setState({ optionGroupCountry: options })
  }
  async getOptionsCity(country_id) {
    const res = await axios.get(baseURL + "/api/country-cities/" + country_id, config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d.id,
      "label": d.title
    }))
    this.setState({ optionGroupCity: options })
  }
  componentDidMount() {
    Promise.all([get_current_project(localStorage.getItem("current_project_id"))]).then((values) => {
      this.setState({ project_name: values[0].name });
      this.setState({ project_phone_primary: values[0].phone_primary });
      this.setState({ project_phone_secondary: values[0].phone_secondary });
      this.setState({ project_email: values[0].email });;
      this.setState({ project_address_line_1: values[0].address_line_1 });
      this.setState({ project_address_line_2: values[0].address_line_2 });
      this.setState({ project_postcode: values[0].post_code });
      this.setState({ project_country_id: values[0].country_id });
      this.setState({ project_country_title: values[0].country_title });
      this.setState({ project_website: values[0].website });
      this.setState({ project_late_fee: values[0].late_fee });
      this.setState({ project_prefix: values[0].prefix });
      this.setState({ project_project_type_id: values[0].project_type_id });
      this.setState({ project_project_type_title: values[0].project_type_name });
      this.setState({ project_organisation_id: values[0].organisation_id });
      this.setState({ show_picture: values[0].picture });
      this.setState({ show_icon: values[0].icon });
      this.setState({ show_logo: values[0].logo });
      window.localStorage.setItem("has_phase_blocks", values[0].has_phases);
      window.localStorage.setItem("has_sizes", values[0].has_sizes);
      window.localStorage.setItem("has_floors", values[0].has_floors);
      this.setState({
        hasFloors: values[0].has_floors == 1 ? true : false,
        hasPhasesBlocks: values[0].has_phases == 1 ? true : false,
        hasSizes: values[0].has_sizes == 1 ? true : false,
      });

      this.setState({ project_city_id: values[0].city_id });
      this.setState({ project_city_title: values[0].city_title });
      this.getOptionsCity(values[0].country_id);
    });
    this.getOptionsCountry()
    this.getOptionsProjectTypes()
  }
  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    this.setState({
      [name]: checked,
    });
  };
  // handleSelectedCustomerTitle = selectedCustomerTitle => {
  //   this.setState({ project_title: selectedCustomerTitle.value });
  //   this.setState({ selectedCustomerTitle })
  //   console.log(selectedCustomerTitle);
  // }
  handleSelectGroupProjectType = selectedGroupProjectType => {
    this.setState({ selectedGroupProjectType })
    this.setState({ project_project_type_title: selectedGroupProjectType.label });
    this.setState({ project_project_type_id: selectedGroupProjectType.value });
    console.log(selectedGroupProjectType);
  }
  handleSelectGroupCountry = selectedGroupCountry => {
    this.setState({ selectedGroupCountry })
    this.getOptionsCity(selectedGroupCountry.value)
    this.setState({ project_city_title: '' })
    this.setState({ project_country_title: selectedGroupCountry.label });
    this.setState({ project_country_id: selectedGroupCountry.value });
    console.log(selectedGroupCountry);
  }
  handleSelectGroupCity = selectedGroupCity => {
    this.setState({ project_city_title: selectedGroupCity.label });
    this.setState({ project_city_id: selectedGroupCity.value });
    this.setState({ selectedGroupCity })
    console.log(selectedGroupCity);
  }
  handleImageChangeLogo = (e) => {
    if (e.target.files && e.target.files[0]) {
      console.log('Logo');
      console.log(e.target.files[0]);
      this.setState({ project_logo: e.target.files[0] });
    }
  };
  handleImageChangeIcon = (e) => {
    if (e.target.files && e.target.files[0]) {
      console.log('Icon');
      console.log(e.target.files[0]);
      this.setState({ project_icon: e.target.files[0] });
    }
  };
  handleImageChangePicture = (e) => {
    if (e.target.files && e.target.files[0]) {
      console.log('Picture');
      console.log(e.target.files[0]);
      this.setState({ project_picture: e.target.files[0] });
    }
  };
  showToast(title, message, toastType) {
    //position class
    var positionClass = "toast-top-right";
    toastr.options = {
      positionClass: positionClass,
      timeOut: this.state.timeOut,
      extendedTimeOut: this.state.extendedTimeOut,
      showEasing: this.state.showEasing,
      hideEasing: this.state.hideEasing,
      showMethod: this.state.showMethod,
      hideMethod: this.state.hideMethod,
      showDuration: this.state.showDuration,
      hideDuration: this.state.hideDuration,
    };

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }
  handleSubmit(e) {

    e.preventDefault();
    console.log(e.target.projectName.value)
    console.log(e.target.addresslineOne.value)
    console.log(e.target.addresslineTwo.value)
    console.log(e.target.postcode.value)
    console.log(e.target.phonePrimary.value)
    console.log(e.target.phoneSecondary.value)
    console.log(e.target.projectEmail.value)
    console.log(this.state.project_country_id);
    console.log(this.state.project_city_id);
    console.log(e.target.projectWebsite.value);
    console.log(e.target.projectPrefix.value);
    console.log(e.target.projectLatefee.value);

    const formData = new FormData();
    formData.append('test', 'value')

    // axios.patch(baseURL + "/api/projects/" + localStorage.getItem("current_project_id"), formData, config)
    // .then((response) => {
    //   var toastTitle = "Edit Project";
    //   var toastMessage = "Project Edit Successfully";
    //   var toastType = "success";
    //   this.showToast(toastTitle, toastMessage, toastType);
    //   // window.location.replace('/project/edit-sizes')            
    //     console.log(response);
    //   })
    //   .catch((error) => {
    //     var toastTitle = "Edit Project";
    //     var toastMessage = "Project Not Edit Successfully";
    //     var toastType = "error";
    //     this.showToast(toastTitle, toastMessage, toastType);
    //     console.log(error);
    //   });
    if (!this.state.project_name || !this.state.project_address_line_1 || !this.state.project_phone_primary || !this.state.project_postcode || !this.state.project_email || !this.state.project_late_fee) {
      let missingFields = '';
      if (!this.state.project_name) {
        missingFields += '<li>Project Name</li>';
      }
      if (!this.state.project_address_line_1) {
        missingFields += '<li>Address Line 1</li>';
      }
      if (!this.state.project_phone_primary) {
        missingFields += '<li>Primary Phone</li>';
      }
      if (!this.state.project_postcode) {
        missingFields += '<li>Postcode</li>';
      }
      if (!this.state.project_email) {
        missingFields += '<li>Email</li>';
      }
      if (!this.state.project_late_fee) {
        missingFields += '<li>Late fee</li>';
      }
      this.showToast("Failed", "These fields are missing. Please fill the required fields: " + missingFields, "error");
      return false;
    }
    axios.post(baseURL + "/api/projectupdate/" + localStorage.getItem("current_project_id"), {
      "name": e.target.projectName.value,
      "project_type_id": this.state.project_project_type_id,
      "organisation_id": this.state.project_organisation_id,
      "address_line_1": e.target.addresslineOne.value,
      "address_line_2": e.target.addresslineTwo.value,
      "city_id": this.state.project_city_id,
      "country_id": this.state.project_country_id,
      "post_code": e.target.postcode.value,
      "phone_primary": e.target.phonePrimary.value,
      "phone_secondary": e.target.phoneSecondary.value,
      "email": e.target.projectEmail.value,
      "website": e.target.projectWebsite.value,
      "late_fee": e.target.projectLatefee.value,
      "prefix": e.target.projectPrefix.value,
      "logo": this.state.project_logo,
      "icon": this.state.project_icon,
      "picture": this.state.project_picture,
      "has_phases": this.state.hasPhasesBlocks == true ? 1 : 0,
      "has_blocks": this.state.hasPhasesBlocks == true ? 1 : 0,
      "has_floors": this.state.hasFloors == true ? 1 : 0,
      "has_sizes": this.state.hasSizes == true ? 1 : 0
    }, config)
      .then((response) => {
        var toastTitle = "Success";
        var toastMessage = "Project Edit Successfully";
        var toastType = "success";
        this.showToast(toastTitle, toastMessage, toastType);
        this.setState({ redirect: "/project/edit-sizes" });
        window.localStorage.setItem("has_phase_blocks", this.state.hasPhasesBlocks == true ? 1 : 0);
        window.localStorage.setItem("has_sizes", this.state.hasSizes == true ? 1 : 0);
        window.localStorage.setItem("has_floors", this.state.hasFloors == true ? 1 : 0);
      })
      .catch((error) => {
        if (error.response.data.message) {
          var toastMessage = error.response.data.message;
        } else {
          var toastMessage = "Project Not Edited Successfully";
        }
        var toastTitle = "Failed";
        var toastType = "error";
        this.showToast(toastTitle, toastMessage, toastType);
      });
  }

  //for change tooltip display propery
  onChangeValidation(fieldName, value) {
    const modifiedV = { ...this.state.validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    this.setState({ validation: modifiedV });
  }

  render() {
    //meta title
    document.title = "Edit Project";
    const { validation } = this.state;
    const { selectedCustomerTitle } = this.state;
    const { selectedGroupCountry } = this.state;
    const { selectedGroupCity } = this.state;
    const { selectedGroupProjectType } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Project" breadcrumbItem="Edit Project" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Formik>
                      {({ errors, status, touched }) => (
                        <Form className="needs-validation" onSubmit={this.handleSubmit}>
                          <Row>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="projectName">
                                  Project*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="projectName"
                                    placeholder="Project Name"
                                    type="text"
                                    // value={this.state.customerData.first_name}
                                    value={this.state.project_name}
                                    // onChange={this.handleChange}
                                    onChange={(e) => this.setState({ project_name: e.target.value })}
                                    className={
                                      "form-control" +
                                      (errors.projectName && touched.projectName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="projectName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectCountry">Project Type</Label>
                                <div className="col-md-10">
                                  <Select
                                    value={{ label: this.state.project_project_type_title, value: 0 }}
                                    onChange={this.handleSelectGroupProjectType}
                                    options={this.state.optionProjectType}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="addresslineOne">
                                  Address Line 1*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="addresslineOne"
                                    placeholder="Address Line 1"
                                    type="text"
                                    onChange={(e) => this.setState({ project_address_line_1: e.target.value })}
                                    value={this.state.project_address_line_1}
                                    className={
                                      "form-control" +
                                      (errors.addresslineOne && touched.addresslineOne
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="addresslineOne"
                                    component="div"
                                    className="invalid-feedback"
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="addresslineTwo">
                                  Address Line 2
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="addresslineTwo"
                                    placeholder="Address Line 2"
                                    type="text"
                                    onChange={(e) => this.setState({ project_address_line_2: e.target.value })}
                                    value={this.state.project_address_line_2}
                                    className={
                                      "form-control"
                                    }
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="phonePrimary">
                                  Primary Phone*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="phonePrimary"
                                    placeholder="Phone Primary"
                                    type="text"
                                    onChange={(e) => this.setState({ project_phone_primary: e.target.value })}
                                    value={this.state.project_phone_primary}
                                    className={
                                      "form-control" +
                                      (errors.phonePrimary && touched.phonePrimary
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="phonePrimary"
                                    component="div"
                                    className="invalid-feedback"
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="phoneSecondary">
                                  Secondary Phone
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="phoneSecondary"
                                    placeholder="Phone Secondary"
                                    type="text"
                                    onChange={(e) => this.setState({ project_phone_secondary: e.target.value })}
                                    value={this.state.project_phone_secondary}
                                    className={
                                      "form-control"
                                    }
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectCountry">Country*</Label>
                                <div className="col-md-10">
                                  <Select
                                    value={{ label: this.state.project_country_title, value: 0 }}
                                    // value={selectedGroupCountry}
                                    onChange={this.handleSelectGroupCountry}
                                    options={this.state.optionGroupCountry}
                                    classNamePrefix="select2-selection"
                                  // className={
                                  //   "" +
                                  //   (errors.selectCountry && touched.selectCountry
                                  //     ? " is-invalid"
                                  //     : "")
                                  // }
                                  />
                                  {/* <ErrorMessage
                                    name="selectCountry"
                                    component="div"
                                    className="invalid-feedback"
                                  /> */}
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectCity">City*</Label>
                                <div className="col-md-10">
                                  <Select
                                    value={{ label: this.state.project_city_title, value: 0 }}
                                    onChange={this.handleSelectGroupCity}
                                    options={this.state.optionGroupCity}
                                    classNamePrefix="select2-selection"
                                  // className={
                                  //   "" +
                                  //   (errors.selectCity && touched.selectCity
                                  //     ? " is-invalid"
                                  //     : "")
                                  // }
                                  />
                                  {/* <ErrorMessage
                                    name="selectCity"
                                    component="div"
                                    className="invalid-feedback"
                                  /> */}
                                </div>
                              </div>
                            </Col>

                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="postcode">Postcode*</Label>
                                <div className="col-md-10">
                                  <Field
                                    name="postcode"
                                    placeholder="Postcode"
                                    type="text"
                                    onChange={(e) => this.setState({ project_postcode: e.target.value })}
                                    value={this.state.project_postcode}
                                    className={
                                      "form-control" +
                                      (errors.postcode && touched.postcode
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="postcode"
                                    component="div"
                                    className="invalid-feedback"
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="projectEmail">
                                  Email*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="projectEmail"
                                    placeholder="Project Email"
                                    type="text"
                                    onChange={(e) => this.setState({ project_email: e.target.value })}
                                    value={this.state.project_email}
                                    className={
                                      "form-control" +
                                      (errors.projectEmail && touched.projectEmail
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="projectEmail"
                                    component="div"
                                    className="invalid-feedback"
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="projectWebsite">
                                  Website
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="projectWebsite"
                                    placeholder="Website"
                                    type="text"
                                    value={this.state.project_website}
                                    onChange={(e) => this.setState({ project_website: e.target.value })}
                                    className={
                                      "form-control"
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="projectPrefix">
                                  Prefix
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="projectPrefix"
                                    placeholder="Prefix"
                                    type="text"
                                    value={this.state.project_prefix}
                                    onChange={(e) => this.setState({ project_prefix: e.target.value })}
                                    className={
                                      "form-control"
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="projectLatefee">
                                  Late Fee*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="projectLatefee"
                                    placeholder="Late Fee"
                                    type="text"
                                    value={this.state.project_late_fee}
                                    onChange={(e) => this.setState({ project_late_fee: e.target.value })}
                                    className={
                                      "form-control" +
                                      (errors.projectLatefee && touched.projectLatefee
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="projectLatefee"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="logo">
                                  Logo
                                </Label>
                                <div className="col-md-10">
                                  <Input className="form-control form-control" type="file" id="inputLogo" accept="image/*"
                                    onChange={this.handleImageChangeLogo} />
                                </div>
                              </div>
                            </Col>
                            {this.state.show_logo == null ?
                              <Col md="12">
                                <div className="mb-3 row d-flex">
                                  <Label className="col-md-2 col-form-label" htmlFor="logo">
                                    Existing Logo
                                  </Label>
                                  <div className="col-md-10 mt-2">
                                    No Logo Exists
                                  </div>
                                </div>
                              </Col>
                              :
                              <Col md="12">
                                <div className="mb-3 row d-flex">
                                  <Label className="col-md-2 col-form-label" htmlFor="logo">
                                    Existing Logo
                                  </Label>
                                  <div className="col-md-10">
                                    <img className="rounded me-2" alt="200x200" width="200" src={this.state.show_logo} data-holder-rendered="true" />
                                  </div>
                                </div>
                              </Col>
                            }
                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="icon">
                                  Icon
                                </Label>
                                <div className="col-md-10">
                                  <Input className="form-control form-control" type="file" id="inputIcon" accept="image/*"
                                    onChange={this.handleImageChangeIcon} />
                                </div>
                              </div>
                            </Col>
                            {this.state.show_icon == null ?
                              <Col md="12">
                                <div className="mb-3 row d-flex">
                                  <Label className="col-md-2 col-form-label" htmlFor="icon">
                                    Existing Icon
                                  </Label>
                                  <div className="col-md-10 mt-2">
                                    No Icon Exists
                                  </div>
                                </div>
                              </Col>
                              :
                              <Col md="12">
                                <div className="mb-3 row d-flex">
                                  <Label className="col-md-2 col-form-label" htmlFor="icon">
                                    Existing Icon
                                  </Label>
                                  <div className="col-md-10">
                                    <img className="rounded me-2" alt="200x200" width="200" src={this.state.show_icon} data-holder-rendered="true" />
                                  </div>
                                </div>
                              </Col>
                            }
                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="picture">
                                  Picture
                                </Label>
                                <div className="col-md-10">
                                  <Input className="form-control form-control" type="file" id="inputPicture" accept="image/*"
                                    onChange={this.handleImageChangePicture} />
                                </div>
                              </div>
                            </Col>
                            {this.state.show_picture == null ?

                              <Col md="12">
                                <div className="mb-3 row d-flex">
                                  <Label className="col-md-2 col-form-label" htmlFor="picture">
                                    Existing Picture
                                  </Label>
                                  <div className="col-md-10 mt-2">
                                    No Picture Exists
                                  </div>
                                </div>
                              </Col>
                              :
                              <Col md="12">
                                <div className="mb-3 row d-flex">
                                  <Label className="col-md-2 col-form-label" htmlFor="picture">
                                    Existing Picture
                                  </Label>
                                  <div className="col-md-10">
                                    <img className="rounded me-2" alt="200x200" width="200" src={this.state.show_picture} data-holder-rendered="true" />
                                  </div>
                                </div>
                              </Col>
                            }
                            <div className="row mb-3 d-flex">
                              <Label className="col-md-2 col-form-label">
                                Specifications
                              </Label>

                              <div className="col-md-10 form-check d-flex">
                                <div className="col-md-2">
                                  <Field
                                    className="ms-0 form-check-input"
                                    type="checkbox"
                                    name="hasSizes"
                                    checked={this.state.hasSizes}
                                    onChange={this.handleCheckboxChange}
                                    disabled
                                  />
                                  <label
                                    className="ms-1 form-check-label"
                                    htmlFor="hasSizes"
                                  >
                                    Has Sizes
                                  </label>
                                </div>
                                <div className="col-md-3">
                                  <Field
                                    className={"form-check-input"}
                                    type="checkbox"
                                    name="hasPhasesBlocks"
                                    checked={this.state.hasPhasesBlocks}
                                    onChange={this.handleCheckboxChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="hasPhasesBlocks"
                                  >
                                    Has Phases / Blocks
                                  </label>
                                </div>
                                <div className="col-md-3">
                                  <Field
                                    className={"form-check-input"}
                                    type="checkbox"
                                    name="hasFloors"
                                    checked={this.state.hasFloors}
                                    onChange={this.handleCheckboxChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="hasFloors"
                                  >
                                    Has Floors
                                  </label>
                                </div>
                              </div>
                            </div>
                          </Row>
                          <Button color="primary" type="submit" className="float-end" >
                            Update Project
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default EditProject;
