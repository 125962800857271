import React, { Component } from "react";
import { Row, Col, Card, CardBody, UncontrolledTooltip } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { isEmpty, size } from "lodash";
import { get_user_permissions } from "../../../../helpers/custom_helper"

import moment from "moment";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { get_sale_receipt_documents, download_documents } from '../../api/documentsApi';
import { get_current_sale } from '../../../Sales/api/salesApi';
import { numberWithCommas } from "pages/UI/numeric-helper";

var saleEdit, saleView, saleCreate, saleDelete;
class ListReceiptDocuments extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            page: 1,
            sizePerPage: 10,
            listData: [],
            listdata: "",
            recordDeleteID: null,
            redirect: null,
            currentSaleInfo: {},
            currency_name: '',
        };
        this.onClickDownload = this.onClickDownload.bind(this);
    }
    componentDidMount() {
        saleEdit = get_user_permissions('sales-edit');
        saleView = get_user_permissions('sales-view');
        saleCreate = get_user_permissions('sales-create');
        saleDelete = get_user_permissions('sales-delete');

        Promise.all([get_current_sale(localStorage.getItem("payments_sale_id"))]).then((values) => {
            this.setState({ currentSaleInfo: values[0] });
        });
        Promise.all([get_sale_receipt_documents(localStorage.getItem("payments_sale_id"))]).then((values) => {
            this.setState({ listData: values[0] });
            this.setState({ currency_name: values[0][0].currency_name });
        });

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { listData } = this.props;
        if (!isEmpty(listData) && size(prevProps.listData) !== size(listData)) {
            this.setState({ listData: {}, isEdit: false });
        }
    }
    onPaginationPageChange = page => {
        if (
            this.node &&
            this.node.current &&
            this.node.current.props &&
            this.node.current.props.pagination &&
            this.node.current.props.pagination.options
        ) {
            this.node.current.props.pagination.options.onPageChange(page);
        }
    };

    onClickDownload = listdata => {
        Promise.all([download_documents(listdata.receipt_document)]).then((values) => {
            const contentType = values[0].split(';')[0].split(':')[1];
            const base64Content = values[0].split(',')[1];
            const byteCharacters = atob(base64Content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const extension = values[0].split(';')[0].split('/')[1];
            const blob = new Blob([byteArray], { type: contentType });
            const fileName = `${listdata.id}-R.${extension}`;

            setTimeout(() => {
                if (contentType.startsWith('image/png') || contentType.startsWith('image/jpg') || contentType.startsWith('image/jpeg')) {
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.target = '_blank';
                    a.rel = 'noopener noreferrer';
                    a.click();
                } else {
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    a.target = '_blank';
                    a.rel = 'noopener noreferrer';
                    a.click();
                }
                showToast("Success", "Download Success.", "success")
            }, 100);
        }).catch((error) => {
            showToast("Failed", "Download Failed!", "error")
        });
    };

    render() {
        const { currency_name } = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        const columns = [
            {
                dataField: "receipt_date",
                text: "Receipt Date",
                sort: true,
            },
            {
                dataField: "received_amount",
                text: `${'Received Amount'} (${currency_name ? currency_name : ''})`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <div className="text-center">
                        {numberWithCommas(listdata.received_amount)}
                    </div>
                ),
            },
            {
                dataField: "mode_of_payment",
                text: "Mode Of Payments",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <div className="text-center">
                        {listdata.mode_of_payment}
                    </div>
                ),
            },
            {
                dataField: "updated_at",
                text: "Generated At",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {moment(listdata.updated_at).format('DD/MM/YYYY h:mma')}
                    </>

                ),
            },
            {
                dataField: "action",
                isDummyField: true,
                text: "Action",
                formatter: (cellContent, listdata) => (
                    <>
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li>
                                <Link
                                    to="#"
                                    onClick={() => this.onClickDownload(listdata)}
                                    className="btn btn-sm btn-soft-info" >
                                    <i className="mdi mdi-file-download-outline" id="downloadDocument" />
                                    <UncontrolledTooltip placement="top" target="downloadDocument">
                                        Download Document
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    </>
                ),
            },

        ];

        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];

        const pageOptions = {
            sizePerPage: 10,
            custom: true,
        };

        // Select All Button operation
        const selectRow = {
            mode: "checkbox",
        };

        const { SearchBar } = Search;

        //meta title
        document.title = "Documents of a Sale";
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                console.log(row);
                this.setState({ recordDeleteID: row.id });

            }
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Financial Documents" breadcrumbItem="List Documents" parentLink="/payments/documents" />
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody className="border-bottom">
                                        <h5 className="mb-3 card-title flex-grow-1">Sale Details</h5>
                                        <div className="d-flex">
                                            <div className="flex-grow-1 ms-3">
                                                <h5 className="fw-semibold">Property Name: {this.state.currentSaleInfo.property_name}</h5>
                                                <ul className="list-unstyled gap-2 mb-0">
                                                    <li>
                                                        <label>Project Name:&nbsp;</label><span className="text-muted">{this.state.currentSaleInfo.project_name}</span>
                                                    </li>
                                                    <li>
                                                        <label>Sale Date:&nbsp;</label><span className="text-muted">{this.state.currentSaleInfo.sale_date}</span>
                                                    </li>
                                                    <li>
                                                        <label>Status:&nbsp;</label><span className="text-muted">{this.state.currentSaleInfo.status}</span>
                                                    </li>
                                                    <li>
                                                        <label>Price:&nbsp;</label><span className="text-muted">{this.state.currentSaleInfo.currency_symbol}&nbsp;&nbsp;{numberWithCommas(this.state.currentSaleInfo.sale_total)}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">List Receipt Documents</h5>
                                            </div>
                                        </CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField="id"
                                            columns={columns}
                                            data={this.state.listData}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    columns={columns}
                                                    data={this.state.listData}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col md="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"id"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            selectRow={selectRow}
                                                                            rowEvents={rowEvents}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            ref={this.node}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default ListReceiptDocuments;
