import axios from "axios";
// import { Link, useHistory } from "react-router-dom";  
import { withRouter } from 'react-router-dom'   

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
const baseURL = process.env.REACT_APP_API_URL;

export const get_all_integrations = async () => {
    let response = await axios.get(baseURL + "/api/integrations", config);
    return response.data.data;
}

export const delete_integration = async (integration_id) => {
    let response = await axios.delete(baseURL + "/api/integrations/"+integration_id, config);
    return response.data.data;
}

export const get_current_integration = async (integration_id) => {
    let current_integration_response = await axios.get(baseURL + "/api/integrations/"+integration_id, config);
    return current_integration_response.data.data;
}
