import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";
import { showToast } from '../../UI/ui-toast'

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};

import constant from '../../../constants.json';
const baseURL = process.env.REACT_APP_API_URL;

class AddSalesAgent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      optionGroupCountry: [],
      optionGroupCity: [],
      redirect: null,
      agent_picture: null,
      titlesArray: constant.titles_list,
      id: "",
      name: '',
      titleSelected: -1,
      pictureSelected: -1,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
  }

  handleSelectedAgentTitle = selectedAgentTitle => {
    this.setState({ titleSelected: 1 })
    this.setState({ selectedAgentTitle })
  }
  checkValidation = () => {
    if (this.state.titleSelected != 1) {
      this.setState({ titleSelected: 0 })
    }
    // if (this.state.pictureSelected != 1) {
    //   this.setState({ pictureSelected: 0 })
    // }
  }
  handleImageChangePicture = (e) => {
    this.setState({ pictureSelected: 1 })
    if (e.target.files && e.target.files[0]) {
      this.setState({ agent_picture: e.target.files[0] });
    }
  };

  handleSubmit(e) {
    e.preventDefault();

    const modifiedV = { ...this.state.validation };
    var fnm = document.getElementById("validationTooltip01").value;
    var lnm = document.getElementById("validationTooltip02").value;
    var unm = document.getElementById("validationTooltipUsername").value;
    var stateV = document.getElementById("validationTooltip04").value;

    if (fnm === "") {
      modifiedV["fnm"] = false;
    } else {
      modifiedV["fnm"] = true;
    }

    if (lnm === "") {
      modifiedV["lnm"] = false;
    } else {
      modifiedV["lnm"] = true;
    }

    if (unm === "") {
      modifiedV["unm"] = false;
    } else {
      modifiedV["unm"] = true;
    }

    if (stateV === "") {
      modifiedV["stateV"] = false;
    } else {
      modifiedV["stateV"] = true;
    }

    this.setState({ validation: modifiedV });
  }

  //for change tooltip display propery
  onChangeValidation(fieldName, value) {
    const modifiedV = { ...this.state.validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    this.setState({ validation: modifiedV });
  }

  render() {
    //meta title
    document.title = "Add Sales Agent";
    const { validation } = this.state;
    const { selectedAgentTitle } = this.state;
    const { selectedGroupCountry } = this.state;
    const { selectedGroupCity } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Sales Agent" breadcrumbItem="Add Sales Agent" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        agentFirstName: (this.state && this.state.agentFirstName) || "",
                        agentLastName: (this.state && this.state.agentLastName) || "",
                        mobileNumber: (this.state && this.state.mobileNumber) || "",
                        email: (this.state && this.state.email) || "",
                      }}
                      validationSchema={Yup.object().shape({
                        agentFirstName: Yup.string().required(
                          "Sale Agent first name is required!"
                        ),
                        agentLastName: Yup.string().required(
                          "Sale Agent last name is required!"
                        ),
                        mobileNumber: Yup.string().required(
                          "Please Enter Your Mobile Number"
                        ),
                        email: Yup.string().required("Please Enter Your Email"),
                      })}
                      onSubmit={values => {
                        console.log(this.state.titleSelected);
                        if (this.state.titleSelected == 0) {
                          showToast("Failed", "Title not selected, select first.", "error")
                        } else {
                          axios.post(baseURL + "/api/sales_agents", {

                            "title": selectedAgentTitle.value,
                            "first_name": values.agentFirstName,
                            "middle_name": values.agentMidName,
                            "last_name": values.agentLastName,
                            "telephone": values.telephone,
                            "mobile": values.mobileNumber,
                            "email": values.email,
                            "picture": this.state.agent_picture,
                            "organisation_id": localStorage.getItem("organisation_id")
                          }, config)
                            .then((response) => {
                              showToast("Success", "The sales agent "+ response.data.data.full_name + " has been added successfully.", "success");
                              this.setState({ redirect: "/salesagents/list" });
                              console.log(response);
                            })
                            .catch((error) => {
                              showToast("Failed", "Sales Agent Not Added", "error")
                              console.log(error);
                            });
                        }

                      }}
                    >
                      {({ errors, status, touched }) => (
                        <Form className="needs-validation">
                          <Row>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="agentTitle">Title*</Label>
                                <div className="col-md-10">
                                  <Select
                                    value={selectedAgentTitle}
                                    onChange={this.handleSelectedAgentTitle}
                                    options={this.state.titlesArray}
                                    classNamePrefix="select2-selection"
                                  // className={
                                  //   "" +
                                  //   (errors.agentTitle && touched.agentTitle
                                  //     ? " is-invalid"
                                  //     : "")
                                  // }
                                  />
                                  {this.state.titleSelected == 0 ? <>
                                    <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Title is not selected, please select first</p>
                                  </> : <></>
                                  }
                                  { /*<ErrorMessage
                                    name="agentTitle"
                                    component="div"
                                    className="invalid-feedback"
                                  />*/ }
                                </div>
                              </div>
                            </Col>

                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="agentFirstName">
                                  First Name*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="agentFirstName"
                                    placeholder="First Name"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.agentFirstName && touched.agentFirstName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="agentFirstName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>

                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="">
                                  Middle Name
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="agentMidName"
                                    placeholder="Middle Name"
                                    type="text"
                                    className={
                                      "form-control"
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="agentLastName">
                                  Last Name*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="agentLastName"
                                    placeholder="Last Name"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.agentLastName && touched.agentLastName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="agentLastName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>

                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="telephone">
                                  Telephone
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="telephone"
                                    placeholder="Telephone"
                                    type="text"
                                    className={
                                      "form-control"
                                    }
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="mobileNumber">
                                  Mobile Number*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="mobileNumber"
                                    placeholder="Mobile Number"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.mobileNumber && touched.mobileNumber
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="mobileNumber"
                                    component="div"
                                    className="invalid-feedback"
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="email">
                                  Email*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="email"
                                    placeholder="Email"
                                    type="email"
                                    className={
                                      "form-control" +
                                      (errors.email && touched.email
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="invalid-feedback"
                                  />

                                </div>
                              </FormGroup>
                            </Col>

                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="picture">
                                  Picture
                                </Label>
                                <div className="col-md-10">
                                  <Input className="form-control form-control" type="file" id="inputPicture" accept="image/*"
                                    onChange={this.handleImageChangePicture} />
                                  {/* {this.state.pictureSelected == 0 ? <>
                                    <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Picture is not selected, please select first</p>
                                  </> : <></>
                                  } */}
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Button color="primary" type="submit" onClick={this.checkValidation} className="float-end">
                            Add Sales Agent
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default AddSalesAgent;
