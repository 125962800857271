import {
    GET_PROJECT_LIST,
    GET_PROJECT_LIST_FAIL,
    GET_PROJECT_LIST_SUCCESS,
    ADD_NEW_PROJECT_LIST,
    ADD_PROJECT_LIST_SUCCESS,
    ADD_PROJECT_LIST_FAIL,
    UPDATE_PROJECT_LIST,
    UPDATE_PROJECT_LIST_SUCCESS,
    UPDATE_PROJECT_LIST_FAIL,
    DELETE_PROJECT_LIST,
    DELETE_PROJECT_LIST_SUCCESS,
    DELETE_PROJECT_LIST_FAIL,
    GET_APPLY_PROJECT,
    GET_APPLY_PROJECT_SUCCESS,
    GET_APPLY_PROJECT_FAIL,
    DELETE_APPLY_PROJECT,
    DELETE_APPLY_PROJECT_SUCCESS,
    DELETE_APPLY_PROJECT_FAIL,
} from "./actionTypes";

export const getProjectList = () => ({
    type: GET_PROJECT_LIST,
})

export const getProjectListSuccess = projects => ({
    type: GET_PROJECT_LIST_SUCCESS,
    payload: projects,
})

export const getProjectListFail = error => ({
    type: GET_PROJECT_LIST_FAIL,
    payload: error,
})

export const addNewProjectList = project => ({
    type: ADD_NEW_PROJECT_LIST,
    payload: project,
})

export const addProjectListSuccess = project => ({
    type: ADD_PROJECT_LIST_SUCCESS,
    payload: project,
})

export const addProjectListFail = error => ({
    type: ADD_PROJECT_LIST_FAIL,
    payload: error,
})

export const updateProjectList = project => ({
    type: UPDATE_PROJECT_LIST,
    payload: project,
})

export const updateProjectListSuccess = project => ({
    type: UPDATE_PROJECT_LIST_SUCCESS,
    payload: project,
})

export const updateProjectListFail = error => ({
    type: UPDATE_PROJECT_LIST_FAIL,
    payload: error,
})

export const deleteProjectList = project => ({
    type: DELETE_PROJECT_LIST,
    payload: project,
})

export const deleteProjectListSuccess = project => ({
    type: DELETE_PROJECT_LIST_SUCCESS,
    payload: project,
})

export const deleteProjectListFail = error => ({
    type: DELETE_PROJECT_LIST_FAIL,
    payload: error,
})

export const getApplyProject = () => ({
    type: GET_APPLY_PROJECT,
})

export const getApplyProjectSuccess = data => ({
    type: GET_APPLY_PROJECT_SUCCESS,
    payload: data
})

export const getApplyProjectFail = error => ({
    type: GET_APPLY_PROJECT_FAIL,
    payload: error
})

export const deleteApplyProject = data => ({
    type: DELETE_APPLY_PROJECT,
    payload: data
})

export const deleteApplyProjectSuccess = data => ({
    type: DELETE_APPLY_PROJECT_SUCCESS,
    payload: data
})

export const deleteApplyProjectFail = error => ({
    type: DELETE_APPLY_PROJECT_FAIL,
    payload: error
})