import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import axios from "axios";
import { showToast } from '../../UI/ui-toast'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import moment from "moment";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { get_current_customer, get_all_nominees } from '../api/customersApi';
const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};

import constant from '../../../constants.json';
import default_avatar from "../../../assets/images/avatar_01.png";
const baseURL = process.env.REACT_APP_API_URL;

class ViewCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      optionGroupCountry: [],
      optionGroupCity: [],
      optionGroupIdentity: [],
      optionGroupNationalities: [],
      customer_details: {},
      c_first_name: '',
      c_last_name: '',
      c_middle_name: '',
      c_telephone_home: '',
      c_mobile_number: '',
      c_telephone_office: '',
      c_address_line_1: '',
      c_address_line_2: '',
      c_primary_email: '',
      c_secondary_email: '',
      c_father_name: '',
      c_husband_name: '',
      c_identity_number: '',
      c_nationality: '',
      c_postcode: '',
      c_country_id: '',
      c_city_id: '',
      c_title: '',
      c_city_title: '',
      c_country_title: '',
      c_picture: null,
      c_picture_show: null,
      redirect: null,
      titlesArray: constant.titles_list,
      id: "",
      name: '',
      nomineesData: null,
      nomineesCount: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
  }

  handleChange(event) {
  }

  async getCountriesList() {
    const countriesResponse = await axios.get(baseURL + "/api/countries-list", config);
    console.log(countriesResponse.data);
    const countriesListData = countriesResponse.data.data
    
    console.log(countriesListData);

    const options = countriesListData.map(d => ({
      "value": d,
      "label": d
    }));

    console.log(options);
    this.setState({ optionGroupCountry: options })
  }

  async getOptionsCountry() {

    /*
      This function is replaced by getCountriesList()
     */
    
    // const res = await axios.get(baseURL + "/api/countries-listing", config);
    // const data = res.data.data
    // // console.log(data);

    // const options = data.map(d => ({
    //   "value": d.id,
    //   "label": d.title
    // }))
    // this.setState({ optionGroupCountry: options })
  }
  async getOptionsCity(country_id) {
    const res = await axios.get(baseURL + "/api/country-cities/" + country_id, config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d.id,
      "label": d.title
    }))
    this.setState({ optionGroupCity: options })
  }
  async getOptionsNationalities() {
    const res = await axios.get(baseURL + "/api/nationalities", config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d.id,
      "label": d.name
    }))
    this.setState({ optionGroupNationalities: options })
  }
  async getOptionsIdentity() {
    const res = await axios.get(baseURL + "/api/identity-types", config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d,
      "label": d
    }))
    this.setState({ optionGroupIdentity: options })
  }
  componentDidMount() {
    Promise.all([get_current_customer(localStorage.getItem("current_customer_id"))]).then((values) => {
      console.log(values[0]);
      this.setState({ customer_details: values[0] });
      // this.setState({ c_first_name: values[0].first_name });
      // this.setState({ c_last_name: values[0].last_name });
      // this.setState({ c_middle_name: values[0].middle_name });
      // this.setState({ c_mobile_number: values[0].mobile_number });
      // this.setState({ c_father_name: values[0].father_name });
      // this.setState({ c_telephone_home: values[0].telephone_home });
      // this.setState({ c_primary_email: values[0].primary_email });
      // this.setState({ c_secondary_email: values[0].secondary_email });
      // this.setState({ c_telephone_office: values[0].telephone_office });
      // this.setState({ c_address_line_1: values[0].address_line_1 });
      // this.setState({ c_address_line_2: values[0].address_line_2 });
      // this.setState({ c_nationality: values[0].nationality });
      // this.setState({ c_identity_type: values[0].identity_type });
      // this.setState({ c_identity_number: values[0].identity_number });
      // this.setState({ c_postcode: values[0].postcode });
      // this.setState({ c_husband_name: values[0].husband_name });
      // this.setState({ c_title: values[0].title });
      // this.setState({ c_city_id: values[0].city_id });
      // this.setState({ c_country_id: values[0].country_id });
      // this.setState({ c_country_title: values[0].country });
      // this.setState({ c_city_title: values[0].city });
      // this.setState({ c_picture_show: values[0].picture_path });
    });
    this.getCountriesList()
    // this.getOptionsCountry()
    this.getOptionsNationalities()
    this.getOptionsIdentity();

    Promise.all([get_all_nominees(localStorage.getItem("current_customer_id"))]).then((values) => {
      var nData = values[0];
      console.log(values);
      console.log(values[0][0]);
      console.log("nData", nData);
      this.setState({ nomineesData: nData });
      this.setState({ nomineesCount: nData.length });

      // nData.map((data, index) => (
      // )
      // const testData = nData.map(d => ([d]));
      // console.log(testData);
      // var nomineesArray = [];
      // var nomineesObjs = {};
      // var nCounter = 0;
      // testData.forEach((rowData, c_index) => {
      //   console.log(rowData[0]);
      //   console.log(rowData[0].full_name);
      //   nomineesArray.push(rowData[0]);
      //   nomineesObjs[nCounter] = rowData[0];
      //   nCounter++;
      // });

      // console.log(nomineesObjs);

      // nomineesObjs.map((nominee, i) => (

      //     console.log(nominee)
      //   )
      // )
      
      // nomineesObjs.forEach((value, key) => {
      //    console.log(value);
      // });
      // this.setState({ nomineesData: nomineesArray });
    });
  }

  handleSelectedCustomerTitle = selectedCustomerTitle => {
    this.setState({ c_title: selectedCustomerTitle.value });
    this.setState({ selectedCustomerTitle })
    console.log(selectedCustomerTitle);
  }
  handleSelectGroupCountry = selectedGroupCountry => {
    this.setState({ selectedGroupCountry })
    // this.getOptionsCity(selectedGroupCountry.value)
    // this.setState({ c_city_title: '' })
    this.setState({ c_country_title: selectedGroupCountry.label });
    this.setState({ c_country_id: selectedGroupCountry.value });
    console.log(selectedGroupCountry);
  }
  handleSelectGroupCity = selectedGroupCity => {
    this.setState({ c_city_title: selectedGroupCity.label });
    this.setState({ c_city_id: selectedGroupCity.value });
    this.setState({ selectedGroupCity })
    console.log(selectedGroupCity);
  }
  handleSelectGroupNationality = selectedGroupNationality => {
    this.setState({ c_nationality: selectedGroupNationality.label });
    this.setState({ selectedGroupNationality })
    console.log(selectedGroupNationality);
  }
  handleSelectGroupIdentity = selectedGroupIdentity => {
    this.setState({ c_identity_type: selectedGroupIdentity.label });
    this.setState({ selectedGroupIdentity })
    console.log(selectedGroupIdentity);
  }
  handleImageChangePicture = (e) => {
    if (e.target.files && e.target.files[0]) {
      console.log('Picture');
      console.log(e.target.files[0]);
      this.setState({ c_picture: e.target.files[0] });
    }
  };

  handleSubmit(e) {

    e.preventDefault();
    
  }

  //for change tooltip display propery
  onChangeValidation(fieldName, value) {
    const modifiedV = { ...this.state.validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    this.setState({ validation: modifiedV });
  }

  render() {
    //meta title
    document.title = "Customer Details";
    const { validation } = this.state;
    const { selectedCustomerTitle } = this.state;
    const { selectedGroupCountry } = this.state;
    const { selectedGroupCity } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Customers" breadcrumbItem="View Customer" parentLink="/customers/list" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody className="border-bottom">
                      <div className="d-flex">
                          <img src={this.state.customer_details.picture_path == null ? default_avatar : this.state.customer_details.picture_path} alt="" height="50" />
                          <div className="flex-grow-1 ms-3">
                              <h5 className="fw-semibold">{this.state.customer_details.full_name}</h5>
                              <ul className="list-unstyled hstack gap-2 mb-0">
                                  <li>
                                      <i className="bx bx-map"></i> <span className="text-muted">{this.state.customer_details.city}, {this.state.customer_details.country}</span>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </CardBody>
                  <CardBody>
                    <div className="mt-0">
                      <h5 className="mb-3">Customer Details :</h5>
                      <div className="table-responsive">
                        <Table className="table mb-0 table-bordered">
                          <tbody>
                            <tr >
                              <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                Identity Type
                              </th>
                              <td>{this.state.customer_details.identity_type}</td>
                            </tr>
                            <tr >
                              <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                Identity Number
                              </th>
                              <td>{this.state.customer_details.identity_number}</td>
                            </tr>
                            <tr >
                              <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                Father Name
                              </th>
                              <td>{this.state.customer_details.father_name}</td>
                            </tr>
                            <tr >
                              <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                Husband Name
                              </th>
                              <td>{this.state.customer_details.husband_name}</td>
                            </tr>
                            <tr >
                              <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                Nationality
                              </th>
                              <td>{this.state.customer_details.nationality}</td>
                            </tr>
                            <tr >
                              <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                Address 1
                              </th>
                              <td>{this.state.customer_details.address_line_1}</td>
                            </tr>
                            <tr >
                              <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                Address 2
                              </th>
                              <td>{this.state.customer_details.address_line_2}</td>
                            </tr>
                            <tr >
                              <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                City
                              </th>
                              <td>{this.state.customer_details.city}</td>
                            </tr>
                            <tr >
                              <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                Country
                              </th>
                              <td>{this.state.customer_details.country}</td>
                            </tr>
                            
                            <tr >
                              <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                Postcode
                              </th>
                              <td>{this.state.customer_details.postcode}</td>
                            </tr>
                            <tr >
                              <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                Phone Home
                              </th>
                              <td>{this.state.customer_details.telephone_home}</td>
                            </tr>
                            <tr >
                              <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                Phone Office
                              </th>
                              <td>{this.state.customer_details.telephone_office}</td>
                            </tr>
                            <tr >
                              <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                Mobile Number
                              </th>
                              <td>{this.state.customer_details.mobile_number}</td>
                            </tr>
                            <tr >
                              <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                Primary Email
                              </th>
                              <td>{this.state.customer_details.primary_email}</td>
                            </tr>
                            <tr >
                              <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                Secondary Email
                              </th>
                              <td>{this.state.customer_details.secondary_email}</td>
                            </tr>
                            <tr >
                              <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                Updated By
                              </th>
                              <td>{this.state.customer_details.updated_name}</td>
                            </tr>
                            <tr >
                              <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                Updated at
                              </th>
                              <td>{moment(this.state.customer_details.updated_at).format('DD/MM/YYYY h:mma')}</td>
                            </tr>
                            
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody className="border-bottom" >
                    <div className="mt-1">
                      <h5 className="mb-1 fw-semibold">Nominees :</h5>
                      <h5 className="mb-1 fw-semibold">{this.state.nomineesCount} nominees found.</h5>
                    </div>
                  </CardBody>
                        {
                          this.state.nomineesData?.map(
                            (nominee, i) => (
                      
                        <CardBody className="border-bottom" key={i}>
                          <div className="mt-0">
                            <h5 className="mb-3">Name: {nominee.full_name}</h5>
                            <h5 className="mb-3">Details:</h5>
                              <div className="table-responsive">
                                <Table className="table mb-0 table-striped table-bordered" >
                                  <tbody>
                                    <tr >
                                      <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                        Identity Type
                                      </th>
                                      <td>{nominee.identity_type}</td>
                                    </tr>
                                    <tr >
                                      <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                        Identity Number
                                      </th>
                                      <td>{nominee.identity_number}</td>
                                    </tr>
                                    <tr >
                                      <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                        Nationality
                                      </th>
                                      <td>{nominee.nationality}</td>
                                    </tr>
                                    <tr >
                                      <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                        Address 1
                                      </th>
                                      <td>{nominee.address_line_1}</td>
                                    </tr>
                                    <tr >
                                      <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                        Address 2
                                      </th>
                                      <td>{nominee.address_line_2}</td>
                                    </tr>
                                    <tr >
                                      <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                        Mobile Number
                                      </th>
                                      <td>{nominee.mobile_number}</td>
                                    </tr>
                                    <tr >
                                      <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                        City
                                      </th>
                                      <td>{nominee.city}</td>
                                    </tr>
                                    <tr >
                                      <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                        Country
                                      </th>
                                      <td>{nominee.country}</td>
                                    </tr>
                                    <tr >
                                      <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                        Postcode
                                      </th>
                                      <td>{nominee.postcode}</td>
                                    </tr>
                                    <tr >
                                      <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                        Phone Home
                                      </th>
                                      <td>{nominee.telephone_home}</td>
                                    </tr>
                                    <tr >
                                      <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                        Phone Office
                                      </th>
                                      <td>{nominee.telephone_office}</td>
                                    </tr>
                                    <tr >
                                      <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                        Primary Email
                                      </th>
                                      <td>{nominee.primary_email}</td>
                                    </tr>
                                    <tr >
                                      <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                        Secondary Email
                                      </th>
                                      <td>{nominee.secondary_email}</td>
                                    </tr>
                                    <tr >
                                      <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                        Updated By
                                      </th>
                                      <td>{nominee.updated_name}</td>
                                    </tr>
                                    <tr >
                                      <th scope="row" style={{ width: "400px" }} className={"text-capitalize"} >
                                        Updated at
                                      </th>
                                      <td>{moment(nominee.updated_at).format('DD/MM/YYYY h:mma')}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </CardBody>
                        
                            )
                          )
                        }
                </Card>      
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default ViewCustomer;
