import React, { Component } from "react";
import { Row, Col, Table, Card, CardBody, CardTitle, Button, Label, InputGroup, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, FormGroup } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { isEmpty, size } from "lodash";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import DeleteModal from "../../../components/Common/DeleteModal";
import { get_user_permissions } from "../../../helpers/custom_helper"
import Flatpickr from "react-flatpickr"
import axios from "axios";
import { showToast } from '../../UI/ui-toast'
import Select from "react-select"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
import constants from '../../../constants'
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
const baseURL = process.env.REACT_APP_API_URL;
import { get_payment_against_sale, get_current_sale } from '../../Sales/api/salesApi';
import { get_all_payment_types_for_organisation } from '../../Catalogue/PaymentTypes/api/paymentTypesApi'
import { delete_payment, payment_confirm_mail, payment_invoice_mail, generated_invoice_download, receive_sales_receipt } from '../api/paymentsApi'
var saleEdit, saleView, saleCreate, saleDelete, saleLog, paymentsLog, receiptEmailSend, invoiceEmailSend;
import { numberWithCommas } from "../../UI/numeric-helper"
class ListPayments extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            page: 1,
            sizePerPage: 500,
            listData: [],
            listdata: "",
            viewmodal: false,
            modal: false,
            isOpen: false,
            selectDate: false,
            deleteModal: false,
            recordDeleteID: null,
            country_id_of_city: '',
            currentSaleId: 0,
            currentSaleData: [],
            redirect: null,
            paymentReceivedDate: [new Date()],
            receivedDateValue: [new Date()],
            receivedDate: [new Date()],
            receiptDate: [new Date()],
            paymentID: 0,
            statusArray: [
                {
                    "value": 'Received',
                    "label": 'Received'
                },
                {
                    "value": 'Partially Received',
                    "label": 'Partially Received'
                },
                {
                    "value": 'Due',
                    "label": 'Due'
                }
            ],
            status: '',
            optionArrayPaymentType: [],
            optionArrayModePayments: constants.mode_of_payments,
            late_fee: '',
            total_edit: '',
            amount_edit: '',
            currency_name: ''
        };
        this.handleRecordClick = this.handleRecordClick.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleNewPayment = this.toggleNewPayment.bind(this);
        this.toggleReceivePayment = this.toggleReceivePayment.bind(this);
        this.handleRecordClicks = this.handleRecordClicks.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.onClickActivityLogPayments = this.onClickActivityLogPayments.bind(this);
        this.onClickView = this.onClickView.bind(this);
        this.onClickSendReceiptEmail = this.onClickSendReceiptEmail.bind(this);
        this.onClickSendInvoiceEmail = this.onClickSendInvoiceEmail.bind(this);
        this.onClickDownloadInvoice = this.onClickDownloadInvoice.bind(this);
        this.onClickViewReceiptDetail = this.onClickViewReceiptDetail.bind(this);
    }
    componentDidMount() {
        saleEdit = get_user_permissions('sales-edit');
        saleView = get_user_permissions('sales-view');
        saleCreate = get_user_permissions('sales-create');
        saleDelete = get_user_permissions('sales-delete');
        saleLog = get_user_permissions('sale-logs-view');
        paymentsLog = get_user_permissions('payments-logs-view');
        receiptEmailSend = get_user_permissions('receipt-email-create');
        invoiceEmailSend = get_user_permissions('invoice-email-create');
        var salesId = localStorage.getItem("payments_sale_id");
        this.setState({ currentSaleId: localStorage.getItem("payments_sale_id") });
        this.getPaymentLists(salesId);
        this.getSaleData(salesId);
        this.getPaymentTypes();
    }

    async getPaymentTypes() {
        Promise.all([get_all_payment_types_for_organisation()]).then((values) => {
            const paymentTypeOPtions = values[0].map(d => ({
                "value": d.id,
                "label": d.payment_types_name
            }))
            this.setState({ optionArrayPaymentType: paymentTypeOPtions })
        });
    }
    async getPaymentLists(salesId) {
        Promise.all([get_payment_against_sale(salesId)]).then((values) => {
            this.setState({ listData: values[0] });
            this.setState({ currency_name: values[0][0].currency_name });
            this.setState({ late_fee: values[0].late_fee });
        });
    }

    async getSaleData(salesId) {
        Promise.all([get_current_sale(salesId)]).then((values) => {
            this.setState({ currentSaleData: values[0] });
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { listData } = this.props;
        if (!isEmpty(listData) && size(prevProps.listData) !== size(listData)) {
            this.setState({ listData: {}, isEdit: false });
        }
    }
    getDateFormateMethod = (myDate) => {
        var today = myDate
        const dd = today.getDate().toString()
        const mm = today.getMonth() + 1
        const yyyy = today.getFullYear()
        const fromate_date = (today = dd + "/" + mm + "/" + yyyy)
        return fromate_date
    }
    onPaginationPageChange = page => {
        if (
            this.node &&
            this.node.current &&
            this.node.current.props &&
            this.node.current.props.pagination &&
            this.node.current.props.pagination.options
        ) {
            this.node.current.props.pagination.options.onPageChange(page);
        }
    };
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    toggleNewPayment() {
        this.setState(prevState => ({
            modalNewPayment: !prevState.modalNewPayment,
        }));
    }
    toggleReceivePayment() {
        this.setState(prevState => ({
            modalReceivePayment: !prevState.modalReceivePayment,
        }));
    }
    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };
    onClickDelete = listdata => {
        this.setState({ listdata: listdata });
        this.setState({ deleteModal: true });
    };
    onClickSendReceiptEmail = arg => {
        const listdata = arg;
        Promise.all([payment_confirm_mail(listdata.id)])
            .then((values) => {
                showToast("Success", "The receipt has been emailed successfully.", "success");
            }).catch((error) => {
                showToast("Failed", "The receipt has not been emailed successfully.", "error");
            });
    };
    onClickSendInvoiceEmail = arg => {
        const listdata = arg;
        Promise.all([payment_invoice_mail(listdata.id)])
            .then((values) => {
                showToast("Success", "The invoice has been emailed successfully.", "success");
            }).catch((error) => {
                showToast("Failed", "The invoice has not been emailed successfully. " + error.response.data.message, "error");
            });
    };
    onClickReceivePayment = arg => {
        this.toggleReceivePayment();
    };
    onClickActivityLogPayments = arg => {
        const listdata = arg;
        window.localStorage.setItem("current_payment_id", listdata.id);
        this.setState({ redirect: "/payment/log" });
    }
    handleSelectedStatus = selectedStatus => {
        this.setState({ selectedStatus })
        this.setState({ status: selectedStatus.label })

    }
    handleAmountChange = (e) => {
        this.setState({ amount_edit: e.target.value })
        var amount = parseInt(document.getElementById('amount-edit').value);
        var lateFee = parseInt(document.getElementById('late-fee-edit').value);
        // var total = amount+lateFee;
        this.setState({ total_edit: parseFloat(amount + lateFee).toFixed(2) });
    }
    handleLateFeeChange = (e) => {
        this.setState({ late_fee: e.target.value })
        var amount = parseInt(document.getElementById('amount-edit').value);
        var lateFee = parseInt(document.getElementById('late-fee-edit').value);
        // var total = amount+lateFee;
        this.setState({ total_edit: parseFloat(amount + lateFee).toFixed(2) });
    }
    handleSelectedPaymentTypes = selectedPaymentType => {
        this.setState({ selectedPaymentType })
    }
    handleSelectedModePayments = selectedModePayments => {
        this.setState({ selectedModePayments })
    }
    handleDeletejob = () => {
        const { listdata } = this.state;
        if (listdata.id !== undefined) {
            // onDeleteJobList(listdata);
            Promise.all([delete_payment(this.state.recordDeleteID)])
                .then((delValues) => {
                    showToast("Success", "The payment has been deleted successfully.", "success");
                    Promise.all([get_payment_against_sale(localStorage.getItem("payments_sale_id"))]).then((values) => {
                        this.setState({ listData: values[0] });
                    });
                    Promise.all([get_current_sale(localStorage.getItem("payments_sale_id"))]).then((values) => {
                        this.setState({ currentSaleData: values[0] });
                    });
                }).catch((error) => {
                    showToast("Failed", "Payment Not Deleted!", "error");
                });
            this.setState({ deleteModal: false });
        }
    };
    handleRecordClicks = () => {
        this.setState({ listdata: "", isEdit: false });
        this.toggle();
    };
    handleRecordClick = arg => {
        const listdata = arg;
        this.setState({
            listdata: {
                id: listdata.id,
                due_date: listdata.due_date,
                amount: listdata.amount,
                late_fee: listdata.late_fee,
                total: listdata.total,
                paid: listdata.paid,
                receipt_date: listdata.receipt_date,
                status: listdata.status,
                notes: listdata.notes
            },
            isEdit: true,
        });

        this.toggle();
    };
    handleNewPayment = arg => {
        this.toggleNewPayment();
    };
    handleViewAllReceipt = arg => {
        const listdata = arg;
        window.localStorage.setItem("current_payment_id", listdata.id);
        this.setState({ redirect: "/sales/receipts" });
    };
    handleReceivePayment = arg => {
        this.toggleReceivePayment();
    };
    onClickView = arg => {
        const listdata = arg;
        window.localStorage.setItem("current_payment_id", listdata.id);
        this.setState({ redirect: "/payment/view" });
    }
    onClickViewReceiptDetail = arg => {
        const listdata = arg;
        window.localStorage.setItem("current_payment_id", listdata.id);
        this.setState({ redirect: "/payment/receipt/view" });
    }
    onClickDownloadInvoice = arg => {
        const listdata = arg;
        Promise.all([generated_invoice_download(listdata.id)]).then((values) => {
            const contentType = values[0].file_path.split(';')[0].split(':')[1];
            const base64Content = values[0].file_path.split(',')[1];
            const byteCharacters = atob(base64Content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const extension = values[0].file_path.split(';')[0].split('/')[1];
            const blob = new Blob([byteArray], { type: contentType });
            const fileName = `${values[0].template_name}.${extension}`;
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.target = '_blank';
            a.rel = 'noopener noreferrer';
            a.click();
            showToast("Success", "Download Success.", "success")
        }).catch((error) => {
            showToast("Failed", "Download Failed!", "error")
        });
    }
    showToast(title, message, toastType) {
        //position class
        var positionClass = "toast-top-right";
        toastr.options = {
            positionClass: positionClass,
            timeOut: this.state.timeOut,
            extendedTimeOut: this.state.extendedTimeOut,
            showEasing: this.state.showEasing,
            hideEasing: this.state.hideEasing,
            showMethod: this.state.showMethod,
            hideMethod: this.state.hideMethod,
            showDuration: this.state.showDuration,
            hideDuration: this.state.hideDuration,
        };

        // setTimeout(() => toastr.success(`Settings updated `), 300)
        //Toaster Types
        if (toastType === "info") toastr.info(message, title);
        else if (toastType === "warning") toastr.warning(message, title);
        else if (toastType === "error") toastr.error(message, title);
        else toastr.success(message, title);
    }
    // Function to parse the date string and create a Date object in DD/MM/YYYY format
    parseDate(dateString) {
        const [day, month, year] = dateString.split('/');
        // Assuming date format is "DD/MM/YYYY"
        return new Date(`${year}-${month}-${day}`);
    }
    // Function to calculate the time difference between two dates in days (excluding the start date)
    getDaysDifference(startDate, endDate) {
        const timeDiff = endDate.getTime() - startDate.getTime();
        return Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1;
    }
    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        const { isEdit, deleteModal } = this.state;
        const { currency_name } = this.state;
        const listdata = this.state.listdata;
        const { selectedStatus } = this.state;
        const { selectedPaymentType, selectedModePayments } = this.state;
        const columns = [
            {
                dataField: "due_date",
                text: "Due Date",
                sort: true,
            },
            {
                dataField: "payment_types_name",
                text: "Payment Type",
                sort: true,
            },
            {
                dataField: "amount",
                text: `${'Amount '} (${currency_name ? currency_name : ''})`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {numberWithCommas(listdata.amount)}
                    </>
                ),
            },
            {
                dataField: "late_fee",
                text: `${'Late fee '} (${currency_name ? currency_name : ''})`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {numberWithCommas(listdata.late_fee)}
                    </>
                ),
            },
            // commented code for later use if needed.
            // {
            //     dataField: "total",
            //     text: "Total",
            //     sort: true,
            //     formatter: (cellContent, listdata) => (
            //         <>
            //             {(parseFloat(listdata.amount) + parseFloat(listdata.late_fee)).toFixed(2)}
            //         </>
            //     ),
            // },
            {
                dataField: "paid",
                text: `${'Paid '} (${currency_name ? currency_name : ''})`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {numberWithCommas(listdata.paid)}
                    </>
                ),
            },
            {
                dataField: "paid",
                text: `${'Outstanding '} (${currency_name ? currency_name : ''})`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <div className="text-center">
                        {numberWithCommas((listdata.amount) - (listdata.paid))}
                    </div>
                ),
            },
            {
                dataField: "status",
                text: "Status",
                sort: true,
            },
            // commented code for later use if needed.
            // {
            //     dataField: "remaining",
            //     text: "Remaining",
            //     sort: true,
            //     formatter: (cellContent, listdata) => (
            //         <>
            //             {((parseFloat(listdata.amount) + parseFloat(listdata.late_fee)) - parseFloat(listdata.paid)).toFixed(2)}
            //         </>
            //     ),
            // },
            {
                dataField: "action",
                isDummyField: true,
                text: "Action",
                formatter: (cellContent, listdata) => {
                    const dueDate = this.parseDate(listdata.due_date);
                    const currentDate = new Date();
                    const daysBeforeDueDate = 15;
                    const daysDifference = this.getDaysDifference(currentDate, dueDate);
                    // The condition should be true if the due date is from the next 15 days or before the current date and has not yet passed
                    const shouldGenerateButton = daysDifference <= daysBeforeDueDate;
                    return (
                        <>
                            <ul className="list-unstyled hstack gap-1 mb-0">
                                {saleView ? <>
                                    {paymentsLog ? <>
                                        <li>
                                            <Link
                                                to="#"
                                                className="btn btn-sm btn-soft-primary"
                                                onClick={() => this.onClickActivityLogPayments(listdata)}
                                            >
                                                <i className="mdi mdi-file-document-outline" id="logtooltip" />
                                                <UncontrolledTooltip placement="top" target="logtooltip">
                                                    Activity Log
                                                </UncontrolledTooltip>
                                            </Link>
                                        </li>
                                    </> : <></>}
                                </> : <></>}
                                {saleView ? <>
                                    <li>
                                        <Link
                                            to="#"
                                            className="btn btn-sm btn-soft-primary"
                                            onClick={() => this.onClickView(listdata)}
                                        >
                                            <i className="mdi mdi-eye-outline" id="viewtooltip" />
                                            <UncontrolledTooltip placement="top" target="viewtooltip">
                                                View Payment Detail
                                            </UncontrolledTooltip>
                                        </Link>
                                    </li>
                                </> : <></>}
                                {saleEdit ? <>
                                    {(listdata.status === 'Received') ? null : <>
                                        <li>
                                            <Link
                                                to="#"
                                                className="btn btn-sm btn-soft-info"
                                                onClick={() => this.handleRecordClick(listdata)}
                                            >
                                                <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                                <UncontrolledTooltip placement="top" target="edittooltip">
                                                    Edit
                                                </UncontrolledTooltip>
                                            </Link>
                                        </li>
                                    </>}

                                </> : <></>}
                                {saleDelete ? <>
                                    {(listdata.status === 'Received' || listdata.status === 'Partially Received') ? null : <>
                                        <li>
                                            <Link
                                                to="#"
                                                className="btn btn-sm btn-soft-danger"
                                                onClick={() => this.onClickDelete(listdata)}
                                            >
                                                <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                                    Delete
                                                </UncontrolledTooltip>
                                            </Link>
                                        </li>
                                    </>}
                                </> : <></>}
                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-soft-info"
                                        onClick={() => this.onClickDownloadInvoice(listdata)}
                                    >
                                        <i className="mdi mdi-file-download-outline" id="downloadInvoice" />
                                        <UncontrolledTooltip placement="top" target="downloadInvoice">
                                            Donwload Invoice
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                                {/* {receiptEmailSend ? <>
                                    {listdata.status == 'Partially Received' || listdata.status == 'Received' ? <>
                                        <li>
                                            <Link
                                                to="#"
                                                className="btn btn-sm btn-soft-success"
                                                onClick={() => this.onClickSendReceiptEmail(listdata)}
                                            >
                                                <p style={{ "marginBottom": "0px" }} id="sendReceiptEmailtooltip">R</p>
                                                <UncontrolledTooltip placement="top" target="sendReceiptEmailtooltip">
                                                    Send Receipt Email
                                                </UncontrolledTooltip>
                                            </Link>
                                        </li>
                                    </> : <></>}
                                </> : <></>} */}
                                {invoiceEmailSend ? <>
                                    {(shouldGenerateButton) && (listdata.status == 'Due') ? <>
                                        <li>
                                            <Link
                                                to="#"
                                                className="btn btn-sm btn-soft-info"
                                                onClick={() => this.onClickSendInvoiceEmail(listdata)}
                                            >
                                                <p style={{ "marginBottom": "0px" }} id="sendInvoiceEmailtooltip">I</p>
                                                <UncontrolledTooltip placement="top" target="sendInvoiceEmailtooltip">
                                                    Send Invoice Email
                                                </UncontrolledTooltip>
                                            </Link>
                                        </li>
                                    </> : <></>}
                                </> : <></>}
                                {listdata.paymentReceiveButton ? <>
                                    <li>
                                        <Link
                                            to="#"
                                            className="btn btn-sm btn-soft-success"
                                            onClick={() => this.onClickReceivePayment('')}
                                        >
                                            <i className="bx bx-wallet-alt" id="receivePaymentTooltip" />
                                            <UncontrolledTooltip placement="top" target="receivePaymentTooltip">
                                                Receive Payment
                                            </UncontrolledTooltip>
                                        </Link>
                                    </li>
                                </> : null}
                                {(listdata.status === 'Received' || listdata.status === 'Partially Received') ? <>
                                    <li>
                                        <Link
                                            to="#"
                                            className="btn btn-sm btn-soft-success"
                                            onClick={() => this.onClickViewReceiptDetail(listdata)}
                                        >
                                            <i className="mdi mdi-eye-outline" id="viewReceiptDetailtooltip" />
                                            <UncontrolledTooltip placement="top" target="viewReceiptDetailtooltip">
                                                View Receipt Detail
                                            </UncontrolledTooltip>
                                        </Link>
                                    </li>
                                </> : null}
                            </ul>
                        </>
                    )
                },
            },

        ];

        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];

        const pageOptions = {
            sizePerPage: 500,
            custom: true,
        };

        // Select All Button operation
        const selectRow = {
            mode: "checkbox",
        };

        const { SearchBar } = Search;

        //meta title
        document.title = "List Sale Payments";
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.setState({ recordDeleteID: row.id });
                this.setState({ status: row.status });
                this.setState({ receiptDate: row.receipt_date })
                this.setState({ late_fee: row.late_fee })
                this.setState({ amount_edit: row.amount })
                this.setState({
                    total_edit: (parseFloat(row.amount) + parseFloat(row.late_fee)).toFixed(2)
                })
            }
        }
        return (
            <React.Fragment>
                <DeleteModal
                    show={deleteModal}
                    record={`sale payment`}
                    onDeleteClick={this.handleDeletejob}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Sales" breadcrumbItem="List Sale Payments" parentLink="/sales/list" />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">Sale Details</h5>
                                            </div>
                                        </CardBody>
                                        <CardBody>
                                            <Row>
                                                <Col md="12">
                                                    <div className="mb-3 row d-flex ">
                                                        <Label className="col-md-2 col-form-label" htmlFor="selectDate">Project: </Label>
                                                        <div className="col-md-4 mt-2">{this.state.currentSaleData.project_name}</div>
                                                        <Label className="col-md-2 col-form-label" htmlFor="selectDate">Sale Date: </Label>
                                                        <div className="col-md-4 mt-2">{this.state.currentSaleData.sale_date}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <div className="mb-3 row d-flex ">
                                                        <Label className="col-md-2 col-form-label" htmlFor="selectDate">Property: </Label>
                                                        <div className="col-md-4 mt-2">{this.state.currentSaleData.property_name}</div>
                                                        <Label className="col-md-2 col-form-label" htmlFor="selectDate">Price: </Label>
                                                        <div className="col-md-4 mt-2"><strong>{this.state.currentSaleData.currency_symbol}</strong>{' '}{numberWithCommas(this.state.currentSaleData.sale_price)}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <div className="mb-3 row d-flex ">
                                                        <Label className="col-md-2 col-form-label" htmlFor="selectDate">Discount:</Label>
                                                        <div className="col-md-4 mt-2"><strong>{this.state.currentSaleData.currency_symbol}</strong>{' '}{numberWithCommas(this.state.currentSaleData.sale_discount)}</div>
                                                        <Label className="col-md-2 col-form-label" htmlFor="selectDate">Final Price: </Label>
                                                        <div className="col-md-4 mt-2"><strong>{this.state.currentSaleData.currency_symbol}</strong>{' '}{numberWithCommas(this.state.currentSaleData.sale_total)}</div>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </CardBody>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">List Sale Payments</h5>
                                                <div className="flex-shrink-0">
                                                    <Link to="#" onClick={() => this.handleViewAllReceipt("")} className="btn btn-info me-1">View All Receipts</Link>
                                                    <Link to="#" onClick={() => this.handleNewPayment("")} className="btn btn-primary me-1">Add New Payment</Link>
                                                </div>
                                            </div>
                                        </CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField="id"
                                            columns={columns}
                                            data={this.state.listData}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    columns={columns}
                                                    data={this.state.listData}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col md="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"id"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            // selectRow={selectRow}
                                                                            rowEvents={rowEvents}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            ref={this.node}
                                                                        />
                                                                        <Modal
                                                                            isOpen={this.state.modal}
                                                                            className={this.props.className}
                                                                        >
                                                                            <ModalHeader toggle={this.toggle} tag="h4">
                                                                                {!!isEdit ? "Edit Sale Payment" : "Add Sale"}
                                                                            </ModalHeader>
                                                                            <ModalBody>
                                                                                <Formik
                                                                                    enableReinitialize={true}
                                                                                    initialValues={{
                                                                                        id: (listdata && listdata.id) || '',
                                                                                        due_date: (listdata && listdata.due_date) || '',
                                                                                        amount: (listdata && listdata.amount) || '',
                                                                                        late_fee: (listdata && listdata.late_fee) || '',
                                                                                        total: (listdata && listdata.total) || '',
                                                                                        paid: (listdata && listdata.paid) || '',
                                                                                        status: (listdata && listdata.status) || '',
                                                                                        receipt_date: (listdata && listdata.receipt_date) || '',
                                                                                        notes: (listdata && listdata.notes) || '',
                                                                                    }}
                                                                                    onSubmit={values => {
                                                                                        let errorMessage = '';
                                                                                        if (values.due_date == '') {
                                                                                            errorMessage += '<li>Due Date</li>';
                                                                                        }
                                                                                        if (this.state.late_fee == '') {
                                                                                            errorMessage += '<li>Late fee</li>';
                                                                                        }
                                                                                        if (this.state.amount_edit == '') {
                                                                                            errorMessage += '<li>Amount</li>';
                                                                                        }
                                                                                        if (values.paid == '') {
                                                                                            errorMessage += '<li>Paid</li>';
                                                                                        }
                                                                                        if (errorMessage) {
                                                                                            showToast("Failed", "These fields are missing. Please fill the required fields: " + errorMessage, "error");
                                                                                            return false;
                                                                                        }
                                                                                        var receipt_date = this.getDateFormateMethod(this.state.paymentReceivedDate[0]);
                                                                                        // Split the date string by '/'
                                                                                        var datePartsReceipt = receipt_date.split('/');
                                                                                        var datePartsDue = values.due_date.split('/');
                                                                                        // Format the date as "DD-MM-YYYY"
                                                                                        var receipt_date = datePartsReceipt[2] + '-' + datePartsReceipt[1] + '-' + datePartsReceipt[0];
                                                                                        var due_date = datePartsDue[2] + '-' + datePartsDue[1] + '-' + datePartsDue[0];
                                                                                        axios.post(baseURL + "/api/payment-receive/" + values.id, {
                                                                                            "receipt_date": receipt_date,
                                                                                            "due_date": due_date,
                                                                                            "sale_id": localStorage.getItem('payments_sale_id'),
                                                                                            "amount": this.state.amount_edit,
                                                                                            "late_fee": this.state.late_fee,
                                                                                            "total": this.state.total_edit,
                                                                                            "paid": values.paid,
                                                                                            "status": this.state.status,
                                                                                            "notes": values.notes,
                                                                                        }, config)
                                                                                            .then((response) => {
                                                                                                showToast("Success", "Payment Added Successfully", "success");
                                                                                                Promise.all([get_payment_against_sale(localStorage.getItem("payments_sale_id"))]).then((values) => {
                                                                                                    this.setState({ listData: values[0] });
                                                                                                });
                                                                                                Promise.all([get_current_sale(localStorage.getItem("payments_sale_id"))]).then((values) => {
                                                                                                    this.setState({ currentSaleData: values[0] });
                                                                                                });
                                                                                            })
                                                                                            .catch((error) => {
                                                                                                showToast("Failed", "Payment Not Added Successfully", "error")
                                                                                            });
                                                                                        this.toggle();
                                                                                    }}
                                                                                >
                                                                                    {({ errors, status, touched }) => (
                                                                                        <Form>
                                                                                            <Row>
                                                                                                <Col className="col-12">
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label">
                                                                                                            Sale Payment Id
                                                                                                        </Label>
                                                                                                        <Field
                                                                                                            name="id"
                                                                                                            disabled
                                                                                                            type="text"
                                                                                                            className="form-control"
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label" htmlFor="selectDate">Received Date</Label>

                                                                                                        <div className="docs-datepicker col-md-12">
                                                                                                            <InputGroup>
                                                                                                                <Flatpickr
                                                                                                                    className="form-control d-block"
                                                                                                                    placeholder="Pick a date"
                                                                                                                    value={this.state.receiptDate}
                                                                                                                    options={{
                                                                                                                        enableTime: false,
                                                                                                                        dateFormat: "d/m/Y",
                                                                                                                    }}
                                                                                                                    onChange={date => {
                                                                                                                        this.setState({ paymentReceivedDate: date })
                                                                                                                    }}
                                                                                                                />
                                                                                                                <div className="input-group-append">
                                                                                                                    <button
                                                                                                                        type="button"
                                                                                                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                                                                        disabled
                                                                                                                    >
                                                                                                                        <i
                                                                                                                            className="fa fa-calendar"
                                                                                                                            aria-hidden="true"
                                                                                                                        />
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            </InputGroup>
                                                                                                            <div className="docs-datepicker-container" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label">
                                                                                                            Payment Due Date
                                                                                                        </Label>
                                                                                                        <Field
                                                                                                            name="due_date"
                                                                                                            type="text"
                                                                                                            className="form-control"
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label">
                                                                                                            Amount ({this.state.currentSaleData.currency_symbol})
                                                                                                        </Label>
                                                                                                        <Field
                                                                                                            id='amount-edit'
                                                                                                            // name="amount"
                                                                                                            value={this.state.amount_edit}
                                                                                                            type="text"
                                                                                                            className="form-control"
                                                                                                            onBlur={(e) => {
                                                                                                                if (e.target.value.trim() === "") {
                                                                                                                    e.target.value = ""; // Empty value stays empty
                                                                                                                } else {
                                                                                                                    const formattedValue = parseFloat(e.target.value).toFixed(2);
                                                                                                                    this.setState({ amount_edit: formattedValue });
                                                                                                                }
                                                                                                            }}
                                                                                                            onChange={(e) => this.handleAmountChange(e)}

                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label">
                                                                                                            Late Fee
                                                                                                        </Label>
                                                                                                        <Field
                                                                                                            id='late-fee-edit'
                                                                                                            // name="late_fee"
                                                                                                            type="text"
                                                                                                            value={this.state.late_fee}
                                                                                                            className="form-control"
                                                                                                            onBlur={(e) => {
                                                                                                                if (e.target.value.trim() === "") {
                                                                                                                    e.target.value = ""; // Empty value stays empty
                                                                                                                } else {
                                                                                                                    const formattedValue = parseFloat(e.target.value).toFixed(2);
                                                                                                                    this.setState({ late_fee: formattedValue });
                                                                                                                }
                                                                                                            }}
                                                                                                            // onChange={this.handleLateFeeChange}
                                                                                                            onChange={(e) => this.handleLateFeeChange(e)}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label">
                                                                                                            Total
                                                                                                        </Label>
                                                                                                        <Field
                                                                                                            disabled
                                                                                                            // id='total-edit'
                                                                                                            // value={(parseFloat(listdata.amount) + parseFloat(listdata.late_fee)).toFixed(2)}
                                                                                                            value={this.state.total_edit}
                                                                                                            name="total"
                                                                                                            type="text"
                                                                                                            className="form-control"
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label">
                                                                                                            Paid
                                                                                                        </Label>
                                                                                                        <Field
                                                                                                            name="paid"
                                                                                                            type="text"
                                                                                                            className="form-control"
                                                                                                            onBlur={(e) => {
                                                                                                                if (e.target.value.trim() === "") {
                                                                                                                    e.target.value = ""; // Empty value stays empty
                                                                                                                } else {
                                                                                                                    const formattedValue = parseFloat(e.target.value).toFixed(2);
                                                                                                                    e.target.value = formattedValue;
                                                                                                                }
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="mb-3 select2-container">
                                                                                                        <Label className="form-label" htmlFor="selectedStatus">Status</Label>
                                                                                                        <div className="col-md-12">
                                                                                                            <Select
                                                                                                                value={{ label: this.state.status, value: 0 }}
                                                                                                                onChange={this.handleSelectedStatus}
                                                                                                                options={this.state.statusArray}
                                                                                                                classNamePrefix="select2-selection"
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col md="12">
                                                                                                    <FormGroup className="mb-3 row d-flex">
                                                                                                        <Label className="col-form-label" htmlFor="notes">
                                                                                                            Notes
                                                                                                        </Label>
                                                                                                        <div className="col-md-12">
                                                                                                            <Field
                                                                                                                name="notes"
                                                                                                                placeholder="Notes"
                                                                                                                as="textarea"
                                                                                                                rows={5}
                                                                                                                className={
                                                                                                                    "form-control"}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </FormGroup>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>
                                                                                                    <div className="text-end">
                                                                                                        <button
                                                                                                            type="submit"
                                                                                                            className="btn btn-primary save-user"
                                                                                                        >
                                                                                                            Save
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Form>
                                                                                    )}
                                                                                </Formik>
                                                                            </ModalBody>
                                                                        </Modal>
                                                                        {/* Modal for Add New Payment Plan*/}
                                                                        <Modal
                                                                            isOpen={this.state.modalNewPayment}
                                                                            className={this.props.className}
                                                                        >
                                                                            <ModalHeader toggle={this.toggleNewPayment} tag="h4">
                                                                                {!!isEdit ? "Add New Payment" : "Add New Payment"}
                                                                            </ModalHeader>
                                                                            <ModalBody>
                                                                                <Formik
                                                                                    enableReinitialize={true}
                                                                                    initialValues={{
                                                                                        // id: (listdata && listdata.id) || '',
                                                                                        // due_date: (listdata && listdata.due_date) || '',
                                                                                        // amount: (listdata && listdata.amount) || '',
                                                                                        // late_fee: (listdata && listdata.late_fee) || '',
                                                                                        // total: (listdata && listdata.total) || '',
                                                                                        // paid: (listdata && listdata.paid) || '',
                                                                                        // status: (listdata && listdata.status) || '',
                                                                                        // receipt_date: (listdata && listdata.receipt_date) || '',
                                                                                    }}
                                                                                    validationSchema={Yup.object().shape({
                                                                                        // id: Yup.string().required("Please Enter Your Id"),
                                                                                        // due_date: Yup.string().required("Please Enter Due Date"),
                                                                                        // amount: Yup.string().required("Please Enter Amount"),
                                                                                        // late_fee: Yup.string().required("Please Enter Late Fee"),
                                                                                        // total: Yup.string().required("Please Enter Total"),
                                                                                        // paid: Yup.string().required("Please Enter Amount Paid"),
                                                                                        // receipt_date: Yup.string().required("Please Enter Received Date"),
                                                                                    })}
                                                                                    onSubmit={values => {
                                                                                        const formattedDate = this.getDateFormateMethod(this.state.paymentReceivedDate[0]);
                                                                                        // Split the date string by '/'
                                                                                        const datePartsReceipt = formattedDate.split('/');
                                                                                        // Format the date as "DD-MM-YYYY"
                                                                                        var additionalPaymentDueDate = datePartsReceipt[2] + '-' + datePartsReceipt[1] + '-' + datePartsReceipt[0];
                                                                                        axios.post(baseURL + "/api/save-additional-payments", {
                                                                                            "payment_type_id": selectedPaymentType.value,
                                                                                            "sale_id": this.state.currentSaleData.id,
                                                                                            "amount": values.amount,
                                                                                            "due_date": additionalPaymentDueDate,
                                                                                            "currency_id": this.state.currentSaleData.currency_id,
                                                                                            "no_of_recurrences": values.no_of_recurrences
                                                                                        }, config)
                                                                                            .then((response) => {
                                                                                                showToast("Success", "Payment Added Successfully", "success");
                                                                                                Promise.all([get_payment_against_sale(localStorage.getItem("payments_sale_id"))]).then((values) => {
                                                                                                    this.setState({ listData: values[0] });
                                                                                                });
                                                                                                Promise.all([get_current_sale(localStorage.getItem("payments_sale_id"))]).then((values) => {
                                                                                                    this.setState({ currentSaleData: values[0] });
                                                                                                });
                                                                                            })
                                                                                            .catch((error) => {
                                                                                                showToast("Failed", "Payment Not Added Successfully", "error")
                                                                                            });
                                                                                        this.toggleNewPayment();
                                                                                    }}
                                                                                >
                                                                                    {({ errors, status, touched }) => (
                                                                                        <Form>
                                                                                            <Row>
                                                                                                <Col className="col-12">
                                                                                                    <div className="mb-3 select2-container">
                                                                                                        <Label className="form-label" htmlFor="selectedStatus">Select Payment Type</Label>
                                                                                                        <div className="col-md-12">
                                                                                                            <Select
                                                                                                                // value={{ label: this.state.status, value: 0 }}
                                                                                                                onChange={this.handleSelectedPaymentTypes}
                                                                                                                options={this.state.optionArrayPaymentType}
                                                                                                                classNamePrefix="select2-selection"
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label" htmlFor="selectDate">Due Date</Label>

                                                                                                        <div className="docs-datepicker col-md-12">
                                                                                                            <InputGroup>
                                                                                                                <Flatpickr
                                                                                                                    className="form-control d-block"
                                                                                                                    placeholder="Pick a date"
                                                                                                                    value={this.state.receiptDate}
                                                                                                                    options={{
                                                                                                                        // altInput: true,
                                                                                                                        // // altFormat: "F j, Y",
                                                                                                                        // dateFormat: "d-m-y"
                                                                                                                        enableTime: false,
                                                                                                                        dateFormat: "d/m/Y",
                                                                                                                    }}
                                                                                                                    onChange={date => {
                                                                                                                        this.setState({ paymentReceivedDate: date })
                                                                                                                    }}
                                                                                                                />
                                                                                                                <div className="input-group-append">
                                                                                                                    <button
                                                                                                                        type="button"
                                                                                                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                                                                        disabled
                                                                                                                    >
                                                                                                                        <i
                                                                                                                            className="fa fa-calendar"
                                                                                                                            aria-hidden="true"
                                                                                                                        />
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            </InputGroup>
                                                                                                            <div className="docs-datepicker-container" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label">
                                                                                                            Amount ({this.state.currentSaleData.currency_symbol})
                                                                                                        </Label>
                                                                                                        <Field
                                                                                                            name="amount"
                                                                                                            type="text"
                                                                                                            className={
                                                                                                                "form-control" +
                                                                                                                (errors.amount &&
                                                                                                                    touched.amount
                                                                                                                    ? " is-invalid"
                                                                                                                    : "")
                                                                                                            }
                                                                                                            onBlur={(e) => {
                                                                                                                if (e.target.value.trim() === "") {
                                                                                                                    e.target.value = ""; // Empty value stays empty
                                                                                                                } else {
                                                                                                                    const formattedValue = parseFloat(e.target.value).toFixed(2);
                                                                                                                    e.target.value = formattedValue;
                                                                                                                }
                                                                                                            }}
                                                                                                        />
                                                                                                        <ErrorMessage
                                                                                                            name="amount"
                                                                                                            component="div"
                                                                                                            className="invalid-feedback"
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label">
                                                                                                            No of Recurrences
                                                                                                        </Label>
                                                                                                        <Field
                                                                                                            name="no_of_recurrences"
                                                                                                            type="text"
                                                                                                            className={
                                                                                                                "form-control" +
                                                                                                                (errors.no_of_recurrences &&
                                                                                                                    touched.no_of_recurrences
                                                                                                                    ? " is-invalid"
                                                                                                                    : "")
                                                                                                            }
                                                                                                        />
                                                                                                        <ErrorMessage
                                                                                                            name="no_of_recurrences"
                                                                                                            component="div"
                                                                                                            className="invalid-feedback"
                                                                                                        />
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>
                                                                                                    <div className="text-end">
                                                                                                        <button
                                                                                                            type="submit"
                                                                                                            className="btn btn-primary save-user"
                                                                                                        >
                                                                                                            Save
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Form>
                                                                                    )}
                                                                                </Formik>
                                                                            </ModalBody>
                                                                        </Modal>
                                                                        {/* Modal End Add New Payment Plan */}

                                                                        {/* Modal for Receive Payment*/}
                                                                        <Modal
                                                                            isOpen={this.state.modalReceivePayment}
                                                                            className={this.props.className}
                                                                        >
                                                                            <ModalHeader toggle={this.toggleReceivePayment} tag="h4">
                                                                                {!!isEdit ? "Receive Payment" : "Receive Payment"}
                                                                            </ModalHeader>
                                                                            <ModalBody>
                                                                                <Formik
                                                                                    enableReinitialize={true}
                                                                                    initialValues={{
                                                                                        id: (listdata && listdata.id) || '',
                                                                                        due_date: (listdata && listdata.due_date) || '',
                                                                                        amount: (listdata && listdata.amount) || '',
                                                                                        notes: (listdata && listdata.notes) || '',
                                                                                    }}
                                                                                    validationSchema={Yup.object().shape({})}
                                                                                    onSubmit={values => {
                                                                                        const formattedDate = this.getDateFormateMethod(this.state.receivedDateValue[0]);
                                                                                        // Split the date string by '/'
                                                                                        const datePartsReceipt = formattedDate.split('/');
                                                                                        // Format the date as "DD-MM-YYYY"
                                                                                        var additionalPaymentDueDate = datePartsReceipt[2] + '-' + datePartsReceipt[1] + '-' + datePartsReceipt[0];
                                                                                        axios.post(baseURL + "/api/receive-sales-receipt", {
                                                                                            "receipt_date": additionalPaymentDueDate,
                                                                                            "received_amount": values.amount,
                                                                                            "mode_of_payment": selectedModePayments.value,
                                                                                            "sale_id": this.state.currentSaleData.id,
                                                                                            "currency_id": this.state.currentSaleData.currency_id,
                                                                                            "notes": values.notes,
                                                                                        }, config)
                                                                                            .then((response) => {
                                                                                                showToast("Success", "Payment Received Successfully", "success");
                                                                                                Promise.all([get_payment_against_sale(localStorage.getItem("payments_sale_id"))]).then((values) => {
                                                                                                    this.setState({ listData: values[0] });
                                                                                                });
                                                                                                Promise.all([get_current_sale(localStorage.getItem("payments_sale_id"))]).then((values) => {
                                                                                                    this.setState({ currentSaleData: values[0] });
                                                                                                });
                                                                                            })
                                                                                            .catch((error) => {
                                                                                                showToast("Failed", error.response.data.message, "error")
                                                                                            });
                                                                                        this.toggleReceivePayment();
                                                                                    }}
                                                                                >
                                                                                    {({ errors, status, touched }) => (
                                                                                        <Form>
                                                                                            <Row>
                                                                                                <Col className="col-12">
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label" htmlFor="selectDate">Received Date</Label>

                                                                                                        <div className="docs-datepicker col-md-12">
                                                                                                            <InputGroup>
                                                                                                                <Flatpickr
                                                                                                                    className="form-control d-block"
                                                                                                                    placeholder="Pick a date"
                                                                                                                    value={this.state.receivedDate}
                                                                                                                    options={{
                                                                                                                        // altInput: true,
                                                                                                                        // // altFormat: "F j, Y",
                                                                                                                        // dateFormat: "d-m-y"
                                                                                                                        enableTime: false,
                                                                                                                        dateFormat: "d/m/Y",
                                                                                                                    }}
                                                                                                                    onChange={date => {
                                                                                                                        this.setState({ receivedDateValue: date })
                                                                                                                    }}
                                                                                                                />
                                                                                                                <div className="input-group-append">
                                                                                                                    <button
                                                                                                                        type="button"
                                                                                                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                                                                        disabled
                                                                                                                    >
                                                                                                                        <i
                                                                                                                            className="fa fa-calendar"
                                                                                                                            aria-hidden="true"
                                                                                                                        />
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            </InputGroup>
                                                                                                            <div className="docs-datepicker-container" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label">
                                                                                                            Received Amount ({this.state.currentSaleData.currency_symbol})
                                                                                                        </Label>
                                                                                                        <Field
                                                                                                            name="amount"
                                                                                                            type="text"
                                                                                                            className={
                                                                                                                "form-control" +
                                                                                                                (errors.amount &&
                                                                                                                    touched.amount
                                                                                                                    ? " is-invalid"
                                                                                                                    : "")
                                                                                                            }
                                                                                                            onBlur={(e) => {
                                                                                                                if (e.target.value.trim() === "") {
                                                                                                                    e.target.value = ""; // Empty value stays empty
                                                                                                                } else {
                                                                                                                    const formattedValue = parseFloat(e.target.value).toFixed(2);
                                                                                                                    e.target.value = formattedValue;
                                                                                                                }
                                                                                                            }}
                                                                                                        />
                                                                                                        <ErrorMessage
                                                                                                            name="amount"
                                                                                                            component="div"
                                                                                                            className="invalid-feedback"
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="mb-3 select2-container">
                                                                                                        <Label className="form-label">Mode of Payments</Label>
                                                                                                        <div className="col-md-12">
                                                                                                            <Select
                                                                                                                // value={{ label: this.state.status, value: 0 }}
                                                                                                                onChange={this.handleSelectedModePayments}
                                                                                                                options={this.state.optionArrayModePayments}
                                                                                                                classNamePrefix="select2-selection"
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Col>
                                                                                                <Col md="12">
                                                                                                    <FormGroup className="mb-3 row d-flex">
                                                                                                        <Label className="col-form-label" htmlFor="notes">
                                                                                                            Notes
                                                                                                        </Label>
                                                                                                        <div className="col-md-12">
                                                                                                            <Field
                                                                                                                name="notes"
                                                                                                                placeholder="Notes"
                                                                                                                as="textarea"
                                                                                                                rows={5}
                                                                                                                className={
                                                                                                                    "form-control"}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </FormGroup>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>
                                                                                                    <div className="text-end">
                                                                                                        <button
                                                                                                            type="submit"
                                                                                                            className="btn btn-primary save-user"
                                                                                                        >
                                                                                                            Save
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Form>
                                                                                    )}
                                                                                </Formik>
                                                                            </ModalBody>
                                                                        </Modal>
                                                                        {/* Modal End Receive Payment */}
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">Total</h5>
                                            </div>
                                        </CardBody>
                                        <div className="table-responsive">
                                            <Table className="table mb-4">
                                                <thead>
                                                    <tr>
                                                        <th>Grand Total</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th>Amount</th>
                                                        <th>Late Fee</th>
                                                        <th>Receivable</th>
                                                        <th>Paid</th>
                                                        <th>Remaining</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                            {currency_name}{" "}{numberWithCommas(this.state.currentSaleData.sum_of_amount)}
                                                        </td>
                                                        <td>
                                                            {currency_name}{" "}{numberWithCommas(this.state.currentSaleData.sum_of_late_fee)}
                                                        </td>
                                                        <td>
                                                            {currency_name}{" "}{numberWithCommas(this.state.currentSaleData.sum_of_amount)}
                                                        </td>
                                                        <td>
                                                            {currency_name}{" "}{numberWithCommas(this.state.currentSaleData.sum_of_paid)}
                                                        </td>
                                                        <td>
                                                            {currency_name}{" "}{numberWithCommas((this.state.currentSaleData.sum_of_amount) - (this.state.currentSaleData.sum_of_paid))}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default ListPayments;
