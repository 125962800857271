import React, { Component } from "react"
import { useForm } from "react-hook-form";
import PhaseArray from "./PhaseArray";
import { Link, useHistory } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { add_phases_blocks } from '../api/projectApi';
// const defaultValues = {
//     phases: [
//         {
//             name: "Phase 1",
//             blocks: [{ name: "Block 1" }]
//         }
//     ]
// };
// const project_id = window.localStorage.getItem("project_id");
const defaultValues = {
    project_id: '',
    phases: []
};

function FormBuilder() {
    const {
        control,
        register,
        handleSubmit,
        getValues,
        errors,
        reset,
        setValue
    } = useForm({
        defaultValues
    });
    const onSubmit = (data) => {
        data.project_id = window.localStorage.getItem("project_id");
        console.log(data);
        if (data.phases == '') {
            var toastTitle = "Failed";
            var toastMessage = "Please add Phase first.";
            var toastType = "error";
            showToast(toastTitle, toastMessage, toastType);
        } else {
            var toastTitle = "Failed";
            var toastMessage = "Name fields empty.";
            var toastType = "error";
            for (let i = 0; i < data.phases.length; i++) {
                for (let j = 0; j < data.phases[i].blocks.length; j++) {
                    if (data.phases[i].name == '') {
                        showToast(toastTitle, toastMessage, toastType);
                        return false;
                    }
                    if (data.phases[i].blocks[j].name == '') {
                        showToast(toastTitle, toastMessage, toastType);
                        return false;
                    }
                }

            }
            Promise.all([add_phases_blocks(data)]).then((values) => {
                var toastTitle = "Success";
                var toastMessage = "The phases and blocks have been added successfully.";
                var toastType = "success";
                showToast(toastTitle, toastMessage, toastType);
                console.log(values[0]);
                if (window.localStorage.getItem("has_floors") == 1) {
                    handleClick();
                } else {
                    handleClickFeatures();
                }
            }).catch((error) => {
                var toastTitle = "Failed";
                var toastMessage = "Not Added";
                var toastType = "error";
                showToast(toastTitle, toastMessage, toastType);
                console.log(error);
            });
        }

        console.log("data", data);
        // window.localStorage.setItem("section", "2");
        // handleClick();
    }
    function showToast(title, message, toastType) {
        //position class
        var positionClass = "toast-top-right";
        toastr.options = {
            positionClass: positionClass,
            timeOut: 5000,
            extendedTimeOut: 1000,
            showEasing: "swing",
            hideEasing: "linear",
            showMethod: "fadeIn",
            hideMethod: "fadeOut",
            showDuration: 300,
            hideDuration: 1000,
        };

        // setTimeout(() => toastr.success(`Settings updated `), 300)
        //Toaster Types
        if (toastType === "info") toastr.info(message, title);
        else if (toastType === "warning") toastr.warning(message, title);
        else if (toastType === "error") toastr.error(message, title);
        else toastr.success(message, title);
    }
    let history = useHistory();

    function handleClick() {
        history.push("/projects/floors?section=4");
    }
    function handleClickFeatures() {
        history.push("/projects/features?section=5");
    }

    return (
        <form>
            <PhaseArray
                {...{ control, register, defaultValues, getValues, setValue, errors }}
            />

            {/* <button type="button" onClick={() => reset(defaultValues)}>
                      Reset
                    </button> */}

            <button style={{ float: "right" }} onClick={handleSubmit(onSubmit)} color="primary"
                className="btn btn-primary">Next</button>


        </form>
    )
}


export default FormBuilder
