import React, { Component } from "react";
import { Row, Col, Table, Card, CardBody, CardTitle, Button, Label, InputGroup, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from "reactstrap";
import { Redirect, Link } from "react-router-dom";
import { isEmpty, size } from "lodash";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import DeleteModal from "../../../components/Common/DeleteModal";
import { get_user_permissions } from "../../../helpers/custom_helper"
import Flatpickr from "react-flatpickr"
import axios from "axios";
import { showToast } from '../../UI/ui-toast'
import Select from "react-select"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import {
    Container,
    FormGroup,
    Input,
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css"
import Dropzone from "react-dropzone"


import { get_project_sizes, get_project_phases_blocks, get_blocks_against_phases, get_project_floors } from "../../Projects/api/projectApi"
import { get_current_sale, get_payment_against_sale } from '../api/salesApi';
import { get_all_payment_types_for_organisation } from '../../Catalogue/PaymentTypes/api/paymentTypesApi';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
import { getFormatedDate } from '../../../common/CommonFunctions.js';
import { numberWithCommas } from "pages/UI/numeric-helper";

const baseURL = process.env.REACT_APP_API_URL;
var currency_id = null;
var selected_property_id = null;
var paymentAmount = null;
var calculatedCountTotal = 0;
class ViewSale extends Component {
    constructor(props) {
        super(props);
        this.state = {
            optionalExtras: '',
            validation: "",
            current_sale_id: null,
            listData: [],
            currentSaleData: [],
            propertyPrice: '',
            saleDate: '',
            totalPrice: '',
            discountPrice: '',
            customers: [],
            customers_list: "",
            purchasers: [],
            status: '',
            property_id: '',
            sales_agent_id: '',
            currency_id: '',
            grandTotal: 0,
            sales_agent_full_name: '',
            property_name: '',
            project_id: '',
            project_name: '',
            currency_name: '',
            currency_symbol: '',
            offer_detail: '',
            notes: '',
            deleteModal: false,
            redirect: null,
        };
    }

    componentDidMount() {
        var salesId = localStorage.getItem("current_sale_id");
        Promise.all([get_current_sale(localStorage.getItem("current_sale_id"))])
            .then((values) => {
                console.log(values[0]);
                this.setState({ current_sale_id: localStorage.getItem("current_sale_id") })
                this.setState({ totalPrice: values[0].sale_total })
                this.setState({ discountPrice: values[0].sale_discount })
                this.setState({ propertyPrice: values[0].sale_price })
                this.setState({ sales_agent_full_name: values[0].sales_agent_full_name })
                this.setState({ sales_agent_id: values[0].sales_agent_id })
                this.setState({ project_name: values[0].project_name })
                this.setState({ project_id: values[0].project_id })
                this.setState({ property_name: values[0].property_name })
                this.setState({ property_id: values[0].property_id })
                this.setState({ currency_id: values[0].currency_id })
                this.setState({ currency_name: values[0].currency_name })
                this.setState({ currency_symbol: values[0].currency_symbol })
                this.setState({ status: values[0].status })
                this.setState({ saleDate: values[0].sale_date })
                this.setState({ offer_detail: (values[0].offer_detail == null ? '' : values[0].offer_detail) })
                // this.setState({ notes: values[0].notes })
                this.setState({ notes: (values[0].notes == null ? '' : values[0].notes) })

                console.log((values[0].sale_date));

                // Map current purchasers
                var customer_name = "";
                const options = values[0].purchasers.map(d => ({
                    "value": d.customer_id,
                    "label": d.full_name,
                    "id": d.id
                }));

                values[0].purchasers.forEach((rowVal) => {
                    customer_name = (customer_name == '' ? rowVal.full_name : customer_name + ', ' + rowVal.full_name);
                });
                this.setState({ customers_list: customer_name })
                // this.setState({ customers: options })
                // this.setState({ purchasers: options })
                this.setState({ optionalExtras: values[0].optional_extras });
            });

        /*Promise.all([get_payment_against_sale(localStorage.getItem("current_sale_id"))])
        .then((paymentValues) => {
            console.log(paymentValues[0]);
            this.setState({ listData: paymentValues[0] });
        });*/

        this.getPaymentLists(salesId);
        this.getSaleData(salesId);
    }

    async getPaymentLists(salesId) {
        Promise.all([get_payment_against_sale(salesId)]).then((values) => {
            this.setState({ listData: values[0] });
            // this.setState({ late_fee: values[0].late_fee });
        });
    }

    async getSaleData(salesId) {
        Promise.all([get_current_sale(salesId)]).then((values) => {
            console.log(values[0]);
            this.setState({ currentSaleData: values[0] });
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { listData } = this.props;
        if (!isEmpty(listData) && size(prevProps.listData) !== size(listData)) {
            this.setState({ listData: {}, isEdit: false });
        }
    }

    render() {
        const listdata = this.state.listdata;
        const { selectedStatus } = this.state;
        const { selectedPaymentType, currency_symbol } = this.state;
        const columns = [
            // {
            //     dataField: "id",
            //     text: "Id",
            //     sort: true,
            // },
            {
                dataField: "due_date",
                text: "Due Date",
                sort: true,
                // formatter: (cellContent, listdata) => (
                //     <>
                //         {moment(listdata.due_date).format('DD/MM/YYYY')}
                //     </>

                // ),
            },
            {
                dataField: "payment_types_name",
                text: "Payment Type",
                sort: true,
            },
            {
                dataField: "amount",
                text: `${'Amount '} (${currency_symbol ? currency_symbol : ''})`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {numberWithCommas(listdata.amount)}
                    </>
                ),
            },
            {
                dataField: "late_fee",
                text: `${'Late Fee '} (${currency_symbol ? currency_symbol : ''})`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {numberWithCommas(listdata.late_fee)}
                    </>
                ),
            },
            {
                dataField: "paid",
                text: `${'Paid '} (${currency_symbol ? currency_symbol : ''})`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {numberWithCommas(listdata.paid)}
                    </>
                ),
            },
            {
                dataField: "status",
                text: "Status",
                sort: true,
            },

        ];

        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];

        const pageOptions = {
            sizePerPage: 500,
            custom: true,
        };

        // Select All Button operation
        const selectRow = {
            mode: "checkbox",
        };

        const { SearchBar } = Search;
        //meta title
        document.title = "View Sale";
        const { isEdit, deleteModal } = this.state;
        const { validation } = this.state;
        const { selectedGroupSizes } = this.state;
        const { selectedGroupProject } = this.state;
        const { selectedGroupSaleStatus } = this.state;
        const { selectedGroupPhases } = this.state;
        const { selectedGroupBlocks } = this.state;
        const { selectedGroupFloors } = this.state;
        const { selectedGroupFeatures } = this.state;
        const { selectedGroupPropertyTypes } = this.state;
        const { selectedGroupSalesAgent } = this.state;
        const { selectedGroupCustomers } = this.state;
        const { selectedGroupProperties } = this.state;

        const { pick } = this.state
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                console.log(row);
                this.setState({ recordDeleteID: row.id });
                this.setState({ status: row.status });
                this.setState({ receiptDate: row.receipt_date })
                this.setState({ late_fee: row.late_fee })
                this.setState({ amount_edit: row.amount })
                this.setState({
                    total_edit: (parseFloat(row.amount) + parseFloat(row.late_fee)).toFixed(2)
                })
            }
        }
        return (
            <React.Fragment>
                <DeleteModal
                    show={deleteModal}
                    record={`sale payment`}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Sales" breadcrumbItem="View Sale" parentLink="/sales/list" />
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md="12">
                                                <div className="mb-3 row d-flex ">
                                                    <Label className="col-md-2 col-form-label" htmlFor="">Sale Date</Label>
                                                    <div className="col-md-10 mt-2">{this.state.saleDate}</div>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="mb-3 row d-flex select2-container">
                                                    <Label className="col-md-2 col-form-label" htmlFor="">Customers</Label>
                                                    <div className="col-md-10 mt-2">{this.state.customers_list}</div>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="mb-3 row d-flex select2-container">
                                                    <Label className="col-md-2 col-form-label" htmlFor="salesAgent">Sales Agent</Label>
                                                    <div className="col-md-10 mt-2">{this.state.sales_agent_full_name}</div>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="mb-3 row d-flex select2-container">
                                                    <Label className="col-md-2 col-form-label" htmlFor="selectProject">Project</Label>
                                                    <div className="col-md-10 mt-2">{this.state.project_name}</div>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="mb-3 row d-flex select2-container">
                                                    <Label className="col-md-2 col-form-label" htmlFor="selectProperty">Property</Label>
                                                    <div className="col-md-10 mt-2">{this.state.property_name}</div>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="mb-3 row d-flex select2-container">
                                                    <Label className="col-md-2 col-form-label" htmlFor="selectCurrency">Currency</Label>
                                                    <div className="col-md-10 mt-2">{this.state.currency_name}</div>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="mb-3 row d-flex select2-container">
                                                    <Label className="col-md-2 col-form-label" htmlFor="price">Price</Label>
                                                    <div className="col-md-10 mt-2">{currency_symbol}{" "}{numberWithCommas(this.state.propertyPrice)}</div>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="mb-3 row d-flex select2-container">
                                                    <Label className="col-md-2 col-form-label" htmlFor="discount">Discount</Label>
                                                    <div className="col-md-10 mt-2">{currency_symbol}{" "}{numberWithCommas(this.state.discountPrice)}</div>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="mb-3 row d-flex select2-container">
                                                    <Label className="col-md-2 col-form-label" htmlFor="total">Total</Label>
                                                    <div className="col-md-10 mt-2">{currency_symbol}{" "}{numberWithCommas(this.state.totalPrice)}</div>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="mb-3 row d-flex select2-container">
                                                    <Label className="col-md-2 col-form-label" htmlFor="offerDetails">Offer Details</Label>
                                                    <div className="col-md-10 mt-2">{this.state.offer_detail}</div>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="mb-3 row d-flex select2-container">
                                                    <Label className="col-md-2 col-form-label" htmlFor="notes">Notes</Label>
                                                    <div className="col-md-10 mt-2">{this.state.notes}</div>
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className="mb-3 row d-flex select2-container">
                                                    <Label className="col-md-2 col-form-label" htmlFor="notes">Optional Extras</Label>
                                                    <div className="col-md-10 mt-2">{this.state.optionalExtras}</div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">Total</h5>
                                            </div>
                                        </CardBody>
                                        <div className="table-responsive">
                                            <Table className="table mb-4">
                                                <thead>
                                                    <tr>
                                                        <th>Grand Total</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th>Amount</th>
                                                        <th>Late Fee</th>
                                                        <th>Receivable</th>
                                                        <th>Paid</th>
                                                        <th>Remaining</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                            {currency_symbol}{" "}{numberWithCommas(this.state.currentSaleData.sum_of_amount)}
                                                        </td>
                                                        <td>
                                                            {currency_symbol}{" "}{numberWithCommas(this.state.currentSaleData.sum_of_late_fee)}
                                                        </td>
                                                        <td>
                                                            {currency_symbol}{" "}{numberWithCommas(this.state.currentSaleData.sum_of_amount)}
                                                        </td>
                                                        <td>
                                                            {currency_symbol}{" "}{numberWithCommas(this.state.currentSaleData.sum_of_paid)}
                                                        </td>
                                                        <td>
                                                            {currency_symbol}{" "}{numberWithCommas((this.state.currentSaleData.sum_of_amount) - (this.state.currentSaleData.sum_of_paid))}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">List Sale Payments</h5>
                                                <div className="flex-shrink-0">
                                                </div>
                                            </div>
                                        </CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField="id"
                                            columns={columns}
                                            data={this.state.listData}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    columns={columns}
                                                    data={this.state.listData}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col md="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"id"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            rowEvents={rowEvents}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            ref={this.node}
                                                                        />


                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default ViewSale;
