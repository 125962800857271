import axios from "axios";
// import { Link, useHistory } from "react-router-dom";  
import { withRouter } from 'react-router-dom'   

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
const baseURL = process.env.REACT_APP_API_URL;

export const get_all_users = async () => {
    let response = await axios.get(baseURL + "/api/orginasation-user", config);
    return response.data.data;
}

export const get_organisation_users_except_current = async () => {
    let response = await axios.get(baseURL + "/api/orginasation-user-list-without-me", config);
    return response.data.data;
}

export const delete_user = async (user_id) => {
    let response = await axios.delete(baseURL + "/api/orginasation-user/"+user_id, config);
    return response.data.data;
}
export const get_current_orginasation_user = async (user_id) => {
    console.log(user_id);
    let response = await axios.get(baseURL + "/api/orginasation-user/"+user_id, config);
    return response.data.data.orgUser;
}