import React, { useEffect, useState } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import Select from "react-select";
import axios from "axios";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
const baseURL = process.env.REACT_APP_API_URL;
let renderCount = 0;
let featureCount = 0;
// var options;
export default function Features({ control, register, setValue, getValues }) {
    const { fields, append, remove, prepend, swap } = useFieldArray({
        control,
        name: "features"
    });
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isAddButtonVisible, setIsAddButtonVisible] = useState(true);
    useEffect(() => {
        console.log("EFFECT");
        const getOptionsFeatures = async () => {
            const res = await axios.get(baseURL + "/api/property-features-listing", config);
            const data = res.data.data;
            console.log(data);

            let options = data.map(d => ({
                "feature_id": d.id,
                "feature_name": d.feature_name,
                "label": d.feature_name
            }))
            setOptions(options);
            console.log(options);

        }
        getOptionsFeatures();
    }, []);

    const handleChange = (index, selectedOption) => {
        console.log(selectedOption);
        setValue(`features[${index}].feature`, selectedOption);
        console.log(getValues('features'));
        setSelectedOptions((prevSelectedOptions) => [...prevSelectedOptions, selectedOption]);
        // if (selectedOptions.length + 1 === options.length) {
        //     setIsAddButtonVisible(false);
        //   }
    };
    // const filteredOptions = options.filter((option) => !selectedOptions.includes(option));
    // const filteredOptions = options && options.filter((option) => !selectedOptions.includes(option));

    const filteredOptions = options.filter(
        (option) =>
          !fields.some((field) => field.feature?.feature_id === option.feature_id)
      );
    renderCount++;

    return (
        <>
            <ul style={{ listStyle: "none" }}>
                {fields.map((item, index) => {
                    return (
                        <li key={item.id}>
                            <label htmlFor="example-text-input" className="col-md-3 col-form-label">Feature</label>
                            <Controller
                                name={`features[${index}].feature`}
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        isClearable
                                        {...field}
                                        options={filteredOptions}
                                        onChange={(selectedOption) => handleChange(index, selectedOption)}
                                    />
                                )}
                            />

                            <label htmlFor="example-text-input" className="col-md-3 col-form-label">Percentage Value (%)</label>
                            <input placeholder="Percentage Value (%)" type="number" max={99.99} min={1} className="form-control"
                                required
                                pattern="^(?:([2])(\.[0]*)? | (\.[0]{1,2})?|([1])(\.[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?)?)$"
                                {...register(`features.${index}.percentage_value`)}
                            />
                            <button type="button" color="danger"
                                className="btn btn-danger mt-3" onClick={() => {
                                    featureCount--;
                                    remove(index)
                                }}>
                                Delete
                            </button>
                        </li>
                    );
                })}
                <button
                    type="button" color="success"
                    className="btn btn-success mt-2 mb-2"
                    disabled={filteredOptions.length === 1 && fields.length === options.length}
                    onClick={() => {
                        append({ percentage_value: "" });
                        featureCount++;
                    }}
                >
                    Add
                </button>
            </ul>
        </>
    );
}
