import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from "reactstrap";
import { Link, useHistory, Redirect } from "react-router-dom";
import { isEmpty, size } from "lodash";
import { showToast } from '../../UI/ui-toast'
import { get_user_permissions } from "../../../helpers/custom_helper"

import DeleteModal from "../../../components/Common/DeleteModal";
import moment from "moment";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { get_all_deleted_sales_agents, restore_sales_agents } from '../api/salesAgentsApi';
import RestoreModal from "components/Common/RestoreModal";
import default_avatar from "../../../assets/images/avatar_01.png";
var agentView, agentLog, agentRestore;
class DeletedSalesAgentsList extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            page: 1,
            sizePerPage: 10,
            listData: [],
            listdata: "",
            modal: false,
            restoreModal: false,
            recordDeleteID: null,
            redirect: null
        };
        this.toggle = this.toggle.bind(this);
        this.onClickActivityLog = this.onClickActivityLog.bind(this);
    }
    componentDidMount() {
        agentLog = get_user_permissions('sale-agent-logs-view');
        agentView = get_user_permissions('sale-agents-view');
        agentRestore = get_user_permissions('sale-agent-restore');
        window.localStorage.removeItem("current_salesagent")
        Promise.all([get_all_deleted_sales_agents()]).then((values) => {
            console.log(values[0]);
            this.setState({ listData: values[0] });
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { listData } = this.props;
        if (!isEmpty(listData) && size(prevProps.listData) !== size(listData)) {
            this.setState({ listData: {}, isEdit: false });
        }
    }
    onPaginationPageChange = page => {
        if (
            this.node &&
            this.node.current &&
            this.node.current.props &&
            this.node.current.props.pagination &&
            this.node.current.props.pagination.options
        ) {
            this.node.current.props.pagination.options.onPageChange(page);
        }
    };
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    toggleRestoreModal = () => {
        this.setState(prevState => ({
            restoreModal: !prevState.restoreModal,
        }));
    };
    onClickActivityLog = arg => {
        const listdata = arg;
        window.localStorage.setItem("current_salesagent", listdata.id);
        this.setState({ redirect: "/salesagents/log" });
    }
    onClickRestore = listdata => {
        this.setState({ listdata: listdata });
        this.setState({ restoreModal: true });
    };
    handleRestore = () => {
        const { listdata } = this.state;
        if (listdata.id !== undefined) {
            console.log(listdata);
            console.log(this.state.recordDeleteID);
            Promise.all([restore_sales_agents(this.state.recordDeleteID)]).then((values) => {
                showToast("Success", "The sales agent " + listdata.name + " has been restore successfully.", "success");
                this.setState({ redirect: "/salesagents/list" });
            }).catch((error) => {
                showToast("Failed", "Sales Agent Not Restored Successfully", "error");
            });
            this.setState({ restoreModal: false });
        }
    };
    render() {
        const { restoreModal } = this.state;
        const listdata = this.state.listdata;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        const columns = [
            {
                dataField: "full_name",
                text: "Name",
                sort: true,
            },
            {
                dataField: "email",
                text: "Email",
                sort: true,
            },
            {
                dataField: "mobile",
                text: "Mobile Number",
                sort: true,
            },
            {
                dataField: "updated_name",
                text: "Updated By",
                sort: true,
            },
            {
                dataField: "updated_at",
                text: "Updated At",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {moment(listdata.updated_at).format('DD/MM/YYYY h:mma')}
                    </>
                ),
            },
            {
                dataField: "action",
                isDummyField: true,
                text: "Action",
                formatter: (cellContent, listdata) => (
                    <>
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            {agentView ? <>
                                {agentLog ? <>
                                    <li>
                                        <Link
                                            to="#"
                                            className="btn btn-sm btn-soft-primary"
                                            onClick={() => this.onClickActivityLog(listdata)}
                                        >
                                            <i className="mdi mdi-file-document-outline" id="logtooltip" />
                                            <UncontrolledTooltip placement="top" target="logtooltip">
                                                Activity Log
                                            </UncontrolledTooltip>
                                        </Link>
                                    </li>
                                </> : <></>}
                            </> : <></>}
                            {agentRestore ? <>
                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-soft-success"
                                        onClick={() => this.onClickRestore(listdata)}
                                    >
                                        <i className="mdi mdi-restore" id="restoretooltip" />
                                        <UncontrolledTooltip placement="top" target="restoretooltip">
                                            Restore Sales Agent
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                            </> : <></>}
                        </ul>
                    </>
                ),
            },
        ];
        const defaultSorted = [
            {
                dataField: "full_name",
                order: "asc",
            },
        ];
        const pageOptions = {
            sizePerPage: 10,
            custom: true,
        };
        const selectRow = {
            mode: "checkbox",
        };
        const { SearchBar } = Search;
        document.title = "List Sales Agents";
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.setState({ recordDeleteID: row.id });
            }
        }
        return (
            <React.Fragment>
                <RestoreModal
                    show={restoreModal}
                    onDeleteClick={this.handleRestore}
                    record={`sales agent`}
                    onCloseClick={() => this.setState({ restoreModal: false })}
                />
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Sales Agents" breadcrumbItem="Deleted Sales Agents" />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField="id"
                                            columns={columns}
                                            data={this.state.listData}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    columns={columns}
                                                    data={this.state.listData}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col md="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"id"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            selectRow={selectRow}
                                                                            rowEvents={rowEvents}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            ref={this.node}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default DeletedSalesAgentsList;
