import axios from "axios";

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
const baseURL = process.env.REACT_APP_API_URL;

export const get_all_platform_permissions = async () => {
    let all_platform_permissions_response = await axios.get(baseURL + "/api/platform/allPermission", config);
    return all_platform_permissions_response.data.data;
}

export const get_all_platform_roles = async () => {
    let all_platform_roles_response = await axios.get(baseURL + "/api/platform-role", config);
    return all_platform_roles_response.data.data;
}

export const get_platform_active_roles = async () => {
    let all_platform_roles_response = await axios.get(baseURL + "/api/platform-active-role", config);
    return all_platform_roles_response.data.data;
}

export const get_current_platformrole = async (platform_role_id) => {
    let current_platformrole_response = await axios.get(baseURL + "/api/platform-role/" + platform_role_id + "/edit", config);
    return current_platformrole_response.data.data;
}

export const update_platform_role = async (platform_role_id,name) => {
    let update_response = await axios.patch(baseURL + "/api/platform-role/"+platform_role_id,{
        "name": name, 
      }, config);
    return update_response.data.data;
}

export const delete_platform_role = async (platform_role_id) => {
    let delete_response = await axios.delete(baseURL + "/api/platform-role/"+platform_role_id, config);
    return delete_response.data.data;
}