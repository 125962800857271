import React, { useEffect, useState } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import Select from "react-select";
import axios from "axios";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import { get_features_against_project } from "../../api/projectApi"
const baseURL = process.env.REACT_APP_API_URL;
let renderCount = 0;
let featureCount = 0;
export default function Features({ control, register, setValue, getValues }) {
    const { fields, append, remove, prepend, swap } = useFieldArray({
        control,
        name: "features"
    });

    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isAddButtonVisible, setIsAddButtonVisible] = useState(true);
    const [count, setCount] = useState([0]);
    useEffect(() => {
        Promise.all([get_features_against_project(localStorage.getItem("current_project_id"))]).then((values) => {
            console.log(values[0]);
            setValue("features", values[0]);
            // setFeatureName(values[0].feature_name);
            getOptionsFeatures();
        });
    }, []);
    const getOptionsFeatures = async () => {
        const res = await axios.get(baseURL + "/api/property-features-listing", config);
        const data = res.data.data;
        console.log(data);
        setCount(data.length)
        // Get the array of added features
        const addedFeatures = getValues("features");

        // Filter out the options that are already added
        let options = data
            .filter((d) => !addedFeatures.some((f) => f.feature_id === d.id))
            .map((d) => ({
                feature_id: d.id,
                feature_name: d.feature_name,
                label: d.feature_name,
            }));

        setOptions(options);

    };
    
    const handleChange = (index, selectedOption) => {
        console.log(selectedOption);
        setValue(`features[${index}].feature`, selectedOption);
        console.log(getValues('features'));
        setSelectedOptions((prevSelectedOptions) => [...prevSelectedOptions, selectedOption]);
    };
    const filteredOptions = options.filter(
        (option) =>
            !fields.some((field) => field.feature?.feature_id === option.feature_id)
    );
    const filterRecordAfterDelete = () =>{
        console.log('FilterRec');
        getOptionsFeatures();
    }
    console.log('length',filteredOptions.length);
    console.log('length',fields.length);
    console.log('length',options.length);
    renderCount++;

    return (
        <>
            <ul style={{ listStyle: "none" }}>
                {fields.map((item, index) => {
                    return (
                        <li key={item.id}>
                            <label htmlFor="example-text-input" className="col-md-3 col-form-label">Feature</label>
                            <Controller
                                name={`features[${index}].feature`}
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        isClearable
                                        {...field}
                                        options={filteredOptions}
                                        defaultValue={{ label: item.feature_name, value: 0 }}
                                        onChange={(selectedOption) => handleChange(index, selectedOption)}
                                    />
                                )}
                            />
                            <label htmlFor="example-text-input" className="col-md-3 col-form-label">Percentage Value (%)</label>
                            <input placeholder="Enter Value" type="number" max={99.99} min={1} className="form-control"
                                required
                                pattern="^(?:([2])(\.[0]*)? | (\.[0]{1,2})?|([1])(\.[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?)?)$"
                                {...register(`features.${index}.percentage_value`)}
                            />
                            <button type="button" color="danger"
                                className="btn btn-danger mt-3" onClick={() => {
                                    featureCount--;
                                    remove(index);
                                    filterRecordAfterDelete();
                                }}>
                                Delete
                            </button>
                        </li>
                    );
                })}
                <button
                    type="button" color="success"
                    className="btn btn-success mt-2 mb-2"
                    disabled={fields.length === count}
                    onClick={() => {
                        append({ percentage_value: "", id: 0 });
                        featureCount++;
                    }}
                >
                    Add
                </button>
            </ul>
        </>
    );
}
