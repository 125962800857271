// reducers.js

import { combineReducers } from 'redux';
import { FETCH_LOGO_SUCCESS, FETCH_LOGO_FAILURE } from './actions';

const initialLogoState = {
  logoUrl: null,
  error: null
};

const logoReducer = (state = initialLogoState, action) => {
  switch (action.type) {
    case FETCH_LOGO_SUCCESS:
      return {
        ...state,
        logoUrl: action.payload,
        error: null
      };
    case FETCH_LOGO_FAILURE:
      return {
        ...state,
        logoUrl: null,
        error: action.payload
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  logo: logoReducer,
  // other reducers...
});

export default rootReducer;
