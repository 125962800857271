import axios from "axios";

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
import constant from '../../../../constants.json';
const baseURL = process.env.REACT_APP_API_URL;

export const get_all_payment_types = async () => {
    let response = await axios.get(baseURL + "/api/payment-type", config);
    return response.data.data;
}
export const get_all_payment_types_for_organisation = async () => {
    let response = await axios.get(baseURL + "/api/payment-type-listing", config);
    return response.data.data;
}
export const update_payment_type = async (payment_type_id,name,payment_frequency) => {
    let response = await axios.patch(baseURL + "/api/payment-type/"+payment_type_id,{
        "payment_types_name": name,
        "payment_frequency": payment_frequency 
      }, config);
    return response.data.data;
}
export const delete_payment_type = async (payment_type_id) => {
    let response = await axios.delete(baseURL + "/api/payment-type/"+payment_type_id, config);
    return response.data.data;
}