import React, {useEffect} from "react";
import { useFieldArray } from "react-hook-form";
import BlockArray from "./BlockArray";
import {get_project_phases_blocks } from '../../api/projectApi';

let renderCount = 0;
let phaseCount = 1;
export default function Fields({ control, register, setValue, getValues }) {
    const { fields, append, remove, prepend, swap } = useFieldArray({
        control,
        name: "phases"
    });

    renderCount++;
    useEffect(() => {
        Promise.all([get_project_phases_blocks(localStorage.getItem('current_project_id'))]).then((values) => {
            setValue("phases", values[0]);
            phaseCount = values[0].length
        });
    }, []);
    return (
        <>
            <ul style={{ listStyle: "none" }}>
                {fields.map((item, index) => {
                    const isEditable = item.has_phase_property
                    return (
                        <li key={item.id}>
                            <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                            >
                                Phase
                            </label>
                            <input placeholder="Enter Phase" disabled={isEditable} className="form-control" {...register(`phases.${index}.name`)} />

                            <button type="button" color="danger"
                                className="btn btn-danger mt-2 mb-2" onClick={() => {
                                    phaseCount--;
                                    remove(index)
                                }}
                                disabled={isEditable}
                                >
                                Delete
                            </button>
                            {/* <button
                                type="button" color="primary"
                                className="btn btn-primary"
                                onClick={() => {
                                    setValue("test", [
                                        ...(getValues().test || []),
                                        {
                                            name: "append",
                                            BlockArray: [{ field1: "append" }]
                                        }
                                    ]);
                                }}
                            >
                                Add Block
                            </button> */}
                             {index + 1 >= phaseCount ?
                                <>
                                    {index + 1 == phaseCount ? <>
                                        {index >= 1 ?
                                            <button color="primary"
                                                className="btn btn-primary ms-1 mt-2 mb-2" type="button" onClick={() => swap(index - 1, index)}>
                                                Up
                                            </button>
                                            :
                                            <></>
                                        }


                                    </>
                                        :
                                        <>
                                            <button color="primary"
                                                className="btn btn-primary ms-1 mt-2 mb-2" type="button" onClick={() => swap(index, index + 1)}>
                                                Down
                                            </button>
                                        </>
                                    }
                                </>
                                :
                                <>
                                    {index >= 1 ?
                                        <button color="primary"
                                            className="btn btn-primary ms-1 mt-2 mb-2" type="button" onClick={() => swap(index - 1, index)}>
                                            Up
                                        </button>
                                        :
                                        <></>
                                    }

                                    <button color="primary"
                                        className="btn btn-primary ms-1 mt-2 mb-2" type="button" onClick={() => swap(index, index + 1)}>
                                        Down
                                    </button>
                                </>
                            }
                            <BlockArray nestIndex={index} {...{ control, register }} />
                        </li>
                    );
                })}
                <button
                    type="button" color="success"
                    className="btn btn-success mt-2 mb-2"
                    onClick={() => {
                        append({ name: "", id:0 });
                        phaseCount++;
                    }}
                >
                    Add Phase
                </button>
            </ul>
            {/* <span className="counter">Render Count: {renderCount}</span> */}
        </>
    );
}
