import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";
import moment from "moment";
class QuickInfoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <Modal size="md" isOpen={this.props.show} toggle={this.props.onCloseClick} centered={true}>
          <div className="modal-content">
            <ModalBody className="px-4 py-5 text-center">
              <button type="button" onClick={this.props.onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
              <table className="mb-4 mx-auto">
                <tr className="info-modal-table-data-head">
                  <th>Updated By</th>
                  <th>Updated At</th>
                </tr>
                <tr>
                  <td className="info-modal-table-data">{this.props.record.updated_name}</td>
                  <td className="info-modal-table-data">{moment(this.props.record.updated_at).format('DD/MM/YYYY h:mma')}</td>
                </tr>
              </table>
              <div className="hstack gap-2 justify-content-center mb-0">
                <button type="button" className="btn btn-secondary" onClick={this.props.onCloseClick}>Close</button>
              </div>
            </ModalBody>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
QuickInfoModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};
export default QuickInfoModal;
