import React, { Component } from "react";
import { Row, Col, Card, CardBody, UncontrolledTooltip } from "reactstrap";
import { Link, useHistory, Redirect } from "react-router-dom";
import { isEmpty, size } from "lodash";
import { showToast } from '../../../UI/ui-toast'
import DeleteModal from "../../../../components/Common/DeleteModal";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { get_nominees_with_docs, delete_nominee } from '../../api/customersApi';
import { get_user_permissions } from "helpers/custom_helper";
var documentsLogsView;
class ListDocumentsNominees extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            page: 1,
            sizePerPage: 10,
            listData: [],
            listdata: "",
            viewmodal: false,
            modal: false,
            isOpen: false,
            selectDate: false,
            deleteModal: false,
            recordDeleteID: null,
            redirect: null
        };
        this.handleRecordClick = this.handleRecordClick.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleRecordClicks = this.handleRecordClicks.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.onClickActivityLog = this.onClickActivityLog.bind(this);
        this.handleListDocuments = this.handleListDocuments.bind(this);
    }
    componentDidMount() {
        documentsLogsView = get_user_permissions('documents-logs-view');
        window.localStorage.removeItem('current_nominee_id')
        Promise.all([get_nominees_with_docs(localStorage.getItem("current_customer_id"))]).then((values) => {
            console.log(values[0]);
            this.setState({ listData: values[0] });
        });

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { listData } = this.props;
        if (!isEmpty(listData) && size(prevProps.listData) !== size(listData)) {
            this.setState({ listData: {}, isEdit: false });
        }
    }
    onPaginationPageChange = page => {
        if (
            this.node &&
            this.node.current &&
            this.node.current.props &&
            this.node.current.props.pagination &&
            this.node.current.props.pagination.options
        ) {
            this.node.current.props.pagination.options.onPageChange(page);
        }
    };
    handleListDocuments = arg => {
        const listdata = arg;
        window.localStorage.setItem("current_nominee_id", listdata.id);
        this.setState({ redirect: "/customers/nominee/documents" });
    };
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };
    onClickDelete = listdata => {
        this.setState({ listdata: listdata });
        this.setState({ deleteModal: true });
    };
    onClickActivityLog = arg => {
        const listdata = arg;
        window.localStorage.setItem("current_nominee_id", listdata.id);
        this.setState({ redirect: "/nominee-documents/log" });
    }
    handleDeletejob = () => {
        // const { onDeleteJobList } = this.props;
        const { listdata } = this.state;
        if (listdata.id !== undefined) {
            console.log(this.state.recordDeleteID);
            // onDeleteJobList(listdata);
            Promise.all([delete_nominee(this.state.recordDeleteID)]).then((values) => {
                showToast("Success", "The nominee has been deleted successfully.", "success");
                Promise.all([get_nominees_with_docs(localStorage.getItem("current_customer_id"))]).then((values) => {
                    console.log(values[0]);
                    this.setState({ listData: values[0] });
                });
            }).catch((error) => {
                showToast("Failed", "Nominee Not Deleted Successfully", "error");
            });
            this.setState({ deleteModal: false });
        }
    };
    handleRecordClicks = () => {
        this.setState({ listdata: "", isEdit: false });
        this.toggle();
    };
    handleRecordClick = arg => {
        const listdata = arg;
        window.localStorage.setItem("current_nominee_id", listdata.id);
        this.setState({ redirect: "/customer/nominee/edit" });
    };
    render() {

        const { isEdit, deleteModal } = this.state;

        const listdata = this.state.listdata;

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        const columns = [
            {
                dataField: "full_name",
                text: "Name",
                sort: true,
            },
            {
                dataField: "mobile_number",
                text: "Mobile Number",
                sort: true,
            },
            {
                dataField: "primary_email",
                text: "Email",
                sort: true,
            },
            {
                dataField: "customer_full_name",
                text: "Customer",
                sort: true,
            },
            {
                dataField: "action",
                isDummyField: true,
                text: "Documents",
                formatter: (cellContent, listdata) => (
                    <>
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    onClick={() => this.handleListDocuments(listdata)}
                                >
                                    {listdata.document_count} Document(s)
                                </Link>
                            </li>
                        </ul>
                    </>
                ),
            },
            {
                dataField: "logs",
                isDummyField: true,
                text: "Logs",
                formatter: (cellContent, listdata) => (
                    <>
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            {documentsLogsView ? <>
                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-soft-primary"
                                        onClick={() => this.onClickActivityLog(listdata)}
                                    >
                                        <i className="mdi mdi-file-document-outline" id="logtooltip" />
                                        <UncontrolledTooltip placement="top" target="logtooltip">
                                            Activity Log
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                            </> : <></>}
                        </ul>
                    </>
                ),
            },
        ];
        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];
        const pageOptions = {
            sizePerPage: 10,
            // totalSize: productsData.length, // replace later with size(customers),
            custom: true,
        };
        // Select All Button operation
        const selectRow = {
            mode: "checkbox",
        };
        const { SearchBar } = Search;

        //meta title
        document.title = "List Customers";
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.setState({ recordDeleteID: row.id });
            }
        }
        return (
            <React.Fragment>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeletejob}
                    record={`nominee`}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Nominees" breadcrumbItem="List Nominees" />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        {this.state.listData.length == 0 ? <>
                                            <h5 className="mb-0 card-title flex-grow-1">Nominees not found against this customer.</h5>
                                        </> : <>
                                            <CardBody className="border-bottom">
                                                <div className="d-flex align-items-center">
                                                    <h5 className="mb-0 card-title flex-grow-1">List Nominees</h5>
                                                    <div className="flex-shrink-0">
                                                        <Link to="/customer/nominee/add" className="btn btn-primary me-1">Add New Nominee</Link>
                                                    </div>
                                                </div>
                                            </CardBody>
                                            <PaginationProvider
                                                pagination={paginationFactory(pageOptions)}
                                                keyField="id"
                                                columns={columns}
                                                data={this.state.listData}
                                            >
                                                {({ paginationProps, paginationTableProps }) => (
                                                    <ToolkitProvider
                                                        keyField="id"
                                                        columns={columns}
                                                        data={this.state.listData}
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                                <Row className="mb-2">
                                                                    <Col md="4">
                                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                                            <div className="position-relative">
                                                                                <SearchBar
                                                                                    {...toolkitProps.searchProps}
                                                                                />
                                                                                <i className="bx bx-search-alt search-icon" />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xl="12">
                                                                        <div className="table-responsive">
                                                                            <BootstrapTable
                                                                                keyField={"id"}
                                                                                responsive
                                                                                bordered={false}
                                                                                striped={false}
                                                                                defaultSorted={defaultSorted}
                                                                                selectRow={selectRow}
                                                                                rowEvents={rowEvents}
                                                                                classes={
                                                                                    "table align-middle table-nowrap"
                                                                                }
                                                                                headerWrapperClasses={"thead-light"}
                                                                                {...toolkitProps.baseProps}
                                                                                {...paginationTableProps}
                                                                                ref={this.node}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="align-items-md-center mt-30">
                                                                    <Col className="inner-custom-pagination d-flex">
                                                                        <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                                            <SizePerPageDropdownStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                        <div className="text-md-right ms-auto">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                                )}
                                            </PaginationProvider>
                                        </>}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default ListDocumentsNominees;
