import React, { Component } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import default_avatar from "../../../assets/images/avatar_01.png";

import { connect } from "react-redux";
import secureLocalStorage from "react-secure-storage";

var personalData = null;
var finalUserInfo = null;

const getUserName = () => {
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    return obj;
  }
}

class ProfileMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menu: false,
      name: "Admin",
      profilePicture: '',
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }))
  }

  componentDidMount() {
    if (localStorage.getItem('adminUserLoginFlag')) {
      personalData = secureLocalStorage.getItem("headerPlatformUserData");
    }
    else {
      personalData = secureLocalStorage.getItem("headerOrganisationUserData");
    }
    if (personalData != undefined) {
      finalUserInfo = personalData;
      var fullName = finalUserInfo.full_name;
      // var fullName = finalUserInfo.title + (finalUserInfo.first_name==''?'':' '+finalUserInfo.first_name);
      this.setState({ name: fullName })
      this.setState({ profilePicture: (finalUserInfo.picture == null ? default_avatar : finalUserInfo.picture) })
      //this.setState({ profilePicture: default_avatar })
    }
    else {
      const userData = getUserName();
      if (userData) {
        this.setState({ name: userData.username })
        this.setState({ profilePicture: default_avatar })
      }
    }

  }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success) {
      const userData = getUserName();
      if (userData) {
        this.setState({ name: userData.username })
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item"
            id="page-header-user-dropdown"
            tag="button"
          >
            <img
              className="rounded-circle header-profile-user"
              src={this.state.profilePicture}
              alt="Avatar"
            />{" "}
            <span className="d-none d-xl-inline-block ms-1">
              {this.state.name}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem tag="a" href="/user/profile">
              <i className="bx bx-user font-size-16 align-middle ms-1" />
              {this.props.t("Profile")}
            </DropdownItem>
            {/* <DropdownItem tag="a" href="/crypto-wallet">
              <i className="bx bx-wallet font-size-16 align-middle me-1" />
              {this.props.t("My Wallet")}
            </DropdownItem> */}
            <DropdownItem tag="a" href="/user/settings">
              {/* <span className="badge bg-success float-end">11</span> */}
              <i className="bx bx-wrench font-size-17 align-middle me-1" />
              {this.props.t("Settings")}
            </DropdownItem>
            <DropdownItem tag="a" href="/auth-lock-screen">
              <i className="bx bx-lock-open font-size-16 align-middle me-1" />
              {this.props.t("Lock screen")}
            </DropdownItem>
            <div className="dropdown-divider" />
            {localStorage.getItem('adminUserLoginFlag') ? <>
              <Link to="/admin/login" className="dropdown-item">
                <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                <span>{this.props.t("Logout")}</span>
              </Link>
            </> : <>
              <Link to="/logout" className="dropdown-item">
                <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                <span>{this.props.t("Logout")}</span>
              </Link>
            </>}
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    )
  }
}

ProfileMenu.propTypes = {
  t: PropTypes.any,
  success: PropTypes.string
}

const mapStateToProps = state => {
  const { success } = state.Profile
  return { success }
}

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(ProfileMenu))
)
