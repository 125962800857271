import React, { Component } from "react";
import { Row, Col, Table, Card, CardBody, CardTitle, Button, Label, InputGroup, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { isEmpty, size } from "lodash";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
const baseURL = process.env.REACT_APP_API_URL;
import { get_payment_against_sale, get_current_sale_detail } from '../../Sales/api/salesApi';
import { get_all_payment_types_for_organisation } from '../../Catalogue/PaymentTypes/api/paymentTypesApi';
import { get_sale_payments_documents, get_current_payment_document } from '../api/documentsApi';
import { get_user_permissions } from "../../../helpers/custom_helper"
import { numberWithCommas } from "pages/UI/numeric-helper";
var documentsLogsView;
class PaymentList extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            page: 1,
            sizePerPage: 500,
            listData: [],
            listdata: "",
            viewmodal: false,
            modal: false,
            isOpen: false,
            selectDate: false,
            deleteModal: false,
            recordDeleteID: null,
            country_id_of_city: '',
            currentSaleId: 0,
            currentSaleData: [],
            redirect: null,
            paymentReceivedDate: [new Date()],
            receiptDate: [new Date()],
            paymentID: 0,
            statusArray: [
                {
                    "value": 'Received',
                    "label": 'Received'
                },
                {
                    "value": 'Partially Received',
                    "label": 'Partially Received'
                }
            ],
            status: '',
            optionArrayPaymentType: [],
            late_fee: '',
            total_edit: '',
            amount_edit: '',
        };
        this.onClickListDocuments = this.onClickListDocuments.bind(this);
        this.onClickActivityLog = this.onClickActivityLog.bind(this);
    }
    componentDidMount() {
        documentsLogsView = get_user_permissions('documents-logs-view');
        localStorage.removeItem("current_payment_type_id")
        var salesId = localStorage.getItem("payments_sale_id");
        this.setState({ currentSaleId: localStorage.getItem("payments_sale_id") });

        this.getPaymentLists(salesId);
        this.getSaleData(salesId);
        this.getPaymentTypes();
    }

    async getPaymentTypes() {
        Promise.all([get_all_payment_types_for_organisation()]).then((values) => {
            console.log(values[0]);
            const paymentTypeOPtions = values[0].map(d => ({
                "value": d.id,
                "label": d.payment_types_name
            }))
            this.setState({ optionArrayPaymentType: paymentTypeOPtions })
        });
    }
    async getPaymentLists(salesId) {
        Promise.all([get_sale_payments_documents(salesId)]).then((values) => {
            console.log(values[0]);
            this.setState({ listData: values[0] });
            this.setState({ late_fee: values[0].late_fee });
        });
    }

    async getSaleData(salesId) {
        Promise.all([get_current_sale_detail(salesId)]).then((values) => {
            console.log(values[0]);
            this.setState({ currentSaleData: values[0] });
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { listData } = this.props;
        if (!isEmpty(listData) && size(prevProps.listData) !== size(listData)) {
            this.setState({ listData: {}, isEdit: false });
        }
    }
    onPaginationPageChange = page => {
        if (
            this.node &&
            this.node.current &&
            this.node.current.props &&
            this.node.current.props.pagination &&
            this.node.current.props.pagination.options
        ) {
            this.node.current.props.pagination.options.onPageChange(page);
        }
    };
    onClickListDocuments = arg => {
        const listdata = arg;
        window.localStorage.setItem("current_payment_type_id", listdata.id);
        this.setState({ redirect: "/salepayment/documents/list" });
    }
    handleRecordClick = arg => {
        const listdata = arg;
        this.setState({
            listdata: {
                id: listdata.id,
                due_date: listdata.due_date,
                amount: listdata.amount,
                late_fee: listdata.late_fee,
                total: listdata.total,
                paid: listdata.paid,
                receipt_date: listdata.receipt_date,
                status: listdata.status
            },
            isEdit: true,
        });

        this.toggle();
    };
    showToast(title, message, toastType) {
        //position class
        var positionClass = "toast-top-right";
        toastr.options = {
            positionClass: positionClass,
            timeOut: this.state.timeOut,
            extendedTimeOut: this.state.extendedTimeOut,
            showEasing: this.state.showEasing,
            hideEasing: this.state.hideEasing,
            showMethod: this.state.showMethod,
            hideMethod: this.state.hideMethod,
            showDuration: this.state.showDuration,
            hideDuration: this.state.hideDuration,
        };
        if (toastType === "info") toastr.info(message, title);
        else if (toastType === "warning") toastr.warning(message, title);
        else if (toastType === "error") toastr.error(message, title);
        else toastr.success(message, title);
    }
    onClickActivityLog = arg => {
        const listdata = arg;
        window.localStorage.setItem("current_payment_type_id", listdata.id);
        this.setState({ redirect: "/payments-documents/log" });
    }
    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        const columns = [
            {
                dataField: "due_date",
                text: "Due Date",
                sort: true,
            },
            {
                dataField: "payment_types_name",
                text: "Payment Type",
                sort: true,
            },
            {
                dataField: "amount",
                text: `${'Amount '} (${this.state.currentSaleData.currency_symbol ? this.state.currentSaleData.currency_symbol : ''})`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {numberWithCommas(listdata.amount)}
                    </>
                ),
            },
            {
                dataField: "late_fee",
                text: `${'Late Fee '} (${this.state.currentSaleData.currency_symbol ? this.state.currentSaleData.currency_symbol : ''})`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {numberWithCommas(listdata.late_fee)}
                    </>
                ),
            },
            {
                dataField: "paid",
                text: `${'Paid '} (${this.state.currentSaleData.currency_symbol ? this.state.currentSaleData.currency_symbol : ''})`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {numberWithCommas(listdata.paid)}
                    </>

                ),
            },
            {
                dataField: "status",
                text: "Status",
                sort: true,
            },
            {
                dataField: "action",
                isDummyField: true,
                text: "Action",
                formatter: (cellContent, listdata) => (
                    <>
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li>
                                <Link
                                    to="#"
                                    onClick={() => this.onClickListDocuments(listdata)}
                                    className="btn btn-sm btn-soft-info" >
                                    <i className="mdi mdi-file-outline" id="listDocuments" />
                                    <UncontrolledTooltip placement="top" target="listDocuments">
                                        List Documents
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    </>
                ),
            },
            {
                dataField: "log",
                isDummyField: true,
                text: "Logs",
                formatter: (cellContent, listdata) => (
                    <>
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            {documentsLogsView ? <>
                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-soft-primary"
                                        onClick={() => this.onClickActivityLog(listdata)}
                                    >
                                        <i className="mdi mdi-file-document-outline" id="logtooltip" />
                                        <UncontrolledTooltip placement="top" target="logtooltip">
                                            Activity Log
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                            </> : <></>}
                        </ul>
                    </>
                ),
            },

        ];

        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];

        const pageOptions = {
            sizePerPage: 500,
            custom: true,
        };

        // Select All Button operation
        const selectRow = {
            mode: "checkbox",
        };

        const { SearchBar } = Search;

        //meta title
        document.title = "List Payments";
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                console.log(row);
                this.setState({ recordDeleteID: row.id });
                this.setState({ status: row.status });
                this.setState({ receiptDate: row.receipt_date })
                this.setState({ late_fee: row.late_fee })
                this.setState({ amount_edit: row.amount })
                this.setState({
                    total_edit: (parseFloat(row.amount) + parseFloat(row.late_fee)).toFixed(2)
                })
            }
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Financial Documents" breadcrumbItem="List Payments" parentLink="/payments/documents" />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">Sale Details</h5>
                                            </div>
                                        </CardBody>
                                        <CardBody>
                                            <Row>
                                                <Col md="12">
                                                    <div className="mb-3 row d-flex ">
                                                        <Label className="col-md-2 col-form-label" htmlFor="selectDate">Project: </Label>
                                                        <div className="col-md-4 mt-2">{this.state.currentSaleData.project_name}</div>
                                                        <Label className="col-md-2 col-form-label" htmlFor="selectDate">Sale date: </Label>
                                                        <div className="col-md-4 mt-2">{this.state.currentSaleData.sale_date}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <div className="mb-3 row d-flex ">
                                                        <Label className="col-md-2 col-form-label" htmlFor="selectDate">Property: </Label>
                                                        <div className="col-md-4 mt-2">{this.state.currentSaleData.property_name}</div>
                                                        <Label className="col-md-2 col-form-label" htmlFor="selectDate">Price: </Label>
                                                        <div className="col-md-4 mt-2">{this.state.currentSaleData.currency_symbol}{' '}{numberWithCommas(this.state.currentSaleData.sale_price)}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <div className="mb-3 row d-flex ">
                                                        <Label className="col-md-2 col-form-label" htmlFor="selectDate">Discount: </Label>
                                                        <div className="col-md-4 mt-2">{this.state.currentSaleData.currency_symbol}{' '}{numberWithCommas(this.state.currentSaleData.sale_discount)}</div>
                                                        <Label className="col-md-2 col-form-label" htmlFor="selectDate">Final Price: </Label>
                                                        <div className="col-md-4 mt-2">{this.state.currentSaleData.currency_symbol}{' '}{numberWithCommas(this.state.currentSaleData.sale_total)}</div>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </CardBody>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">Total</h5>
                                            </div>
                                        </CardBody>
                                        <div className="table-responsive">
                                            <Table className="table mb-4">
                                                <thead>
                                                    <tr>
                                                        <th>Grand Total</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th>Amount ({this.state.currentSaleData.currency_symbol})</th>
                                                        <th>Late Fee ({this.state.currentSaleData.currency_symbol})</th>
                                                        <th>Receivable ({this.state.currentSaleData.currency_symbol})</th>
                                                        <th>Paid ({this.state.currentSaleData.currency_symbol})</th>
                                                        <th>Remaining ({this.state.currentSaleData.currency_symbol})</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            {numberWithCommas(this.state.currentSaleData.sum_of_amount)}
                                                        </td>
                                                        <td>
                                                            {numberWithCommas(this.state.currentSaleData.sum_of_late_fee)}
                                                        </td>
                                                        <td>
                                                            {numberWithCommas(this.state.currentSaleData.sum_of_amount)}
                                                        </td>
                                                        <td>
                                                            {numberWithCommas(this.state.currentSaleData.sum_of_paid)}
                                                        </td>
                                                        <td>
                                                            {numberWithCommas(this.state.currentSaleData.sum_of_amount - this.state.currentSaleData.sum_of_paid)}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">List Payments</h5>
                                            </div>
                                        </CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField="id"
                                            columns={columns}
                                            data={this.state.listData}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    columns={columns}
                                                    data={this.state.listData}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col md="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"id"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            selectRow={selectRow}
                                                                            rowEvents={rowEvents}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            ref={this.node}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default PaymentList;
