import React, { Component } from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row, UncontrolledAlert
} from "reactstrap";
import axios from "axios";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"

//Import Breadcrumb
import { get_current_currencies, update_project_currencies } from '../../api/projectApi';
const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};

import constant from '../../../../constants.json';
const baseURL = process.env.REACT_APP_API_URL;

class EditProjectCurrency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      optionGroup: [],
      currencies: [],
      project_currencies: [],
      redirect: null,
      id: "",
      name: '',
      isSubmitting: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
  }

  async getOptions() {
    const res = await axios.get(baseURL + "/api/currencies-listing", config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d.id,
      "label": d.currency_name
    }))
    this.setState({ optionGroup: options })
  }
  componentDidMount() {
    Promise.all([get_current_currencies(localStorage.getItem("current_project_id"))]).then((values) => {
      console.log(values[0]);
      // Map current features
      const options = values[0].map(d => ({
        "value": d.currency_id,
        "label": d.currency_name,
        "id": d.id
      }))
      this.setState({ project_currencies: options })
      this.setState({ currencies: options })

    });
    this.getOptions()
  }

  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup });
  
    const currencies = [];
  
    for (let i = 0; i < selectedGroup.length; i++) {
      const selectedCurrency = selectedGroup[i];
      const existingCurrency = this.state.project_currencies.find(
        currency => currency.value === selectedCurrency.value
      );
  
      // const currency_id = existingCurrency ? existingCurrency.value : 0;
      const currency_id = existingCurrency ? existingCurrency.value : selectedCurrency.value;
      const id = existingCurrency ? existingCurrency.id : 0;
  
      currencies.push({ currency_id, id });
    }
    this.setState({ currencies });
  };
  

  handleSubmit(e) {
    e.preventDefault();
    // Check if the form is already submitting
    if (this.state.isSubmitting) {
      return;
    }
    // Set the submitting state to true
    this.setState({ isSubmitting: true });
    if (this.state.currencies == '') {
      var toastTitle = "Failed";
      var toastMessage = "Currency not selected.";
      var toastType = "error";
      this.showToast(toastTitle, toastMessage, toastType);
    } else {
      Promise.all([update_project_currencies(localStorage.getItem("current_project_id"),
        {
          "project_id": localStorage.getItem("current_project_id"),
          "project_currencies": this.state.currencies
        }
      )]).then((response) => {
        var toastTitle = "Success";
        var toastMessage = "Update Currency Successfully";
        var toastType = "success";
        this.showToast(toastTitle, toastMessage, toastType);
        this.setState({ isSubmitting: false });
        localStorage.removeItem('current_project_id');
        localStorage.removeItem('has_phase_blocks');
        localStorage.removeItem('has_sizes');
        localStorage.removeItem('has_floors');
        this.setState({ redirect: "/projects/list" });
      })
        .catch((error) => {
          var toastTitle = "Failed";
          var toastMessage = error.response.data.message;
          var toastType = "error";
          this.showToast(toastTitle, toastMessage, toastType);
          this.setState({ isSubmitting: false });
        });
    }
  }

  //for change tooltip display propery
  onChangeValidation(fieldName, value) {
    const modifiedV = { ...this.state.validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    this.setState({ validation: modifiedV });
  }
  showToast(title, message, toastType) {
    //position class
    var positionClass = "toast-top-right";
    toastr.options = {
      positionClass: positionClass,
      timeOut: 5000,
      extendedTimeOut: 1000,
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
      showDuration: 300,
      hideDuration: 1000,
    };

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }
  render() {
    const { validation } = this.state;
    const { selectedGroup, isSubmitting } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <Row className="mt-5 pt-4">
        <Col xs="12">
          <Card>
            <CardBody style={{ height: "380px" }} className="mb-4">
              <Formik
                enableReinitialize={true}
                initialValues={{

                }}
                validationSchema={Yup.object().shape({

                })}
              >
                {({ errors, status, touched }) => (
                  <Form className="mt-4 needs-validation" onSubmit={this.handleSubmit}>
                    <UncontrolledAlert color="danger" role="alert">
                      You will not delete a selected currency after saving or updating this page.
                    </UncontrolledAlert>
                    <Row>
                      <Col md="12">
                        <div className="mt-3 mb-3 row d-flex select2-container">
                          <Label className="col-md-2 col-form-label">Currency Select</Label>
                          <div className="col-md-10">
                            <Select
                              value={selectedGroup ? selectedGroup : this.state.project_currencies}
                              isMulti={true}
                              onChange={this.handleSelectGroup}
                              options={this.state.optionGroup}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Button
                      style={{ float: "right" }}
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Saving..." : "Save Project"}
                    </Button>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default EditProjectCurrency;
