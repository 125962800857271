import axios from "axios";

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../../constants.json';
const baseURL = process.env.REACT_APP_API_URL;

export const get_all_project_types = async () => {
    let response = await axios.get(baseURL + "/api/project_types", config);
    return response.data.data;
}
export const update_project_types = async (project_type_id,name) => {
    let response = await axios.patch(baseURL + "/api/project_type/update/"+project_type_id,{
        "name": name,
      }, config);
    return response.data.data;
}
export const delete_project_type = async (project_type_id) => {
    let response = await axios.delete(baseURL + "/api/project_type/delete/"+project_type_id, config);
    return response.data.data;
}