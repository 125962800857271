import React, { Component } from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//common
// import AddProjectTypeInputForm from "../child.components/AddProjectTypeInputForm"
const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};

import constant from '../../../../constants.json';
const baseURL = process.env.REACT_APP_API_URL;

class AddDocumentType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      redirect: null
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    const modifiedV = { ...this.state.validation };

    if (stateV === "") {
      modifiedV["stateV"] = false;
    } else {
      modifiedV["stateV"] = true;
    }

    this.setState({ validation: modifiedV });
  }

  //for change tooltip display propery
  onChangeValidation(fieldName, value) {
    const modifiedV = { ...this.state.validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    this.setState({ validation: modifiedV });
  }
  showToast(title, message, toastType) {
    //position class
    var positionClass = "toast-top-right";
    toastr.options = {
      positionClass: positionClass,
      timeOut: this.state.timeOut,
      extendedTimeOut: this.state.extendedTimeOut,
      showEasing: this.state.showEasing,
      hideEasing: this.state.hideEasing,
      showMethod: this.state.showMethod,
      hideMethod: this.state.hideMethod,
      showDuration: this.state.showDuration,
      hideDuration: this.state.hideDuration,
    };

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }
  render() {
    //meta title
    document.title = "Add";
    const { validation } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Document Type" breadcrumbItem="Add Document Type" />

            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        documentTypeName: (this.state && this.state.documentTypeName) || "",
                      }}
                      validationSchema={Yup.object().shape({
                        documentTypeName: Yup.string().required(
                          "Please Enter Your Document Type Name"
                        ),
                      })}
                      onSubmit={values => {
                        // console.log(values);
                        axios.post(baseURL + "/api/document-type", {

                          "document_type_name": values.documentTypeName,
                        }, config)
                          .then((response) => {
                            var toastTitle = "Success";
                            var toastMessage = "The document type has been added successfully.";
                            var toastType = "success";
                            this.showToast(toastTitle, toastMessage, toastType);
                            console.log(response);
                            this.setState({ redirect: "/catalogue/document-types/list" });
                          })
                          .catch((error) => {
                            var toastTitle = "Failed";
                            var toastMessage = "Document Type Not Added";
                            var toastType = "error";
                            this.showToast(toastTitle, toastMessage, toastType);
                            console.log(error);
                          });

                      }}
                    >
                      {({ errors, status, touched }) => (
                        <Form className="needs-validation">
                          <Row>
                            <Col md="12">
                              <FormGroup className="mb-3 d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="documentTypeName">
                                  Document Type Name
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="documentTypeName"
                                    placeholder="Document Type Name"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.documentTypeName && touched.documentTypeName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="documentTypeName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Button color="primary" type="submit" className="float-end">
                            Add Document Type
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default AddDocumentType
