import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import axios from "axios";
import { showToast } from '../../../../../UI/ui-toast'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../../../../../components/Common/Breadcrumb";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: `${token}`
    }
};
import constant from '../../../../../../constants.json';
const baseURL = process.env.REACT_APP_API_URL;
import { get_document_template } from '../../../../api/templatesApi';
class EditPaymentSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validation: "",
            redirect: null,
            project_logo: null,
            company_logo: null,
            show_project_logo: null,
            show_company_logo: null,
            status_templates: constant.status_templates,
            template_name: '',
            address: '',
            email: '',
            phone: '',
            mobile: '',
            website: '',
            company_reg_no: '',
            project_name: '',
            document_sub_type_id: '',
            status: '',
            project_id: '',
            default_template_name: '',
        };
    }
    componentDidMount() {
        Promise.all([get_document_template(localStorage.getItem("current_sample_id"))]).then((values) => {
            console.log(values[0]);
            this.setState({ template_name: values[0].template_name })
            this.setState({ show_project_logo: values[0].project_logo })
            this.setState({ show_company_logo: values[0].company_logo })
            this.setState({ email: values[0].email })
            this.setState({ website: values[0].website })
            this.setState({ company_reg_no: values[0].company_reg_no })
            this.setState({ project_name: values[0].project_name })
            this.setState({ mobile: values[0].mobile })
            this.setState({ phone: values[0].phone })
            this.setState({ status: values[0].status })
            this.setState({ address: values[0].address })
            this.setState({ document_sub_type_id: values[0].document_sub_type_id })
            this.setState({ project_id: values[0].project_id })
            this.setState({ default_template_name: values[0].default_template_name })
        });
    }
    componentDidUpdate() {
    }
    handleProjectLogo = (e) => {
        if (e.target.files && e.target.files[0]) {
            console.log(e.target.files[0]);
            this.setState({ project_logo: e.target.files[0] });
        }
    };
    handleCompanyLogo = (e) => {
        if (e.target.files && e.target.files[0]) {
            console.log(e.target.files[0]);
            this.setState({ company_logo: e.target.files[0] });
        }
    };
    handleSelectedStatusTemplate = selectedStatusTemplate => {
        console.log(selectedStatusTemplate);
        this.setState({ status: selectedStatusTemplate.value })
    }
    handleSubmit = (e) => {

        e.preventDefault();
        if (!this.state.template_name ||
            !this.state.show_project_logo || !this.state.phone ||
            !this.state.mobile ||
            !this.state.email ||
            !this.state.website ||
            !this.state.address) {
            let missingFields = '';
            if (!this.state.template_name) {
                missingFields += '<li>Template Name</li>';
            }
            if (!this.state.show_project_logo) {
                missingFields += '<li>Project Logo</li>';
            }
            if (!this.state.address) {
                missingFields += '<li>Address</li>';
            }
            if (!this.state.email) {
                missingFields += '<li>Email</li>';
            }
            if (!this.state.phone) {
                missingFields += '<li>Phone</li>';
            }
            if (!this.state.mobile) {
                missingFields += '<li>Mobile</li>';
            }
            if (!this.state.website) {
                missingFields += '<li>Website</li>';
            }
            showToast("Failed", "These fields are missing. Please fill the required fields: " + missingFields, "error");
            return false;
        }
        axios.post(baseURL + "/api/update-document-templates/" + window.localStorage.getItem("current_sample_id"), {
            "template_name": this.state.template_name,
            'default_template_name': this.state.default_template_name,
            'project_logo': this.state.project_logo == null ? '' : this.state.project_logo,
            'company_logo': this.state.company_logo == null ? '' : this.state.company_logo,
            'address': this.state.address,
            'phone': this.state.phone,
            'mobile': this.state.mobile,
            'email': this.state.email,
            'website': this.state.website,
            'company_reg_no': this.state.company_reg_no,
            'project_id': this.state.project_id,
            'status': this.state.status,
            'document_sub_type_id': this.state.document_sub_type_id,
        }, config)
            .then((response) => {
                showToast("Success", "The template has been updated successfully.", "success");
                this.setState({ redirect: "/template/documents/sales/payment-schedule/clone-list" });
                console.log(response);
            })
            .catch((error) => {
                showToast("Failed", error.response.data.message , "error")
                console.log(error.response.data.message);
            });
    }
    render() {
        //meta title
        document.title = "Clone Template";
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Payment Schedule" breadcrumbItem="Clone Template" />
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <Formik>
                                            {({ errors, status, touched }) => (
                                                <Form className="needs-validation" onSubmit={this.handleSubmit}>
                                                    <Row>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="template_name">
                                                                    Template Name*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="template_name"
                                                                        placeholder="Template Name"
                                                                        type="text"
                                                                        value={this.state.template_name}
                                                                        onChange={(e) => this.setState({ template_name: e.target.value })}
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="project">
                                                                    Project*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="project"
                                                                        placeholder="Project"
                                                                        type="text"
                                                                        disabled
                                                                        value={this.state.project_name}
                                                                        onChange={(e) => this.setState({ project_name: e.target.value })}
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />

                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="company_reg_no">
                                                                Footer Details*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="company_reg_no"
                                                                        placeholder="Footer Details"
                                                                        type="text"
                                                                        value={this.state.company_reg_no}
                                                                        onChange={(e) => this.setState({ company_reg_no: e.target.value })}
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="address">
                                                                    Address*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="address"
                                                                        placeholder="Address"
                                                                        type="text"
                                                                        value={this.state.address}
                                                                        onChange={(e) => this.setState({ address: e.target.value })}
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="phone">
                                                                    Phone*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="phone"
                                                                        placeholder="Phone"
                                                                        type="text"
                                                                        value={this.state.phone}
                                                                        onChange={(e) => this.setState({ phone: e.target.value })}
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="mobileNumber">
                                                                    Mobile Number*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="mobileNumber"
                                                                        placeholder="Mobile Number"
                                                                        type="text"
                                                                        value={this.state.mobile}
                                                                        onChange={(e) => this.setState({ mobile: e.target.value })}
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.mobileNumber && touched.mobileNumber
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="mobileNumber"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="email">
                                                                    Email*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="email"
                                                                        placeholder="Email"
                                                                        type="email"
                                                                        value={this.state.email}
                                                                        onChange={(e) => this.setState({ email: e.target.value })}
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.email && touched.email
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="email"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>

                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="project_logo">
                                                                    Project Logo*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Input className="form-control form-control" type="file" accept="image/*"
                                                                        onChange={this.handleProjectLogo} />
                                                                    {/* {this.state.pictureSelected == 0 ? <>
                                    <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Picture is not selected, please select first</p>
                                  </> : <></>
                                  } */}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        {this.state.show_project_logo == null ?

                                                            <Col md="12">
                                                                <div className="mb-3 row d-flex">
                                                                    <Label className="col-md-2 col-form-label" htmlFor="project_logo">
                                                                        Existing Project Logo
                                                                    </Label>
                                                                    <div className="col-md-10 mt-2">
                                                                        No Project Logo Exists
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            :
                                                            <Col md="12">
                                                                <div className="mb-3 row d-flex">
                                                                    <Label className="col-md-2 col-form-label" htmlFor="project_logo">
                                                                        Existing Project Logo
                                                                    </Label>
                                                                    <div className="col-md-10">
                                                                        <img className="rounded me-2" alt="200x200" width="200" src={this.state.show_project_logo} data-holder-rendered="true" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        }
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="company_logo">
                                                                    Company Logo
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Input className="form-control form-control" type="file" accept="image/*"
                                                                        onChange={this.handleCompanyLogo} />
                                                                    {/* {this.state.pictureSelected == 0 ? <>
                                    <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Picture is not selected, please select first</p>
                                  </> : <></>
                                  } */}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        {this.state.show_company_logo == null ?

                                                            <Col md="12">
                                                                <div className="mb-3 row d-flex">
                                                                    <Label className="col-md-2 col-form-label" htmlFor="company_logo">
                                                                        Existing Company Logo
                                                                    </Label>
                                                                    <div className="col-md-10 mt-2">
                                                                        No Company Logo Exists
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            :
                                                            <Col md="12">
                                                                <div className="mb-3 row d-flex">
                                                                    <Label className="col-md-2 col-form-label" htmlFor="company_logo">
                                                                        Existing Company Logo
                                                                    </Label>
                                                                    <div className="col-md-10">
                                                                        <img className="rounded me-2" alt="200x200" width="200" src={this.state.show_company_logo} data-holder-rendered="true" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        }
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="website">
                                                                    Website*
                                                                </Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="website"
                                                                        placeholder="Website"
                                                                        type="text"
                                                                        value={this.state.website}
                                                                        onChange={(e) => this.setState({ website: e.target.value })}
                                                                        className={
                                                                            "form-control" +
                                                                            (errors.website && touched.website
                                                                                ? " is-invalid"
                                                                                : "")
                                                                        }
                                                                    />
                                                                    <ErrorMessage
                                                                        name="website"
                                                                        component="div"
                                                                        className="invalid-feedback"
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="userTitle">Status*</Label>
                                                                <div className="col-md-10">
                                                                    <Select
                                                                        onChange={this.handleSelectedStatusTemplate}
                                                                        options={this.state.status_templates}
                                                                        value={this.state.status == 1 ? { label: "Active", value: this.state.status } : { label: "Inactive", value: this.state.status }}
                                                                        classNamePrefix="select2-selection"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Button color="primary" type="submit" className="float-end" >
                                                        Update Template
                                                    </Button>
                                                </Form>
                                            )}
                                        </Formik>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default EditPaymentSchedule;
