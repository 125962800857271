import React, { Component } from "react";
import { Row, Col, Table, Card, CardBody, CardTitle, Button, Label, Container, InputGroup, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { isEmpty, size } from "lodash";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import DeleteModal from "../../../components/Common/DeleteModal";
import { get_user_permissions } from "../../../helpers/custom_helper"
import Flatpickr from "react-flatpickr"
import axios from "axios";
import { showToast } from '../../UI/ui-toast'
import Select from "react-select";
import { get_current_payment_details } from '../api/paymentsApi';

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
const baseURL = process.env.REACT_APP_API_URL;
import { get_payment_against_sale, get_current_sale } from '../../Sales/api/salesApi';
import { get_all_payment_types_for_organisation } from '../../Catalogue/PaymentTypes/api/paymentTypesApi'
import { delete_payment } from '../api/paymentsApi'
import { numberWithCommas } from "pages/UI/numeric-helper";
class PaymentView extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            currentSaleId: 0,
            redirect: null,
            paymentID: 0,
            paymentDueDate: '',
            paymentAmount: 0,
            paymentLatefee: 0,
            paymentTotal: 0,
            paymentStatus: '',
            receiptDate: '',
            updatedDate: '',
            updatedBy: '',
            amountPaid: 0,
            paymentTypesName: '',
            paymentCurrency: '',
        };
    }
    componentDidMount() {
        var paymentId = localStorage.getItem("current_payment_id");
        this.setState({ currentPaymentId: localStorage.getItem("current_payment_id") });
        if (paymentId == null || paymentId == undefined) {
            console.log("paymentId is null");
        }
        else{
            this.getPaymentDetails(paymentId);
        }
    }

    async getPaymentDetails(paymentId) {
        Promise.all([get_current_payment_details(paymentId)]).then((values) => {
          console.log(values[0]);
          var paymentData = values[0];
          //var dueDate = this.getDateFormateMethod(paymentData.due_date);
          this.setState({ paymentDueDate: paymentData.due_date });
          this.setState({ currentSaleId: paymentData.sale_id });
          this.setState({ paymentAmount: paymentData.amount });
          this.setState({ paymentLatefee: paymentData.late_fee });
          this.setState({ paymentStatus: paymentData.status });
          this.setState({ receiptDate: paymentData.receipt_date });
          this.setState({ updatedDate: paymentData.updated_at });
          this.setState({ updatedBy: paymentData.updated_name });
          this.setState({ amountPaid: paymentData.paid });
          this.setState({ paymentTypesName: paymentData.payment_types_name });
          this.setState({ paymentCurrency: paymentData.currency_name });
          this.setState({ paymentTotal: (parseFloat(paymentData.amount) + parseFloat(paymentData.late_fee)) });
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("In didUpdate");
    }
    getDateFormateMethod = (myDate) => {
        var today = myDate
        const dd = today.getDate().toString()
        const mm = today.getMonth() + 1
        const yyyy = today.getFullYear()
        const fromate_date = (today = yyyy + "-" + mm + "-" + dd)
        return fromate_date
    }
    
    handleGotoBack = () => {
        window.localStorage.setItem("payments_sale_id", this.state.currentSaleId);
        this.setState({ redirect: "/salepayments/list" });
    };
    
    render() {
        //meta title
        document.title = "View Payment";
        const { validation } = this.state;
        var nPaymentId = localStorage.getItem("current_payment_id");
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        if (nPaymentId == null || nPaymentId == undefined) {
            console.log("paymentId is null");
            return <Redirect to="/sales/list" />
        }
        return (
          <React.Fragment>
            <div className="page-content">
              <Container fluid={true}>
                <Breadcrumbs title="View Sales" breadcrumbItem="View Payment" parentLink="/sales/list" />
                <Row>
                  <Col xl="12">
                    <Card>
                      <CardBody>
                          <Row>
                            <Col md="12">
                                <div className="d-flex align-items-center">
                                    <h5 className="mb-0 card-title flex-grow-1"></h5>
                                    <div className="flex-shrink-0">
                                        <Link to="#" onClick={() => this.handleGotoBack()} className="btn btn-primary me-1">Go Back</Link>
                                    </div>
                                </div>
                            </Col>
                            <Col md="12">
                                <div className="mb-3 row d-flex">
                                    <Label className="col-md-2 col-form-label" htmlFor="">Due Date:</Label>
                                    <div className="mt-2 col-md-10">{this.state.paymentDueDate}</div>
                                </div>
                            </Col>
                            <Col md="12">
                                <div className="mb-3 row d-flex">
                                    <Label className="col-md-2 col-form-label" htmlFor="">Payment Type:</Label>
                                    <div className="mt-2 col-md-10">{this.state.paymentTypesName}</div>
                                </div>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="payment_amount">Amount:</Label>
                                <div className="mt-2 col-md-10">{this.state.paymentCurrency}&nbsp;{numberWithCommas(this.state.paymentAmount)}</div>
                              </div>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="late_fee">Late Fee:</Label>
                                <div className="mt-2 col-md-10">{this.state.paymentCurrency}&nbsp;{numberWithCommas(this.state.paymentLatefee)}</div>
                              </div>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="payment_total">Total:</Label>
                                <div className="mt-2 col-md-10">{this.state.paymentCurrency}&nbsp;{numberWithCommas(this.state.paymentTotal)}</div>
                              </div>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="email">Status:</Label>
                                <div className="mt-2 col-md-10">{this.state.paymentStatus}</div>
                              </div>
                            </Col>
                            {this.state.paymentStatus == 'Due' ? <></>
                            :
                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="email">Received Date:</Label>
                                <div className="mt-2 col-md-10">{this.state.receiptDate}</div>
                              </div>
                            </Col>
                            }
                            {this.state.paymentStatus == 'Due' ? <></>
                            :
                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="email">Received Amount:</Label>
                                <div className="mt-2 col-md-10">{this.state.paymentCurrency}&nbsp;{numberWithCommas(this.state.amountPaid)}</div>
                              </div>
                            </Col>
                            }
                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="email">Updated Date:</Label>
                                <div className="mt-2 col-md-10">{moment(this.state.updatedDate).format('DD/MM/YYYY')}</div>
                              </div>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="email">Updated By:</Label>
                                <div className="mt-2 col-md-10">{this.state.updatedBy}</div>
                              </div>
                            </Col>
                          </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </React.Fragment>
        );
    }
}
export default PaymentView;
