import React, { Component } from "react";
import { Link, useHistory,Redirect } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Container, FormGroup,UncontrolledTooltip } from "reactstrap";
import axios from "axios";
import { showToast } from '../../../UI/ui-toast'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import moment from "moment";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { get_current_nominee, get_nominee_identity_documents } from '../../api/customersApi';
const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};

import constant from '../../../../constants.json';
const baseURL = process.env.REACT_APP_API_URL;
var newData = [];
class ViewNominee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: { document_sub_type_name: '', document_name: '', file: '', id: 0 },
      listData: [],
      tableData: [],
      validation: "",
      optionGroupCountry: [],
      optionGroupCity: [],
      optionGroupIdentity: [],
      optionGroupNationalities: [],
      fullName: '',
      c_telephone_home: '',
      c_mobile_number: '',
      c_telephone_office: '',
      c_address_line_1: '',
      c_address_line_2: '',
      c_primary_email: '',
      c_secondary_email: '',
      c_identity_number: '',
      c_nationality: '',
      c_postcode: '',
      c_city_title: '',
      c_country_title: '',
      c_org_id: '',
      c_customer_id: '',
      redirect: null,
      titlesArray: constant.titles_list,
      id: "",
      name: '',
    };
    this.onClickDownload = this.onClickDownload.bind(this);
  }

// Duplicate async function use for get NomineesDocuments. get document promise already use line no: 102  {get_nominee_identity_documents} and just set state this response nothing more.
//   async getNomineeDocuments(n_id) {
//     const res = await axios.get(baseURL + "/api/nominee-documents/" + n_id, config);
//     const data = res.data.data
//     console.log(data);
//     this.setState({ tableData: data })
//   }
  componentDidMount() {
    Promise.all([get_current_nominee(localStorage.getItem("current_nominee_id"))]).then((values) => {
      console.log(values[0]);
      this.setState({ c_mobile_number: values[0].mobile_number });
      this.setState({ c_telephone_home: values[0].telephone_home });
      this.setState({ fullName: values[0].full_name });
      this.setState({ c_primary_email: values[0].primary_email });
      this.setState({ c_secondary_email: values[0].secondary_email });
      this.setState({ c_telephone_office: values[0].telephone_office });
      this.setState({ c_address_line_1: values[0].address_line_1 });
      this.setState({ c_address_line_2: values[0].address_line_2 });
      this.setState({ c_nationality: values[0].nationality });
      this.setState({ c_identity_type: values[0].identity_type });
      this.setState({ c_identity_number: values[0].identity_number });
      this.setState({ c_postcode: values[0].postcode });
      this.setState({ c_org_id: values[0].organisation_id });
      this.setState({ c_customer_id: values[0].customer_id });
      this.setState({ c_country_title: values[0].country });
      this.setState({ c_city_title: values[0].city });
    //   this.getNomineeDocuments(values[0].id)
    });
    
    Promise.all([get_nominee_identity_documents(localStorage.getItem("current_nominee_id"))]).then((values) => {
      console.log(values[0]);
      this.setState({ listData: values[0] });
    });
  }
  
  onClickDownload = listdata => {
        const contentType = listdata.document_path.split(';')[0].split(':')[1];
        const base64Content = listdata.document_path.split(',')[1];
        const byteCharacters = atob(base64Content);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const extension = listdata.document_path.split(';')[0].split('/')[1];
        const blob = new Blob([byteArray], { type: contentType });
        const fileName = `${listdata.document_name}.${extension}`;
      
        setTimeout(() => {
            if (contentType.startsWith('image/png') || contentType.startsWith('image/jpg') || contentType.startsWith('image/jpeg')) {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.target = '_blank';
                a.rel = 'noopener noreferrer';
                a.click();
            } else {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.target = '_blank';
                a.rel = 'noopener noreferrer';
                a.click();
            }
        }, 100);
  };

  render() {

    const { isEdit, deleteModal } = this.state;
    const listdata = this.state.listdata;

    const columns = [
            {
                dataField: "id",
                text: "Id",
                sort: true,
            },
            {
                dataField: "document_name",
                text: "Document Name",
                sort: true,
            },
            {
                dataField: "document_sub_type_name",
                text: "Document Type",
                sort: true,
            },
            {
                dataField: "updated_name",
                text: "Updated By",
                sort: true,
            },
            {
                dataField: "updated_at",
                text: "Updated At",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {moment(listdata.updated_at).format('DD/MM/YYYY h:mma')}
                    </>

                ),
            },
            {
                dataField: "action",
                isDummyField: true,
                text: "Action",
                formatter: (cellContent, listdata) => (
                    <>
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li>
                                <Link
                                    to="#"
                                    onClick={() => this.onClickDownload(listdata)}
                                    className="btn btn-sm btn-soft-info" >
                                    <i className="mdi mdi-file-download-outline" id="downloadDocument" />
                                    <UncontrolledTooltip placement="top" target="downloadDocument">
                                        Download Document
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    </>
                ),
            },

        ];

        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];

        const pageOptions = {
            sizePerPage: 10,
            custom: true,
        };

        // Select All Button operation
        const selectRow = {
            mode: "checkbox",
        };
    const { SearchBar } = Search;

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            this.setState({ recordDeleteID: row.id });

        }
    }

    //meta title
    document.title = "View Nominee";
    const { validation } = this.state;
    const { selectedCustomerTitle } = this.state;
    const { selectedGroupCountry } = this.state;
    const { selectedGroupCity } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Nominees" breadcrumbItem="View Nominee" parentLink="/customer/nominees/list" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                
                    <Row>
                      <Col md="12">
                          <div className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="">Name:</Label>
                              <div className="mt-2 col-md-10">{this.state.fullName}</div>
                          </div>
                      </Col>
                      <Col md="12">
                          <div className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="">Identity Type:</Label>
                              <div className="mt-2 col-md-10">{this.state.c_identity_type}</div>
                          </div>
                      </Col>
                      <Col md="12">
                          <div className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="">Identity Number:</Label>
                              <div className="mt-2 col-md-10">{this.state.c_identity_number}</div>
                          </div>
                      </Col>
                      <Col md="12">
                          <div className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="">Nationality:</Label>
                              <div className="mt-2 col-md-10">{this.state.c_nationality}</div>
                          </div>
                      </Col>
                      <Col md="12">
                          <div className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="">Address:</Label>
                              <div className="mt-2 col-md-10">
                                {this.state.c_address_line_1}
                                {this.state.c_address_line_2 == null ? <></>
                                  :
                                  <>,&nbsp;{this.state.c_address_line_2}</>
                                }
                              </div>
                          </div>
                      </Col>
                      <Col md="12">
                          <div className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="">Country:</Label>
                              <div className="mt-2 col-md-10">{this.state.c_country_title}</div>
                          </div>
                      </Col>
                      <Col md="12">
                          <div className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="">City:</Label>
                              <div className="mt-2 col-md-10">{this.state.c_city_title}</div>
                          </div>
                      </Col>
                      <Col md="12">
                          <div className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="">Postcode:</Label>
                              <div className="mt-2 col-md-10">{this.state.c_postcode}</div>
                          </div>
                      </Col>
                      <Col md="12">
                          <div className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="">Phone Home:</Label>
                              <div className="mt-2 col-md-10">{this.state.c_telephone_home}</div>
                          </div>
                      </Col>
                      <Col md="12">
                          <div className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="">Phone Office:</Label>
                              <div className="mt-2 col-md-10">{this.state.c_telephone_office}</div>
                          </div>
                      </Col>
                      <Col md="12">
                          <div className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="">Mobile Number:</Label>
                              <div className="mt-2 col-md-10">{this.state.c_mobile_number}</div>
                          </div>
                      </Col>
                      <Col md="12">
                          <div className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="">Primary Email:</Label>
                              <div className="mt-2 col-md-10">{this.state.c_primary_email}</div>
                          </div>
                      </Col>
                      <Col md="12">
                          <div className="mb-3 row d-flex">
                              <Label className="col-md-2 col-form-label" htmlFor="">Secondary Email:</Label>
                              <div className="mt-2 col-md-10">{this.state.c_secondary_email}</div>
                          </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <div className="d-flex align-items-center mb-3 mt-3">
                          <h5 className="mb-0 card-title flex-grow-1">Identity Documents</h5>
                        </div>
                            <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField="id"
                                columns={columns}
                                data={this.state.listData}
                            >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                        keyField="id"
                                        columns={columns}
                                        data={this.state.listData}
                                        search
                                    >
                                        {toolkitProps => (
                                            <React.Fragment>
                                                <Row className="mb-2">
                                                    <Col md="4">
                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                            <div className="position-relative">
                                                                <SearchBar
                                                                    {...toolkitProps.searchProps}
                                                                />
                                                                <i className="bx bx-search-alt search-icon" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col xl="12">
                                                        <div className="table-responsive">
                                                            <BootstrapTable
                                                                keyField={"id"}
                                                                responsive
                                                                bordered={false}
                                                                striped={false}
                                                                defaultSorted={defaultSorted}
                                                                rowEvents={rowEvents}
                                                                classes={
                                                                    "table align-middle table-nowrap"
                                                                }
                                                                headerWrapperClasses={"thead-light"}
                                                                {...toolkitProps.baseProps}
                                                                {...paginationTableProps}
                                                                ref={this.node}
                                                            />
                                                            
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row className="align-items-md-center mt-30">
                                                    <Col className="inner-custom-pagination d-flex">
                                                    <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                            <SizePerPageDropdownStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                        <div className="text-md-right ms-auto">
                                                            <PaginationListStandalone
                                                                {...paginationProps}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )}
                                    </ToolkitProvider>
                                )}
                            </PaginationProvider>
                      </Col>
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default ViewNominee;
