import React, { Component } from 'react';
import { get_project_sizes, get_all_projects, get_current_currencies } from "../../Projects/api/projectApi"
import { get_all_payment_types_for_organisation } from "../../Catalogue/PaymentTypes/api/paymentTypesApi"
import { post_payment_plan, get_current_payment_plans, update_payment_plan } from '../api/paymentPlanApi'
import { showToast } from '../../UI/ui-toast';
import { Redirect } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Select from "react-select"
import axios from "axios";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
import { numberWithCommas } from 'pages/UI/numeric-helper';
const baseURL = process.env.REACT_APP_API_URL;
class EditPaymentPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: { PaymentType: '', no_of_recurrences: '', order: '', id: 0 },
            currentPaymentId: 0,
            tableData: [],
            paymentTypesArray: [],
            optionGroupProjects: [],
            optionGroupSizes: [],
            currencies: [],
            tableHead: [],
            amount: [],
            project_size_id: '',
            project_id: '',
            project_name: '',
            size_name: '',
            payment_plan_name: '',
            has_payment_types: 0,
            has_sizes: 0,
            redirect: null,
            saved_payment_types: [],
            payement_types_listing: [],
            // currenciesTotals: '',
        };
    }

    componentDidMount() {
        this.setState({ currentPaymentId: localStorage.getItem('current_payment_plan') })
        Promise.all([get_current_payment_plans(localStorage.getItem('current_payment_plan'))]).then((values) => {
            this.setState({ project_size_id: values[0].size_id })
            this.setState({ project_id: values[0].project_id })
            this.setState({ project_name: values[0].project_name })
            this.setState({ size_name: values[0].size_name })
            this.setState({ payment_plan_name: values[0].name })

            this.setState({ saved_payment_types: values[0].payment_types })
            // this.setState({ tableData: values[0].payment_types })
            const tableheading = values[0].payment_types[0].currencies.map(d => ([d.currency_name]))
            this.setState({ tableHead: tableheading })
            const tableAmount = values[0].payment_types.map(p => (
                // [p.currencies]
                p.currencies.map(c => ([c.amount]))
            ))
            this.setState({ tableAmount: tableAmount })
            this.getProjectDetail(values[0].project_id, values[0].payment_types);
        });
        // Promise.all([get_all_projects()]).then((values) => {
        //     const projectOptions = values[0].map(d => ({
        //         "value": d.id,
        //         "label": d.name
        //     }))
        //     this.setState({ optionGroupProjects: projectOptions })
        // });
        // this.state.saved_payment_types[0].payment_type_name
        setTimeout(() => {
            Promise.all([get_all_payment_types_for_organisation()]).then((values) => {
                // const paymentTypesOptions = values[0].map(d => ([d.payment_types_name]))
                // this.setState({ paymentTypesArray: paymentTypesOptions })

                const payment_types_listing = values[0].map(data => ({
                    "payment_types_name": data.payment_types_name,
                    "payment_frequency": data.payment_frequency
                }))
                // section for compare array for dropdown options.
                const paymentTypesOptions = values[0].filter(d => {
                    // Check if the payment type is already added in the table
                    const isAdded = this.state.saved_payment_types.some(tableType => tableType.payment_type_name === d.payment_types_name);

                    // If the payment type is not added, add it to the options
                    return !isAdded;
                }).map(d => ([d.payment_types_name]))

                this.setState({ paymentTypesArray: paymentTypesOptions });
                this.setState({ payment_types_listing: payment_types_listing });
            });
        }, 3000);
    }

    setPlanPaymentTypeData(planPaymentTypes, projectCurrencies) {
        var updatedData = [];
        planPaymentTypes.forEach((rowData, index) => {
            var nCurrencies = rowData.currencies;
            var currencyArray = [];
            projectCurrencies.forEach((pcData, pcIndex) => {
                var currencyFound = 0;
                var currencyObj = null;

                nCurrencies.forEach((cData, cIndex) => {
                    if (pcData.currency_id == cData.currency_id) {
                        currencyFound = 1;
                        currencyObj = cData;
                    }
                })
                if (currencyFound == 1) {
                    // If found the keep the currencyObj
                }
                else {
                    currencyObj = {
                        id: 0,
                        amount: 0,
                        currency_id: pcData.currency_id,
                        currency_name: pcData.currency_name,
                    }

                }
                currencyArray[pcIndex] = currencyObj;
            })
            rowData.currencies = currencyArray;
            updatedData[index] = rowData;
        });

        this.setState({ tableData: updatedData })
    }

    componentDidUpdate() {
        this.state.tableData.forEach((rowData, index) => {
            if (document.getElementById("PaymentType-" + index)) {
                if (rowData.payment_type_name == undefined) {
                    document.getElementById("PaymentType-" + index).value = rowData.payment_type_id;
                }
                else {
                    document.getElementById("PaymentType-" + index).value = rowData.payment_type_name;
                }
            }
        })

        // const currenciesTotals = this.calculateTotals()
        // this.setState({currenciesTotals: currenciesTotals})
    }

    async getProjectDetail(project_id, payment_types) {
        const res = await axios.get(baseURL + "/api/project-details/" + project_id, config);
        const data = res.data.data;
        this.setState({ has_sizes: data.has_sizes });

        if (data.has_sizes) {
            this.setState({ project_id: project_id })
            Promise.all([get_project_sizes(project_id)]).then((values) => {
                this.getOptionsSizes(values[0]);
            });
        }
        const currencies = await axios.get(baseURL + "/api/project-currencies/" + project_id, config);
        const currenciesData = currencies.data.data;
        this.setState({ currencies: currenciesData });
        this.setPlanPaymentTypeData(payment_types, currenciesData);
        const tableheading = currenciesData.map(d => ([d.currency_name]))
        this.setState({ tableHead: tableheading })
        this.setState({
            formData: { PaymentType: '', no_of_recurrences: '', order: '', currenciesData, id: 0 }
        });
        // const listItems = currenciesData?.map((d) =>
        //     <li key={d.currency_id}>{d.currency_name}</li>
        // );
    }
    getOptionsSizes(sizes) {
        const options = sizes.map(d => ({
            "value": d.id,
            "label": d.name
        }))
        this.setState({ optionGroupSizes: options })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { formData, tableData, payment_types_listing } = this.state;
        console.log(formData);
        console.log(payment_types_listing);

        const selectedPaymentType = formData.PaymentType;
        const isOneTimePayment = payment_types_listing.some(
            (paymentType) =>
                paymentType.payment_types_name === selectedPaymentType &&
                paymentType.payment_frequency === "One-time"
        );

        if (isOneTimePayment) {
            if (formData.no_of_recurrences == 1) {

                var nCurrencies = [];
                formData.currenciesData.map((data, index) => (
                    nCurrencies[index] = {
                        amount: formData[data.currency_name],
                        currency_name: data.currency_name,
                        currency_id: data.currency_id,
                        id: 0
                    }
                ));
                var formattedFormData = {
                    payment_type_id: formData.PaymentType,
                    payment_type_name: formData.PaymentType,
                    no_of_recurrences: formData.no_of_recurrences,
                    order: formData.order,
                    currencies: nCurrencies,
                    id: 0

                };
                var paymentTypesArray = this.state.paymentTypesArray;
                // Find the index of the payment_type_name in optionsArray
                var index = paymentTypesArray.findIndex(option => option[0] === formattedFormData.payment_type_name);
                // Remove the element at the found index
                if (index !== -1) {
                    paymentTypesArray.splice(index, 1);
                }
                this.setState({
                    tableData: [...tableData, formattedFormData],
                    formData: { PaymentType: '', no_of_recurrences: '', order: '', currenciesData: this.state.currencies, id: 0 }
                });
                for (var i = 0; i <= this.state.currencies.length - 1; i++) {
                    var price_input = document.getElementById("price-" + i)
                    price_input.value = ""
                }
            } else {
                showToast("Failed", "The payment type frequencey is one-time by have added 0 occurance. Please add 1 to proceed further.", "error");
                return false;
            }
        } else {
            if (formData.no_of_recurrences <= 0) {
                showToast("Failed", "This Payment Type occurance cannot be zero or less than zero.", "error");
                return false;
            } else {
                var nCurrencies = [];
                formData.currenciesData.map((data, index) => (
                    nCurrencies[index] = {
                        amount: formData[data.currency_name],
                        currency_name: data.currency_name,
                        currency_id: data.currency_id,
                        id: 0
                    }
                ));
                var formattedFormData = {
                    payment_type_id: formData.PaymentType,
                    payment_type_name: formData.PaymentType,
                    no_of_recurrences: formData.no_of_recurrences,
                    order: formData.order,
                    currencies: nCurrencies,
                    id: 0

                };
                var paymentTypesArray = this.state.paymentTypesArray;
                // Find the index of the payment_type_name in optionsArray
                var index = paymentTypesArray.findIndex(option => option[0] === formattedFormData.payment_type_name);
                // Remove the element at the found index
                if (index !== -1) {
                    paymentTypesArray.splice(index, 1);
                }
                this.setState({
                    tableData: [...tableData, formattedFormData],
                    formData: { PaymentType: '', no_of_recurrences: '', order: '', currenciesData: this.state.currencies, id: 0 }
                });
                for (var i = 0; i <= this.state.currencies.length - 1; i++) {
                    var price_input = document.getElementById("price-" + i)
                    price_input.value = ""
                }
            }
        }
    };
    submitPaymentPlanData = () => {
        var myTableData = this.state.tableData
        for (var i = 0; i <= this.state.currencies.length - 1; i++) {
            // Commented the blow code, as this appears illogical
            // delete myTableData[i].currenciesData
            var price_input = document.getElementById("price-" + i)
            price_input.value = ""
        }
        var formData = {}
        formData.name = this.state.payment_plan_name
        formData.size_id = this.state.project_size_id
        formData.project_id = this.state.project_id
        formData.payment_types = myTableData
        if (!formData.name || !formData.size_id || !formData.project_id || !formData.payment_types) {
            let missingFields = '';
            if (!formData.name) {
                missingFields += '<li>Payment Plan Name</li>';
            }
            if (!formData.project_id) {
                missingFields += '<li>Project</li>';
            }
            if (!formData.size_id) {
                missingFields += '<li>Project Size</li>';
            }
            if (!formData.payment_types) {
                missingFields += '<li>Payment Types</li>';
            }
            showToast("Failed", "These fields are missing. Please fill the required fields: " + missingFields, "error");
            return false;
        }
        Promise.all([update_payment_plan(this.state.currentPaymentId, formData)]).then((values) => {
            showToast("Success", "Payment plan has been updated successfully.", "success");
            this.setState({ redirect: "/payment-plan/list" });
        });
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        const { formData } = this.state;
        this.setState({ formData: { ...formData, [name]: value } });
    };
    // This function is an event handler that is triggered when the value of the "no_of_recurrences" input field is changed
    // It takes in the index of the row in which the input field is located and the event object
    // It updates the "no_of_recurrences" value of the corresponding row in the state's "tableData" array with the new value entered by the user
    handleChangeRecurrences = (index, event) => {
        const updatedTableData = [...this.state.tableData]
        console.log(updatedTableData);
        const { payment_types_listing } = this.state;
        const isOneTimePayment = payment_types_listing.some(
            (paymentType) =>
                paymentType.payment_types_name === updatedTableData[index].payment_type_name &&
                paymentType.payment_frequency === "One-time"
        );

        if (isOneTimePayment) {
            if (event.target.value == 1) {
                updatedTableData[index].no_of_recurrences = event.target.value;
                this.setState({ tableData: updatedTableData })
            } else {
                showToast("Failed", "The payment type frequencey is one-time by have added 0 occurance. Please add 1 to proceed further.", "error");
                document.getElementById('occurance-' + index).value = '1';
                updatedTableData[index].no_of_recurrences = event.target.value;
                this.setState({ tableData: updatedTableData })
            }
        } else {
            if (event.target.value <= 0) {
                showToast("Failed", "This Payment Type occurance cannot be zero or less than zero.", "error");
                return false;
            } else {
                updatedTableData[index].no_of_recurrences = event.target.value;
                this.setState({ tableData: updatedTableData })
            }
        }
    }
    // This function is an event handler that is triggered when the value of the "order" input field is changed
    // It takes in the index of the row in which the input field is located and the event object
    // It updates the "order" value of the corresponding row in the state's "tableData" array with the new value entered by the user
    handleChangeOrder = (index, event) => {
        const updatedTableData = [...this.state.tableData]
        updatedTableData[index].order = event.target.value;
        this.setState({ tableData: updatedTableData })
    }
    // This function is an event handler that is triggered when the value of a currency input field is changed
    // It takes in the index of the row in which the input field is located, the index of the currency object within the "currencies" array, and the event object
    // It updates the "amount" value of the corresponding currency object in the state's "tableData" array with the new value entered by the user
    handleChangeCurrencies = (index, i, event) => {
        const updatedTableData = [...this.state.tableData]
        updatedTableData[index].currencies[i].amount = event.target.value;
        this.setState({ tableData: updatedTableData })
    }
    // This function is an event handler that is triggered when the value of the "payment_type_name" input field is changed
    // It takes in the index of the row in which the input field is located and the event object
    // It updates the "payment_type_name" value of the corresponding row in the state's "tableData" array with the new value entered by the user
    handleChangePaymentType = (index, event) => {
        let paymentTypesArray = this.state.paymentTypesArray
        paymentTypesArray.push([this.state.tableData[index].payment_type_name])
        var ind = paymentTypesArray.findIndex(option => option[0] === event.target.value);
        if (ind !== -1) {
            paymentTypesArray.splice(ind, 1);
        }
        this.setState({ paymentTypesArray: paymentTypesArray })
        const updatedTableData = [...this.state.tableData]
        updatedTableData[index].payment_type_name = event.target.value;
        this.setState({ tableData: updatedTableData })
    }
    handleDelete = (index, data) => {
        var newTableData = this.state.tableData
        let paymentTypesArray = this.state.paymentTypesArray
        paymentTypesArray.push([newTableData[index].payment_type_name])
        this.setState({ paymentTypesArray: paymentTypesArray })
        delete newTableData[index]

        // const { tableData } = this.state;
        // var newTableData = [...tableData];
        // newTableData.splice(index, 1);
        // update index values
        // for (let i = index; i < newTableData.length; i++) {
        //     newTableData[i].index = i;
        // }
        // document.getElementById('table-record-' + index).remove();


        // this.setState({tableData: newArray})
        this.setState({ tableData: newTableData })
        var currentTableData = this.state.tableData;
        var newArray = [];
        // var newTableData = this.state.tableData;
        var counter = 0;
        currentTableData.forEach((rowData, c_index) => {
            // newArray.push(rowData)
            newArray[counter] = rowData;
            counter++;
            // this.setState({ tableData: newArray })
        })
        this.setState({ tableData: newArray })
    };
    //   Test
    // handleDelete = (index, data) => {
    //     const { tableData } = this.state;
    //     var newTableData = tableData;
    //     delete newTableData[index];
    //     let tempArr = [];
    //     for (let i = 0; i < newTableData.length; i++) {
    //         if (newTableData[i]) {
    //             tempArr.push(newTableData[i]);
    //         }
    //     }
    //     tempArr.sort();
    //     let newArr = [];
    //     for (let i = 0; i < tempArr.length; i++) {
    //         newArr.push(tempArr[i]);
    //     }
    //     this.setState({ tableData: newTableData })
    //     document.getElementById('table-record-' + index).remove();
    // };

    // test
    // handleDelete = (index, data) => {
    //     const { tableData } = this.state;
    //     var newTableData = tableData;
    //     delete newTableData[index];
    //     const newTableData = tableData.filter((data, i) => i !== index);
    //     newTableData.sort((a, b) => a.order - b.order); 
    //     newTableData.sort((a, b) => a.order - b.order);
    //     this.setState({ tableData: newTableData })
    //     this.setState({ tableData: newTableData }, () => {
    //         this.setState({tableData: newTableData})
    //       });
    //     var tableRecord = document.getElementById('table-record-' + index).remove();
    // };

    // old
    // handleDelete = (index) => {
    //     const { tableData } = this.state;
    //     const newTableData = [...tableData]; // Create a copy of the array
    //     newTableData.splice(index, 1); // Remove the element at the given index
    //     this.setState({ tableData: newTableData });
    // };
    calculateTotals = () => {
        const currencyTotals = {};
        let tableData = [...this.state.tableData];
        tableData.forEach((data) => {
            data.currencies?.forEach((c) => {
                if (!currencyTotals[c.currency_name]) {
                    currencyTotals[c.currency_name] = 0;
                }
                currencyTotals[c.currency_name] += parseFloat(data.no_of_recurrences) * parseFloat(c.amount);
            });
        });
        Object.entries(currencyTotals).forEach(([currency_name, total]) => {
        });
        // this.setState({currenciesTotals: currencyTotals})
        return currencyTotals
    }
    handleSelectGroupProject = selectedGroupProject => {
        this.setState({ selectedGroupProject })
        this.getProjectDetail(selectedGroupProject.value)
    }
    handleSelectGroupSizes = selectedGroupSizes => {
        this.setState({ selectedGroupSizes })
        this.setState({ project_size_id: selectedGroupSizes.value })
    }

    render() {
        const currenciesTotals = this.calculateTotals();
        document.title = "Edit Payment Plan";
        const { formData, tableData } = this.state;
        const { selectedGroupSizes } = this.state;
        const { selectedGroupProject } = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Payment Plans" breadcrumbItem="Edit Payment Plan" parentLink="/payment-plan/list" />
                    <Row className="">
                        <Col xl="12" className="col-12">
                            <Card>
                                <CardBody>
                                    <div>
                                        <Col md="12">
                                            <FormGroup className="mb-3 row d-flex">
                                                <Label className="col-md-2 col-form-label" htmlFor="payment_plan_name">Payment Plan*</Label>
                                                <div className="col-md-10">
                                                    <input
                                                        id='paymentPlanName'
                                                        name="payment_plan_name"
                                                        placeholder="Payment Plan Name"
                                                        type="text"
                                                        value={this.state.payment_plan_name}
                                                        onChange={(e) => this.setState({ payment_plan_name: e.target.value })}
                                                        required="required"
                                                        className={"form-control"}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <div className="mb-3 row d-flex select2-container">
                                                <Label className="col-md-2 col-form-label" htmlFor="selectProject">Project</Label>
                                                <div className="col-md-10">
                                                    {this.state.project_name}
                                                </div>
                                            </div>
                                        </Col>

                                        <Col md="12">
                                            <div className="mb-3 row d-flex select2-container">
                                                <Label className="col-md-2 col-form-label" htmlFor="selectSizes">Size </Label>
                                                <div className="col-md-10">
                                                    {this.state.size_name}
                                                </div>
                                            </div>
                                        </Col>

                                        <form onSubmit={this.handleSubmit}>
                                            <Card id="payment-type-box">
                                                <CardBody className="border card border-primary">
                                                    <CardBody className="border-bottom">
                                                        <div className="d-flex align-items-center">
                                                            <h5 className="mb-0 card-title flex-grow-1">Add payment type to payment plan</h5>
                                                        </div>
                                                    </CardBody>
                                                    <div className="mb-3 row d-flex select2-container">
                                                        <label className="mt-3 col-md-2 col-form-label">
                                                            Payment Types:
                                                        </label>
                                                        <div className="col-md-10">
                                                            <select required className="mt-3 form-select" name="PaymentType" value={formData.PaymentType} onChange={this.handleChange}>
                                                                <option value="">Select...</option>
                                                                {this.state.paymentTypesArray?.map(option => (
                                                                    <option value={option} key={option}>{option}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="mb-3 row d-flex">
                                                        <label className="col-md-2 col-form-label">
                                                            Occurance:
                                                        </label>
                                                        <div className="col-md-10">
                                                            <input required className="form-control" type="number" name="no_of_recurrences" value={this.state.formData.no_of_recurrences} onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="mb-3 row d-flex">
                                                        <label className="col-md-2 col-form-label">
                                                            Order:
                                                        </label>
                                                        <div className="col-md-10">
                                                            <input required type="number" className="form-control" name="order" value={this.state.formData.order} onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <Col md="12">
                                                        <div className="mb-3 row d-flex select2-container">
                                                            <Label className="col-md-2 col-form-label" htmlFor="currency">Currency Prices</Label>
                                                            {this.state.currencies?.map((d, i) =>
                                                                <div key={d.currency_id} className="col-md-12 d-flex mb-2" style={{ "marginLeft": "1.5rem" }}>
                                                                    <Label className="col-md-2 col-form-label" htmlFor="currency">Currency</Label>
                                                                    <div className="col-md-4">
                                                                        <input
                                                                            name={d.currency_name}
                                                                            value={d.currency_name}
                                                                            disabled
                                                                            placeholder="Price"
                                                                            // value={this.state.price?this.state.price:""}
                                                                            // onChange={(e) => this.setState({ price: e.target.value })}
                                                                            type="text"
                                                                            className={
                                                                                "form-control"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <Label style={{ "marginLeft": "1.5rem" }} className="col-md-2 col-form-label" htmlFor="currency">Price</Label>
                                                                    <div className="col-md-3">
                                                                        <input
                                                                            // name={d.currency_id}
                                                                            id={"price-" + i}
                                                                            name={d.currency_name} value={this.state.formData.currency_name} onChange={this.handleChange}
                                                                            placeholder="0.00"
                                                                            // value={this.state.price?this.state.price:""}
                                                                            type="number"
                                                                            step="0.01"
                                                                            pattern="[0-9]+([\.,][0-9]+)?"
                                                                            style={{ textAlign: 'right' }}
                                                                            required="required"
                                                                            className={"form-control"}
                                                                            onBlur={(e) => {
                                                                                if (e.target.value.trim() === "") {
                                                                                    e.target.value = ""; // Empty value stays empty
                                                                                } else {
                                                                                    const formattedValue = parseFloat(e.target.value).toFixed(2);
                                                                                    e.target.value = formattedValue;
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Col>

                                                </CardBody>
                                                <div className='float-end'>
                                                    <button color="primary" className="btn btn-primary ms-1 mt-2 mb-2" type="submit">Add Payment Type</button>
                                                </div>
                                            </Card>
                                        </form>
                                        <br />
                                        <br />
                                        {
                                            this.state.tableHead != '' ? <>
                                                {/* {JSON.stringify(currenciesTotals)} */}
                                                {this.state.tableData.map((data, index) => (
                                                    <div key={index}>
                                                        <Card id="payment-type-box">
                                                            <CardBody className="border card border-primary">
                                                                <div className="mb-3 row d-flex select2-container">
                                                                    <label className="mt-3 col-md-2 col-form-label">
                                                                        Payment Type:
                                                                    </label>
                                                                    <div className="mt-3 col-md-10">
                                                                        {/* <select value={selectedOptions[index]} onChange={(event) => handleOptionChange(index, event)}> */}
                                                                        <select required className="form-select test-000" name={"PaymentType-" + index} id={"PaymentType-" + index} value={data.payment_type_name} onChange={(event) => this.handleChangePaymentType(index, event)}>
                                                                            <option disabled value={data.payment_type_name}>{data.payment_type_name}</option>
                                                                            {this.state.paymentTypesArray?.map(option => (
                                                                                <option value={option} key={option} selected>{option}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-3 row d-flex">
                                                                    <label className="col-md-2 col-form-label">
                                                                        Occurances:
                                                                    </label>
                                                                    <div className="col-md-4">
                                                                        <input
                                                                            name='occurance[]'
                                                                            id={'occurance-' + index}
                                                                            placeholder="0.00"
                                                                            defaultValue={data.no_of_recurrences}
                                                                            onChange={(event) => this.handleChangeRecurrences(index, event)}
                                                                            type="number"
                                                                            style={{ textAlign: 'right' }}
                                                                            required="required"
                                                                            className={"form-control"}
                                                                        />
                                                                    </div>
                                                                    <label style={{ textAlign: 'right' }} className="col-md-1 col-form-label text-right">
                                                                        Order:
                                                                    </label>
                                                                    <div className="col-md-5">
                                                                        <input
                                                                            name='order'
                                                                            id={'order-' + index}
                                                                            placeholder="0.00"
                                                                            defaultValue={data.order}
                                                                            onChange={(event) => this.handleChangeOrder(index, event)}
                                                                            type="number"
                                                                            style={{ textAlign: 'right' }}
                                                                            required="required"
                                                                            className={"form-control"}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <Col md="12">
                                                                    <div className="row d-flex">
                                                                        {data.currencies?.map((c, i) => (
                                                                            <div className="row d-flex mb-3" key={i}>
                                                                                <Label className="col-md-2 col-form-label">{this.state.tableHead[i]}</Label>
                                                                                <div className='col-md-4'>
                                                                                    <input
                                                                                        name='amount'
                                                                                        id={'amount-' + index + '-' + i}
                                                                                        placeholder="0.00"
                                                                                        defaultValue={parseFloat(c.amount).toFixed(2)}
                                                                                        // onChange={(e) => this.handleAmountChange(e, i)}
                                                                                        onChange={(event) => this.handleChangeCurrencies(index, i, event)}
                                                                                        type="number"
                                                                                        // pattern="[0-9]+([\.,][0-9]+)?"
                                                                                        style={{ textAlign: 'right', marginLeft: '4px' }}
                                                                                        required="required"
                                                                                        className={"form-control"}
                                                                                        onBlur={(e) => {
                                                                                            if (e.target.value.trim() === "") {
                                                                                                e.target.value = ""; // Empty value stays empty
                                                                                            } else {
                                                                                                const formattedValue = parseFloat(e.target.value).toFixed(2);
                                                                                                e.target.value = formattedValue;
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <Label className="col-md-6 col-form-label">x{" "}&nbsp;&nbsp;{data.no_of_recurrences}{" "}&nbsp;&nbsp; = &nbsp;&nbsp;{this.state.currencies[i].currency_symbol}{" "}{numberWithCommas(data.no_of_recurrences * c.amount)}</Label>
                                                                            </div>
                                                                        ))}
                                                                        <div><button color="danger" className="btn btn-danger ms-1 mt-2 mb-2" onClick={() => this.handleDelete(index, data)}>Delete</button></div>
                                                                    </div>
                                                                </Col>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                ))}
                                            </> : <></>}
                                        <Row>
                                            <Col md="12">
                                                <Card>
                                                    <CardBody>
                                                        <div className='d-flex'>
                                                            {this.state.currencies?.map((c, i) => (
                                                                <div key={i}>
                                                                    <div md='12'>
                                                                        <b>Total in {c.currency_name}: {" "}</b>
                                                                        {numberWithCommas(currenciesTotals[c.currency_name])} &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>

                                        {this.state.tableData.length == 0 ? <></> : <>
                                            <div className='float-end'>
                                                <button color="success" className="btn btn-success ms-1 mt-2 mb-2" onClick={() => this.submitPaymentPlanData()}>Submit</button>
                                            </div>
                                        </>}
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </div >
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container >
            </div >
        );
    }
}
export default EditPaymentPlan;
