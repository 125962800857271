import React, { Component } from "react";
import { Row, Col, Card, CardBody, UncontrolledTooltip } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import moment from "moment";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../../../../../components/Common/Breadcrumb";
import { get_clone_email_template_list, delete_clone_email_template, email_template_test_email } from '../../../../api/templatesApi';
import { showToast } from "pages/UI/ui-toast";
import DeleteModal from "../../../../../../components/Common/DeleteModal";
import { get_user_permissions } from "../../../../../../helpers/custom_helper"
class ListCloneStatementOfAccountEmail extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            page: 1,
            sizePerPage: 10,
            listData: [],
            listdata: "",
            recordDeleteID: null,
            redirect: null,
            deleteModal: false,
        };
        this.onClickDelete = this.onClickDelete.bind(this);
        this.onClickSendTestEmail = this.onClickSendTestEmail.bind(this);
    }
    componentDidMount() {
        window.localStorage.removeItem('current_sample_id')
        window.localStorage.removeItem('current_sample_template_name')
        Promise.all([get_clone_email_template_list("Statement Of Accounts Template")]).then((values) => {
            this.setState({ listData: values[0] });
        });
    }
    onPaginationPageChange = page => {
        if (
            this.node &&
            this.node.current &&
            this.node.current.props &&
            this.node.current.props.pagination &&
            this.node.current.props.pagination.options
        ) {
            this.node.current.props.pagination.options.onPageChange(page);
        }
    };
    handleRecordClick = arg => {
        const listdata = arg;
        window.localStorage.setItem("current_sample_id", listdata.id);
        window.localStorage.setItem("current_sample_template_name", listdata.template_name);
        this.setState({ redirect: "/template/email/sales/statement-account-email/clone-list/edit" });
    };
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };
    onClickDelete = listdata => {
        this.setState({ listdata: listdata });
        this.setState({ deleteModal: true });
    };

    handleDeletejob = () => {
        const { listdata } = this.state;
        if (listdata.id !== undefined) {
            Promise.all([delete_clone_email_template(this.state.recordDeleteID)]).then((values) => {
                showToast("Success", "The template " + listdata.template_name + " has been deleted successfully.", "success");
                Promise.all([get_clone_email_template_list("Statement Of Accounts Template")]).then((values) => {
                    this.setState({ listData: values[0] });
                });
            }).catch((error) => {
                showToast("Failed", "Template Not Deleted Successfully", "error");
            });
            this.setState({ deleteModal: false });
        }
    };
    onClickSendTestEmail = listdata => {
        Promise.all([email_template_test_email("Payment Summary Template", listdata.id)]).then((values) => {
            console.log(values)
            showToast("Success", "Payment Summary Template testing email sent successfully.", "success")
        }).catch((error) => {
            showToast("Failed", "Payment Summary Template testing email not sent successfully!", "error")
        });
    };
    render() {
        const isEditPermission = get_user_permissions('email-templates-edit')
        const isDeletePermission = get_user_permissions('email-templates-delete')
        const listdata = this.state.listdata;
        const { deleteModal } = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        const columns = [
            {
                dataField: "template_name",
                text: "Template Name",
                sort: true,
            },
            {
                dataField: "default_template_name",
                text: "Cloned Template Name",
                sort: true,
            },
            {
                dataField: "project_name",
                text: "Project Name",
                sort: true,
            },
            {
                dataField: "status",
                text: "Status",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {listdata.status == 1 ? "Active" : "Inactive"}
                    </>
                ),
            },
            {
                dataField: "updated_name",
                text: "Updated By",
                sort: true,
            },
            {
                dataField: "updated_at",
                text: "Updated At",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {moment(listdata.updated_at).format('DD/MM/YYYY h:mma')}
                    </>
                ),
            },
            {
                dataField: "action",
                isDummyField: true,
                text: "Action",
                formatter: (cellContent, listdata) => (
                    <>
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li>
                                <Link
                                    to="#"
                                    onClick={() => this.onClickSendTestEmail(listdata)}
                                    className="btn btn-sm btn-soft-success" >
                                    <i className="mdi mdi-send-outline" id="sendTestEmail" />
                                    <UncontrolledTooltip placement="top" target="sendTestEmail">
                                        Send Test Email
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                            {isEditPermission ? <>
                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-soft-info"
                                        onClick={() => this.handleRecordClick(listdata)}
                                    >
                                        <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                        <UncontrolledTooltip placement="top" target="edittooltip">
                                            Edit
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                            </> : <></>}
                            {listdata.status == 0 ? <>
                                {isDeletePermission ? <>
                                    <li>
                                        <Link
                                            to="#"
                                            className="btn btn-sm btn-soft-danger"
                                            onClick={() => this.onClickDelete(listdata)}
                                        >
                                            <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                            <UncontrolledTooltip placement="top" target="deletetooltip">
                                                Delete
                                            </UncontrolledTooltip>
                                        </Link>
                                    </li>
                                </> : <></>}
                            </> : <></>}
                        </ul>
                    </>
                ),
            },
        ];

        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];

        const pageOptions = {
            sizePerPage: 10,
            custom: true,
        };

        // Select All Button operation
        const selectRow = {
            mode: "checkbox",
        };

        const { SearchBar } = Search;

        //meta title
        document.title = "List Templates";
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.setState({ recordDeleteID: row.id });
            }
        }
        return (
            <React.Fragment>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeletejob}
                    record={`template`}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Cloned Statement of Account Email" breadcrumbItem="List Statement of Account Email Cloned Templates" />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">List Statement of Account Email Cloned Templates</h5>
                                            </div>
                                        </CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField="id"
                                            columns={columns}
                                            data={this.state.listData}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    columns={columns}
                                                    data={this.state.listData}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col md="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"id"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            // selectRow={selectRow}
                                                                            rowEvents={rowEvents}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            ref={this.node}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default ListCloneStatementOfAccountEmail;