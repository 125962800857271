import axios from "axios";
// import { Link, useHistory } from "react-router-dom";  
import { withRouter } from 'react-router-dom'

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
const baseURL = process.env.REACT_APP_API_URL;


export const get_current_payment_details = async (current_payment_id) => {
    let response = await axios.get(baseURL + "/api/payments/" + current_payment_id, config);
    return response.data.data;
}
export const delete_payment = async (payment_id) => {
    let response = await axios.delete(baseURL + "/api/payments/" + payment_id, config);
    return response.data.data;
}
export const payment_confirm_mail = async (payment_id) => {
    let response = await axios.post(baseURL + "/api/payment-confirm-mail/" + payment_id, {}, config);
    return response.data.data;
}
export const payment_invoice_mail = async (payment_id) => {
    let response = await axios.post(baseURL + "/api/payment-invoice-mail/" + payment_id, {}, config);
    return response.data.data;
}
export const generated_invoice_download = async (payment_id) => {
    let response = await axios.post(baseURL + "/api/payment-invoice-generate/" + payment_id, {}, config);
    return response.data.data;
}
export const sales_receipts = async (id) => {
    let response = await axios.get(baseURL + "/api/get-sales-receipts/" + id, config);
    return response.data.data;
}
export const receipt_view = async (id) => {
    let response = await axios.get(baseURL + "/api/sales-receipts/" + id, config);
    return response.data.data;
}
export const delete_receipt = async (receipt_id) => {
    let response = await axios.delete(baseURL + "/api/sales-receipts/" + receipt_id, config);
    return response.data.data;
}
export const payment_receipt_detail = async (id) => {
    let response = await axios.get(baseURL + "/api/get-single-payment-details/" + id, config);
    return response.data.data;
}
export const generate_all_receipt_documents = async (payment_sale_id) => {
    let response = await axios.get(baseURL + "/api/generate-sales-receipts-pdf/" + payment_sale_id, config);
    return response.data.data;
}