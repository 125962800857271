import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import { isEmpty, size } from "lodash";
import moment from "moment";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import DeleteModal from "../../../../components/Common/DeleteModal";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { get_all_countries, delete_country, update_country } from '../api/countriesApi';
import { get_user_permissions } from "../../../../helpers/custom_helper";

var countryEdit, countryView, countryCreate, countryDelete;

class ListCountries extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            page: 1,
            sizePerPage: 10,
            listData: [],
            listdata: "",
            viewmodal: false,
            modal: false,
            isOpen: false,
            selectDate: false,
            deleteModal: false,
            recordDeleteID: null,
        };
        this.handleRecordClick = this.handleRecordClick.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleRecordClicks = this.handleRecordClicks.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
    }
    componentDidMount() {
        Promise.all([get_all_countries()]).then((values) => {
            console.log(values[0]);
            this.setState({ listData: values[0] });
        });

        countryCreate = get_user_permissions('countries-create');
        countryDelete = get_user_permissions('countries-delete');
        countryEdit = get_user_permissions('countries-edit');
        countryView = get_user_permissions('countries-view');

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { listData } = this.props;
        if (!isEmpty(listData) && size(prevProps.listData) !== size(listData)) {
            this.setState({ listData: {}, isEdit: false });
        }
    }
    onPaginationPageChange = page => {
        if (
            this.node &&
            this.node.current &&
            this.node.current.props &&
            this.node.current.props.pagination &&
            this.node.current.props.pagination.options
        ) {
            this.node.current.props.pagination.options.onPageChange(page);
        }
    };
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };
    onClickDelete = listdata => {
        this.setState({ listdata: listdata });
        this.setState({ deleteModal: true });
    };

    handleDeletejob = () => {
        // const { onDeleteJobList } = this.props;
        const { listdata } = this.state;
        if (listdata.id !== undefined) {
            console.log(this.state.recordDeleteID);
            // onDeleteJobList(listdata);
            Promise.all([delete_country(this.state.recordDeleteID)]).then((values) => {
                var toastTitle = "Success";
                var toastMessage = "The country has been deleted successfully.";
                var toastType = "success";
                this.showToast(toastTitle, toastMessage, toastType);
                Promise.all([get_all_countries()]).then((values) => {
                    console.log(values[0]);
                    this.setState({ listData: values[0] });
                });
            }).catch((error) => {
                var toastTitle = "Failed";
                var toastMessage = "Country Not Deleted";
                var toastType = "error";
                this.showToast(toastTitle, toastMessage, toastType);
                console.log(error);
            });
            this.setState({ deleteModal: false });
        }
    };
    handleRecordClicks = () => {
        this.setState({ listdata: "", isEdit: false });
        this.toggle();
    };
    handleRecordClick = arg => {
        const listdata = arg;

        this.setState({
            listdata: {
                id: listdata.id,
                name: listdata.title,
            },
            isEdit: true,
        });

        this.toggle();
    };
    showToast(title, message, toastType) {
        //position class
        var positionClass = "toast-top-right";
        toastr.options = {
            positionClass: positionClass,
            timeOut: this.state.timeOut,
            extendedTimeOut: this.state.extendedTimeOut,
            showEasing: this.state.showEasing,
            hideEasing: this.state.hideEasing,
            showMethod: this.state.showMethod,
            hideMethod: this.state.hideMethod,
            showDuration: this.state.showDuration,
            hideDuration: this.state.hideDuration,
        };

        // setTimeout(() => toastr.success(`Settings updated `), 300)
        //Toaster Types
        if (toastType === "info") toastr.info(message, title);
        else if (toastType === "warning") toastr.warning(message, title);
        else if (toastType === "error") toastr.error(message, title);
        else toastr.success(message, title);
    }
    render() {

        const { isEdit, deleteModal } = this.state;

        const listdata = this.state.listdata;

        const columns = [
            {
                dataField: "title",
                text: "Country Name",
                sort: true,
            },
            {
                dataField: "updated_name",
                text: "Updated By",
                sort: true,
            },
            {
                dataField: "updated_at",
                text: "Updated At",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {moment(listdata.updated_at).format('DD/MM/YYYY h:mma')}
                    </>

                ),
            },
            {
                dataField: "action",
                isDummyField: true,
                text: "Action",
                formatter: (cellContent, listdata) => (
                    <>
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            {countryEdit ? <>
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    onClick={() => this.handleRecordClick(listdata)}
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                            </> : <></>}
                            {countryDelete ? <>
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => this.onClickDelete(listdata)}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                            </> : <></>}
                        </ul>
                    </>
                ),
            },

        ];

        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];

        const pageOptions = {
            sizePerPage: 10,
            custom: true,
        };

        // Select All Button operation
        const selectRow = {
            mode: "checkbox",
        };

        const { SearchBar } = Search;

        //meta title
        document.title = "List Countries";
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                console.log(row);
                this.setState({ recordDeleteID: row.id });

            }
        }
        return (
            <React.Fragment>
                <DeleteModal
                    show={deleteModal}
                    record={`country`}
                    onDeleteClick={this.handleDeletejob}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Countries" breadcrumbItem="List Countries" />

                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">List Countries</h5>
                                                {countryCreate ? <>
                                                <div className="flex-shrink-0">
                                                    <Link to="/catalogue/country/add" className="btn btn-primary me-1">Add New Country</Link>
                                                </div>
                                                </> : <></>}
                                            </div>
                                        </CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField="id"
                                            columns={columns}
                                            data={this.state.listData}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    columns={columns}
                                                    data={this.state.listData}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col md="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"id"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            selectRow={selectRow}
                                                                            rowEvents={rowEvents}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            ref={this.node}
                                                                        />
                                                                        <Modal
                                                                            isOpen={this.state.modal}
                                                                            className={this.props.className}
                                                                        >
                                                                            <ModalHeader toggle={this.toggle} tag="h4">
                                                                                {!!isEdit ? "Edit Country" : "Add Country"}
                                                                            </ModalHeader>
                                                                            <ModalBody>
                                                                                <Formik
                                                                                    enableReinitialize={true}
                                                                                    initialValues={{
                                                                                        id: (listdata && listdata.id) || '',
                                                                                        name: (listdata && listdata.name) || '',
                                                                                    }}
                                                                                    validationSchema={Yup.object().shape({
                                                                                        id: Yup.string().required("Please Enter Your Id"),
                                                                                        name: Yup.string().required("Please Enter Country Name"),
                                                                                    })}
                                                                                    onSubmit={values => {
                                                                                        Promise.all([update_country(values.id, values.name)]).then((response) => {
                                                                                            console.log(response[0]);
                                                                                            var toastTitle = "Success";
                                                                                            var toastMessage = "The country has been updated successfully.";
                                                                                            var toastType = "success";
                                                                                            this.showToast(toastTitle, toastMessage, toastType);
                                                                                            Promise.all([get_all_countries()]).then((values) => {
                                                                                                console.log(values[0]);
                                                                                                this.setState({ listData: values[0] });
                                                                                            });
                                                                                        }).catch((error) => {
                                                                                            var toastTitle = "Failed";
                                                                                            var toastMessage = "Country Not Edited";
                                                                                            var toastType = "error";
                                                                                            this.showToast(toastTitle, toastMessage, toastType);
                                                                                            console.log(error);
                                                                                        });
                                                                                        this.toggle();
                                                                                    }}
                                                                                >
                                                                                    {({ errors, status, touched }) => (
                                                                                        <Form>
                                                                                            <Row>
                                                                                                <Col className="col-12">
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label">
                                                                                                            Country Id
                                                                                                        </Label>
                                                                                                        <Field
                                                                                                            name="id"
                                                                                                            disabled
                                                                                                            type="text"
                                                                                                            className={
                                                                                                                "form-control" +
                                                                                                                (errors.id &&
                                                                                                                    touched.id
                                                                                                                    ? " is-invalid"
                                                                                                                    : "")
                                                                                                            }
                                                                                                        />
                                                                                                        <ErrorMessage
                                                                                                            name="id"
                                                                                                            component="div"
                                                                                                            className="invalid-feedback"
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label">
                                                                                                            Country Name
                                                                                                        </Label>
                                                                                                        <Field
                                                                                                            name="name"
                                                                                                            type="text"
                                                                                                            className={
                                                                                                                "form-control" +
                                                                                                                (errors.name &&
                                                                                                                    touched.name
                                                                                                                    ? " is-invalid"
                                                                                                                    : "")
                                                                                                            }
                                                                                                        />
                                                                                                        <ErrorMessage
                                                                                                            name="name"
                                                                                                            component="div"
                                                                                                            className="invalid-feedback"
                                                                                                        />
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>
                                                                                                    <div className="text-end">
                                                                                                        <button
                                                                                                            type="submit"
                                                                                                            className="btn btn-primary save-user"
                                                                                                        >
                                                                                                            Save
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Form>
                                                                                    )}
                                                                                </Formik>
                                                                            </ModalBody>
                                                                        </Modal>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default ListCountries;
