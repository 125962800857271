import axios from "axios";
// import { Link, useHistory } from "react-router-dom";  
import { withRouter } from 'react-router-dom'   

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
const baseURL = process.env.REACT_APP_API_URL;

export const get_all_properties = async () => {
    let response = await axios.get(baseURL + "/api/properties", config);
    return response.data.data;
}
export const delete_property = async (property_id) => {
    let response = await axios.delete(baseURL + "/api/properties/"+property_id, config);
    return response.data.data;
}
export const get_current_property = async (property_id) => {
    let response = await axios.get(baseURL + "/api/properties/"+property_id, config);
    return response.data.data;
}
export const get_property_detail = async (property_id) => {
    let response = await axios.get(baseURL + "/api/properties-details/"+property_id, config);
    return response.data.data;
}
export const get_all_available_properties = async (project_id) => {
    let response = await axios.get(baseURL + "/api/get-available-properties-against-project/"+ project_id, config);
    return response.data.data;
}
export const get_all_sold_properties = async (project_id) => {
    let response = await axios.get(baseURL + "/api/get-sold-properties-against-project/"+ project_id, config);
    return response.data.data;
}
export const get_all_total_properties = async (project_id) => {
    let response = await axios.get(baseURL + "/api/get-properties-against-project/"+ project_id, config);
    return response.data.data;
}
export const get_property_log = async (property_id) => {
    let response = await axios.get(baseURL + "/api/property-activity-log/"+ property_id, config);
    return response.data.data;
}
export const get_all_properties_log = async () => {
    let response = await axios.get(baseURL + "/api/all-property-activity-log", config);
    return response.data.data;
}
export const get_all_deleted_properties = async () => {
    let response = await axios.get(baseURL + "/api/deleted-property-list", config);
    return response.data.data;
}
export const restore_property = async (property_id) => {
    let response = await axios.post(baseURL + "/api/restore-the-property/"+property_id, {}, config);
    return response.data.data;
}