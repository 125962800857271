import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Table, UncontrolledTooltip } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { isEmpty, size } from "lodash";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
// comment for later use if needed.
// import paginationFactory, {
//     PaginationProvider,
//     PaginationListStandalone,
//     SizePerPageDropdownStandalone,
// } from "react-bootstrap-table2-paginator";
import moment from "moment";
import { sales_receipts, payment_confirm_mail, delete_receipt, generate_all_receipt_documents } from '../api/paymentsApi'
import { download_documents } from '../../Documents/api/documentsApi'
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { showToast } from "pages/UI/ui-toast";
import DeleteModal from "../../../components/Common/DeleteModal";
import { numberWithCommas } from "pages/UI/numeric-helper";
class ListSalesReceipts extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            page: 1,
            sizePerPage: 10,
            viewmodal: false,
            modal: false,
            isOpen: false,
            selectDate: false,
            deleteModal: false,
            listData: [],
            currency_name: '',
            listdata: "",
            recordDeleteID: null,
            redirect: null,
            sum_received_amount: 0.00
        };
        this.onClickView = this.onClickView.bind(this);
        this.onClickDownloadReceipt = this.onClickDownloadReceipt.bind(this);
        this.onClickSendReceiptEmail = this.onClickSendReceiptEmail.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.onClickGenerateAllReceipt = this.onClickGenerateAllReceipt.bind(this);
    }
    componentDidMount() {
        Promise.all([sales_receipts(localStorage.getItem("payments_sale_id"))]).then((values) => {
            this.setState({ listData: values[0] });
            this.setState({ currency_name: values[0][0].currency_name });
            const sum_received_amount = values[0].reduce((sum, item) => {
                const parsedAmount = parseFloat(item.received_amount);
                // Exclude items where isDeleted is true
                if (!item.isDeleted) {
                    return sum + (isNaN(parsedAmount) ? 0 : parsedAmount);
                }
                return sum;
            }, 0);
            const formattedSum = sum_received_amount.toFixed(2);
            this.setState({ sum_received_amount: formattedSum })
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { listData } = this.props;
        if (!isEmpty(listData) && size(prevProps.listData) !== size(listData)) {
            this.setState({ listData: {}, isEdit: false });
        }
    }
    // comment for later use if needed.
    // onPaginationPageChange = page => {
    //     if (
    //         this.node &&
    //         this.node.current &&
    //         this.node.current.props &&
    //         this.node.current.props.pagination &&
    //         this.node.current.props.pagination.options
    //     ) {
    //         this.node.current.props.pagination.options.onPageChange(page);
    //     }
    // };
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    onClickDelete = listdata => {
        this.setState({ listdata: listdata });
        this.setState({ deleteModal: true });
    };
    handleDeletejob = () => {
        const { listdata } = this.state;
        if (listdata.id !== undefined) {
            // onDeleteJobList(listdata);
            Promise.all([delete_receipt(this.state.recordDeleteID)])
                .then((delValues) => {
                    showToast("Success", "The receipt has been deleted successfully.", "success");
                    Promise.all([sales_receipts(localStorage.getItem("payments_sale_id"))]).then((values) => {
                        this.setState({ listData: values[0] });
                        const sum_received_amount = values[0].reduce((sum, item) => {
                            const parsedAmount = parseFloat(item.received_amount);
                            // Exclude items where isDeleted is true
                            if (!item.isDeleted) {
                                return sum + (isNaN(parsedAmount) ? 0 : parsedAmount);
                            }
                            return sum;
                        }, 0);
                        const formattedSum = sum_received_amount.toFixed(2);
                        this.setState({ sum_received_amount: formattedSum })
                    });
                }).catch((error) => {
                    showToast("Failed", "Receipt Not Deleted!", "error");
                });
            this.setState({ deleteModal: false });
        }
    };
    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };
    onClickView = arg => {
        const listdata = arg;
        window.localStorage.setItem("current_sale_receipt_id", listdata.id);
        this.setState({ redirect: "/sales/receipt/view" });
    }
    onClickSendReceiptEmail = arg => {
        const listdata = arg;
        Promise.all([payment_confirm_mail(listdata.id)])
            .then((values) => {
                showToast("Success", "The receipt has been emailed successfully.", "success");
            }).catch((error) => {
                showToast("Failed", "The receipt has not been emailed successfully.", "error");
            });
    };
    onClickDownloadReceipt = arg => {
        const listdata = arg;
        Promise.all([download_documents(listdata.receipt_document)]).then((values) => {
            const contentType = values[0].split(';')[0].split(':')[1];
            const base64Content = values[0].split(',')[1];
            const byteCharacters = atob(base64Content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const extension = values[0].split(';')[0].split('/')[1];
            const blob = new Blob([byteArray], { type: contentType });
            const fileName = `${listdata.id}` + '-R.' + `${extension}`;

            setTimeout(() => {
                if (contentType.startsWith('image/png') || contentType.startsWith('image/jpg') || contentType.startsWith('image/jpeg')) {
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.target = '_blank';
                    a.rel = 'noopener noreferrer';
                    a.click();
                } else {
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    a.target = '_blank';
                    a.rel = 'noopener noreferrer';
                    a.click();
                }
                showToast("Success", "Download Success.", "success")
            }, 100);
        }).catch((error) => {
            showToast("Failed", "Download Failed!", "error")
        });
    }
    onClickGenerateAllReceipt = () => {
        Promise.all([generate_all_receipt_documents(localStorage.getItem("payments_sale_id"))]).then((values) => {
            const contentType = values[0].file_base64.split(';')[0].split(':')[1];
            const base64Content = values[0].file_base64.split(',')[1];
            const byteCharacters = atob(base64Content);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const extension = values[0].file_base64.split(';')[0].split('/')[1];
            const blob = new Blob([byteArray], { type: contentType });
            const fileName = `${values[0].file_name}.${extension}`;
            setTimeout(() => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.target = '_blank';
                a.rel = 'noopener noreferrer';
                a.click();
                showToast("Success", "Download Success.", "success")
            }, 100);
        }).catch((error) => {
            showToast("Failed", "Download Failed!", "error")
        });
    }
    render() {
        const listdata = this.state.listdata;
        const { currency_name } = this.state;
        const { deleteModal } = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        const columns = [
            {
                dataField: "receipt_date",
                text: "Received Date",
                sort: true,
            },
            {
                dataField: "received_amount",
                text: `${'Received Amount '} (${currency_name ? currency_name : ''})`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <div className="text-center">
                        {numberWithCommas(listdata.received_amount)}
                    </div>
                ),
            },
            {
                dataField: "mode_of_payment",
                text: "Payment Mode",
                sort: true,
            },
            {
                dataField: "updated_at",
                text: "Updated At",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {moment(listdata.updated_at).format('DD/MM/YYYY h:mma')}
                    </>
                ),
            },
            {
                dataField: "updated_name",
                text: "Updated By",
                sort: true,
            },
            {
                dataField: "action",
                isDummyField: true,
                text: "Action",
                formatter: (cellContent, listdata) => {
                    return (
                        <>
                            <ul className="list-unstyled hstack gap-1 mb-0">
                                {listdata.isDeleted ? null : <>
                                    <li>
                                        <Link
                                            to="#"
                                            className="btn btn-sm btn-soft-primary"
                                            onClick={() => this.onClickView(listdata)}
                                        >
                                            <i className="mdi mdi-eye-outline" id="viewtooltip" />
                                            <UncontrolledTooltip placement="top" target="viewtooltip">
                                                View Receipt
                                            </UncontrolledTooltip>
                                        </Link>
                                    </li>
                                </>}
                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-soft-info"
                                        onClick={() => this.onClickDownloadReceipt(listdata)}
                                    >
                                        <i className="mdi mdi-file-download-outline" id="downloadReceipt" />
                                        <UncontrolledTooltip placement="top" target="downloadReceipt">
                                            Donwload Receipt
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-soft-success"
                                        onClick={() => this.onClickSendReceiptEmail(listdata)}
                                    >
                                        <p style={{ "marginBottom": "0px" }} id="sendReceiptEmailtooltip">R</p>
                                        <UncontrolledTooltip placement="top" target="sendReceiptEmailtooltip">
                                            Send Receipt Email
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                                {listdata.isDeleted && !listdata.isLastRecord ? <>
                                    <div
                                        className="text-danger"
                                    >
                                        Deleted
                                    </div>
                                </> : null}
                                {listdata.isLastRecord ? <>
                                    {listdata.isDeleted ? <>
                                        <li>
                                            <div
                                                className="text-danger"
                                            >
                                                Deleted
                                            </div>
                                        </li>
                                    </> : <>
                                        <li>
                                            <Link
                                                to="#"
                                                className="btn btn-sm btn-soft-danger"
                                                onClick={() => this.onClickDelete(listdata)}
                                            >
                                                <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                                    Delete
                                                </UncontrolledTooltip>
                                            </Link>
                                        </li>
                                    </>}
                                </> : <></>}
                            </ul>
                        </>
                    )
                },
            },
        ];

        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];
        // comment for later use if needed.
        // const pageOptions = {
        //     sizePerPage: 10,
        //     // totalSize: productsData.length, // replace later with size(customers),
        //     custom: true,
        // };

        // Select All Button operation
        const selectRow = {
            mode: "checkbox",
        };

        const { SearchBar } = Search;
        //meta title
        document.title = "List Sales Receipts";
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.setState({ recordDeleteID: row.id });
            }
        }
        return (
            <React.Fragment>
                <DeleteModal
                    show={deleteModal}
                    record={`payment receipt`}
                    onDeleteClick={this.handleDeletejob}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Payments" breadcrumbItem="List Sales Receipts" parentLink="/salepayments/list" />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">List Sales Receipts</h5>
                                                <div className="flex-shrink-0">
                                                    <Link to="#" onClick={() => this.onClickGenerateAllReceipt()} className="btn btn-primary me-1">Download Receipts Statement</Link>
                                                </div>
                                            </div>
                                        </CardBody>
                                        <ToolkitProvider
                                            keyField="id"
                                            columns={columns}
                                            data={this.state.listData}
                                            search
                                        >
                                            {toolkitProps => (
                                                <React.Fragment>
                                                    <Row className="mb-2">
                                                        <Col md="4">
                                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                                <div className="position-relative">
                                                                    <SearchBar
                                                                        {...toolkitProps.searchProps}
                                                                    />
                                                                    <i className="bx bx-search-alt search-icon" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl="12">
                                                            <div className="table-responsive">
                                                                <BootstrapTable
                                                                    keyField={"id"}
                                                                    responsive
                                                                    bordered={false}
                                                                    striped={false}
                                                                    defaultSorted={defaultSorted}
                                                                    // selectRow={selectRow}
                                                                    rowEvents={rowEvents}
                                                                    classes={
                                                                        "table align-middle table-nowrap"
                                                                    }
                                                                    headerWrapperClasses={"thead-light"}
                                                                    {...toolkitProps.baseProps}
                                                                    // {...paginationTableProps}
                                                                    ref={this.node}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                            )}
                                        </ToolkitProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h6 className="mb-0 card-title flex-grow-1">Total: &nbsp;{currency_name}{" "}{numberWithCommas(this.state.sum_received_amount)}</h6>
                                            </div>
                                        </CardBody>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default ListSalesReceipts;
