import axios from "axios";
// import { Link, useHistory } from "react-router-dom";  
import { withRouter } from 'react-router-dom'

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
const baseURL = process.env.REACT_APP_API_URL;
export const get_current_user = async () => {
    let response = await axios.get(baseURL + "/api/platform-user-profile-show", config);
    return response.data.data;
}
export const get_current_orginasation_user = async () => {
    let response = await axios.get(baseURL + "/api/orginasation-profile-show", config);
    return response.data.data;
}