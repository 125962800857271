import React, { Component } from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    FormGroup,
    Input,
    Label,
    Row, InputGroup
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { isEmpty, size } from "lodash";
import Select from "react-select"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Formik, Field, Form, ErrorMessage } from "formik";
import axios from "axios";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
import { showToast } from '../../UI/ui-toast'
const baseURL = process.env.REACT_APP_API_URL;
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { download_report_pdf } from '../api/reportsApi';
import { numberWithCommas } from "pages/UI/numeric-helper";

class SalesReport extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            page: 1,
            sizePerPage: 10,
            listData: [],
            listdata: "",
            viewmodal: false,
            modal: false,
            isOpen: false,
            selectDate: false,
            deleteModal: false,
            recordDeleteID: null,
            redirect: null,
            optionGroupProjects: [],
            projectSelected: -1,
            dateFilter: 'allTime',
            projectFilter: 'allProjects',
            todayDate: '',
            toDate: null,
            fromDate: null,
            project_id: null,
            date_flag: 'all',
            project_flag: 'all',
            btnDisabled: false,
            generateList: false,
            filteredPayload: null,
            currencies: [],
            currenciesAll: [],
            currencySelected: -1,
            showCurrenciesDropdown: false,
            showCurrenciesDropdownAll: false,
            currency_id: null,
            currency_symbol: '',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.generatePDF = this.generatePDF.bind(this);
    }
    componentDidMount() {
        this.getOptionsProjects();
        this.getAllProjectsCurrencies();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { listData } = this.props;
        if (!isEmpty(listData) && size(prevProps.listData) !== size(listData)) {
            this.setState({ listData: {}, isEdit: false });
        }
    }
    onPaginationPageChange = page => {
        if (
            this.node &&
            this.node.current &&
            this.node.current.props &&
            this.node.current.props.pagination &&
            this.node.current.props.pagination.options
        ) {
            this.node.current.props.pagination.options.onPageChange(page);
        }
    };
    async getOptionsProjects() {
        const res = await axios.get(baseURL + "/api/project-list", config);
        const data = res.data.data;
        const options = data.map(d => ({
            "value": d.id,
            "label": d.name,
            "doc_template_message": d.doc_template_message
        }))
        this.setState({ optionGroupProjects: options })
    }
    async getAllProjectsCurrencies() {
        const res = await axios.get(baseURL + "/api/all-project-currencies", config);
        const data = res.data.data;
        const status = res.data.status;
        const options = data.map(d => ({
            "value": d.id,
            "label": d.currency_name,
        }))
        if (status == true) {
            this.setState({ currenciesAll: options })
            this.setState({ showCurrenciesDropdownAll: true })
        }
    }
    async getProjectDetail(project_id) {
        const currencies = await axios.get(baseURL + "/api/project-currencies/" + project_id, config);
        if (currencies.data.data == '') {
            showToast("Failed", "No currency found against this project!", "error")
            this.setState({ showCurrenciesDropdown: false })
        } else {
            this.setState({ showCurrenciesDropdown: true })
        }
        const currenciesData = currencies.data.data;
        const optionsCurrencies = currenciesData.map(d => ({
            "value": d.currency_id,
            "label": d.currency_name
        }))
        this.setState({ currencies: optionsCurrencies })
    }
    handleSelectGroupProject = selectedGroupProject => {
        this.getProjectDetail(selectedGroupProject.value)
        this.setState({ project_id: selectedGroupProject.value })
        this.setState({ projectSelected: 1 })
    }
    handleSelectGroupProjectCurrencies = selectedGroupProjectCurrencies => {
        this.setState({ currencySelected: 1 });
        this.setState({ currency_id: selectedGroupProjectCurrencies.value });
    }
    handleDateFilterChange = (event) => {
        if (event.target.value === 'allTime') {
            this.setState({ toDate: null })
            this.setState({ fromDate: null })
            this.setState({ date_flag: "all" })
            this.setState({ dateFilter: event.target.value });
        } else {
            this.setState({ date_flag: "specific" })
            this.setState({ dateFilter: event.target.value });
        }
    };
    handleFromDateChange = (date) => {
        this.setState({ fromDate: date });
    };
    handleToDateChange = (date) => {
        this.setState({ toDate: date });
    };
    handleProjectFilterChange = (event) => {
        if (event.target.value === 'allProjects') {
            this.setState({ project_id: null })
            this.setState({ currency_id: null })
            this.setState({ project_flag: "all" })
            this.setState({ projectFilter: event.target.value });
        } else {
            this.setState({ project_id: null })
            this.setState({ projectSelected: -1 })
            this.setState({ currency_id: null })
            this.setState({ project_flag: "specific" })
            this.setState({ projectFilter: event.target.value });
        }
    };
    getDateFormateMethod = (myDate) => {
        var today = myDate
        const dd = today.getDate().toString()
        const mm = today.getMonth() + 1
        const yyyy = today.getFullYear()
        const fromate_date = (today = yyyy + "-" + mm + "-" + dd)
        return fromate_date
    }
    handleSubmit(e) {
        e.preventDefault();
        var toDate = '';
        var fromDate = '';
        if (Array.isArray(this.state.toDate) && this.state.toDate.length > 0) {
            toDate = this.getDateFormateMethod(this.state.toDate[0]);
        }
        if (Array.isArray(this.state.fromDate) && this.state.fromDate.length > 0) {
            fromDate = this.getDateFormateMethod(this.state.fromDate[0]);
        }
        let errorMessage = '';
        if (this.state.dateFilter === "specificTimeframe") {
            if (!toDate) {
                errorMessage += '<li>To Date</li>';
            }
            if (!fromDate) {
                errorMessage += '<li>From Date</li>';
            }
        }
        if (this.state.projectFilter === "specificProject" && this.state.projectSelected === -1) {
            errorMessage += '<li>Project</li>';
        }
        if (this.state.currency_id === null) {
            errorMessage += '<li>Currency</li>';
        }
        if (errorMessage) {
            showToast("Failed", "These fields are missing. Please fill the required fields: " + errorMessage, "error");
            return false;
        }
        // this.setState({ btnDisabled: true })
        const payload = {
            "date_flag": this.state.date_flag,
            "project_flag": this.state.project_flag,
            "from_date": fromDate,
            "to_date": toDate,
            "project_id": this.state.project_id == null ? '' : this.state.project_id,
            "currency_id": this.state.currency_id == null ? '' : this.state.currency_id
        }
        axios.post(baseURL + "/api/sold-properties-report", payload, config)
            .then((response) => {
                this.setState({ generateList: true })
                this.setState({ listData: response.data.data });
                this.setState({ currency_symbol: response.data.data[0].currency_symbol });
                showToast("Success", "Filtered data generated successfully", "success");
                payload["report_type"] = "sales_report";
                if (response.data.data.length === 0) {
                    this.setState({ filteredPayload: null })
                } else {
                    this.setState({ filteredPayload: payload })
                }
            })
            .catch((error) => {
                showToast("Failed", error.response.data.message, "error")
                this.setState({ filteredPayload: null })
                this.setState({ listData: [] })
                this.setState({ generateList: false })
            });
    }
    generatePDF() {
        Promise.all([download_report_pdf(this.state.filteredPayload)])
            .then((values) => {
                const contentType = values[0].split(';')[0].split(':')[1];
                const base64Content = values[0].split(',')[1];
                const byteCharacters = atob(base64Content);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const extension = values[0].split(';')[0].split('/')[1];
                // Format the timestamp
                const timestamp = new Date().toLocaleString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                });
                const fileName = `Sales_Report_${timestamp}.${extension}`;
                const blob = new Blob([byteArray], { type: contentType });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.target = '_blank';
                a.rel = 'noopener noreferrer';
                a.click();
                showToast("Success", "Download Success.", "success");
            })
            .catch((error) => {
                showToast("Failed", "Download Failed!", "error");
            });
    }
    render() {
        const { selectedGroupProjectCurrencies } = this.state;
        const { selectedGroupProject } = this.state;
        const { dateFilter, projectFilter, generateList, filteredPayload } = this.state;
        const listdata = this.state.listdata;
        const { currency_symbol } = this.state;
        const columns = [
            {
                dataField: "sale_date",
                text: <p>Sale Date</p>,
                sort: true,
            },
            {
                dataField: "project_name",
                text: <p>Project Name</p>,
                sort: true,
            },
            {
                dataField: "property_name",
                text: <p>Property Name</p>,
                sort: true,
            },
            // due to Requirement changed
            // {
            //     dataField: "purchasers",
            //     text: "Customers",
            //     formatter: (cellContent, listdata) => {
            //         if (Array.isArray(listdata.purchasers) && listdata.purchasers.length > 0) {
            //             return (
            //                 <ol className="purchaser-list">
            //                     {listdata.purchasers.map((purchaser) => (
            //                         <li key={purchaser.id}>{purchaser.full_name}</li>
            //                     ))}
            //                 </ol>
            //             );
            //         } else {
            //             return "No customer.";
            //         }
            //     },
            // },
            // {
            //     dataField: "currency_abbreviation",
            //     text: <p className="text-center">Currency</p>,
            //     sort: true,
            //     formatter: (cellContent, listdata) => (
            //         <>
            //             <p className="text-center">{listdata.currency_abbreviation}</p>
            //         </>
            //     ),
            // },
            {
                dataField: "sale_price",
                text: (
                    <p className="float-end">
                        {`Sale Price (${currency_symbol ? currency_symbol : ''})`}
                    </p>
                ),
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        <p className="float-end">{numberWithCommas(listdata.sale_price)}</p>
                    </>
                ),
            },
            {
                dataField: "sale_discount",
                text: (
                    <p className="float-end">
                        {`Sale Discount (${currency_symbol ? currency_symbol : ''})`}
                    </p>
                ),
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        <p className="float-end">{numberWithCommas(listdata.sale_discount)}</p>
                    </>
                ),
            },
            {
                dataField: "sale_total",
                text: (
                    <p className="float-end">
                        {`Sale Total (${currency_symbol ? currency_symbol : ''})`}
                    </p>
                ),
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        <p className="float-end">{numberWithCommas(listdata.sale_total)}</p>
                    </>
                ),
            },
        ];

        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];

        const pageOptions = {
            sizePerPage: 10,
            // totalSize: productsData.length, // replace later with size(customers),
            custom: true,
        };

        // Select All Button operation
        const selectRow = {
            mode: "checkbox",
        };

        const { SearchBar } = Search;

        //meta title
        document.title = "Sales Report";
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.setState({ recordDeleteID: row.id });
            }
        }
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Reports" breadcrumbItem="Sales Report" />
                        <Row>
                            <Col md="12">
                                <Card>
                                    <CardBody>
                                        <Formik
                                            enableReinitialize={true}
                                        >
                                            {({ errors, status, touched }) => (
                                                <Form className="needs-validation" onSubmit={this.handleSubmit}>
                                                    <Row className="m-2">
                                                        <Col md="2" className="">
                                                            <Label className="mt-2" htmlFor="dateFilter">Date</Label>
                                                        </Col>
                                                        <Col md="10">
                                                            <div className="d-flex">
                                                                <div className="col-md-4 ">
                                                                    <FormGroup check inline>
                                                                        <Label className="mt-2" check>
                                                                            <Input
                                                                                type="radio"
                                                                                name="dateFilterAll"
                                                                                id="allTime"
                                                                                value="allTime"
                                                                                checked={dateFilter === 'allTime'}
                                                                                onChange={this.handleDateFilterChange}
                                                                            />
                                                                            {' '}
                                                                            All Time
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup check inline>
                                                                        <Label className="mt-2" check>
                                                                            <Input
                                                                                type="radio"
                                                                                name="dateFilterSpecific"
                                                                                id="specificTimeframe"
                                                                                value="specificTimeframe"
                                                                                checked={dateFilter === 'specificTimeframe'}
                                                                                onChange={this.handleDateFilterChange}
                                                                            />
                                                                            {' '}
                                                                            Specific Timeframe
                                                                        </Label>
                                                                    </FormGroup>
                                                                </div>
                                                                {dateFilter === 'specificTimeframe' && (
                                                                    <div className="col-md-8 ">
                                                                        <div className="d-flex">
                                                                            <div className="col-md-6 d-flex">
                                                                                <Label className="mt-2">From:  &nbsp;&nbsp;&nbsp;</Label>{" "}
                                                                                <div className="docs-datepicker">
                                                                                    <InputGroup>
                                                                                        <Flatpickr
                                                                                            className="form-control d-block"
                                                                                            placeholder="Pick a date"
                                                                                            value={this.state.todayDate}
                                                                                            options={{
                                                                                                // minDate: "today",
                                                                                                enableTime: false,
                                                                                                dateFormat: "d/m/Y",
                                                                                            }}
                                                                                            onChange={date => {
                                                                                                this.setState({ fromDate: date })
                                                                                            }}
                                                                                        />
                                                                                        <div className="input-group-append">
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                                                disabled
                                                                                            >
                                                                                                <i
                                                                                                    className="fa fa-calendar"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                            </button>
                                                                                        </div>
                                                                                    </InputGroup>
                                                                                    <div className="docs-datepicker-container" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-6 d-flex justify-content-end">
                                                                                <Label className="mt-2">&nbsp;&nbsp;&nbsp;To: &nbsp;&nbsp;&nbsp;</Label>{" "}
                                                                                <div className="docs-datepicker">
                                                                                    <InputGroup>
                                                                                        <Flatpickr
                                                                                            className="form-control d-block"
                                                                                            placeholder="Pick a date"
                                                                                            value={this.state.todayDate}
                                                                                            options={{
                                                                                                maxDate: "today",
                                                                                                enableTime: false,
                                                                                                dateFormat: "d/m/Y",
                                                                                            }}
                                                                                            onChange={date => {
                                                                                                this.setState({ toDate: date })
                                                                                            }}
                                                                                        />
                                                                                        <div className="input-group-append">
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                                                disabled
                                                                                            >
                                                                                                <i
                                                                                                    className="fa fa-calendar"
                                                                                                    aria-hidden="true"
                                                                                                />
                                                                                            </button>
                                                                                        </div>
                                                                                    </InputGroup>
                                                                                    <div className="docs-datepicker-container" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="m-2 pt-2">
                                                        <Col md="2" className="">
                                                            <Label className="mt-2" htmlFor="projectFilter">Project</Label>
                                                        </Col>
                                                        <Col md="10">
                                                            <div className="d-flex">
                                                                <div className="col-md-4 ">
                                                                    <FormGroup check inline>
                                                                        <Label className="mt-2" check>
                                                                            <Input type="radio" name="projectFilterAll" id="allProjects" value="allProjects"
                                                                                checked={projectFilter === 'allProjects'}
                                                                                onChange={this.handleProjectFilterChange} />{' '}
                                                                            All Projects
                                                                        </Label>
                                                                    </FormGroup>
                                                                    <FormGroup check inline>
                                                                        <Label className="mt-2" check>
                                                                            <Input type="radio" name="projectFilterSpecific" id="specificProject" value="specificProject"
                                                                                checked={projectFilter === 'specificProject'}
                                                                                onChange={this.handleProjectFilterChange} />{' '}
                                                                            Specific Project
                                                                        </Label>
                                                                    </FormGroup>
                                                                </div>
                                                                {projectFilter === 'specificProject' ? (
                                                                    <>
                                                                        <div className="col-md-8">
                                                                            <div className="d-flex">
                                                                                <div className="col-md-6 d-flex">
                                                                                    <Label className="mt-2">Project: &nbsp;&nbsp;&nbsp;</Label>{" "}
                                                                                    <Select className="flex-auto" name="selectProject" value={selectedGroupProject} onChange={this.handleSelectGroupProject}
                                                                                        options={this.state.optionGroupProjects} classNamePrefix="select2-selection" />
                                                                                </div>
                                                                                {this.state.showCurrenciesDropdown === true && (
                                                                                    <div className="col-md-6 d-flex justify-content-end">
                                                                                        <Label className="mt-2">&nbsp;&nbsp;&nbsp;Currency: &nbsp;&nbsp;&nbsp;</Label>{" "}
                                                                                        <Select
                                                                                            className="flex-auto"
                                                                                            name="selectCurrency"
                                                                                            value={selectedGroupProjectCurrencies}
                                                                                            onChange={this.handleSelectGroupProjectCurrencies}
                                                                                            options={this.state.currencies}
                                                                                            classNamePrefix="select2-selection"
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : <>
                                                                    {this.state.showCurrenciesDropdownAll === true && (
                                                                        <div className="col-md-8 d-flex justify-content-end">
                                                                            <Label className="mt-2">&nbsp;&nbsp;&nbsp;Currency: &nbsp;&nbsp;&nbsp;</Label>{" "}
                                                                            <Select
                                                                                className="flex-auto"
                                                                                name="selectCurrency"
                                                                                value={selectedGroupProjectCurrencies}
                                                                                onChange={this.handleSelectGroupProjectCurrencies}
                                                                                options={this.state.currenciesAll}
                                                                                classNamePrefix="select2-selection"
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </>}
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                    <Row className="float-end mt-2">
                                                        <Col className="d-flex" md="12">
                                                            <div className="p-2">
                                                                <Button disabled={this.state.btnDisabled} type="submit" color="primary" className="btn btn-primary">
                                                                    Generate Report
                                                                </Button>
                                                            </div>
                                                            {filteredPayload && (
                                                                <div className="p-2">
                                                                    <Button color="success" className="btn btn-success" onClick={this.generatePDF}>
                                                                        Export as PDF
                                                                    </Button>
                                                                </div>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}
                                        </Formik>
                                    </CardBody>
                                </Card>
                                {generateList ?
                                    <>
                                    </>
                                    :
                                    <>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </>}

                            </Col>
                        </Row>
                        {generateList && (
                            <Row>
                                <Col className="col-12">
                                    <Card>
                                        <CardBody>
                                            <PaginationProvider
                                                pagination={paginationFactory(pageOptions)}
                                                keyField="id"
                                                columns={columns}
                                                data={this.state.listData}
                                            >
                                                {({ paginationProps, paginationTableProps }) => (
                                                    <ToolkitProvider
                                                        keyField="id"
                                                        columns={columns}
                                                        data={this.state.listData}
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                                <Row>
                                                                    <Col xl="12">
                                                                        <div className="table-responsive">
                                                                            <BootstrapTable
                                                                                keyField={"id"}
                                                                                responsive
                                                                                bordered={false}
                                                                                striped={false}
                                                                                defaultSorted={defaultSorted}
                                                                                // selectRow={selectRow}
                                                                                rowEvents={rowEvents}
                                                                                classes={
                                                                                    "table align-top table-nowrap"
                                                                                }
                                                                                headerWrapperClasses={"thead-light"}
                                                                                {...toolkitProps.baseProps}
                                                                                {...paginationTableProps}
                                                                                ref={this.node}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                <Row className="align-items-md-center mt-30">
                                                                    <Col className="inner-custom-pagination d-flex">
                                                                        <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                                            <SizePerPageDropdownStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                        <div className="text-md-right ms-auto">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                                )}
                                            </PaginationProvider>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </div>
                </div>
            </React.Fragment >
        );
    }
}
export default SalesReport;