import React, { Component } from "react";
import {
    Card,
    CardBody,
    Col,
    Collapse,
    Container,
    Row,
} from "reactstrap";
import { Link } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../../../../components/Common/Breadcrumb";
import { get_user_permissions } from "helpers/custom_helper";
var documentTemplatesLogs;
class TabView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            col1: true,
            col2: false,
            col3: false,
            col4: false,
        };
        this.t_col1 = this.t_col1.bind(this);
        this.t_col2 = this.t_col2.bind(this);
        this.t_col3 = this.t_col3.bind(this);
        this.t_col4 = this.t_col4.bind(this);
    }

    t_col1() {
        this.setState({
            col1: !this.state.col1,
            col2: false,
            col3: false,
            col4: false,
        });
    }

    t_col2() {
        this.setState({
            col1: false,
            col2: !this.state.col2,
            col3: false,
            col4: false,
        });
    }

    t_col3() {
        this.setState({
            col1: false,
            col2: false,
            col3: !this.state.col3,
            col4: false,
        });
    }
    t_col4() {
        this.setState({
            col1: false,
            col2: false,
            col3: false,
            col4: !this.state.col4,
        });
    }
    render() {
        //meta title
        document.title = "Tab View";
        documentTemplatesLogs = get_user_permissions("document-templates-logs-view");
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs
                            title="Documents Templates"
                            breadcrumbItem="Sales Templates"
                        />
                        <Row>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="accordion" id="accordion">
                                                {/* Welcome Letter */}
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingOne">
                                                        <button
                                                            className="accordion-button fw-medium"
                                                            type="button"
                                                            onClick={this.t_col1}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Welcome Letter
                                                        </button>
                                                    </h2>
                                                    <Collapse
                                                        isOpen={this.state.col1}
                                                        className="accordion-collapse"
                                                    >
                                                        <div className="accordion-body">
                                                            <div className="text-dark">
                                                                <Link to="/template/documents/sales/welcome-letter/samples-list">
                                                                    <p>
                                                                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                                        Samples Templates
                                                                    </p>
                                                                </Link>
                                                                <Link to="/template/documents/sales/welcome-letter/clone-list">
                                                                    <p>
                                                                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                                        List Cloned Templates
                                                                    </p>
                                                                </Link>
                                                                {documentTemplatesLogs ? <>
                                                                    <Link to="/template/documents/sales/welcome-letter/logs">
                                                                        <p>
                                                                            <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                                            Logs
                                                                        </p>
                                                                    </Link>
                                                                </> : <></>}
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                                {/* End Welcome Letter */}
                                                {/* Payment Schedule */}
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingFour">
                                                        <button
                                                            className="accordion-button fw-medium collapsed"
                                                            type="button"
                                                            onClick={this.t_col4}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Payment Schedule
                                                        </button>
                                                    </h2>
                                                    <Collapse
                                                        isOpen={this.state.col4}
                                                        className="accordion-collapse"
                                                    >
                                                        <div className="accordion-body">
                                                            <div className="text-dark">
                                                                <Link to="/template/documents/sales/payment-schedule/samples-list">
                                                                    <p>
                                                                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                                        Samples Templates
                                                                    </p>
                                                                </Link>
                                                                <Link to="/template/documents/sales/payment-schedule/clone-list">
                                                                    <p>
                                                                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                                        List Cloned Templates
                                                                    </p>
                                                                </Link>
                                                                {documentTemplatesLogs ? <>
                                                                    <Link to="/template/documents/sales/payment-schedule/logs">
                                                                        <p>
                                                                            <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                                            Logs
                                                                        </p>
                                                                    </Link>
                                                                </> : <></>}
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                                {/* End Payment Schedule */}
                                                {/* Payment Summary */}
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingThree">
                                                        <button
                                                            className="accordion-button fw-medium collapsed"
                                                            type="button"
                                                            onClick={this.t_col3}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Payment Summary
                                                        </button>
                                                    </h2>
                                                    <Collapse
                                                        isOpen={this.state.col3}
                                                        className="accordion-collapse"
                                                    >
                                                        <div className="accordion-body">
                                                            <div className="text-dark">
                                                                <Link to="/template/documents/sales/payment-summary/samples-list">
                                                                    <p>
                                                                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                                        Samples Templates
                                                                    </p>
                                                                </Link>
                                                                <Link to="/template/documents/sales/payment-summary/clone-list">
                                                                    <p>
                                                                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                                        List Cloned Templates
                                                                    </p>
                                                                </Link>
                                                                {documentTemplatesLogs ? <>
                                                                    <Link to="/template/documents/sales/payment-summary/logs">
                                                                        <p>
                                                                            <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                                            Logs
                                                                        </p>
                                                                    </Link>
                                                                </> : <></>}
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                                {/* End Payment Summary */}
                                                {/*  Statement of Accounts */}
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingTwo">
                                                        <button
                                                            className="accordion-button fw-medium collapsed"
                                                            type="button"
                                                            onClick={this.t_col2}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            Statement of Accounts
                                                        </button>
                                                    </h2>
                                                    <Collapse
                                                        isOpen={this.state.col2}
                                                        className="accordion-collapse"
                                                    >
                                                        <div className="accordion-body">
                                                            <div className="text-dark">
                                                                <Link to="/template/documents/sales/statement-accounts/samples-list">
                                                                    <p>
                                                                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                                        Samples Templates
                                                                    </p>
                                                                </Link>
                                                                <Link to="/template/documents/sales/statement-accounts/clone-list">
                                                                    <p>
                                                                        <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                                        List Cloned Templates
                                                                    </p>
                                                                </Link>
                                                                {documentTemplatesLogs ? <>
                                                                    <Link to="/template/documents/sales/statement-accounts/logs">
                                                                        <p>
                                                                            <i className="mdi mdi-chevron-right text-primary me-1"></i>
                                                                            Logs
                                                                        </p>
                                                                    </Link>
                                                                </> : <></>}
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                                {/* End Statement of Accounts */}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default TabView;
