import React, { Component } from "react"
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import Floors from "./Floors"
import { add_floors } from '../api/projectApi';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
// const project_id = window.localStorage.getItem("project_id");
const defaultValues = {
    project_id: '',
    floors: []
};

function FormBuilderSizesAndFloors() {
    const {
        control,
        register,
        handleSubmit,
        getValues,
        errors,
        reset,
        setValue
    } = useForm({
        defaultValues
    });
    const onSubmit = (data) => {
        data.project_id = window.localStorage.getItem("project_id");
        if (data.floors == '') {
            var toastTitle = "Failed";
            var toastMessage = "Please add Floor first.";
            var toastType = "error";
            showToast(toastTitle, toastMessage, toastType);
        } else {
            var toastTitle = "Failed";
            var toastMessage = "Floor name empty.";
            var toastType = "error";
            for (let i = 0; i < data.floors.length; i++) {
                if (data.floors[i].name == '') {
                    showToast(toastTitle, toastMessage, toastType);
                    return false;
                }
            }

            Promise.all([add_floors(data)]).then((values) => {
                var toastTitle = "Success";
                var toastMessage = "The project floors have been added successfully.";
                var toastType = "success";
                showToast(toastTitle, toastMessage, toastType);
                console.log(values[0]);
                handleClick();
            }).catch((error) => {
                var toastTitle = "Failed";
                var toastMessage = "Not Added";
                var toastType = "error";
                showToast(toastTitle, toastMessage, toastType);
                console.log(error);
            });
        }
        // console.log("data", data);
        // window.localStorage.setItem("section", "4");
        // handleClick();
    }
    function showToast(title, message, toastType) {
        //position class
        var positionClass = "toast-top-right";
        toastr.options = {
            positionClass: positionClass,
            timeOut: 5000,
            extendedTimeOut: 1000,
            showEasing: "swing",
            hideEasing: "linear",
            showMethod: "fadeIn",
            hideMethod: "fadeOut",
            showDuration: 300,
            hideDuration: 1000,
        };

        // setTimeout(() => toastr.success(`Settings updated `), 300)
        //Toaster Types
        if (toastType === "info") toastr.info(message, title);
        else if (toastType === "warning") toastr.warning(message, title);
        else if (toastType === "error") toastr.error(message, title);
        else toastr.success(message, title);
    }
    let history = useHistory();

    function handleClick() {
        history.push("/projects/features?section=5");
        // history.push("/projects/add?section=1");
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Floors
                {...{ control, register, defaultValues, getValues, setValue, errors }}
            />

            {/* <button type="button" onClick={() => reset(defaultValues)}>
                      Reset
                    </button> */}

            <button style={{ float: "right" }} onClick={handleSubmit(onSubmit)} color="primary"
                className="btn btn-primary">Next</button>
        </form>
    )
}


export default FormBuilderSizesAndFloors
