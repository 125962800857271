import React, { Component } from "react";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row, Table,
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { get_property_detail } from '../api/propertyApi';
import { numberWithCommas } from '../../UI/numeric-helper'
class ViewProperty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validation: "",
            optionGroupProjects: [],
            optionGroupSizes: [],
            size_id: '',
            size_name: '',
            floor_id: '',
            floor_name: '',
            phase_id: '',
            phase_name: '',
            block_id: '',
            property_id: '',
            block_name: '',
            property_type_id: '',
            property_type_name: '',
            project_name: '',
            project_id: '',
            unique_reference: '',
            property_features: [],
            optionGroupPhases: [],
            optionGroupBlocks: [],
            optionGroupFloors: [],
            optionGroupFeatures: [],
            optionGroupPropertyTypes: [],
            prices: [],
            percentage_value_against_price: '',
            pricesArray: [],
            totalFeaturesPrices: [],
            currencies: [],
            features: [],
            has_phases: 0,
            has_blocks: 0,
            has_floors: 0,
            has_sizes: 0,
            redirect: null,
            titlesArray: [
                {
                    "value": 'Mr',
                    "label": 'Mr'
                },
                {
                    "value": 'Mrs',
                    "label": 'Mrs'
                }
            ],
            id: "",
            name: '',
            project_logo: '',
            country_name: '',
            address_line_1: '',
            property_prices: [],
            property_features: [],
            property_sale: [],
            property_payments: [],
        };
    }

    componentDidMount() {
        Promise.all([get_property_detail(localStorage.getItem("current_property"))]).then((values) => {
            console.log(values[0]);
            this.setState({ prices: values[0].prices })
            this.setState({ property_id: values[0].property.id })
            this.setState({ project_id: values[0].property.project_id })
            this.setState({ project_name: values[0].property.project_name })
            this.setState({ size_name: values[0].property.size_name })
            this.setState({ size_id: values[0].property.size_id })
            this.setState({ block_name: values[0].property.block_name })
            this.setState({ block_id: values[0].property.block_id })
            this.setState({ phase_name: values[0].property.phase_name })
            this.setState({ phase_id: values[0].property.phase_id })
            this.setState({ floor_name: values[0].property.floor_name })
            this.setState({ floor_id: values[0].property.floor_id })
            this.setState({ property_type_id: values[0].property.type_id })
            this.setState({ property_type_name: values[0].property.type_name })
            this.setState({ unique_reference: values[0].property.unique_reference })

            this.setState({ project_logo: values[0].project.logo })
            this.setState({ country_name: values[0].project.country_title })
            this.setState({ address_line_1: values[0].project.address_line_1 })
            this.setState({ property_prices: values[0].property.prices })
            this.setState({ property_features: values[0].property.property_feature })
            this.setState({ property_sale: values[0].sale })
            this.setState({ property_payments: values[0].payments })


        });
    }
    render() {
        //meta title
        document.title = "Property Details";
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Properties" breadcrumbItem="Property Detail" parentLink="/properties/list" />
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardBody className="border-bottom">
                                        <div className="d-flex">
                                            <img src={this.state.project_logo} alt="" height="50" />
                                            <div className="flex-grow-1 ms-3">
                                                <h5 className="fw-semibold">{this.state.project_name}</h5>
                                                <ul className="list-unstyled hstack gap-2 mb-0">
                                                    {/* <li>
                                                        <i className="bx bx-building-house"></i> <span className="text-muted">Themesbrand</span>
                                                    </li> */}
                                                    <li>
                                                        <i className="bx bx-map"></i> <span className="text-muted">{this.state.country_name}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </CardBody>
                                    <CardBody>
                                        <h5 className="fw-semibold mb-3">Description & Details</h5>
                                        {/* <p className="text-muted"> */}
                                        {/* </p> */}
                                        <div className="table-responsive">
                                            <Table className="table table-striped mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Property ID</th>
                                                        <th>Locality</th>
                                                        <th>Unique Reference</th>
                                                        <th>Size</th>
                                                        {this.state.phase_name == null ? <></> : <>
                                                            <th>Phase</th>
                                                        </>}
                                                        {this.state.block_name == null ? <></> : <>
                                                            <th>Block</th>
                                                        </>}
                                                        {this.state.floor_name == null ? <></> : <>
                                                            <th>Floor</th>
                                                        </>}
                                                        <th>Property Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{this.state.property_id}</td>
                                                        <td>{this.state.address_line_1}</td>
                                                        <td>{this.state.unique_reference}</td>
                                                        <td>{this.state.size_name}</td>
                                                        {this.state.phase_name == null ? <></> : <>
                                                            <td>{this.state.phase_name}</td>
                                                        </>}
                                                        {this.state.block_name == null ? <></> : <>
                                                            <td>{this.state.block_name}</td>
                                                        </>}
                                                        {this.state.floor_name == null ? <></> : <>
                                                            <td>{this.state.floor_name}</td>
                                                        </>}
                                                        <td>{this.state.property_type_name}</td>
                                                        {/* <td>{this.state.project_organisation_title}</td> */}
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                                {this.state.property_prices == '' ? <></> : <>
                                    <Card>
                                        <CardBody>
                                            <h5 className="fw-semibold mb-3 mt-3">Property Prices</h5>
                                            <div className="table-responsive">
                                                <Table className="table table-striped mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Currency</th>
                                                            <th>Price</th>
                                                            <th>Price with Feature</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.property_prices?.map((data, index) =>

                                                            <tr key={index}>
                                                                <td>{data.currency_name}</td>
                                                                <td>{numberWithCommas(data.price)}</td>
                                                                <td>{numberWithCommas(data.with_feature_price)}</td>
                                                            </tr>

                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </>}
                                {this.state.property_features == '' ? <></> : <>
                                    <Card>
                                        <CardBody>
                                            <h5 className="fw-semibold mb-3 mt-3">Property Features</h5>
                                            <div className="table-responsive">
                                                <Table className="table table-striped mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Feature Name</th>
                                                            <th>Percentage Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.property_features?.map((data, index) =>

                                                            <tr key={index}>
                                                                <td>{data.feature_name}</td>
                                                                <td>{data.percentage_value}</td>
                                                            </tr>

                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </>}
                                {this.state.property_sale == '' ? <></> : <>
                                    <Card>
                                        <CardBody>
                                            <h5 className="fw-semibold mb-3 mt-3">Property Sale</h5>
                                            <div className="table-responsive">
                                                <Table className="table table-striped mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Sale ID</th>
                                                            <th>Sale Date</th>
                                                            <th>Sale Price</th>
                                                            <th>Sale Discount</th>
                                                            <th>Sale Total</th>
                                                            <th>Property Name</th>
                                                            <th>Sales Agent Name</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* {this.state.property_sale?.map((data, index) => */}

                                                        <tr>
                                                            <td>{this.state.property_sale.id}</td>
                                                            <td>{this.state.property_sale.sale_date}</td>
                                                            <td>{this.state.property_sale.currency_symbol}{" "}{numberWithCommas(this.state.property_sale.sale_price)}</td>
                                                            <td>{this.state.property_sale.currency_symbol}{" "}{numberWithCommas(this.state.property_sale.sale_dicount ? this.state.property_sale.sale_dicount : '0')}</td>
                                                            <td>{this.state.property_sale.currency_symbol}{" "}{numberWithCommas(this.state.property_sale.sale_total)}</td>
                                                            <td>{this.state.property_sale.property_name}</td>
                                                            <td>{this.state.property_sale.sales_agent_full_name}</td>
                                                        </tr>

                                                        {/* )} */}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </>}
                                {this.state.property_sale == '' ? <></> : <>
                                    <Card>
                                        <CardBody>
                                            <h5 className="fw-semibold mb-3 mt-3">Property Purchaser</h5>
                                            <div className="table-responsive">
                                                <Table className="table table-striped mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Purchaser ID</th>
                                                            <th>Name</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.property_sale.purchasers?.map((data, index) =>
                                                            <tr key={index}>
                                                                <td>{data.id}</td>
                                                                <td>{data.full_name}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </>}
                                {this.state.property_payments == '' ? <></> : <>
                                    <Card>
                                        <CardBody>
                                            <h5 className="fw-semibold mb-3 mt-3">Property Payments</h5>
                                            <div className="table-responsive">
                                                <Table className="table table-striped mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Payment ID</th>
                                                            <th>Due date</th>
                                                            <th>Receipt date</th>
                                                            <th>Status</th>
                                                            <th>Amount ({this.state.property_sale.currency_symbol})</th>
                                                            <th>Late fee ({this.state.property_sale.currency_symbol})</th>
                                                            <th>Paid ({this.state.property_sale.currency_symbol})</th>
                                                            <th>Payment Type</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.property_payments?.map((data, index) =>
                                                            <tr key={index}>
                                                                <td>{data.id}</td>
                                                                <td>{data.due_date}</td>
                                                                <td>{data.receipt_date ? data.receipt_date : 'N/A'}</td>
                                                                <td>{data.status}</td>
                                                                <td>{numberWithCommas(data.amount)}</td>
                                                                <td>{numberWithCommas(data.late_fee)}</td>
                                                                <td>{numberWithCommas(data.paid)}</td>
                                                                <td>{data.payment_types_name}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </>}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default ViewProperty;
