import React, { Component } from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";

const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};
import constant from '../../../../constants.json';
const baseURL = process.env.REACT_APP_API_URL;

class AddPaymentType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      redirect: null,
      paymentFrequencyArray: constant.payment_frequency_list,
      id: "",
      payment_types_name:'',
      payment_frequency:'',
      selectedPaymentFrequency:null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
  }

  /*
  async getOptions() {
    const res = await axios.get(baseURL + "/api/countries", config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d.id,
      "label": d.title
    }))
    this.setState({ paymentFrequencyArray: options })
  }
  */

  componentDidMount() {
    //this.getOptions()
  }

  handleSelectedPaymentFrequency = selectedPaymentFrequency => {
    this.setState({ selectedPaymentFrequency })
  }


  handleSubmit(e) {
    e.preventDefault();

    const modifiedV = { ...this.state.validation };

    /*if (fnm === "") {
      modifiedV["fnm"] = false;
    } else {
      modifiedV["fnm"] = true;
    }

    if (lnm === "") {
      modifiedV["lnm"] = false;
    } else {
      modifiedV["lnm"] = true;
    }

    if (unm === "") {
      modifiedV["unm"] = false;
    } else {
      modifiedV["unm"] = true;
    }

    if (city === "") {
      modifiedV["city"] = false;
    } else {
      modifiedV["city"] = true;
    }

    if (stateV === "") {
      modifiedV["stateV"] = false;
    } else {
      modifiedV["stateV"] = true;
    }

    this.setState({ validation: modifiedV });*/
  }

  //for change tooltip display propery
  onChangeValidation(fieldName, value) {
    const modifiedV = { ...this.state.validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    this.setState({ validation: modifiedV });
  }
  showToast(title, message, toastType) {
    //position class
    var positionClass = "toast-top-right";
    toastr.options = {
      positionClass: positionClass,
      timeOut: this.state.timeOut,
      extendedTimeOut: this.state.extendedTimeOut,
      showEasing: this.state.showEasing,
      hideEasing: this.state.hideEasing,
      showMethod: this.state.showMethod,
      hideMethod: this.state.hideMethod,
      showDuration: this.state.showDuration,
      hideDuration: this.state.hideDuration,
    };

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }
  render() {
    //meta title
    document.title = "Add Payment Type";
    const { validation } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    const { selectedPaymentFrequency } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Payment Types" breadcrumbItem="Add Payment Type" parentLink="/catalogue/payment-type/list" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        paymentTypeName: (this.state && this.state.payment_types_name) || "",
                      }}
                      validationSchema={Yup.object().shape({
                        paymentTypeName: Yup.string().required(
                          "Please Enter Your Payment Type Name"
                        ),
                      })}
                      onSubmit={values => {
                        console.log(values);
                        axios.post(baseURL + "/api/payment-type", {

                          "payment_types_name": values.paymentTypeName,
                          "payment_frequency": selectedPaymentFrequency.value,
                        }, config)
                        .then((response) => {
                          var toastTitle = "Success";
                          var toastMessage = "The payment type has been added successfully.";
                          var toastType = "success";
                          this.showToast(toastTitle, toastMessage, toastType);
                          console.log(response);
                          this.setState({ redirect: "/catalogue/payment-type/list" });
                        })
                        .catch((error) => {
                          var toastTitle = "Failed";
                          var toastMessage = "Payment Type Not Added";
                          var toastType = "error";
                          this.showToast(toastTitle, toastMessage, toastType);
                          console.log(error);
                        });

                      }}
                    >
                      {({ errors, status, touched }) => (
                        <Form className="needs-validation">
                          <Row>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="paymentTypeName">
                                  Payment Type Name
                                </Label>
                                <div className="col-md-10">
                                <Field
                                  name="paymentTypeName"
                                  placeholder="Payment Type Name"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.paymentTypeName && touched.paymentTypeName
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="paymentTypeName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectCountry">
                                  Select Payment Frequency
                                </Label>
                                <div className="col-md-10">
                                <Select
                                  value={selectedPaymentFrequency}
                                  onChange={this.handleSelectedPaymentFrequency}
                                  options={this.state.paymentFrequencyArray}
                                  classNamePrefix="select2-selection"
                                />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Button color="primary" type="submit" className="float-end">
                            Add Payment Type
                          </Button>
                        </Form>
                      )}
                    </Formik>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default AddPaymentType;
