import React, { Component } from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//common
// import AddCurrencyInputForm from "../child.components/AddCurrencyInputForm"
const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};

import constant from '../../../../constants.json';
const baseURL = process.env.REACT_APP_API_URL;

class AddCurrency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      redirect: null
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();

    const modifiedV = { ...this.state.validation };

    if (stateV === "") {
      modifiedV["stateV"] = false;
    } else {
      modifiedV["stateV"] = true;
    }

    this.setState({ validation: modifiedV });
  }

  //for change tooltip display propery
  onChangeValidation(fieldName, value) {
    const modifiedV = { ...this.state.validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    this.setState({ validation: modifiedV });
  }

  showToast(title, message, toastType) {
    //position class
    var positionClass = "toast-top-right";
    toastr.options = {
      positionClass: positionClass,
      timeOut: this.state.timeOut,
      extendedTimeOut: this.state.extendedTimeOut,
      showEasing: this.state.showEasing,
      hideEasing: this.state.hideEasing,
      showMethod: this.state.showMethod,
      hideMethod: this.state.hideMethod,
      showDuration: this.state.showDuration,
      hideDuration: this.state.hideDuration,
    };

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
  }
  render() {
    //meta title
    document.title = "Add Currency";
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Currencies" breadcrumbItem="Add Currency" parentLink="/catalogue/currencies/list" />

            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        currency_name: (this.state && this.state.currency_name) || "",
                        currency_abbreviation: (this.state && this.state.currency_abbreviation) || "",
                        currency_symbol: (this.state && this.state.currency_symbol) || "",
                      }}
                      validationSchema={Yup.object().shape({
                        currency_name: Yup.string().required(
                          "Please Enter Your Currency Name"
                        ),
                        currency_abbreviation: Yup.string().required(
                          "Please Enter Your Currency Abbreviation"
                        ),
                        currency_symbol: Yup.string().required(
                          "Please Enter Your Currency Symbol"
                        ),
                      })}
                      onSubmit={values => {
                        console.log(values);
                        axios.post(baseURL + "/api/currency", {

                          currency_name: values.currency_name,
                          currency_abbreviation: values.currency_abbreviation,
                          currency_symbol: values.currency_symbol
                        }, config)
                        .then((response) => {
                          var toastTitle = "Success";
                          var toastMessage = "The currency has been added successfully.";
                          var toastType = "success";
                          this.showToast(toastTitle, toastMessage, toastType);
                          console.log(response);
                          this.setState({ redirect: "/catalogue/currencies/list" });
                        })
                        .catch((error) => {
                          var toastTitle = "Failed";
                          var toastMessage = "Currency Not Added";
                          var toastType = "error";
                          this.showToast(toastTitle, toastMessage, toastType);
                          console.log(error);
                        });

                      }}
                    >
                      {({ errors, status, touched }) => (
                        <Form className="needs-validation">
                          <Row>
                            <Col md="12">
                              <FormGroup className="mb-3 d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="currency_name">
                                  Currency Name
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="currency_name"
                                    placeholder="Currency Name"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.currency_name && touched.currency_name
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="currency_name"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="currency_abbreviation">
                                  Currency Abbreviation
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="currency_abbreviation"
                                    placeholder="Currency Abbreviation"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.currency_abbreviation && touched.currency_abbreviation
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="currency_abbreviation"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="currency_symbol">
                                  Currency Symbol
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="currency_symbol"
                                    placeholder="Currency Symbol"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.currency_symbol && touched.currency_symbol
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="currency_symbol"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>

                          </Row>
                          <Button color="primary" type="submit" className="float-end">
                            Add Currency
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default AddCurrency
