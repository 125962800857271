import React, { Component } from "react";
import { showToast } from '../../UI/ui-toast';
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"

import constant from '../../../constants.json';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};

const baseURL = process.env.REACT_APP_API_URL;
class AddPlatformRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      redirect: null,
      permissionList: [],
      statusArray: [
        {
          "value": '1',
          "label": 'Yes'
        },
        {
          "value": '0',
          "label": 'No'
        }
      ],
      id: "",
      name: '',
      status_select_display: 'none',
      role_logo: null,
      isCheckedView: false,
      isCheckedAdd: false,
      isCheckedEdit: false,
      isCheckedDelete: false,
      selectedPermission: {},
      permissionValues:{},
      allViewPermissions: [],
      allAddPermissions: [],
      allEditPermissions: [],
      allDeletePermissions: [],
      selectedPermissions: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
  }

  async getPermissions() {
    let permissionNames = [];
    let nData = null;
    let apiData = null;
    let res = await axios.get(baseURL + "/api/platform/allPermission", config);
    let checkData = [];

    let nViewPermissions = [];
    let nAddPermissions = [];
    let nEditPermissions = [];
    let nDeletePermissions = [];

    if (res.status === 200) {
      nData = res.data;
      const permissionData = nData.data;
      apiData = res.data
      //console.log(apiData);


      permissionNames = Object.keys(apiData);
      
      const permissionOptions = permissionData.map(d => ({
        "nTitle": d.title,
        "nView": d.view,
        "nAdd": d.add,
        "nEdit": d.edit,
        "nDelete": d.delete
      }))
      this.setState({ permissionList: permissionOptions });
      // console.log(permissionOptions);

      var nArray = {};

      permissionData.forEach((el)=>{
          nArray[el.view] = false;
        nViewPermissions.push(el.view);
        nArray[el.add] = false;
        nAddPermissions.push(el.add);
        nArray[el.edit] = false;
        nEditPermissions.push(el.edit);
        nArray[el.delete] = false;
        nDeletePermissions.push(el.delete);
      });
      this.setState({permissionValues:nArray});
      this.setState({allViewPermissions:nViewPermissions});
      this.setState({allAddPermissions:nAddPermissions});
      this.setState({allEditPermissions:nEditPermissions});
      this.setState({allDeletePermissions:nDeletePermissions});
    }
  }
  componentDidMount() {
    this.getPermissions()
  }

  componentDidUpdate() {
    // console.log("permissionValues= ", this.state.permissionValues);
  }

  setCheckAll = (flag) => {

    let oldValues = this.state.permissionValues;
    let newChecked = true;
    // console.log("oldValues: ", oldValues);


    if(flag=='view_check'){
      let nViewPermissions = this.state.allViewPermissions;
      // console.log("nViewPermissions: ", nViewPermissions);
      nViewPermissions.forEach((nData, nIndex) => {
        // oldValues.forEach((nData, nIndex) => {
        //   // newValues[nData] = isChecked;
        // });
        Object.keys(oldValues).forEach(eKey => {
          if(eKey == nData){
            if(oldValues[eKey] == false){
              newChecked = false;
            }
          }
          // console.log(eKey);
          // console.log(oldValues[eKey]);
        });
      });

      this.setState({isCheckedView:newChecked});
    }

    if(flag=='add_check'){
      let nAddPermissions = this.state.allAddPermissions;
      // console.log("nAddPermissions: ", nAddPermissions);
      nAddPermissions.forEach((nData, nIndex) => {
        Object.keys(oldValues).forEach(eKey => {
          if(eKey == nData){
            if(oldValues[eKey] == false){
              newChecked = false;
            }
          }
        });
      });
      this.setState({isCheckedAdd:newChecked});
    }

    if(flag=='edit_check'){
      let nEditPermissions = this.state.allEditPermissions;
      // console.log("nEditPermissions: ", nEditPermissions);
      nEditPermissions.forEach((nData, nIndex) => {
        Object.keys(oldValues).forEach(eKey => {
          if(eKey == nData){
            if(oldValues[eKey] == false){
              newChecked = false;
            }
          }
        });
      });
      this.setState({isCheckedEdit:newChecked});
    }

    if(flag=='delete_check'){
      let nDeletePermissions = this.state.allDeletePermissions;
      // console.log("nDeletePermissions: ", nDeletePermissions);
      nDeletePermissions.forEach((nData, nIndex) => {
        Object.keys(oldValues).forEach(eKey => {
          if(eKey == nData){
            if(oldValues[eKey] == false){
              newChecked = false;
            }
          }
        });
      });
      this.setState({isCheckedDelete:newChecked});
    }

  }

  handleSelectChange(evt, param, chkFlag) {

    var newValues = this.state.permissionValues;
    newValues[param] = evt.currentTarget.checked;

    this.setState({permissionValues:newValues});

    this.setCheckAll(chkFlag);
  }

  handleSelectGroup = selectedGroup => {
    this.setState({ selectedGroup })
  }

  handleSelectPermissionView = (e, nVal, i) => {
    var checkbox = document.getElementById(nVal);
    checkbox.checked = true;
    const isChecked = e.target.checked;
    // console.log('isChecked = ', (isChecked==true?'true':'false'));
  }

  handlePermissionChange = (e, flag, i, param) => {
    var newValues = this.state.permissionValues;
    newValues[param] = e.currentTarget.checked;
    this.setState({permissionValues:newValues});
  }

  updateAllPermissionColumn = (flag, isChecked) => {
    // New code start
    var newValues = this.state.permissionValues;
    if(flag=='view_check'){
      let nViewPermissions = this.state.allViewPermissions;
      // console.log("nViewPermissions: ", nViewPermissions);
      nViewPermissions.forEach((nData, nIndex) => {
        newValues[nData] = isChecked;
      });
    }

    if(flag=='add_check'){
      let nAddPermissions = this.state.allAddPermissions;
      // console.log("nAddPermissions: ", nAddPermissions);
      nAddPermissions.forEach((nData, nIndex) => {
        newValues[nData] = isChecked;
      });
    }

    if(flag=='edit_check'){
      let nEditPermissions = this.state.allEditPermissions;
      // console.log("nEditPermissions: ", nEditPermissions);
      nEditPermissions.forEach((nData, nIndex) => {
        newValues[nData] = isChecked;
      });
    }

    if(flag=='delete_check'){
      let nDeletePermissions = this.state.allDeletePermissions;
      // console.log("nDeletePermissions: ", nDeletePermissions);
      nDeletePermissions.forEach((nData, nIndex) => {
        newValues[nData] = isChecked;
      });
    }

    // console.log("newValues: ", newValues);
    this.setState({permissionValues:newValues});
  }

  handleSelectAllView = (e) => {
    const isChecked = e.target.checked;
    // console.log('isChecked = ', (isChecked==true?'true':'false'));
    this.setState({isCheckedView:isChecked});
    this.updateAllPermissionColumn('view_check', isChecked);
  }
  handleSelectAllAdd = (e) => {
    const isChecked = e.target.checked;
    this.setState({isCheckedAdd:isChecked});
    this.updateAllPermissionColumn('add_check', isChecked);
  }
  handleSelectAllEdit = (e) => {
    const isChecked = e.target.checked;
    this.setState({isCheckedEdit:isChecked});
    this.updateAllPermissionColumn('edit_check', isChecked);
  }
  handleSelectAllDelete = (e) => {
    const isChecked = e.target.checked;
    this.setState({isCheckedDelete:isChecked});
    this.updateAllPermissionColumn('delete_check', isChecked);
  }

  handleSelectedStatus = selectedStatus => {
    this.setState({ selectedStatus })
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  //for change tooltip display propery
  onChangeValidation(fieldName, value) {
    const modifiedV = { ...this.state.validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    this.setState({ validation: modifiedV });
  }

  handleLogoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      this.setState({ role_logo: e.target.files[0] });
    }
  };

  render() {
    //meta title
    document.title = "Add Platform Roles";
    const { validation } = this.state;
    //const { selectedGroup } = this.state;
    const { selectedStatus } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Platform Roles" breadcrumbItem="Add Platform Role" parentLink="/platformroles/list" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        roleName: (this.state && this.state.roleName) || "",
                      }}
                      validationSchema={Yup.object().shape({
                        roleName: Yup.string().required(
                          "Please Enter Role Name."
                        ),
                      })}
                      onSubmit={values => {
                        // console.log(values);
                        let selectedPermissionCount = 0;
                        let selectedPermissionVals = [];

                        let finalPermissionValues = this.state.permissionValues;
                        // console.log(finalPermissionValues);

                        Object.keys(finalPermissionValues).forEach(eKey => {
                          if(finalPermissionValues[eKey]==true){
                            if(eKey != "undefined"){
                              // console.log("eKey = ",eKey);
                              selectedPermissionVals.push(eKey);
                              selectedPermissionCount++;
                            }
                          }
                        });
                        // console.log(selectedPermissionVals);

                        this.setState({ status_select_display: "none" });
                        if(selectedStatus==undefined){
                          // showToast("Role", "Please select status.", "error");
                          this.setState({ status_select_display: "block" });
                          return false;
                        }
                        // if(values.permissions==undefined){
                        //   showToast("Role", "Please select at least one permission to save the role.", "error");
                        //   return false;
                        // }
                        if(selectedPermissionCount==0){
                          showToast("Failed", "Please select at least one permission to save the role.", "error");
                          return false;
                        }
                        
                        axios.post(baseURL + "/api/platform-role", {
                          "name": values.roleName,
                          "is_active": selectedStatus.value,
                          // "logo": this.state.role_logo,
                          "permissions": selectedPermissionVals
                        }, config)
                          .then((response) => {
                            console.log(response);
                            showToast("Success", "The role has been added successfully.", "success");
                            this.setState({ redirect: "/platformroles/list" });
                          })
                          .catch((error) => {
                            console.log(error);
                            console.log(error.response.data.errors);
                            var errorsObj = error.response.data.errors;
                            var errorMsg = "";
                            if(errorsObj != undefined){
                              if(Object.keys(errorsObj).length > 0){
                                Object.keys(errorsObj).forEach(eKey => {
                                  console.log(errorsObj[eKey]);
                                  errorMsg = '<br />' + errorsObj[eKey];
                                });
                              }
                            }
                            else{
                              var errorMsg = '<br />' + error.response.data.message;
                            }
                            showToast("Failed", "Platform Role Not Added" + (errorMsg==""?'':errorMsg), "error");
                          });
                      }}
                    >
                      {({ errors, status, touched }) => (
                        <Form className="needs-validation">
                          <Row>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="roleName">
                                  Role Name*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="roleName"
                                    placeholder="Role Name"
                                    type="text"
                                    className={
                                      "form-control" +
                                      (errors.roleName && touched.roleName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="roleName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectedStatus">Is Active*</Label>
                                <div className="col-md-10">
                                  <Select
                                    value={selectedStatus}
                                    onChange={this.handleSelectedStatus}
                                    options={this.state.statusArray}
                                    classNamePrefix="select2-selection"
                                  />
                                  <div className="invalid-feedback" id="status-select-msg" style={{ display: this.state.status_select_display }}>Please Select Role Status.</div>
                                </div>
                              </div>
                            </Col>

                            {/* <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="logo">
                                  Logo
                                </Label>
                                <div className="col-md-10">
                                  <Input className="form-control form-control" type="file" id="inputLogo" accept="image/*" onChange={this.handleLogoChange} />
                                </div>
                              </div>
                            </Col> */}

                            <Col md="12" className="mt-4">
                              <div className="mb-3 row d-flex select2-container">
                                <div className="col-md-4"><h5>Role Permissions*</h5></div>
                                <div className="col-md-2"><h5><Field type="checkbox" checked={this.state.isCheckedView} onChange={(e) => this.handleSelectAllView(e)} /> View</h5></div>
                                <div className="col-md-2"><h5><Field type="checkbox" checked={this.state.isCheckedAdd} onChange={(e) => this.handleSelectAllAdd(e)} /> Add</h5></div>
                                <div className="col-md-2"><h5><Field type="checkbox" checked={this.state.isCheckedEdit} onChange={(e) => this.handleSelectAllEdit(e)} /> Edit</h5></div>
                                <div className="col-md-2"><h5><Field type="checkbox" checked={this.state.isCheckedDelete} onChange={(e) => this.handleSelectAllDelete(e)} /> Delete</h5></div>
                              </div>
                            </Col>
                            {
                              this.state.permissionList.map((nVal, i) => {
                                return (
                                  <Col md="12" key={i}>
                                    <div className="mb-3 row d-flex select2-container">
                                      <div className="col-md-4">{nVal.nTitle}</div>
                                      {/*<div className="col-md-2">{nVal.nView == undefined ? '' : <Field type="checkbox" name="permissions[]"  checked={this.state.permissionValues[nVal.nView]} onChange={(e) => this.handlePermissionChange(e, 'view_check', i, nVal.nView)} value={nVal.nView}/>}</div>*/}
                                      <div className="col-md-2">{nVal.nView == undefined ? '' : <Field type="checkbox" name="permissions[]" checked={this.state.permissionValues[nVal.nView]} value={nVal.nView} onChange={(e) => this.handleSelectChange(e, nVal.nView, 'view_check')} />}</div>
                                      <div className="col-md-2">{nVal.nAdd == undefined ? '' : <Field type="checkbox" name="permissions[]" checked={this.state.permissionValues[nVal.nAdd]} value = {nVal.nAdd} onChange={(e) => this.handleSelectChange(e, nVal.nAdd, 'add_check')} />}</div>
                                      <div className="col-md-2">{nVal.nEdit == undefined ? '' : <Field type="checkbox" name="permissions[]" checked={this.state.permissionValues[nVal.nEdit]} value={nVal.nEdit} onChange={(e) => this.handleSelectChange(e, nVal.nEdit, 'edit_check')} />}</div>
                                      <div className="col-md-2">{nVal.nDelete == undefined ? '' : <Field type="checkbox" name="permissions[]" checked={this.state.permissionValues[nVal.nDelete]} value={nVal.nDelete} onChange={(e) => this.handleSelectChange(e, nVal.nDelete, 'delete_check')} />}</div>
                                    </div>
                                  </Col>
                                )
                              })
                            }

                          </Row>
                          <Button color="primary" type="submit" className="float-end">
                            Add Role
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default AddPlatformRole;
