import React, { Component } from "react";
import { showToast } from '../../UI/ui-toast'
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"

import constant from '../../../constants.json';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { get_current_organisation } from '../../Platform_OrganisationSetup/api/organisationApi';
import { get_all_integrations } from '../api/integrationApi';


const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};

const baseURL = process.env.REACT_APP_API_URL;

class AddIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      redirect: null,
      integrationsArray: [
        {
          "value": 'Stripe',
          "label": 'Stripe'
        },
        {
          "value": 'Twillio',
          "label": 'Twillio'
        },
        {
          "value": 'Mailchimp',
          "label": 'Mailchimp'
        }
      ],
      id: "",
      credentials: '',
      org_id: "",

    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
  }

  handleSelectedIntegration = selectedIntegration => {
    this.setState({ selectedIntegration })
    console.log(selectedIntegration);
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  onChangeValidation(fieldName, value) {
    const modifiedV = { ...this.state.validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    this.setState({ validation: modifiedV });
  }

  componentDidMount() {
    Promise.all([get_current_organisation()]).then((values) => {
      console.log(values[0].id);
      this.setState({ org_id: values[0].id })
    }).catch((error) => {
      console.log(error);
    });

    Promise.all([get_all_integrations()]).then((values) => {
      var name = this.state.integrationsArray;
      const integration_name = values[0].map(d => ({
        "value": d.integration_name,
        "label": d.integration_name,
      }))
      console.log(integration_name);
      this.setState(prevState => ({
        integrationsArray: prevState.integrationsArray.filter(obj => !integration_name.some(integration => integration.value === obj.value))
      }));
      // Compare the response array with the state array
      // const stateArray = JSON.stringify(this.state.integrationsArray);
      // const responseArray = JSON.stringify(integration_name);
      // if (stateArray !== responseArray) {
      //   this.setState({
      //     integrationsArray: integration_name
      //   });
      // }
    });

  }

  render() {
    //meta title
    document.title = "Add Integration";
    const { validation } = this.state;

    const { selectedIntegration } = this.state;

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Integration" breadcrumbItem="Add Integration" parentLink="/integrations/list" />

            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        credentials: (this.state && this.state.credentials) || "",
                      }}
                      validationSchema={Yup.object().shape({
                        /*credentials: Yup.string().required(
                          "Please Enter Credentials."
                        ),*/
                      })}
                      onSubmit={values => {
                        console.log(values);
                        axios.post(baseURL + "/api/integrations", {

                          "integration_name": selectedIntegration.value,
                          "credentials": values.credentials,
                          "organisation_id": this.state.org_id,
                        }, config)
                          .then((response) => {
                            console.log(response);
                            showToast("Success", "The integration has been added successfully.", "success");
                            this.setState({ redirect: "/integrations/list" });
                          })
                          .catch((error) => {
                            showToast("Failed", "Integration Not Added Successfully", "error");
                            console.log(error);
                          });

                      }}
                    >
                      {({ errors, status, touched }) => (
                        <Form className="needs-validation">
                          <Row>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectedIntegration">Name *</Label>
                                <div className="col-md-10">
                                  <Select
                                    value={selectedIntegration}
                                    onChange={this.handleSelectedIntegration}
                                    options={this.state.integrationsArray}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="credentials">
                                  Credentials *
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="credentials"
                                    placeholder="Credentials"
                                    as="textarea"
                                    rows={5}
                                    className={
                                      "form-control" +
                                      (errors.credentials && touched.credentials
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="credentials"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>


                          </Row>
                          <Button color="primary" type="submit" className="float-end">
                            Add Integration
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default AddIntegration
