import React, { Component } from "react";
import { Row, Col, Table, Card, CardBody, CardTitle, Button, Label, InputGroup, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from "reactstrap";
import { Redirect, Link } from "react-router-dom";
import { isEmpty, size } from "lodash";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import DeleteModal from "../../../components/Common/DeleteModal";
import { get_user_permissions } from "../../../helpers/custom_helper"
import Flatpickr from "react-flatpickr"
import axios from "axios";
import { showToast } from '../../UI/ui-toast'
import Select from "react-select"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import {
    Container,
    FormGroup,
    Input,
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css"
import Dropzone from "react-dropzone"


import { get_project_sizes, get_project_phases_blocks, get_blocks_against_phases, get_project_floors } from "../../Projects/api/projectApi"
import { get_current_sale, get_payment_against_sale } from '../api/salesApi';
import { get_all_payment_types_for_organisation } from '../../Catalogue/PaymentTypes/api/paymentTypesApi';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
import { getFormatedDate } from '../../../common/CommonFunctions.js';

const baseURL = process.env.REACT_APP_API_URL;
var currency_id = null;
var selected_property_id = null;
var paymentAmount = null;
var calculatedCountTotal = 0;
import { delete_payment } from '../../Payments/api/paymentsApi'
import { numberWithCommas } from "pages/UI/numeric-helper";
class EditSale extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validation: "",
            current_sale_id: null,
            listData: [],
            optionGroupSalesAgent: [],
            currentSaleData: [],
            currencies: [],
            payment_types: {},
            propertyPrice: '',
            saleDate: '',
            totalPrice: '',
            discountPrice: '',
            customers: [],
            customers_list: "",
            purchasers: [],
            features: [],
            selectedFiles: [],
            totalCounter: '',
            status: '',
            property_id: '',
            sales_agent_id: '',
            currency_id: '',
            customer_id: '',
            grandTotal: 0,
            sales_agent_full_name: '',
            property_name: '',
            project_id: '',
            project_name: '',
            currency_name: '',
            currency_symbol: '',
            offer_detail: '',
            notes: '',
            deleteModal: false,
            optionArrayPaymentType: [],
            paymentReceivedDate: [new Date()],
            due_installment_date: `${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`,
            statusArray: [
                {
                    "value": 'Confirmed',
                    "label": 'Confirmed'
                },
                {
                    "value": 'Cancelled',
                    "label": 'Cancelled'
                }
            ],
            redirect: null,
            optionalExtras: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSaleEditSubmit = this.handleSaleEditSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleNewPayment = this.toggleNewPayment.bind(this);
        this.handleRecordClick = this.handleRecordClick.bind(this);
        this.handleRecordClicks = this.handleRecordClicks.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        // this.onChangeValidation = this.onChangeValidation.bind(this);
        this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this)
    }

    // Kept this function for future use. May be in future we need to change the Sale Agent in Edit sale.
    async getOptionsSalesAgent() {
        const res = await axios.get(baseURL + "/api/sales_agents", config);
        const data = res.data.data;
        console.log(data);

        const options = data.map(d => ({
            "value": d.id,
            "label": d.full_name
        }))
        this.setState({ optionGroupSalesAgent: options })
    }

    componentDidMount() {
        var salesId = localStorage.getItem("current_sale_id");
        Promise.all([get_current_sale(localStorage.getItem("current_sale_id"))])
            .then((values) => {
                console.log(values[0]);
                this.setState({ current_sale_id: localStorage.getItem("current_sale_id") })
                this.setState({ totalPrice: values[0].sale_total })
                this.setState({ discountPrice: values[0].sale_discount })
                this.setState({ propertyPrice: values[0].sale_price })
                this.setState({ sales_agent_full_name: values[0].sales_agent_full_name })
                this.setState({ sales_agent_id: values[0].sales_agent_id })
                this.setState({ project_name: values[0].project_name })
                this.setState({ project_id: values[0].project_id })
                this.setState({ property_name: values[0].property_name })
                this.setState({ property_id: values[0].property_id })
                this.setState({ currency_id: values[0].currency_id })
                this.setState({ currency_name: values[0].currency_name })
                this.setState({ currency_symbol: values[0].currency_symbol })
                this.setState({ status: values[0].status })
                this.setState({ saleDate: values[0].sale_date })
                this.setState({ offer_detail: (values[0].offer_detail == null ? '' : values[0].offer_detail) })
                // this.setState({ notes: values[0].notes })
                this.setState({ notes: (values[0].notes == null ? '' : values[0].notes) })
                this.setState({ optionalExtras: values[0].optional_extras})

                console.log((values[0].sale_date));

                // Map current purchasers
                var customer_name = "";
                const options = values[0].purchasers.map(d => ({
                    "value": d.customer_id,
                    "label": d.full_name,
                    "id": d.id
                }));

                values[0].purchasers.forEach((rowVal) => {
                    customer_name = (customer_name == '' ? rowVal.full_name : customer_name + ', ' + rowVal.full_name);
                });
                this.setState({ customers_list: customer_name })
                this.setState({ customers: options })
                this.setState({ purchasers: options })
            });

        /*Promise.all([get_payment_against_sale(localStorage.getItem("current_sale_id"))])
        .then((paymentValues) => {
            console.log(paymentValues[0]);
            this.setState({ listData: paymentValues[0] });
        });*/
        this.getOptionsSalesAgent();

        this.getPaymentLists(salesId);
        this.getSaleData(salesId);
        this.getPaymentTypes();
    }

    async getPaymentLists(salesId) {
        Promise.all([get_payment_against_sale(salesId)]).then((values) => {
            console.log(values[0]);
            this.setState({ listData: values[0] });
            this.setState({ late_fee: values[0].late_fee });
        });
    }

    async getSaleData(salesId) {
        Promise.all([get_current_sale(salesId)]).then((values) => {
            console.log(values[0]);
            this.setState({ currentSaleData: values[0] });
        });
    }

    async getPaymentTypes() {
        console.log('Get');
        Promise.all([get_all_payment_types_for_organisation()]).then((values) => {
            console.log(values[0]);
            const paymentTypeOPtions = values[0].map(d => ({
                "value": d.id,
                "label": d.payment_types_name
            }))
            this.setState({ optionArrayPaymentType: paymentTypeOPtions })
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { listData } = this.props;
        if (!isEmpty(listData) && size(prevProps.listData) !== size(listData)) {
            this.setState({ listData: {}, isEdit: false });
        }
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    toggleNewPayment() {
        this.setState(prevState => ({
            modalNewPayment: !prevState.modalNewPayment,
        }));
    }
    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };

    onClickDelete = listdata => {
        this.setState({ listdata: listdata });
        this.setState({ deleteModal: true });
    };

    handleDeletejob = () => {
        const { listdata } = this.state;
        if (listdata.id !== undefined) {
            console.log(this.state.recordDeleteID);
            // onDeleteJobList(listdata);
            Promise.all([delete_payment(this.state.recordDeleteID)])
                .then((delValues) => {
                    showToast("Success", "The payment has been deleted successfully.", "success");
                    Promise.all([get_payment_against_sale(this.state.current_sale_id)]).then((values) => {
                        console.log(values[0]);
                        this.setState({ listData: values[0] });
                    });
                    Promise.all([get_current_sale(this.state.current_sale_id)]).then((values) => {
                        console.log(values[0]);
                        this.setState({ currentSaleData: values[0] });
                        this.setState({ totalPrice: values[0].sale_total })
                        this.setState({ discountPrice: values[0].sale_discount })
                        this.setState({ propertyPrice: values[0].sale_price })
                    });
                }).catch((error) => {
                    showToast("Failed", "Payment Not Deleted!", "error");
                });
            this.setState({ deleteModal: false });
        }
    };

    handleRecordClicks = () => {
        this.setState({ listdata: "", isEdit: false });
        this.toggle();
    };

    handleRecordClick = arg => {
        const listdata = arg;

        this.setState({
            listdata: {
                id: listdata.id,
                due_date: listdata.due_date,
                amount: listdata.amount,
                late_fee: listdata.late_fee,
                total: listdata.total,
                paid: listdata.paid,
                receipt_date: listdata.receipt_date,
                status: listdata.status
            },
            isEdit: true,
        });

        this.toggle();
    };
    // Kept this function for future use. May be in future we need to change the Sale Agent in Edit sale.
    handleSelectGroupSalesAgent = selectedGroupSalesAgent => {
        this.setState({ selectedGroupSalesAgent })
        console.log(selectedGroupSalesAgent);
        this.setState({ sales_agent_id: selectedGroupSalesAgent.value })
        this.setState({ sales_agent_full_name: selectedGroupSalesAgent.label })

    }

    handleAmountChange = (e) => {
        this.setState({ amount_edit: e.target.value })
        var amount = parseInt(document.getElementById('amount-edit').value);
        var lateFee = parseInt(document.getElementById('late-fee-edit').value);
        if (typeof amount == 'number' && !isNaN(amount)) {
        }
        else {
            amount = 0;
        }
        // var total = amount+lateFee;
        this.setState({ total_edit: parseFloat(amount + lateFee).toFixed(2) });
    }

    handleLateFeeChange = (e) => {
        this.setState({ late_fee: e.target.value })
        var amount = parseInt(document.getElementById('amount-edit').value);
        var lateFee = parseInt(document.getElementById('late-fee-edit').value);
        // var total = amount+lateFee;
        if (typeof lateFee == 'number' && !isNaN(lateFee)) {
        }
        else {
            lateFee = 0;
        }
        // var total = amount+lateFee;
        this.setState({ total_edit: parseFloat(amount + lateFee).toFixed(2) });
    }

    setGrandTotal() {
        console.log("GRAND TOTAL");
        // var newVal = 0;
        // var nTotalCount = this.state.totalCounter;
        // // var nTotalCount = document.getElementById('totalCount').value;
        // for(var i = 0; i <= nTotalCount; i++){
        //     var occurance_val = document.getElementById('occurance-' + i).value;
        //     var amt_val = document.getElementById('amount-' + i).value;
        //     newVal = newVal + (occurance_val * amt_val);
        // }
        // //var nTotal = this.state.grandTotal;
        // this.setState({grandTotal:(newVal)});
        var nTotalCount = document.getElementById('totalCount').value;
        console.log(nTotalCount);
        var newVal = 0;
        for (var i = 0; i < nTotalCount; i++) {
            var occurance_val = document.getElementById('occurance-' + i).value;
            var amt_val = document.getElementById('amount-' + i).value;
            newVal = newVal + (occurance_val * amt_val);
        }
        this.setState({ grandTotal: (newVal) });
    }

    priceHandler(e) {
        console.log(e.target.value);
        var discount = e.target.value;
        var total = Number(this.state.propertyPrice - discount).toFixed(2);
        this.setState({ totalPrice: total })
        this.setState({ discountPrice: discount })
    }
    picks = () => {
        console.log("PICK");
        this.setState({ pick: new Date() })
    }
    getDateFormateMethod = (myDate) => {
        var today = myDate
        const dd = today.getDate().toString()
        const mm = today.getMonth() + 1
        const yyyy = today.getFullYear()
        const fromate_date = (today = yyyy + "-" + mm + "-" + dd)
        return fromate_date
    }
    toggleNewPayment() {
        this.setState(prevState => ({
            modalNewPayment: !prevState.modalNewPayment,
        }));
    }
    handleNewPayment = arg => {
        this.toggleNewPayment();
    };


    handleSubmit(e) {
        e.preventDefault();

    }

    handleSaleEditSubmit(e) {
        e.preventDefault();
        // var nTotalCount = document.getElementById('totalCount').value;
        // console.log(nTotalCount);
        // var newVal = 0;
        // console.log(date_val);
        // for (var i = 0; i < nTotalCount; i++) {
        //     var occurance_val = document.getElementById('occurance-' + i).value;
        //     var payment_type_id = document.getElementById('payment-type-id-' + i).value;
        //     var currency_id = document.getElementById('currency-' + i).value;
        //     var amt_val = document.getElementById('amount-' + i).value;

        //     var date_val = document.getElementById('date-' + i).value;
        //     // newVal = newVal + (occurance_val * amt_val);
        //     // payment_type_array.id = i;
        //     // payment_type_array.no_of_occurance = occurance_val;
        //     // payment_type_array.amount = amt_val;
        //     payment_type_array.push({ "payment_type_id": payment_type_id, "currency_id": currency_id, "no_of_recurrences": occurance_val, "amount": amt_val, "due_date": date_val })
        // }
        // console.log(payment_type_array);

        // var formatedDate = this.getDateFormateMethod(this.state.saleDate[0])

        // console.log(this.state.discountPrice);
        // console.log(this.state.totalPrice);
        var currentSaleId = this.state.current_sale_id;

        axios.patch(baseURL + "/api/sales/" + currentSaleId, {
            'sale_discount': this.state.discountPrice,
            'sale_total': this.state.totalPrice,
            'offer_detail': e.target.offerDetails.value,
            'notes': e.target.notes.value,
            'optional_extras': this.state.optionalExtras
        }, config)
            .then((response) => {
                showToast("Success", "Sales Updated Successfully", "success");
                this.setState({ redirect: "/sales/list" });
                console.log(response);
            })
            .catch((error) => {
                showToast("Failed", "Sales Not Added Successfully", "error")
                console.log(error);
            });
    }
    handleAcceptedFiles = files => {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: this.formatBytes(file.size),
            })
        )

        this.setState({ selectedFiles: files })
    }

    handleSelectedPaymentTypes = selectedPaymentType => {
        console.log(selectedPaymentType);
        this.setState({ selectedPaymentType })
    }

    /**
     * Formats the size
     */
    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    render() {
        const { isEdit, deleteModal } = this.state;
        const listdata = this.state.listdata;
        const { selectedStatus } = this.state;
        const { selectedPaymentType, currency_symbol } = this.state;
        const columns = [
            // {
            //     dataField: "id",
            //     text: "Id",
            //     sort: true,
            // },
            {
                dataField: "due_date",
                text: "Due Date",
                sort: true,
                // formatter: (cellContent, listdata) => (
                //     <>
                //         {moment(listdata.due_date).format('DD/MM/YYYY')}
                //     </>

                // ),
            },
            {
                dataField: "payment_types_name",
                text: "Payment Type",
                sort: true,
            },
            {
                dataField: "amount",
                text: `${'Amount '} (${currency_symbol ? currency_symbol : ''})`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {numberWithCommas(listdata.amount)}
                    </>
                ),
            },
            {
                dataField: "late_fee",
                text: `${'Late Fee '} (${currency_symbol ? currency_symbol : ''})`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {numberWithCommas(listdata.late_fee)}
                    </>
                ),
            },
            {
                dataField: "paid",
                text: `${'Paid '} (${currency_symbol ? currency_symbol : ''})`,
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {numberWithCommas(listdata.paid)}
                    </>
                ),
            },
            // {
            //     dataField: "receipt_date",
            //     text: "Received Date",
            //     sort: true,
            // },
            {
                dataField: "status",
                text: "Status",
                sort: true,
            },
            // {
            //     dataField: "updated_name",
            //     text: "Updated By",
            //     sort: true,
            // },
            // {
            //     dataField: "created_at",
            //     text: "Created at",
            //     sort: true,
            //     formatter: (cellContent, listdata) => (
            //         <>
            //             {moment(listdata.created_at).format('DD/MM/YYYY h:mma')}
            //         </>

            //     ),
            // },
            // {
            //     dataField: "updated_at",
            //     text: "Updated at",
            //     sort: true,
            //     formatter: (cellContent, listdata) => (
            //         <>
            //             {moment(listdata.updated_at).format('DD/MM/YYYY h:mma')}
            //         </>

            //     ),
            // },
            {
                dataField: "action",
                isDummyField: true,
                text: "Action",
                formatter: (cellContent, listdata) => (
                    <>
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            {/* {citiesEdit ? <> */}
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    onClick={() => this.handleRecordClick(listdata)}
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                            {/* </> : <></>} */}
                            {/* {citiesDelete ? <> */}
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => this.onClickDelete(listdata)}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                            {/* </> : <></>} */}
                        </ul>
                    </>
                ),
            },

        ];

        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];

        const pageOptions = {
            sizePerPage: 500,
            custom: true,
        };

        // Select All Button operation
        const selectRow = {
            mode: "checkbox",
        };

        const { SearchBar } = Search;
        //meta title
        document.title = "Edit Sale";
        const { pick } = this.state
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                console.log(row);
                this.setState({ recordDeleteID: row.id });
                this.setState({ status: row.status });
                this.setState({ receiptDate: row.receipt_date })
                this.setState({ late_fee: row.late_fee })
                this.setState({ amount_edit: row.amount })
                this.setState({
                    total_edit: (parseFloat(row.amount) + parseFloat(row.late_fee)).toFixed(2)
                })
            }
        }
        return (
            <React.Fragment>
                <DeleteModal
                    show={deleteModal}
                    record={`sale payment, this will also decrease the value of price and total of the sale`}
                    onDeleteClick={this.handleDeletejob}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content">
                    <Container fluid={true}>
                        <Breadcrumbs title="Sales" breadcrumbItem="Edit Sale" parentLink="/sales/list" />
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={{
                                                // firstName: (this.state && this.state.firstName) || "",
                                            }}
                                            validationSchema={Yup.object().shape({
                                                // firstName: Yup.string().required(
                                                //     "User first name is required!"
                                                // ),
                                            })}
                                        // onSubmit={values => {
                                        //     console.log(values);
                                        //     var formatedDate = this.getDateFormateMethod(this.state.saleDate[0])
                                        //     console.log(formatedDate);
                                        //     console.log(this.state.saleDate);
                                        //     console.log(selectedGroupSalesAgent.value);
                                        //     console.log(selectedGroupProject.value);
                                        //     console.log(selectedGroupProperties.value);
                                        //     console.log(this.state.propertyPrice);
                                        //     console.log(this.state.discountPrice);
                                        //     console.log(this.state.totalPrice);
                                        //     console.log(this.state.customers);



                                        //     // axios.post(baseURL + "/api/sales", {
                                        //     //     "status": selectedGroupSaleStatus.value,
                                        //     //     'sale_date': formatedDate,
                                        //     //     'sale_price': this.state.propertyPrice,
                                        //     //     'sale_discount': this.state.discountPrice,
                                        //     //     'sale_total': this.state.totalPrice,
                                        //     //     'offer_detail': values.offerDetails,
                                        //     //     'notes': values.notes,
                                        //     //     'property_id': selectedGroupProperties.value,
                                        //     //     'sales_agent_id': selectedGroupSalesAgent.value,
                                        //     //     'customer_id': this.state.customers
                                        //     // }, config)
                                        //     //     .then((response) => {
                                        //     //         showToast("Sales", "Sales Added Successfully", "success");
                                        //     //         this.setState({ redirect: "/sales/list" });
                                        //     //         console.log(response);
                                        //     //     })
                                        //     //     .catch((error) => {
                                        //     //         showToast("Sales", "Sales Not Added Successfully", "error")
                                        //     //         console.log(error);
                                        //     //     });
                                        // }}
                                        >
                                            {({ errors, status, touched }) => (
                                                <Form className="needs-validation" onSubmit={this.handleSaleEditSubmit}>
                                                    <Row>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex ">
                                                                <Label className="col-md-2 col-form-label" htmlFor="">Date</Label>
                                                                <div className="col-md-10 mt-2">{this.state.saleDate}</div>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="">Customers</Label>
                                                                <div className="col-md-10 mt-2">{this.state.customers_list}</div>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="salesAgent">Sales Agent</Label>
                                                                <div className="col-md-10 mt-2">{this.state.sales_agent_full_name}</div>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="selectProject">Project</Label>
                                                                <div className="col-md-10 mt-2">{this.state.project_name}</div>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="selectProperty">Property</Label>
                                                                <div className="col-md-10 mt-2">{this.state.property_name}</div>
                                                            </div>
                                                        </Col>
                                                        <Col md="12">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <Label className="col-md-2 col-form-label" htmlFor="selectCurrency">Currency</Label>
                                                                <div className="col-md-10 mt-2">{this.state.currency_name}</div>
                                                            </div>
                                                        </Col>
                                                        {false ? <></> : <>
                                                            <Col md="12">
                                                                <div className="mb-3 row d-flex select2-container">
                                                                    <Label className="col-md-2 col-form-label" htmlFor="price">Price</Label>
                                                                    <div className="col-md-4">
                                                                        <Field
                                                                            name="price"
                                                                            placeholder="Price"
                                                                            value={this.state.propertyPrice}
                                                                            readOnly
                                                                            className={
                                                                                "form-control"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <Label className="col-md-2 col-form-label" htmlFor="discount">Discount</Label>
                                                                    <div className="col-md-4">
                                                                        <Field
                                                                            name="discount"
                                                                            placeholder="Discount"
                                                                            type="number"
                                                                            value={this.state.discountPrice}
                                                                            onChange={(e) => this.priceHandler(e)}
                                                                            className={
                                                                                "form-control"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </>}
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="total">Total</Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        name="total"
                                                                        placeholder="Total"
                                                                        value={this.state.totalPrice}
                                                                        type="number"
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="offerDetails">Offer Details</Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        id="offer-detail"
                                                                        name="offerDetails"
                                                                        value={this.state.offer_detail}
                                                                        onChange={(e) => this.setState({ offer_detail: e.target.value })}
                                                                        placeholder="Offer Details"
                                                                        as="textarea"
                                                                        rows={3}
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="notes">Notes</Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        id="notes"
                                                                        name="notes"
                                                                        placeholder="Write your notes..."
                                                                        value={this.state.notes}
                                                                        onChange={(e) => this.setState({ notes: e.target.value })}
                                                                        as="textarea"
                                                                        rows={3}
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="12">
                                                            <FormGroup className="mb-3 row d-flex">
                                                                <Label className="col-md-2 col-form-label" htmlFor="optionalExtras">Optional Extras</Label>
                                                                <div className="col-md-10">
                                                                    <Field
                                                                        id="optionalExtras"
                                                                        name="optionalExtras"
                                                                        placeholder="Write your optional extras..."
                                                                        value={this.state.optionalExtras}
                                                                        onChange={(e) => this.setState({ optionalExtras: e.target.value })}
                                                                        as="textarea"
                                                                        rows={5}
                                                                        className={
                                                                            "form-control"
                                                                        }
                                                                    />
                                                                </div>
                                                            </FormGroup>
                                                        </Col>

                                                    </Row>
                                                    {this.state.currentSaleData.sum_of_amount == this.state.totalPrice ? <>
                                                        <Button color="primary" type="submit" className="float-end" >
                                                            Update Sale
                                                        </Button>
                                                    </> : <>
                                                        <Button color="primary" disabled className="float-end" >
                                                            Update Sale
                                                        </Button>
                                                    </>}
                                                    {/* {this.state.totalPrice == this.state.grandTotal ? <>
                                                        <Button color="primary" type="submit" className="float-end" >
                                                            Update Sale
                                                        </Button>
                                                    </> : <>
                                                        {this.state.totalPrice != 0 ? <>
                                                            <div className="text-center">
                                                                <p className="text-danger">Sale Total Price is not equal Grand Total.</p>
                                                            </div>

                                                        </> : <>
                                                            <div className="text-center">
                                                                <p className="text-danger">Sale Total Price not exist against property.</p>
                                                            </div>
                                                        </>}
                                                    </>} */}

                                                </Form>
                                            )}
                                        </Formik>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">Total</h5>
                                            </div>
                                        </CardBody>
                                        <div className="table-responsive">
                                            <Table className="table mb-4">
                                                <thead>
                                                    <tr>
                                                        <th>Grand Total</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th>Amount</th>
                                                        <th>Late Fee</th>
                                                        <th>Receivable</th>
                                                        <th>Paid</th>
                                                        <th>Remaining</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                        </td>
                                                        <td>
                                                            {this.state.currentSaleData.currency_symbol}{" "}{numberWithCommas(this.state.currentSaleData.sum_of_amount)}
                                                        </td>
                                                        <td>
                                                            {this.state.currentSaleData.currency_symbol}{" "}{numberWithCommas(this.state.currentSaleData.sum_of_late_fee)}
                                                        </td>
                                                        <td>
                                                            {this.state.currentSaleData.currency_symbol}{" "}{numberWithCommas(this.state.currentSaleData.sum_of_amount)}
                                                        </td>
                                                        <td>
                                                            {this.state.currentSaleData.currency_symbol}{" "}{numberWithCommas(this.state.currentSaleData.sum_of_paid)}
                                                        </td>
                                                        <td>
                                                            {this.state.currentSaleData.currency_symbol}{" "}{numberWithCommas((this.state.currentSaleData.sum_of_amount) - (this.state.currentSaleData.sum_of_paid))}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">List Sale Payments</h5>
                                                <div className="flex-shrink-0">
                                                    <Link to="#" onClick={() => this.handleNewPayment("")} className="btn btn-primary me-1">Add New Payment</Link>
                                                </div>
                                            </div>
                                        </CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField="id"
                                            columns={columns}
                                            data={this.state.listData}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    columns={columns}
                                                    data={this.state.listData}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col md="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"id"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            selectRow={selectRow}
                                                                            rowEvents={rowEvents}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            ref={this.node}
                                                                        />
                                                                        <Modal
                                                                            isOpen={this.state.modal}
                                                                            className={this.props.className}
                                                                        >
                                                                            <ModalHeader toggle={this.toggle} tag="h4">
                                                                                {!!isEdit ? "Edit Payment" : "Add Payment"}
                                                                            </ModalHeader>
                                                                            <ModalBody>
                                                                                <Formik
                                                                                    enableReinitialize={true}
                                                                                    initialValues={{
                                                                                        id: (listdata && listdata.id) || '',
                                                                                        due_date: (listdata && listdata.due_date) || '',
                                                                                        amount: (listdata && listdata.amount) || '',
                                                                                        late_fee: (listdata && listdata.late_fee) || '',
                                                                                        total: (listdata && listdata.total) || '',
                                                                                        paid: (listdata && listdata.paid) || '',
                                                                                        status: (listdata && listdata.status) || '',
                                                                                        receipt_date: (listdata && listdata.receipt_date) || '',
                                                                                    }}
                                                                                    validationSchema={Yup.object().shape({
                                                                                        // id: Yup.string().required("Please Enter Your Id"),
                                                                                        // due_date: Yup.string().required("Please Enter Due Date"),
                                                                                        // amount: Yup.string().required("Please Enter Amount"),
                                                                                        // late_fee: Yup.string().required("Please Enter Late Fee"),
                                                                                        // total: Yup.string().required("Please Enter Total"),
                                                                                        // paid: Yup.string().required("Please Enter Amount Paid"),
                                                                                        // receipt_date: Yup.string().required("Please Enter Received Date"),
                                                                                    })}
                                                                                    onSubmit={values => {
                                                                                        console.log(values);

                                                                                        var nDateArray = values.due_date.split("/");
                                                                                        var nDate = nDateArray[2] + "-" + nDateArray[1] + "-" + nDateArray[0];
                                                                                        axios.post(baseURL + "/api/payment-update/" + values.id, {
                                                                                            "sale_id": this.state.current_sale_id,
                                                                                            "due_date": nDate,
                                                                                            "amount": this.state.amount_edit,
                                                                                            "late_fee": this.state.late_fee,
                                                                                            "total": this.state.total_edit,
                                                                                        }, config)
                                                                                            .then((response) => {
                                                                                                showToast("Success", "Payment Updated Successfully", "success");
                                                                                                console.log(response);
                                                                                                Promise.all([get_payment_against_sale(this.state.current_sale_id)]).then((values) => {
                                                                                                    console.log(values[0]);
                                                                                                    this.setState({ listData: values[0] });
                                                                                                });
                                                                                                Promise.all([get_current_sale(this.state.current_sale_id)]).then((values) => {
                                                                                                    console.log(values[0]);
                                                                                                    this.setState({ currentSaleData: values[0] });
                                                                                                });
                                                                                            })
                                                                                            .catch((error) => {
                                                                                                showToast("Failed", "Payment Not Added Successfully", "error")
                                                                                                console.log(error);
                                                                                            });
                                                                                        this.toggle();
                                                                                    }}
                                                                                >
                                                                                    {({ errors, status, touched }) => (
                                                                                        <Form>
                                                                                            <Row>
                                                                                                <Col className="col-12">
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label">
                                                                                                            Sale Payment Id
                                                                                                        </Label>
                                                                                                        <Field
                                                                                                            name="id"
                                                                                                            disabled
                                                                                                            type="text"
                                                                                                            className={
                                                                                                                "form-control" +
                                                                                                                (errors.id &&
                                                                                                                    touched.id
                                                                                                                    ? " is-invalid"
                                                                                                                    : "")
                                                                                                            }
                                                                                                        />
                                                                                                        <ErrorMessage
                                                                                                            name="id"
                                                                                                            component="div"
                                                                                                            className="invalid-feedback"
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label">
                                                                                                            Payment Due Date
                                                                                                        </Label>
                                                                                                        <Field
                                                                                                            name="due_date"
                                                                                                            type="text"
                                                                                                            className={
                                                                                                                "form-control" +
                                                                                                                (errors.due_date &&
                                                                                                                    touched.due_date
                                                                                                                    ? " is-invalid"
                                                                                                                    : "")
                                                                                                            }
                                                                                                        />
                                                                                                        <ErrorMessage
                                                                                                            name="due_date"
                                                                                                            component="div"
                                                                                                            className="invalid-feedback"
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label">
                                                                                                            Amount ({this.state.currentSaleData.currency_symbol})
                                                                                                        </Label>
                                                                                                        <Field
                                                                                                            id='amount-edit'
                                                                                                            // name="amount"
                                                                                                            value={this.state.amount_edit}
                                                                                                            type="number"
                                                                                                            className={
                                                                                                                "form-control" +
                                                                                                                (errors.amount &&
                                                                                                                    touched.amount
                                                                                                                    ? " is-invalid"
                                                                                                                    : "")
                                                                                                            }
                                                                                                            onBlur={(e) => {
                                                                                                                if (e.target.value.trim() === "") {
                                                                                                                    e.target.value = ""; // Empty value stays empty
                                                                                                                } else {
                                                                                                                    const formattedValue = parseFloat(e.target.value).toFixed(2);
                                                                                                                    this.setState({ amount_edit: formattedValue });
                                                                                                                }
                                                                                                            }}
                                                                                                            onChange={(e) => this.handleAmountChange(e)}

                                                                                                        />
                                                                                                        <ErrorMessage
                                                                                                            name="amount"
                                                                                                            component="div"
                                                                                                            className="invalid-feedback"
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label">
                                                                                                            Late Fee
                                                                                                        </Label>
                                                                                                        <Field
                                                                                                            id='late-fee-edit'
                                                                                                            // name="late_fee"
                                                                                                            type="number"
                                                                                                            value={this.state.late_fee}
                                                                                                            className={
                                                                                                                "form-control" +
                                                                                                                (errors.late_fee &&
                                                                                                                    touched.late_fee
                                                                                                                    ? " is-invalid"
                                                                                                                    : "")
                                                                                                            }
                                                                                                            onBlur={(e) => {
                                                                                                                if (e.target.value.trim() === "") {
                                                                                                                    e.target.value = ""; // Empty value stays empty
                                                                                                                } else {
                                                                                                                    const formattedValue = parseFloat(e.target.value).toFixed(2);
                                                                                                                    this.setState({ late_fee: formattedValue });
                                                                                                                }
                                                                                                            }}
                                                                                                            // onChange={this.handleLateFeeChange}
                                                                                                            onChange={(e) => this.handleLateFeeChange(e)}
                                                                                                        />
                                                                                                        <ErrorMessage
                                                                                                            name="late_fee"
                                                                                                            component="div"
                                                                                                            className="invalid-feedback"
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label">
                                                                                                            Total
                                                                                                        </Label>
                                                                                                        <Field
                                                                                                            disabled
                                                                                                            // id='total-edit'
                                                                                                            // value={(parseFloat(listdata.amount) + parseFloat(listdata.late_fee)).toFixed(2)}
                                                                                                            value={this.state.total_edit}
                                                                                                            name="total"
                                                                                                            type="text"
                                                                                                            className={
                                                                                                                "form-control" +
                                                                                                                (errors.total &&
                                                                                                                    touched.total
                                                                                                                    ? " is-invalid"
                                                                                                                    : "")
                                                                                                            }
                                                                                                        />
                                                                                                        <ErrorMessage
                                                                                                            name="total"
                                                                                                            component="div"
                                                                                                            className="invalid-feedback"
                                                                                                        />
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>
                                                                                                    <div className="text-end">
                                                                                                        <button
                                                                                                            type="submit"
                                                                                                            className="btn btn-primary save-user"
                                                                                                        >
                                                                                                            Save
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Form>
                                                                                    )}
                                                                                </Formik>
                                                                            </ModalBody>
                                                                        </Modal>
                                                                        {/* Modal for Add New Payment Plan*/}
                                                                        <Modal
                                                                            isOpen={this.state.modalNewPayment}
                                                                            className={this.props.className}
                                                                        >
                                                                            <ModalHeader toggle={this.toggleNewPayment} tag="h4">
                                                                                {!!isEdit ? "Add New Payment" : "Add New Payment"}
                                                                            </ModalHeader>
                                                                            <ModalBody>
                                                                                <Formik
                                                                                    enableReinitialize={true}
                                                                                    initialValues={{
                                                                                        // id: (listdata && listdata.id) || '',
                                                                                        // due_date: (listdata && listdata.due_date) || '',
                                                                                        // amount: (listdata && listdata.amount) || '',
                                                                                        // late_fee: (listdata && listdata.late_fee) || '',
                                                                                        // total: (listdata && listdata.total) || '',
                                                                                        // paid: (listdata && listdata.paid) || '',
                                                                                        // status: (listdata && listdata.status) || '',
                                                                                        // receipt_date: (listdata && listdata.receipt_date) || '',
                                                                                    }}
                                                                                    validationSchema={Yup.object().shape({
                                                                                        // id: Yup.string().required("Please Enter Your Id"),
                                                                                        // due_date: Yup.string().required("Please Enter Due Date"),
                                                                                        // amount: Yup.string().required("Please Enter Amount"),
                                                                                        // late_fee: Yup.string().required("Please Enter Late Fee"),
                                                                                        // total: Yup.string().required("Please Enter Total"),
                                                                                        // paid: Yup.string().required("Please Enter Amount Paid"),
                                                                                        // receipt_date: Yup.string().required("Please Enter Received Date"),
                                                                                    })}
                                                                                    onSubmit={values => {
                                                                                        console.log(values);
                                                                                        // var formatedDate = this.getDateFormateMethod(this.state.paymentReceivedDate[0]);
                                                                                        axios.post(baseURL + "/api/save-additional-payments", {
                                                                                            "payment_type_id": selectedPaymentType.value,
                                                                                            "sale_id": this.state.currentSaleData.id,
                                                                                            "amount": values.amount,
                                                                                            "due_date": this.state.paymentReceivedDate[0],
                                                                                            "currency_id": this.state.currentSaleData.currency_id,
                                                                                            "no_of_recurrences": values.no_of_recurrences
                                                                                        }, config)
                                                                                            .then((response) => {
                                                                                                showToast("Success", "Payment Added Successfully", "success");
                                                                                                console.log(response);
                                                                                                Promise.all([get_payment_against_sale(this.state.current_sale_id)]).then((values) => {
                                                                                                    console.log(values[0]);
                                                                                                    this.setState({ listData: values[0] });
                                                                                                });
                                                                                                Promise.all([get_current_sale(this.state.current_sale_id)]).then((values) => {
                                                                                                    console.log(values[0]);
                                                                                                    this.setState({ currentSaleData: values[0] });
                                                                                                    this.setState({ totalPrice: values[0].sale_total })
                                                                                                    this.setState({ discountPrice: values[0].sale_discount })
                                                                                                    this.setState({ propertyPrice: values[0].sale_price })
                                                                                                });
                                                                                            })
                                                                                            .catch((error) => {
                                                                                                showToast("Failed", "Payment Not Added Successfully", "error")
                                                                                                console.log(error);
                                                                                            });
                                                                                        this.toggleNewPayment();
                                                                                    }}
                                                                                >
                                                                                    {({ errors, status, touched }) => (
                                                                                        <Form>
                                                                                            <Row>
                                                                                                <Col className="col-12">
                                                                                                    <Label className="form-label text-danger">By adding a payment the price and total of sale will be changed.</Label>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col className="col-12">
                                                                                                    <div className="mb-3 select2-container">
                                                                                                        <Label className="form-label" htmlFor="selectedStatus">Select Payment Type</Label>
                                                                                                        <div className="col-md-12">
                                                                                                            <Select
                                                                                                                // value={{ label: this.state.status, value: 0 }}
                                                                                                                onChange={this.handleSelectedPaymentTypes}
                                                                                                                options={this.state.optionArrayPaymentType}
                                                                                                                classNamePrefix="select2-selection"
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label" htmlFor="selectDate">Due Date</Label>

                                                                                                        <div className="docs-datepicker col-md-12">
                                                                                                            <InputGroup>
                                                                                                                <Flatpickr
                                                                                                                    className="form-control d-block"
                                                                                                                    placeholder="Pick a date"
                                                                                                                    value={this.state.receiptDate}
                                                                                                                    options={{
                                                                                                                        enableTime: false,
                                                                                                                        dateFormat: "d/m/Y",
                                                                                                                    }}
                                                                                                                    onChange={date => {
                                                                                                                        this.setState({ paymentReceivedDate: date })
                                                                                                                    }}
                                                                                                                />
                                                                                                                <div className="input-group-append">
                                                                                                                    <button
                                                                                                                        type="button"
                                                                                                                        className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                                                                        disabled
                                                                                                                    >
                                                                                                                        <i
                                                                                                                            className="fa fa-calendar"
                                                                                                                            aria-hidden="true"
                                                                                                                        />
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            </InputGroup>
                                                                                                            <div className="docs-datepicker-container" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label">
                                                                                                            Amount ({this.state.currentSaleData.currency_symbol})
                                                                                                        </Label>
                                                                                                        <Field
                                                                                                            name="amount"
                                                                                                            type="text"
                                                                                                            className={
                                                                                                                "form-control" +
                                                                                                                (errors.amount &&
                                                                                                                    touched.amount
                                                                                                                    ? " is-invalid"
                                                                                                                    : "")
                                                                                                            }
                                                                                                            onBlur={(e) => {
                                                                                                                if (e.target.value.trim() === "") {
                                                                                                                    e.target.value = ""; // Empty value stays empty
                                                                                                                } else {
                                                                                                                    const formattedValue = parseFloat(e.target.value).toFixed(2);
                                                                                                                    e.target.value = formattedValue;
                                                                                                                }
                                                                                                            }}
                                                                                                        />
                                                                                                        <ErrorMessage
                                                                                                            name="amount"
                                                                                                            component="div"
                                                                                                            className="invalid-feedback"
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <Label className="form-label">
                                                                                                            No of Recurrences
                                                                                                        </Label>
                                                                                                        <Field
                                                                                                            name="no_of_recurrences"
                                                                                                            type="text"
                                                                                                            className={
                                                                                                                "form-control" +
                                                                                                                (errors.no_of_recurrences &&
                                                                                                                    touched.no_of_recurrences
                                                                                                                    ? " is-invalid"
                                                                                                                    : "")
                                                                                                            }
                                                                                                        />
                                                                                                        <ErrorMessage
                                                                                                            name="no_of_recurrences"
                                                                                                            component="div"
                                                                                                            className="invalid-feedback"
                                                                                                        />
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>
                                                                                                    <div className="text-end">
                                                                                                        <button
                                                                                                            type="submit"
                                                                                                            className="btn btn-primary save-user"
                                                                                                        >
                                                                                                            Save
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Form>
                                                                                    )}
                                                                                </Formik>
                                                                            </ModalBody>
                                                                        </Modal>
                                                                        {/* Modal End Add New Payment Plan */}
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default EditSale;
