import axios from "axios";
// import { Link, useHistory } from "react-router-dom";  
import { withRouter } from 'react-router-dom'   

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
const baseURL = process.env.REACT_APP_API_URL;

export const get_all_customers = async () => {
    let response = await axios.get(baseURL + "/api/customers", config);
    return response.data.data;
}
export const delete_customer = async (customer_id) => {
    let response = await axios.delete(baseURL + "/api/customers/"+customer_id, config);
    return response.data.data;
}
// const current_customer = window.localStorage.getItem("current_customer");
export const get_current_customer = async (customer_id) => {
    let current_customer_response = await axios.get(baseURL + "/api/customers/"+customer_id, config);
    return current_customer_response.data.data;
}
export const get_current_customer_detail = async (customer_id) => {
    let current_customer_response = await axios.get(baseURL + "/api/customer-details/"+customer_id, config);
    return current_customer_response.data.data;
}
export const get_customer_documents = async (customer_id) => {
    let current_customer_docs_response = await axios.get(baseURL + "/api/customer-documents/"+customer_id, config);
    return current_customer_docs_response.data.data;
}

export const get_all_nominees = async (customer_id) => {
    let response = await axios.get(baseURL + "/api/customer-nominees/"+customer_id, config);
    return response.data.data;
}
export const delete_nominee = async (nominee_id) => {
    let response = await axios.delete(baseURL + "/api/nominees/"+nominee_id, config);
    return response.data.data;
}
export const get_current_nominee= async (nominee_id) => {
    let response = await axios.get(baseURL + "/api/nominees/"+nominee_id, config);
    return response.data.data;
}
export const get_all_identity_sub_types= async () => {
    let response = await axios.get(baseURL + "/api/identity-sub-types/", config);
    return response.data.data;
}

export const get_nominees_with_docs = async (customer_id) => {
    let response = await axios.get(baseURL + "/api/nominees-documents/"+customer_id, config);
    return response.data.data;
}

export const get_nominee_documents = async (nominee_id) => {
    let current_nominee_docs_response = await axios.get(baseURL + "/api/nominee-documents/"+nominee_id, config);
    return current_nominee_docs_response.data.data;
}
export const get_customer_log = async (customer_id) => {
    let response = await axios.get(baseURL + "/api/customer-activity-log/"+customer_id, config);
    return response.data.data;
}
export const get_all_customers_nominees_log = async () => {
    let response = await axios.get(baseURL + "/api/all-customers-nominees-activity-log", config);
    return response.data.data;
}
export const get_all_deleted_customers = async () => {
    let response = await axios.get(baseURL + "/api/deleted-customers", config);
    return response.data.data;
}
export const restore_customer = async (customer_id) => {
    let response = await axios.post(baseURL + "/api/restore-customer/"+customer_id,{}, config);
    return response.data.data;
}
export const get_nominee_log = async (nominee_id) => {
    let response = await axios.get(baseURL + "/api/nominee-activity-log/"+nominee_id, config);
    return response.data.data;
}
export const get_all_deleted_nominees_against_customer = async (customer_id) => {
    let response = await axios.get(baseURL + "/api/deleted-nominees/"+customer_id, config);
    return response.data.data;
}
export const restore_nominee = async (nominee_id) => {
    let response = await axios.post(baseURL + "/api/restore-nominee/"+nominee_id,{}, config);
    return response.data.data;
}
export const get_nominee_identity_documents = async (nominee_id) => {
    let current_nominee_docs_response = await axios.get(baseURL + "/api/nominee-identity-documents/"+nominee_id, config);
    return current_nominee_docs_response.data.data;
}