import React, { Component } from "react"
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import Sizes from "./Sizes"
import toastr from "toastr";
import "toastr/build/toastr.min.css";
const defaultValues = {
    sizes: []
};
import { update_project_sizes } from '../../api/projectApi';

function FormBuilderSizesAndFloors() {
    const {
        control,
        register,
        handleSubmit,
        getValues,
        errors,
        reset,
        setValue
    } = useForm({
        defaultValues
    });
    const onSubmit = (data) => {
        if (data.sizes == '') {
            var toastTitle = "Failed";
            var toastMessage = "Please add Size first.";
            var toastType = "error";
            showToast(toastTitle, toastMessage, toastType);
        } else {
            var toastTitle = "Failed";
            var toastMessage = "Size name empty.";
            var toastType = "error";
            for (let i = 0; i < data.sizes.length; i++) {
                if (data.sizes[i].name == '') {
                    showToast(toastTitle, toastMessage, toastType);
                    return false;
                }
            }
            Promise.all([update_project_sizes(window.localStorage.getItem("current_project_id"), data)]).then((values) => {
                console.log(values[0]);
                var toastTitle = "Success";
                var toastMessage = "Sizes Updated Successfully";
                var toastType = "success";
                showToast(toastTitle, toastMessage, toastType);
                if (window.localStorage.getItem("has_phase_blocks") == 1) {
                    handleClick();
                }
                else if (window.localStorage.getItem("has_floors") == 1) {
                    console.log("Go to handleClickfloors");
                    handleClickfloors();
                }
                else {
                    handleClickFeatures();
                }
            }).catch((error) => {
                var toastTitle = "Failed";
                var toastMessage = "Sizes Not Updated";
                var toastType = "error";
                showToast(toastTitle, toastMessage, toastType);
                console.log(error);
            });
            console.log("data outside", data);
        }
    }

    function showToast(title, message, toastType) {
        //position class
        var positionClass = "toast-top-right";
        toastr.options = {
            positionClass: positionClass,
            timeOut: 5000,
            extendedTimeOut: 1000,
            showEasing: "swing",
            hideEasing: "linear",
            showMethod: "fadeIn",
            hideMethod: "fadeOut",
            showDuration: 300,
            hideDuration: 1000,
        };

        // setTimeout(() => toastr.success(`Settings updated `), 300)
        //Toaster Types
        if (toastType === "info") toastr.info(message, title);
        else if (toastType === "warning") toastr.warning(message, title);
        else if (toastType === "error") toastr.error(message, title);
        else toastr.success(message, title);
    }

    let history = useHistory();

    function handleClick() {
        history.push("/project/edit-phases-blocks");
    }
    function handleClickfloors() {
        // history.push("/projects/floors?section=4");
        console.log("Go to floors");
        history.push("/project/edit-floors");
    }

    function handleClickFeatures() {
        history.push("/projects/edit-features");
    }
    return (
        <Row className="mt-5 pt-4">
            <Col xs="12">
                <Card>
                    <CardBody className="mb-4">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Sizes
                                {...{ control, register, defaultValues, getValues, setValue, errors }}
                            />

                            {/* <button type="button" onClick={() => reset(defaultValues)}>
                      Reset
                    </button> */}
                            <div className="mb-4">
                                <button style={{ float: "right" }} onClick={handleSubmit(onSubmit)} color="primary"
                                    className="btn btn-primary">Next</button></div>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}


export default FormBuilderSizesAndFloors
