export const GET_PROJECT_LIST = "GET_PROJECT_LIST"
export const GET_PROJECT_LIST_FAIL = "GET_PROJECT_LIST_FAIL"
export const GET_PROJECT_LIST_SUCCESS = "GET_PROJECT_LIST_SUCCESS"
export const ADD_NEW_PROJECT_LIST = "ADD_NEW_PROJECT_LIST"
export const ADD_PROJECT_LIST_SUCCESS = "ADD_PROJECT_LIST_SUCCESS"
export const ADD_PROJECT_LIST_FAIL = "ADD_PROJECT_LIST_FAIL"
export const UPDATE_PROJECT_LIST = "UPDATE_PROJECT_LIST"
export const UPDATE_PROJECT_LIST_SUCCESS = "UPDATE_PROJECT_LIST_SUCCESS"
export const UPDATE_PROJECT_LIST_FAIL = "UPDATE_PROJECT_LIST_FAIL"
export const DELETE_PROJECT_LIST = "DELETE_PROJECT_LIST"
export const DELETE_PROJECT_LIST_SUCCESS = "DELETE_PROJECT_LIST_SUCCESS"
export const DELETE_PROJECT_LIST_FAIL = "DELETE_PROJECT_LIST_FAIL"
export const GET_APPLY_PROJECT = "GET_APPLY_PROJECT"
export const GET_APPLY_PROJECT_SUCCESS = "GET_APPLY_PROJECT_SUCCESS"
export const GET_APPLY_PROJECT_FAIL = "GET_APPLY_PROJECT_FAIL"
export const DELETE_APPLY_PROJECT = "DELETE_APPLY_PROJECT"
export const DELETE_APPLY_PROJECT_SUCCESS = "DELETE_APPLY_PROJECT_SUCCESS"
export const DELETE_APPLY_PROJECT_FAIL = "DELETE_APPLY_PROJECT_FAIL"
