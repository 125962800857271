import React, { Component } from "react"
import { Row, Col } from "reactstrap"
import { Link } from "react-router-dom"

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <div className="container-fluid">
            <Row>
              <Col sm={6}> © {new Date().getFullYear()} <a href='https://propertyappz.com/' target='_blank' rel="noreferrer">Property Appz</a> + <a href='https://pentadmedia.com/' target='_blank' rel="noreferrer">Pentad Media</a></Col>
              <Col sm={6}>
                <div className="text-sm-end d-none d-sm-block">
                  All Rights Reserved.
                </div>
              </Col>
            </Row>
          </div>
        </footer>
      </React.Fragment>
    )
  }
}

export default Footer;