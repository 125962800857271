import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";
import { showToast } from '../../../UI/ui-toast'
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { get_current_nominee, get_all_identity_sub_types } from '../../api/customersApi';
const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};

import constant from '../../../../constants.json';
const baseURL = process.env.REACT_APP_API_URL;
var newData = [];
class EditNominee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: { document_sub_type_name: '', document_name: '', file: '', id: 0 },
      tableData: [],
      optionsArray: [],
      validation: "",
      optionGroupCountry: [],
      optionGroupCity: [],
      optionGroupIdentity: [],
      optionGroupNationalities: [],
      c_first_name: '',
      c_last_name: '',
      c_middle_name: '',
      c_telephone_home: '',
      c_mobile_number: '',
      c_telephone_office: '',
      c_address_line_1: '',
      c_address_line_2: '',
      c_primary_email: '',
      c_secondary_email: '',
      c_identity_number: '',
      c_nationality: '',
      c_postcode: '',
      c_country_id: '',
      c_city_id: '',
      c_title: '',
      c_city_title: '',
      c_country_title: '',
      c_org_id: '',
      c_customer_id: '',
      redirect: null,
      titlesArray: constant.titles_list,
      id: "",
      name: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
  }

  handleChange(event) {
  }

  async getCountriesList() {
    const countriesResponse = await axios.get(baseURL + "/api/countries-list", config);
    console.log(countriesResponse.data);
    const countriesListData = countriesResponse.data.data

    console.log(countriesListData);

    const options = countriesListData.map(d => ({
      "value": d,
      "label": d
    }));

    console.log(options);
    this.setState({ optionGroupCountry: options })
  }

  async getOptionsCountry() {

    /*
      This function is replaced by getCountriesList()
     */

    // const res = await axios.get(baseURL + "/api/countries-listing", config);
    // const data = res.data.data
    // // console.log(data);

    // const options = data.map(d => ({
    //   "value": d.id,
    //   "label": d.title
    // }))
    // this.setState({ optionGroupCountry: options })
  }
  async getOptionsCity(country_id) {
    const res = await axios.get(baseURL + "/api/country-cities/" + country_id, config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d.id,
      "label": d.title
    }))
    this.setState({ optionGroupCity: options })
  }
  async getOptionsNationalities() {
    const res = await axios.get(baseURL + "/api/nationalities", config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d.id,
      "label": d.name
    }))
    this.setState({ optionGroupNationalities: options })
  }
  async getOptionsIdentity() {
    const res = await axios.get(baseURL + "/api/identity-types", config);
    const data = res.data.data
    // console.log(data);

    const options = data.map(d => ({
      "value": d,
      "label": d
    }))
    this.setState({ optionGroupIdentity: options })
  }
  async getNomineeDocuments(n_id) {
    const res = await axios.get(baseURL + "/api/nominee-identity-documents/" + n_id, config);
    const data = res.data.data
    console.log(data);
    this.setState({ tableData: data })
  }
  componentDidMount() {
    Promise.all([get_current_nominee(localStorage.getItem("current_nominee_id"))]).then((values) => {
      this.setState({ c_first_name: values[0].first_name });
      this.setState({ c_last_name: values[0].last_name });
      this.setState({ c_middle_name: values[0].middle_name });
      this.setState({ c_mobile_number: values[0].mobile_number });
      this.setState({ c_telephone_home: values[0].telephone_home });
      // this.setState({ c_husband_name: values[0].husband_name });
      // this.setState({ c_father_name: values[0].father_name });
      this.setState({ c_primary_email: values[0].primary_email });
      this.setState({ c_secondary_email: values[0].secondary_email });
      this.setState({ c_telephone_office: values[0].telephone_office });
      this.setState({ c_address_line_1: values[0].address_line_1 });
      this.setState({ c_address_line_2: values[0].address_line_2 });
      this.setState({ c_nationality: values[0].nationality });
      this.setState({ c_identity_type: values[0].identity_type });
      this.setState({ c_identity_number: values[0].identity_number });
      this.setState({ c_postcode: values[0].postcode });
      this.setState({ c_title: values[0].title });
      this.setState({ c_org_id: values[0].organisation_id });
      this.setState({ c_customer_id: values[0].customer_id });
      // this.setState({ c_country_id: values[0].country_id });
      // this.setState({ c_city_id: values[0].city_id });
      this.setState({ c_country_title: values[0].country });
      this.setState({ c_city_title: values[0].city });
      this.getNomineeDocuments(values[0].id)
    });
    this.getCountriesList();
    // this.getOptionsCountry();
    this.getOptionsNationalities();
    this.getOptionsIdentity();
    Promise.all([get_all_identity_sub_types()]).then((values) => {
      const identityTypesOptions = values[0].map(d => ([d.name]))
      console.log(identityTypesOptions);
      this.setState({ optionsArray: identityTypesOptions })
    });
  }

  handleSelectedCustomerTitle = selectedCustomerTitle => {
    this.setState({ c_title: selectedCustomerTitle.value });
    this.setState({ selectedCustomerTitle })
    console.log(selectedCustomerTitle);
  }
  handleSelectGroupCountry = selectedGroupCountry => {
    this.setState({ selectedGroupCountry })
    // this.getOptionsCity(selectedGroupCountry.value)
    // this.setState({ c_city_title: '' })
    this.setState({ c_country_title: selectedGroupCountry.label });
    this.setState({ c_country_id: selectedGroupCountry.value });
    console.log(selectedGroupCountry);
  }
  handleSelectGroupCity = selectedGroupCity => {
    this.setState({ c_city_title: selectedGroupCity.label });
    this.setState({ c_city_id: selectedGroupCity.value });
    this.setState({ selectedGroupCity })
    console.log(selectedGroupCity);
  }
  handleSelectGroupNationality = selectedGroupNationality => {
    this.setState({ c_nationality: selectedGroupNationality.label });
    this.setState({ selectedGroupNationality })
    console.log(selectedGroupNationality);
  }
  handleSelectGroupIdentity = selectedGroupIdentity => {
    this.setState({ c_identity_type: selectedGroupIdentity.label });
    this.setState({ selectedGroupIdentity })
    console.log(selectedGroupIdentity);
  }
  // handleImageChangePicture = (e) => {
  //   if (e.target.files && e.target.files[0]) {
  //     console.log('Picture');
  //     console.log(e.target.files[0]);
  //     this.setState({ c_picture: e.target.files[0] });
  //   }
  // };
  handleDocumentFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      console.log('Picture');
      console.log(e.target.files[0]);
      // this.setState({ c_picture: e.target.files[0] });
      const { formData } = this.state;
      this.setState({ formData: { ...formData, file: e.target.files[0] } });
    }
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    const { formData } = this.state;
    this.setState({ formData: { ...formData, [name]: value } });
  }
  handleChangeFileName = (index, event) => {
    const updatedTableData = [...this.state.tableData]
    updatedTableData[index].document_name = event.target.value;
    this.setState({ tableData: updatedTableData })
    console.log(updatedTableData);
  }
  handleChangeCategory = (index, event) => {
    // console.log(index);
    // console.log(event.target.value);
    const updatedTableData = [...this.state.tableData]
    updatedTableData[index].document_sub_type_name = event.target.value;
    this.setState({ tableData: updatedTableData })
    console.log(updatedTableData);
  }
  handleDelete = (e, index, data) => {
    e.preventDefault();
    console.log(index);
    // console.log(this.state.tableData[index]);

    var newTableData = this.state.tableData
    delete newTableData[index]
    console.log(newTableData);
    this.setState({ tableData: newTableData })

    var currentTableData = this.state.tableData;
    var newArray = [];
    // var newTableData = this.state.tableData;
    console.log(currentTableData);
    var counter = 0;
    currentTableData.forEach((rowData, c_index) => {
      console.log("RowData: ", rowData);
      console.log("C_Index: ", c_index);
      // newArray.push(rowData)
      newArray[counter] = rowData;
      counter++;
      // this.setState({ tableData: newArray })
    })
    console.log(currentTableData.length);
    console.log(newArray.length);
    console.log("New Array: ", newArray);
    this.setState({ tableData: newArray });
    // console.log(newTableData);
    // console.log(this.state.tableData);
  };
  handleSubmitDocumnet = (e) => {
    e.preventDefault();
    if (!this.state.formData.document_sub_type_name || !this.state.formData.document_name || !this.state.formData.file) {
      let missingFields = '';
      if (!this.state.formData.document_sub_type_name) {
        missingFields += '<li>Category</li>';
      }
      if (!this.state.formData.document_name) {
        missingFields += '<li>Name</li>';
      }
      if (!this.state.formData.file) {
        missingFields += '<li>File</li>';
      }
      showToast("Failed", "These fields are missing. Please fill the required fields: " + missingFields, "error");
      return false;
    }
    const { formData } = this.state;
    // newData.push(formData)
    const newData = [...this.state.tableData, formData]; // create a new array with the existing data and the new record
    this.setState({ tableData: newData });
    this.setState({
      formData: { document_sub_type_name: '', document_name: '', file: '', id: 0 }
    });
    document.getElementById('document_file').value = ''
  }
  handleSubmit(e) {

    e.preventDefault();
    if (!this.state.c_first_name || !this.state.c_last_name || !this.state.c_mobile_number || !this.state.c_address_line_1 || !this.state.c_postcode || !this.state.c_primary_email || !this.state.c_country_title || !this.state.c_city_title) {
      let missingFields = '';
      if (!this.state.c_first_name) {
        missingFields += '<li>First Name</li>';
      }
      if (!this.state.c_last_name) {
        missingFields += '<li>Last Name</li>';
      }
      if (!this.state.c_mobile_number) {
        missingFields += '<li>Mobile Number</li>';
      }
      if (!this.state.c_primary_email) {
        missingFields += '<li>Primary Email</li>';
      }
      if (!this.state.c_address_line_1) {
        missingFields += '<li>Address Line 1</li>';
      }
      if (!this.state.c_postcode) {
        missingFields += '<li>Postcode</li>';
      }
      if (!this.state.c_country_title) {
        missingFields += '<li>Country</li>';
      }
      if (!this.state.c_city_title) {
        missingFields += '<li>City</li>';
      }
      showToast("Failed", "These fields are missing. Please fill the required fields: " + missingFields, "error");
      return false;
    }
    var documentArray = [];
    var counter = 0;
    this.state.tableData.forEach((rowData, c_index) => {
      console.log("RowData: ", rowData);
      console.log("C_Index: ", c_index);
      // newArray.push(rowData)
      documentArray[counter] = rowData;
      counter++;
    })
    console.log(documentArray);
    axios.post(baseURL + "/api/nominee-update/" + localStorage.getItem("current_nominee_id"), {

      "title": this.state.c_title,
      "first_name": e.target.nomineeFirstName.value,
      "middle_name": e.target.customerMiddleName.value,
      "last_name": e.target.nomineeLastName.value,
      "identity_type": this.state.c_identity_type,
      "identity_number": e.target.identityNumber.value,
      "nationality": this.state.c_nationality,
      "organisation_id": this.state.c_org_id,
      "customer_id": this.state.c_customer_id,
      "address_line_1": e.target.addresslineOne.value,
      "address_line_2": e.target.addresslineTwo.value,
      // "city_id": this.state.c_city_id,
      /*"city_title": selectedGroupCity.label,*/
      // "country_id": this.state.c_country_id,
      /*"country_title": selectedGroupCountry.label,*/
      "city": this.state.c_city_title,
      "country": this.state.c_country_title,
      "postcode": e.target.postcode.value,
      "telephone_home": e.target.phoneHome.value,
      "telephone_office": e.target.phoneOffice.value,
      "mobile_number": e.target.mobileNumber.value,
      "primary_email": e.target.primaryEmail.value,
      "secondary_email": e.target.secondaryEmail.value,
      "documents": documentArray,
      "documents_counter": documentArray.length
    }, config)
      .then((response) => {
        showToast("Success", "The nominee " + response.data.data.full_name + " has been updated successfully.", "success");
        this.setState({ redirect: "/customer/nominees/list" });
        localStorage.removeItem("current_nominee_id");
        // window.location.replace('/customers/list');
        console.log(response);
      })
      .catch((error) => {
        showToast("Failed", "Nominee Not Edited Successfully", "error");
        console.log(error);
      });

    // const modifiedV = { ...this.state.validation };
    // var fnm = document.getElementById("validationTooltip01").value;
    // var lnm = document.getElementById("validationTooltip02").value;
    // var unm = document.getElementById("validationTooltipUsername").value;
    // var city = document.getElementById("validationTooltip03").value;
    // var stateV = document.getElementById("validationTooltip04").value;

    // if (fnm === "") {
    //   modifiedV["fnm"] = false;
    // } else {
    //   modifiedV["fnm"] = true;
    // }

    // if (lnm === "") {
    //   modifiedV["lnm"] = false;
    // } else {
    //   modifiedV["lnm"] = true;
    // }

    // if (unm === "") {
    //   modifiedV["unm"] = false;
    // } else {
    //   modifiedV["unm"] = true;
    // }

    // if (city === "") {
    //   modifiedV["city"] = false;
    // } else {
    //   modifiedV["city"] = true;
    // }

    // if (stateV === "") {
    //   modifiedV["stateV"] = false;
    // } else {
    //   modifiedV["stateV"] = true;
    // }

    // this.setState({ validation: modifiedV });
  }

  //for change tooltip display propery
  onChangeValidation(fieldName, value) {
    const modifiedV = { ...this.state.validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    this.setState({ validation: modifiedV });
  }

  render() {
    //meta title
    document.title = "Edit Nominee";
    const { validation } = this.state;
    const { selectedCustomerTitle } = this.state;
    const { selectedGroupCountry } = this.state;
    const { selectedGroupCity } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Nominees" breadcrumbItem="Edit Nominee" parentLink="/customer/nominees/list" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Formik>
                      {({ errors, status, touched }) => (
                        <Form className="needs-validation" onSubmit={this.handleSubmit}>
                          <Row>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="customerTitle">Select title*</Label>
                                <div className="col-md-10">
                                  <Select
                                    onChange={this.handleSelectedCustomerTitle}
                                    options={this.state.titlesArray}
                                    // value={this.state.c_title}
                                    value={{ label: this.state.c_title, value: 0 }}
                                    classNamePrefix="select2-selection"
                                  // className={
                                  //   "" +
                                  //   (errors.customerTitle && touched.customerTitle
                                  //     ? " is-invalid"
                                  //     : "")
                                  // }
                                  />
                                  { /*<ErrorMessage
                                    name="customerTitle"
                                    component="div"
                                    className="invalid-feedback"
                                  />*/ }
                                </div>
                              </div>
                            </Col>

                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="nomineeFirstName">
                                  First Name*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="nomineeFirstName"
                                    placeholder="First Name"
                                    type="text"
                                    // value={this.state.customerData.first_name}
                                    value={this.state.c_first_name}
                                    // onChange={this.handleChange}
                                    onChange={(e) => this.setState({ c_first_name: e.target.value })}
                                    className={
                                      "form-control" +
                                      (errors.nomineeFirstName && touched.nomineeFirstName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="nomineeFirstName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="customerMiddleName">
                                  Middle Name
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="customerMiddleName"
                                    placeholder="Middle Name"
                                    type="text"
                                    value={this.state.c_middle_name}
                                    // onChange={this.handleChange}
                                    onChange={(e) => this.setState({ c_middle_name: e.target.value })}
                                    className={
                                      "form-control"
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="nomineeLastName">
                                  Last Name*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="nomineeLastName"
                                    placeholder="Last Name"
                                    type="text"
                                    onChange={(e) => this.setState({ c_last_name: e.target.value })}
                                    value={this.state.c_last_name}
                                    className={
                                      "form-control" +
                                      (errors.nomineeLastName && touched.nomineeLastName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="nomineeLastName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            {/* <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="identityType">
                                  Identity Type
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="identityType"
                                    placeholder="Identity Type"
                                    type="text"
                                    onChange={(e) => this.setState({ c_identity_type: e.target.value })}
                                    value={this.state.c_identity_type}
                                    className={
                                      "form-control"
                                    }
                                  />

                                </div>
                              </FormGroup>
                            </Col> */}
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectIdentity">Identity Type</Label>
                                <div className="col-md-10">
                                  <Select
                                    value={{ label: this.state.c_identity_type, value: 0 }}
                                    // value={selectedGroupCountry}
                                    onChange={this.handleSelectGroupIdentity}
                                    options={this.state.optionGroupIdentity}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="identityNumber">
                                  Identity Number
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="identityNumber"
                                    placeholder="Identity Number"
                                    type="text"
                                    onChange={(e) => this.setState({ c_identity_number: e.target.value })}
                                    value={this.state.c_identity_number}
                                    className={
                                      "form-control"
                                    }
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="nomineeNationality">Nationality</Label>
                                <div className="col-md-10">
                                  <Select
                                    value={{ label: this.state.c_nationality, value: 0 }}
                                    // value={selectedGroupCountry}
                                    onChange={this.handleSelectGroupNationality}
                                    options={this.state.optionGroupNationalities}
                                    classNamePrefix="select2-selection"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="addresslineOne">
                                  Address Line 1*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="addresslineOne"
                                    placeholder="Address Line 1"
                                    type="text"
                                    onChange={(e) => this.setState({ c_address_line_1: e.target.value })}
                                    value={this.state.c_address_line_1}
                                    className={
                                      "form-control" +
                                      (errors.addresslineOne && touched.addresslineOne
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="addresslineOne"
                                    component="div"
                                    className="invalid-feedback"
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="addresslineTwo">
                                  Address Line 2
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="addresslineTwo"
                                    placeholder="Address Line 2"
                                    type="text"
                                    onChange={(e) => this.setState({ c_address_line_2: e.target.value })}
                                    value={this.state.c_address_line_2}
                                    className={
                                      "form-control"
                                    }
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectCountry">Country Select*</Label>
                                <div className="col-md-10">
                                  <Select
                                    value={{ label: this.state.c_country_title, value: 0 }}
                                    // value={selectedGroupCountry}
                                    onChange={this.handleSelectGroupCountry}
                                    options={this.state.optionGroupCountry}
                                    classNamePrefix="select2-selection"
                                  // className={
                                  //   "" +
                                  //   (errors.selectCountry && touched.selectCountry
                                  //     ? " is-invalid"
                                  //     : "")
                                  // }
                                  />
                                  {/* <ErrorMessage
                                    name="selectCountry"
                                    component="div"
                                    className="invalid-feedback"
                                  /> */}
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="selectCity">City Select*</Label>
                                <div className="col-md-10">
                                  <Field
                                    name="city_name"
                                    placeholder="Enter City Name"
                                    type="text"
                                    onChange={(e) => this.setState({ c_city_title: e.target.value })}
                                    value={this.state.c_city_title}
                                    className={
                                      "form-control" +
                                      (errors.city_name && touched.city_name
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="city_name"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                  {/* 
                                  <Select
                                    value={{ label: this.state.c_city_title, value: 0 }}
                                    onChange={this.handleSelectGroupCity}
                                    options={this.state.optionGroupCity}
                                    classNamePrefix="select2-selection"
                                  // className={
                                  //   "" +
                                  //   (errors.selectCity && touched.selectCity
                                  //     ? " is-invalid"
                                  //     : "")
                                  // }
                                  />
                                  <ErrorMessage
                                    name="selectCity"
                                    component="div"
                                    className="invalid-feedback"
                                  /> */}
                                </div>
                              </div>
                            </Col>

                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="postcode">Postcode*</Label>
                                <div className="col-md-10">
                                  <Field
                                    name="postcode"
                                    placeholder="Postcode"
                                    type="text"
                                    onChange={(e) => this.setState({ c_postcode: e.target.value })}
                                    value={this.state.c_postcode}
                                    className={
                                      "form-control" +
                                      (errors.postcode && touched.postcode
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="postcode"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="phoneHome">
                                  Phone Home
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="phoneHome"
                                    placeholder="Phone Home"
                                    type="text"
                                    onChange={(e) => this.setState({ c_telephone_home: e.target.value })}
                                    value={this.state.c_telephone_home}
                                    className={
                                      "form-control"
                                    }
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="phoneOffice">
                                  Phone Office
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="phoneOffice"
                                    placeholder="Phone Office"
                                    type="text"
                                    onChange={(e) => this.setState({ c_telephone_office: e.target.value })}
                                    value={this.state.c_telephone_office}
                                    className={
                                      "form-control"
                                    }
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="mobileNumber">
                                  Mobile Number*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="mobileNumber"
                                    placeholder="Mobile Number"
                                    type="text"
                                    onChange={(e) => this.setState({ c_mobile_number: e.target.value })}
                                    value={this.state.c_mobile_number}
                                    className={
                                      "form-control" +
                                      (errors.mobileNumber && touched.mobileNumber
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="mobileNumber"
                                    component="div"
                                    className="invalid-feedback"
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="primaryEmail">
                                  Primary Email*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="primaryEmail"
                                    placeholder="Primary Email"
                                    type="email"
                                    onChange={(e) => this.setState({ c_primary_email: e.target.value })}
                                    value={this.state.c_primary_email}
                                    className={
                                      "form-control" +
                                      (errors.primaryEmail && touched.primaryEmail
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="primaryEmail"
                                    component="div"
                                    className="invalid-feedback"
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="secondaryEmail">
                                  Secondary Email
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="secondaryEmail"
                                    placeholder="Secondary Email"
                                    type="email"
                                    onChange={(e) => this.setState({ c_secondary_email: e.target.value })}
                                    value={this.state.c_secondary_email}
                                    className={
                                      "form-control"
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <form>
                                <div className="d-flex align-items-center mb-3 mt-3">
                                  <h5 className="mb-0 card-title flex-grow-1">Identity Document</h5>
                                </div>
                                <Card>
                                  <CardBody className="border card border-primary">
                                    <div className="mb-3 row d-flex select2-container">
                                      <label className="mt-3 col-md-2 col-form-label">
                                        Category:
                                      </label>
                                      <div className="col-md-10">
                                        <select className="mt-3 form-select" name="document_sub_type_name" value={this.state.formData.document_sub_type_name} onChange={this.handleChange}>
                                          <option value="">Select...</option>
                                          {this.state.optionsArray?.map(option => (
                                            <option value={option} key={option}>{option}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    <br />
                                    <div className="mb-3 row d-flex">
                                      <label className="col-md-2 col-form-label">
                                        Name:
                                      </label>
                                      <div className="col-md-10">
                                        <input className="form-control" type="text" name="document_name" value={this.state.formData.document_name} onChange={this.handleChange} />
                                      </div>
                                    </div>
                                    <br />
                                    <Col md="12">
                                      <div className="mb-3 row d-flex">
                                        <Label className="col-md-2 col-form-label" htmlFor="file">
                                          File
                                        </Label>
                                        <div className="col-md-10">
                                          <Input className="form-control form-control" name='file' type="file" id="document_file" accept="image/*"
                                            onChange={this.handleDocumentFile} />
                                        </div>
                                      </div>
                                    </Col>
                                  </CardBody>
                                  <div className='col-md-12 float-end'>
                                    <button color="primary" className="btn btn-primary ms-1 mt-2 mb-2" onClick={this.handleSubmitDocumnet}>Add Document</button>
                                  </div>
                                </Card>
                              </form>
                              {console.log(this.state.tableData)}
                              {this.state.tableData?.map((data, index) => (
                                <div key={index}>
                                  <Card>
                                    <CardBody className="card">
                                      <Row>
                                        <Col md="6">
                                          <div className="mb-3 row d-flex select2-container">
                                            <label className="mt-3 col-md-4 col-form-label">
                                              Category:
                                            </label>
                                            <div className="mt-3 col-md-8">
                                              {/* <select value={selectedOptions[index]} onChange={(event) => handleOptionChange(index, event)}> */}
                                              <select className="form-select test-000" name={"category-" + index} id={"category-" + index} value={data.document_sub_type_name} onChange={(event) => this.handleChangeCategory(index, event)}>
                                                <option value="">Select...</option>
                                                {this.state.optionsArray?.map(option => (
                                                  <option value={option} key={option} selected>{option}</option>
                                                ))}
                                              </select>
                                            </div>
                                          </div>
                                          <div className="mb-3 row d-flex">
                                            <label className="col-md-4 col-form-label">
                                              Name:
                                            </label>
                                            <div className="col-md-8">
                                              <input
                                                name='occurance[]'
                                                id={'occurance-' + index}
                                                placeholder="0.00"
                                                defaultValue={data.document_name}
                                                onChange={(event) => this.handleChangeFileName(index, event)}
                                                type="text"
                                                className={"form-control"}
                                              />
                                            </div>
                                          </div>
                                        </Col>
                                        <Col md="6" className="text-center mt-3">
                                          <i className="fa fa-file-image" style={{ fontSize: '6rem' }} aria-hidden="true"></i>
                                        </Col>
                                        <Col md="12">
                                          <div className="row d-flex">
                                            <div><button color="danger" className="btn btn-danger ms-1 mt-2 mb-2" onClick={(e) => this.handleDelete(e, index)}>Delete</button></div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </div>
                              ))}
                            </Col>
                          </Row>
                          <Button color="primary" type="submit" className="float-end" >
                            Update Nominee
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default EditNominee;
