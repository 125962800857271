import React, { Component } from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import propertyAppzLogoBG from "../../../assets/images/property-appz-bg-1.png";
import { Col } from "reactstrap"

export default class CarouselPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Col xl={9}>
          <div className="auth-full-bg pt-lg-5 p-4">
            <div className="w-100">
              <div className="bg-overlay"></div>
              <div className="d-flex h-100 flex-column">
                <div className="p-4 mt-auto">
                  <div className="row justify-content-center">
                    <div className="col-lg-7">
                      <div className="text-center">
                        <h4 className="mb-3">
                          <i className="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i>
                          Property Appz made it simple
                        </h4>
                        <div dir="ltr">
                          <div className="owl-carousel owl-theme auth-review-carousel slider_css" id="auth-review-carousel"
                            showThumbs={false}>
                            <div>
                              <div className="item">
                                <div className="pb-5 pt-3">
                                  <p className="font-size-16 mb-4">
                                    Elevate property sales with Property Appz! A cloud-based software empowering developers, agents, and investment firms to master every aspect of property sales. Offering unlimited projects, properties, customer management, sales agent management, sales management, payments management, document management, communication management, and more. Simplify your business processes today!
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </React.Fragment>
    )
  }
}
