import React, { Component } from "react";
import { Row, Table, Col, Card, CardBody, CardTitle, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { isEmpty, size } from "lodash";
import { showToast } from '../../UI/ui-toast'
import RestoreModal from "../../../components/Common/RestoreModal";
import { get_user_permissions } from "../../../helpers/custom_helper"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";

import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { get_all_projects, get_project_log, delete_project, update_project } from '../api/projectApi';
var projectEdit, projectView, projectCreate, projectDelete;
class ProjectLog extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            page: 1,
            sizePerPage: 50,
            listData: [],
            listdata: "",
            log_data: [],
            viewmodal: false,
            modal: false,
            isOpen: false,
            selectDate: false,
            deleteModal: false,
            restoreModal: false,
            recordDeleteID: null,
            redirect: null
        };
        this.handleRecordClick = this.handleRecordClick.bind(this);
        this.handleRecordPaymentPlan = this.handleRecordPaymentPlan.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleRecordClicks = this.handleRecordClicks.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.onClickView = this.onClickView.bind(this);
        this.onClickActivityLog = this.onClickActivityLog.bind(this);
    }
    componentDidMount() {
        projectEdit = get_user_permissions('projects-edit');
        projectView = get_user_permissions('projects-view');
        projectCreate = get_user_permissions('projects-create');
        projectDelete = get_user_permissions('projects-delete');
        Promise.all([get_project_log(localStorage.getItem("current_project_id"))]).then((values) => {
            console.log(values[0]);
            this.setState({ listData: values[0] })
            this.setState({ log_data: values[0] })
        });

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { listData } = this.props;
        if (!isEmpty(listData) && size(prevProps.listData) !== size(listData)) {
            this.setState({ listData: {}, isEdit: false });
        }
    }
    onPaginationPageChange = page => {
        if (
            this.node &&
            this.node.current &&
            this.node.current.props &&
            this.node.current.props.pagination &&
            this.node.current.props.pagination.options
        ) {
            this.node.current.props.pagination.options.onPageChange(page);
        }
    };
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };
    onClickDelete = listdata => {
        this.setState({ listdata: listdata });
        this.setState({ deleteModal: true });
    };

    handleDeletejob = () => {
        // const { onDeleteJobList } = this.props;
        const { listdata } = this.state;
        if (listdata.id !== undefined) {
            console.log(listdata);
            console.log(this.state.recordDeleteID);
            // onDeleteJobList(listdata);
            Promise.all([delete_project(this.state.recordDeleteID)]).then((values) => {
                showToast("Success", "The project " + listdata.name + " has been deleted successfully.", "success");
                Promise.all([get_all_projects()]).then((values) => {
                    console.log(values[0]);
                    this.setState({ listData: values[0] });
                });
            }).catch((error) => {
                showToast("Failed", "Project Not Deleted Successfully", "error");
            });
            this.setState({ deleteModal: false });
        }
    };
    onClickView = arg => {
        const listdata = arg;
        console.log(listdata.projectStatus);
        if (listdata.projectStatus == 'Deleted') {
            showToast("Failed", "This project has been deleted. Please restore it first to be able to view it.", "error");
        } else {
            window.localStorage.setItem("current_project_id", listdata.project_id);
            this.setState({ redirect: "/project/view" });
        }
    }
    onClickActivityLog = arg => {
        const listdata = arg;
        window.localStorage.setItem("current_project_id", listdata.project_id);
        this.setState({ redirect: "/project/log" });
    }
    handleRecordClicks = () => {
        this.setState({ listdata: "", isEdit: false });
        this.toggle();
    };
    // handleRecordClick = arg => {
    //     const listdata = arg;

    //     this.setState({
    //         listdata: {
    //             id: listdata.id,
    //             name: listdata.name,
    //         },
    //         isEdit: true,
    //     });

    //     this.toggle();
    // };
    handleRecordClick = arg => {
        const listdata = arg;
        window.localStorage.setItem("current_project_id", listdata.project_id);
        this.setState({ redirect: "/project/edit" });
    };
    handleRecordPaymentPlan = arg => {
        const listdata = arg;
        window.localStorage.setItem("project_id_against_plan", listdata.project_id);
        this.setState({ redirect: "/payment-plan/list" });
    };
    render() {

        const { isEdit, deleteModal } = this.state;

        const listdata = this.state.listdata;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        const { log_data } = this.state;
        const columns = [
            {
                dataField: "id",
                text: "#",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {Object.entries(listdata.properties.new).length > 0 || Object.entries(listdata.properties.old).length > 0 ? (
                            <>
                                {listdata.logsDetails.id}
                            </>
                        ) : null}
                    </>

                ),
            },
            {
                dataField: "full_name",
                text: "Description",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {Object.entries(listdata.properties.new).length > 0 || Object.entries(listdata.properties.old).length > 0 ? (
                            <>
                                {listdata.logsDetails.description.split("-").pop() == 'updated' ? <>
                                    <strong>{listdata.full_name}</strong> made the following changes in <br /><strong>{listdata.logsDetails.subject_type.split("\\").pop()}</strong> of&nbsp;
                                    <Link
                                        to="#"
                                        onClick={() => this.onClickView(listdata)}
                                    >
                                        <strong>{listdata.project_name}</strong>&nbsp;</Link>

                                    at {moment(listdata.logsDetails.created_at).format('DD/MM/YYYY h:mma')}
                                </> : <></>}

                                {listdata.logsDetails.description.split("-").pop() == 'created' ? <>
                                    <strong>{listdata.full_name}</strong> added the following information in <br /><strong>{listdata.logsDetails.subject_type.split("\\").pop()}</strong> of&nbsp;
                                    <Link
                                        to="#"
                                        onClick={() => this.onClickView(listdata)}
                                    >
                                        <strong>{listdata.project_name}</strong>&nbsp;</Link>

                                    at {moment(listdata.logsDetails.created_at).format('DD/MM/YYYY h:mma')}
                                </> : <></>}

                                {listdata.logsDetails.description.split("-").pop() == 'deleted' ? <>
                                    <strong>{listdata.full_name}</strong> deleted the following information in <br /><strong>{listdata.logsDetails.subject_type.split("\\").pop()}</strong> of&nbsp;
                                    <Link
                                        to="#"
                                        onClick={() => this.onClickView(listdata)}
                                    >
                                        <strong>{listdata.project_name}</strong>&nbsp;</Link>

                                    at {moment(listdata.logsDetails.created_at).format('DD/MM/YYYY h:mma')}
                                </> : <></>}
                                {listdata.logsDetails.description.split("-").pop() == 'restored' ? <>
                                    <strong>{listdata.full_name}</strong> restored the following information in <br /><strong>{listdata.logsDetails.subject_type.split("\\").pop()}</strong> of&nbsp;
                                    <Link
                                        to="#"
                                        onClick={() => this.onClickView(listdata)}
                                    >
                                        <strong>{listdata.project_name}</strong>&nbsp;</Link>

                                    at {moment(listdata.logsDetails.created_at).format('DD/MM/YYYY h:mma')}
                                </> : <></>}
                            </>
                        ) : null}
                    </>

                ),
            },
            {
                dataField: "changes",
                text: "Changes",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        <tr>
                            <td colSpan={3}>
                                <div className="table-responsive">
                                    <Table className="table table-bordered table-striped">
                                        {Object.entries(listdata.properties.new).length > 0 || Object.entries(listdata.properties.old).length > 0 ? (
                                            <thead className="border border-1 border-white bg-dark text-white">
                                                <tr>
                                                    <th scope="col">Column</th>
                                                    <th scope="col">Old</th>
                                                    <th scope="col">New</th>
                                                </tr>
                                            </thead>
                                        ) : null}
                                        <tbody>
                                            {Object.entries(listdata.properties.new).map(([key, value], index) => {
                                                const oldValue = listdata.properties.old[key];
                                                if (!oldValue && !value) {
                                                    return null;
                                                } else {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{key}</td>
                                                            <td>{oldValue}</td>
                                                            <td>{value}</td>
                                                        </tr>
                                                    );
                                                }
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </td>
                        </tr>
                    </>
                ),
            },

        ];

        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];

        const pageOptions = {
            sizePerPage: 50,
            // totalSize: productsData.length, // replace later with size(customers),
            custom: true,
        };

        // Select All Button operation
        const selectRow = {
            mode: "checkbox",
        };

        const { SearchBar } = Search;

        //meta title
        document.title = "Project Log";
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                console.log(row);
                this.setState({ recordDeleteID: row.id });

            }
        }
        return (
            <React.Fragment>
                <RestoreModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeletejob}
                    record={`project`}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Project" breadcrumbItem="Project Log" />
                        <Row>
                            {log_data.length === 0 ? (
                                <Card>
                                    <CardBody>No project log data available.</CardBody>
                                </Card>
                            ) : (
                                <Col className="col-12">
                                    <Card>
                                        <CardBody>
                                            <PaginationProvider
                                                pagination={paginationFactory(pageOptions)}
                                                keyField="id"
                                                columns={columns}
                                                data={this.state.listData}
                                            >
                                                {({ paginationProps, paginationTableProps }) => (
                                                    <ToolkitProvider
                                                        keyField="id"
                                                        columns={columns}
                                                        data={this.state.listData}
                                                        search
                                                    >
                                                        {toolkitProps => (
                                                            <React.Fragment>
                                                                <Row className="mb-2">
                                                                    <Col md="4">
                                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                                            <div className="position-relative">
                                                                                <SearchBar
                                                                                    {...toolkitProps.searchProps}
                                                                                />
                                                                                <i className="bx bx-search-alt search-icon" />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col xl="12">
                                                                        <div className="table-responsive">
                                                                            <BootstrapTable
                                                                                keyField={"id"}
                                                                                responsive
                                                                                bordered={false}
                                                                                striped={false}
                                                                                defaultSorted={defaultSorted}
                                                                                // selectRow={selectRow}
                                                                                rowEvents={rowEvents}
                                                                                classes={
                                                                                    "table align-middle table-nowrap"
                                                                                }
                                                                                headerWrapperClasses={"thead-light"}
                                                                                {...toolkitProps.baseProps}
                                                                                {...paginationTableProps}
                                                                                ref={this.node}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                <Row className="align-items-md-center mt-30">
                                                                    <Col className="inner-custom-pagination d-flex">
                                                                        <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                                            <SizePerPageDropdownStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                        <div className="text-md-right ms-auto">
                                                                            <PaginationListStandalone
                                                                                {...paginationProps}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </React.Fragment>
                                                        )}
                                                    </ToolkitProvider>
                                                )}
                                            </PaginationProvider>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default ProjectLog;
