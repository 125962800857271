import axios from "axios";
// import { Link, useHistory } from "react-router-dom";  
import { withRouter } from 'react-router-dom'

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
const baseURL = process.env.REACT_APP_API_URL;

export const get_all_due_payments = async () => {
    let response = await axios.get(baseURL + "/api/due-payments-report", config);
    return response.data.data;
}
export const get_all_project_reports = async () => {
    let response = await axios.get(baseURL + "/api/project-report", config);
    return response.data.data;
}
export const sold_properties_report = async (payload) => {
    let response = await axios.post(baseURL + "/api/sold-properties-report", payload, config);
    return response.data.data;
}
export const download_report_pdf = async (payload) => {
    let response = await axios.post(baseURL + "/api/download-report-pdf", payload, config);
    return response.data.data;
}
export const send_all_overdue_payments_emails = async (payload) => {
    let response = await axios.post(baseURL + "/api/all-over-due-payment-email", payload, config);
    return response.data.data;
}
export const send_selected_overdue_payments_emails = async (payload) => {
    let response = await axios.post(baseURL + "/api/over-due-email-against-selected-sales", payload, config);
    return response.data.data;
}
