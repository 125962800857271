import axios from "axios";
// import { Link, useHistory } from "react-router-dom";  
import { withRouter } from 'react-router-dom'   

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
const baseURL = process.env.REACT_APP_API_URL;

export const get_all_projects = async () => {
    let response = await axios.get(baseURL + "/api/project-list", config);
    return response.data.data;
}
export const update_project = async (project_id,name) => {
    let response = await axios.patch(baseURL + "/api/projects/"+project_id,{
        "name": name,
      }, config);
    return response.data.data;
}
export const delete_project = async (project_id) => {
    let response = await axios.delete(baseURL + "/api/projects/"+project_id, config);
    return response.data.data;
}
export const add_sizes = async (data) => {
    let response = await axios.post(baseURL + "/api/sizes/", data,config);
    return response.data.data;
}
export const add_floors = async (data) => {
    let response = await axios.post(baseURL + "/api/floors/", data,config);
    return response.data.data;
}
export const add_phases_blocks = async (data) => {
    console.log(data);
    let response = await axios.post(baseURL + "/api/phases/", data,config);
    return response.data.data;
}
export const get_all_currencies = async () => {
    let response = await axios.get(baseURL + "/api/currency", config);
    return response.data.data;
}
export const post_project_currencies = async () => {
    let response = await axios.post(baseURL + "/api/project_currency", config);
    return response.data.data;
}
export const get_current_project = async (project_id) => {
    let response = await axios.get(baseURL + "/api/projects/"+project_id, config);
    return response.data.data;
}
export const get_project_sizes = async (project_id) => {
    let response = await axios.get(baseURL + "/api/project_sizes/"+project_id, config);
    return response.data.data;
}
export const get_project_phases_blocks = async (project_id) => {
    let response = await axios.get(baseURL + "/api/project-phases/"+project_id, config);
    return response.data.data;
}
export const update_project_sizes = async (project_id, data) => {
    let response = await axios.patch(baseURL + "/api/sizes/"+project_id, data, config);
    return response.data.data;
}
export const get_blocks_against_phases = async (phase_id) => {
    let response = await axios.get(baseURL + "/api/get-block-against-phases/"+phase_id, config);
    return response.data.data;
}
export const get_project_floors = async (project_id) => {
    let response = await axios.get(baseURL + "/api/project-floors/"+project_id, config);
    return response.data.data;
}
export const update_project_phases_blocks = async (project_id,data) => {
    let response = await axios.patch(baseURL + "/api/project-phases/"+project_id, data, config);
    return response.data.data;
}
export const update_project_floors = async (project_id,data) => {
    let response = await axios.patch(baseURL + "/api/floors/"+project_id, data, config);
    return response.data.data;
}
export const get_current_currencies = async (project_id) => {
    let response = await axios.get(baseURL + "/api/project-currencies/"+project_id, config);
    return response.data.data;
}
export const update_project_currencies = async (project_id,data) => {
    let response = await axios.patch(baseURL + "/api/project_currency/"+project_id, data, config);
    return response.data;
}
export const get_all_project_payment_plans = async (project_id) => {
    let response = await axios.get(baseURL + "/api/project-payment-plan/"+project_id, config);
    return response.data.data;
}
export const add_project_feature = async (data, project_id) => {
    let response = await axios.post(baseURL + "/api/project-feature",
    {
        "project_id": project_id,
        "features": data
    }
    , config);
    return response.data.data;
}
export const get_features_against_project = async (project_id) => {
    let response = await axios.get(baseURL + "/api/feature-against-project/"+project_id, config);
    return response.data.data;
}
export const update_project_feature = async (data, project_id) => {
    let response = await axios.patch(baseURL + "/api/project-feature/"+project_id,
    {
        "project_id": project_id,
        "features": data
    }
    , config);
    return response.data.data;
}
export const get_project_detail = async (project_id) => {
    let response = await axios.get(baseURL + "/api/project-detail/"+project_id, config);
    return response.data.data;
}
export const get_project_log = async (project_id) => {
    let response = await axios.get(baseURL + "/api/project-activity-log/"+project_id, config);
    return response.data.data;
}
export const get_all_deleted_projects = async () => {
    let response = await axios.get(baseURL + "/api/deleted-project-list", config);
    return response.data.data;
}
export const get_all_projects_logs = async () => {
    let response = await axios.get(baseURL + "/api/all-project-activity-log", config);
    return response.data.data;
}
export const restore_project = async (project_id) => {
    let response = await axios.post(baseURL + "/api/restore-the-project/"+project_id, {}, config);
    return response.data.data;
}