import { takeEvery, put, call } from 'redux-saga/effects';
import { FETCH_LOGO_SUCCESS, FETCH_LOGO_FAILURE, FETCH_LOGO } from './actions';
import { get_current_organisation, get_current_organisation_without_auth } from '../../pages/Organisation_OrganisationSetup/api/organisationApi'; // replace with your API function

function* fetchLogoSaga() {
  try {
    const logoUrl = yield call(get_current_organisation_without_auth);
    yield put({ type: FETCH_LOGO_SUCCESS, payload: logoUrl });
    const binaryData = logoUrl.icon; // Binary data of the image

    const head = document.querySelector('head');
    const link = document.createElement('link');
    link.type = 'image/png';
    link.sizes = '16x16'
    link.rel = 'icon';
    link.href = binaryData;

    // Remove any existing favicons
    const existingFavicons = document.querySelectorAll('link[rel="icon"]');
    existingFavicons.forEach((favicon) => head.removeChild(favicon));
    const existingFaviconsResponsive = document.querySelectorAll('link[rel="apple-touch-icon"]');
    existingFaviconsResponsive.forEach((favicon) => head.removeChild(favicon));

    // Append the new favicon to the head
    head.appendChild(link);
  } catch (error) {
    yield put({ type: FETCH_LOGO_FAILURE, payload: error.message });
  }
}

function* logoSaga() {
  yield takeEvery(FETCH_LOGO, fetchLogoSaga);
}

export default logoSaga;
