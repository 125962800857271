import axios from "axios";

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../../constants.json';
const baseURL = process.env.REACT_APP_API_URL;

export const get_all_property_types = async () => {
    let response = await axios.get(baseURL + "/api/property-type", config);
    return response.data.data;
}
export const get_all_property_types_for_organisation = async () => {
    let response = await axios.get(baseURL + "/api/property-type-listing", config);
    return response.data.data;
}
export const update_property_types = async (property_type_id,name) => {
    let response = await axios.patch(baseURL + "/api/property-type/"+property_type_id,{
        "type_name": name,
      }, config);
    return response.data.data;
}
export const delete_property_type = async (property_type_id) => {
    let response = await axios.delete(baseURL + "/api/property-type/"+property_type_id, config);
    return response.data.data;
}