import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import axios from "axios";
import { showToast } from '../../UI/ui-toast'

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { get_current_salesagents } from '../api/salesAgentsApi';

import constant from '../../../constants.json';

const token = window.localStorage.getItem("token");
const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Accept': 'application/json',
    Authorization: `${token}`
  }
};
const baseURL = process.env.REACT_APP_API_URL;

class EditAgent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      optionGroupCountry: [],
      optionGroupCity: [],
      redirect: null,
      agent_title: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      telephone: '',
      mobile: '',
      email: '',
      picture: null,
      show_picture: null,

      titlesArray: constant.titles_list,
      id: "",
      name: '',
      current_salesagent_id: '',
      pictureSelected: -1,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeValidation = this.onChangeValidation.bind(this);
  }

  handleChange(event) {
  }

  handleSelectedAgentTitle = selectedAgentTitle => {
    this.setState({ agent_title: selectedAgentTitle.value });
    this.setState({ selectedAgentTitle })
  }
  // checkValidation = () => {
  //   if (this.state.pictureSelected != 1) {
  //     this.setState({ pictureSelected: 0 })
  //   }
  //   if(this.state.show_picture!=null){
  //     this.setState({ pictureSelected: 1 })
  //   }
  // }
  handleImageChangePicture = (e) => {
    this.setState({ pictureSelected: 1 })
    if (e.target.files && e.target.files[0]) {
      this.setState({ picture: e.target.files[0] });
    }
  };
  componentDidMount() {
    //const current_salesagent = window.localStorage.getItem("current_salesagent");
    Promise.all([get_current_salesagents(localStorage.getItem("current_salesagent"))]).then((values) => {
      this.setState({ agent_title: values[0].title });
      this.setState({ first_name: values[0].first_name });
      this.setState({ middle_name: values[0].middle_name });
      this.setState({ last_name: values[0].last_name });
      this.setState({ telephone: values[0].telephone });
      this.setState({ mobile: values[0].mobile });
      this.setState({ email: values[0].email });
      this.setState({ show_picture: values[0].picture });
      this.setState({ current_salesagent_id: localStorage.getItem("current_salesagent") });
      if(this.state.show_picture!=null){
        this.setState({pictureSelected:1})
      }
    });

  }

  handleSubmit(e) {
    e.preventDefault();

    /*const modifiedV = { ...this.state.validation };
    var fnm = document.getElementById("validationTooltip01").value;
    var lnm = document.getElementById("validationTooltip02").value;
    var unm = document.getElementById("validationTooltipUsername").value;
    var stateV = document.getElementById("validationTooltip04").value;

    if (fnm === "") {
      modifiedV["fnm"] = false;
    } else {
      modifiedV["fnm"] = true;
    }

    if (lnm === "") {
      modifiedV["lnm"] = false;
    } else {
      modifiedV["lnm"] = true;
    }

    if (unm === "") {
      modifiedV["unm"] = false;
    } else {
      modifiedV["unm"] = true;
    }

    if (stateV === "") {
      modifiedV["stateV"] = false;
    } else {
      modifiedV["stateV"] = true;
    }

    this.setState({ validation: modifiedV });*/
      if (!this.state.first_name || !this.state.last_name || !this.state.mobile || !this.state.email) {
        let missingFields = '';
        if (!this.state.first_name) {
          missingFields += '<li>First Name</li>';
        }
        if (!this.state.last_name) {
          missingFields += '<li>Last Name</li>';
        }
        if (!this.state.mobile) {
          missingFields += '<li>Mobile Number</li>';
        }
        if (!this.state.email) {
          missingFields += '<li>Primary Email</li>';
        }
        showToast("Failed", "These fields are missing. Please fill the required fields: " + missingFields, "error");
        return false;
      }
      axios.post(baseURL + "/api/sales-agent-update/" + this.state.current_salesagent_id, {

        "title": this.state.agent_title,
        "first_name": e.target.agentFirstName.value,
        "middle_name": e.target.agentMidName.value,
        "last_name": e.target.agentLastName.value,
        "telephone": e.target.telephone.value,
        "mobile": e.target.mobileNumber.value,
        "email": e.target.email.value,
        "organisation_id": window.localStorage.getItem("organisation_id"),
        "picture": this.state.picture
      }, config)
        .then((response) => {
          showToast("Success", "The sales agent "+ response.data.data.full_name + " has been updated successfully.", "success");
          this.setState({ redirect: '/salesagents/list' });
          localStorage.removeItem("current_salesagent");
        })
        .catch((error) => {
          showToast("Failed", "Sales Agent Not Edited", "error");
        });
  }

  //for change tooltip display propery
  onChangeValidation(fieldName, value) {
    const modifiedV = { ...this.state.validation };
    if (value !== "") {
      modifiedV[fieldName] = true;
    } else {
      modifiedV[fieldName] = false;
    }
    this.setState({ validation: modifiedV });
  }

  render() {
    //meta title
    document.title = "Edit Sales Agent";
    const { validation } = this.state;
    const { selectedAgentTitle } = this.state;
    const { selectedGroupCountry } = this.state;
    const { selectedGroupCity } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs title="Sales Agent" breadcrumbItem="Edit Sales Agent" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <Formik>
                      {({ errors, status, touched }) => (
                        <Form className="needs-validation" onSubmit={this.handleSubmit}>
                          <Row>
                            <Col md="12">
                              <div className="mb-3 row d-flex select2-container">
                                <Label className="col-md-2 col-form-label" htmlFor="agentTitle">Title*</Label>
                                <div className="col-md-10">
                                  <Select
                                    value={{ label: this.state.agent_title, value: 0 }}
                                    onChange={this.handleSelectedAgentTitle}
                                    options={this.state.titlesArray}
                                    classNamePrefix="select2-selection"
                                  // className={
                                  //   "" +
                                  //   (errors.agentTitle && touched.agentTitle
                                  //     ? " is-invalid"
                                  //     : "")
                                  // }
                                  />
                                  { /*<ErrorMessage
                                    name="agentTitle"
                                    component="div"
                                    className="invalid-feedback"
                                  />*/ }
                                </div>
                              </div>
                            </Col>

                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="agentFirstName">
                                  First Name*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="agentFirstName"
                                    placeholder="First Name"
                                    type="text"
                                    value={this.state.first_name}
                                    onChange={(e) => this.setState({ first_name: e.target.value })}
                                    className={
                                      "form-control" +
                                      (errors.agentFirstName && touched.agentFirstName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="agentFirstName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>

                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="">
                                  Middle Name
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="agentMidName"
                                    placeholder="Middle Name"
                                    type="text"
                                    value={this.state.middle_name}
                                    onChange={(e) => this.setState({ middle_name: e.target.value })}
                                    className={
                                      "form-control"
                                    }
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="agentLastName">
                                  Last Name*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="agentLastName"
                                    placeholder="Last Name"
                                    type="text"
                                    value={this.state.last_name}
                                    onChange={(e) => this.setState({ last_name: e.target.value })}
                                    className={
                                      "form-control" +
                                      (errors.agentLastName && touched.agentLastName
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="agentLastName"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>

                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="telephone">
                                  Telephone
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="telephone"
                                    placeholder="Telephone"
                                    type="text"
                                    value={this.state.telephone}
                                    onChange={(e) => this.setState({ telephone: e.target.value })}
                                    className={
                                      "form-control"
                                    }
                                  />

                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="mobileNumber">
                                  Mobile Number*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="mobileNumber"
                                    placeholder="Mobile Number"
                                    type="text"
                                    value={this.state.mobile}
                                    onChange={(e) => this.setState({ mobile: e.target.value })}
                                    className={
                                      "form-control" +
                                      (errors.mobileNumber && touched.mobileNumber
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="mobileNumber"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="email">
                                  Email*
                                </Label>
                                <div className="col-md-10">
                                  <Field
                                    name="email"
                                    placeholder="Email"
                                    type="email"
                                    value={this.state.email}
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                    className={
                                      "form-control" +
                                      (errors.email && touched.email
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name="email"
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                              </FormGroup>
                            </Col>

                            <Col md="12">
                              <div className="mb-3 row d-flex">
                                <Label className="col-md-2 col-form-label" htmlFor="picture">
                                  Picture
                                </Label>
                                <div className="col-md-10">
                                  <Input className="form-control form-control" type="file" id="inputPicture" accept="image/*"
                                    onChange={this.handleImageChangePicture} />
                                  {/* {this.state.pictureSelected == 0 ? <>
                                    <p style={{ 'fontSize': '0.7rem' }} className="text-danger"> Picture is not selected, please select first</p>
                                  </> : <></>
                                  } */}
                                </div>
                              </div>
                            </Col>
                            {this.state.show_picture == null ?

                              <Col md="12">
                                <div className="mb-3 row d-flex">
                                  <Label className="col-md-2 col-form-label" htmlFor="picture">
                                    Existing Picture
                                  </Label>
                                  <div className="col-md-10 mt-2">
                                    No Picture Exists
                                  </div>
                                </div>
                              </Col>
                              :
                              <Col md="12">
                                <div className="mb-3 row d-flex">
                                  <Label className="col-md-2 col-form-label" htmlFor="picture">
                                    Existing Picture
                                  </Label>
                                  <div className="col-md-10">
                                    <img className="rounded me-2" alt="200x200" width="200" src={this.state.show_picture} data-holder-rendered="true" />
                                  </div>
                                </div>
                              </Col>
                            }
                          </Row>
                          <Button color="primary" type="submit" className="float-end">
                            Update Sales Agent
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default EditAgent;
