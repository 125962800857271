import React, { Component } from 'react';
import { get_project_sizes, get_all_projects, get_current_currencies } from "../../Projects/api/projectApi"
import { get_all_payment_types_for_organisation } from "../../Catalogue/PaymentTypes/api/paymentTypesApi"
import { post_payment_plan } from '../api/paymentPlanApi';
import { Redirect, Link } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row, UncontrolledAlert, UncontrolledTooltip
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Select from "react-select"
import axios from "axios";
import { showToast } from '../../UI/ui-toast'
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
import { numberWithCommas } from 'pages/UI/numeric-helper';
const baseURL = process.env.REACT_APP_API_URL;
class AddPaymentPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: { PaymentType: '', no_of_recurrences: '', order: '' },
            tableData: [],
            optionsArray: [],
            optionGroupProjects: [],
            optionGroupSizes: [],
            currencies: [],
            tableHead: [],
            project_size_id: '',
            project_id: '',
            payment_plan_name: '',
            has_payment_types: 0,
            has_sizes: 0,
            payment_plan_total: 0,
            redirect: null,
            currenciesCal: [],
            payement_types_listing: [],
            templateCloneMessage: null,
        };
        // this.dropdownOptions = ['Option 1', 'Option 2', 'Option 3'];
    }

    componentDidMount() {
        Promise.all([get_all_projects()]).then((values) => {
            const projectOptions = values[0].map(d => ({
                "value": d.id,
                "label": d.name
            }))
            this.setState({ optionGroupProjects: projectOptions })
        });
        Promise.all([get_all_payment_types_for_organisation()]).then((values) => {
            const payment_types_listing = values[0].map(data => ({
                "payment_types_name": data.payment_types_name,
                "payment_frequency": data.payment_frequency
            }))
            const paymentTypesOptions = values[0].map(d => ([d.payment_types_name]))
            this.setState({ optionsArray: paymentTypesOptions })
            this.setState({ payment_types_listing: payment_types_listing });
        });
    }
    async getProjectDetail(project_id) {
        const res = await axios.get(baseURL + "/api/project-details/" + project_id, config);
        const data = res.data.data;
        this.setState({ templateCloneMessage: data.doc_template_message })
        this.setState({ has_sizes: data.has_sizes });

        if (data.has_sizes) {
            this.setState({ project_id: project_id })
            Promise.all([get_project_sizes(project_id)]).then((values) => {
                this.getOptionsSizes(values[0]);
            });
        }
        const currencies = await axios.get(baseURL + "/api/project-currencies/" + project_id, config);
        const currenciesData = currencies.data.data;
        this.setState({ currencies: currenciesData });
        const tableheading = currenciesData.map(d => ([d.currency_name]))
        this.setState({ tableHead: tableheading })
        this.setState({
            formData: { PaymentType: '', no_of_recurrences: '', order: '', currenciesData }
        });
        // const listItems = currenciesData?.map((d) =>
        //     <li key={d.currency_id}>{d.currency_name}</li>
        // );
    }
    getOptionsSizes(sizes) {
        const options = sizes.map(d => ({
            "value": d.id,
            "label": d.name
        }))
        this.setState({ optionGroupSizes: options })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { formData, tableData, payment_types_listing } = this.state;
        const selectedPaymentType = formData.PaymentType;
        const isOneTimePayment = payment_types_listing.some(
            (paymentType) =>
                paymentType.payment_types_name === selectedPaymentType &&
                paymentType.payment_frequency === "One-time"
        );

        if (isOneTimePayment) {
            if (formData.no_of_recurrences == 1) {
                var nCurrencies = [];
                formData.currenciesData?.map((data, index) => (
                    nCurrencies[index] = {
                        amount: formData[data.currency_name],
                        currency_name: data.currency_name,
                        currency_id: data.currency_id,
                    }
                ));
                var formattedFormData = {
                    payment_type_name: formData.PaymentType,
                    no_of_recurrences: formData.no_of_recurrences,
                    order: formData.order,
                    currencies: nCurrencies,
                };

                var optionsArray = this.state.optionsArray;

                // Find the index of the payment_type_name in optionsArray
                var index = optionsArray.findIndex(option => option[0] === formattedFormData.payment_type_name);

                // Remove the element at the found index
                if (index !== -1) {
                    optionsArray.splice(index, 1);
                }

                this.setState({
                    tableData: [...tableData, formattedFormData],
                    formData: { PaymentType: '', no_of_recurrences: '', order: '', currenciesData: this.state.currencies, id: 0 }
                });
                for (var i = 0; i <= this.state.currencies.length - 1; i++) {
                    var price_input = document.getElementById("price-" + i)
                    price_input.value = ""
                }
            } else {
                showToast("Failed", "The payment type frequencey is one-time by have added 0 occurance. Please add 1 to proceed further.", "error");
                return false;
            }
        } else {
            if (formData.no_of_recurrences <= 0) {
                showToast("Failed", "This Payment Type occurance cannot be zero or less than zero.", "error");
                return false;
            } else {
                var nCurrencies = [];
                formData.currenciesData?.map((data, index) => (
                    nCurrencies[index] = {
                        amount: formData[data.currency_name],
                        currency_name: data.currency_name,
                        currency_id: data.currency_id,
                    }
                ));
                var formattedFormData = {
                    payment_type_name: formData.PaymentType,
                    no_of_recurrences: formData.no_of_recurrences,
                    order: formData.order,
                    currencies: nCurrencies,
                };

                var optionsArray = this.state.optionsArray;

                // Find the index of the payment_type_name in optionsArray
                var index = optionsArray.findIndex(option => option[0] === formattedFormData.payment_type_name);

                // Remove the element at the found index
                if (index !== -1) {
                    optionsArray.splice(index, 1);
                }

                this.setState({
                    tableData: [...tableData, formattedFormData],
                    formData: { PaymentType: '', no_of_recurrences: '', order: '', currenciesData: this.state.currencies, id: 0 }
                });
                for (var i = 0; i <= this.state.currencies.length - 1; i++) {
                    var price_input = document.getElementById("price-" + i)
                    price_input.value = ""
                }
            }
        }
    };

    submitPaymentPlanData = () => {
        var myTableData = this.state.tableData
        var finalData = [];
        myTableData.forEach((rowData, rowIndex) => {
            // newArray.push(rowData)
            delete rowData.currenciesData;
            finalData[rowIndex] = rowData;
            // this.setState({ tableData: newArray })
        })
        // delete myTableData[0].currenciesData
        var formData = {}
        formData.name = this.state.payment_plan_name
        formData.size_id = this.state.project_size_id
        formData.project_id = this.state.project_id
        // formData.payment_types = myTableData
        formData.payment_types = finalData
        Promise.all([post_payment_plan(formData)]).then((values) => {
            showToast("Success", "Payment plan has been added successfully.", "success");
            this.setState({ redirect: "/payment-plan/list" });
        });
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        const { formData } = this.state;
        this.setState({ formData: { ...formData, [name]: value } });
    };
    // This function is an event handler that is triggered when the value of the "no_of_recurrences" input field is changed
    // It takes in the index of the row in which the input field is located and the event object
    // It updates the "no_of_recurrences" value of the corresponding row in the state's "tableData" array with the new value entered by the user
    handleChangeRecurrences = (index, event) => {
        const updatedTableData = [...this.state.tableData]
        const { payment_types_listing } = this.state;
        const isOneTimePayment = payment_types_listing.some(
            (paymentType) =>
                paymentType.payment_types_name === updatedTableData[index].payment_type_name &&
                paymentType.payment_frequency === "One-time"
        );

        if (isOneTimePayment) {
            if (event.target.value == 1) {
                updatedTableData[index].no_of_recurrences = event.target.value;
                this.setState({ tableData: updatedTableData })
            } else {
                showToast("Failed", "The payment type frequencey is one-time by have added 0 occurance. Please add 1 to proceed further.", "error");
                document.getElementById('occurance-' + index).value = '1';
                updatedTableData[index].no_of_recurrences = event.target.value;
                this.setState({ tableData: updatedTableData })
            }
        } else {
            if (event.target.value <= 0) {
                showToast("Failed", "This Payment Type occurance cannot be zero or less than zero.", "error");
                return false;
            } else {
                updatedTableData[index].no_of_recurrences = event.target.value;
                this.setState({ tableData: updatedTableData })
            }
        }
    }
    // This function is an event handler that is triggered when the value of the "order" input field is changed
    // It takes in the index of the row in which the input field is located and the event object
    // It updates the "order" value of the corresponding row in the state's "tableData" array with the new value entered by the user
    handleChangeOrder = (index, event) => {
        const updatedTableData = [...this.state.tableData]
        updatedTableData[index].order = event.target.value;
        this.setState({ tableData: updatedTableData })
    }
    // This function is an event handler that is triggered when the value of a currency input field is changed
    // It takes in the index of the row in which the input field is located, the index of the currency object within the "currencies" array, and the event object
    // It updates the "amount" value of the corresponding currency object in the state's "tableData" array with the new value entered by the user
    handleChangeCurrencies = (index, i, event) => {
        const updatedTableData = [...this.state.tableData]
        updatedTableData[index].currencies[i].amount = event.target.value;
        this.setState({ tableData: updatedTableData })
    }
    // This function is an event handler that is triggered when the value of the "payment_type_name" input field is changed
    // It takes in the index of the row in which the input field is located and the event object
    // It updates the "PaymentType" value of the corresponding row in the state's "tableData" array with the new value entered by the user
    handleChangePaymentType = (index, event) => {
        let optionsArray = this.state.optionsArray
        optionsArray.push([this.state.tableData[index].payment_type_name])

        // Find the index of the payment_type_name in optionsArray
        var ind = optionsArray.findIndex(option => option[0] === event.target.value);

        // Remove the element at the found index
        if (ind !== -1) {
            optionsArray.splice(ind, 1);
        }
        this.setState({ optionsArray: optionsArray })
        const updatedTableData = [...this.state.tableData]
        updatedTableData[index].payment_type_name = event.target.value;
        this.setState({ tableData: updatedTableData })
    }
    handleDelete = (index, data) => {
        var newTableData = this.state.tableData
        let optionsArray = this.state.optionsArray
        optionsArray.push([newTableData[index].payment_type_name])
        this.setState({ optionsArray: optionsArray })
        delete newTableData[index]
        this.setState({ tableData: newTableData })

        var currentTableData = this.state.tableData;
        var newArray = [];
        // var newTableData = this.state.tableData;
        var counter = 0;
        currentTableData.forEach((rowData, c_index) => {
            // newArray.push(rowData)
            newArray[counter] = rowData;
            counter++;
            // this.setState({ tableData: newArray })
        })
        this.setState({ tableData: newArray });
    };
    handleSelectGroupProject = selectedGroupProject => {
        this.setState({ selectedGroupProject })
        this.getProjectDetail(selectedGroupProject.value)
    }
    handleSelectGroupSizes = selectedGroupSizes => {
        this.setState({ selectedGroupSizes })
        this.setState({ project_size_id: selectedGroupSizes.value })
    }
    calculateTotals = () => {
        const currencyTotals = {};
        let tableData = [...this.state.tableData];
        tableData.forEach((data) => {
            data.currencies?.forEach((c) => {
                if (!currencyTotals[c.currency_name]) {
                    currencyTotals[c.currency_name] = 0;
                }
                currencyTotals[c.currency_name] += parseFloat(data.no_of_recurrences) * parseFloat(c.amount);
            });
        });
        Object.entries(currencyTotals).forEach(([currency_name, total]) => {
        });
        return currencyTotals
    }
    render() {
        const currenciesTotals = this.calculateTotals()
        console.log(currenciesTotals);
        document.title = "Add Payment Plan";
        const { formData, tableData } = this.state;
        const { selectedGroupSizes } = this.state;
        const { selectedGroupProject } = this.state;

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Payment Plans" breadcrumbItem="Add Payment Plan" parentLink="/payment-plan/list" />
                    <Row className="">
                        <Col xl="12" className="col-12">
                            <Card>
                                <CardBody>
                                    {this.state.templateCloneMessage ?
                                        <UncontrolledAlert color="danger" role="alert">
                                            {this.state.templateCloneMessage} &nbsp;&nbsp;&nbsp;
                                            <Link
                                                to="/templates/documents-templates/sales-documents-tabs-view"
                                                className="btn btn-sm btn-info"
                                            >
                                                <i className="mdi mdi-arrow-right" id="templatetooltip" />
                                                <UncontrolledTooltip placement="top" target="templatetooltip">
                                                    Clone Templates
                                                </UncontrolledTooltip>
                                            </Link>
                                        </UncontrolledAlert>
                                        :
                                        null
                                    }
                                    <div>
                                        <Col md="12">
                                            <FormGroup className="mb-3 row d-flex">
                                                <Label className="col-md-2 col-form-label" htmlFor="payment_plan_name">Payment Plan*</Label>
                                                <div className="col-md-10">
                                                    <input
                                                        id='paymentPlanName'
                                                        name="payment_plan_name"
                                                        placeholder="Payment Plan Name"
                                                        type="text"
                                                        value={this.state.payment_plan_name}
                                                        onChange={(e) => this.setState({ payment_plan_name: e.target.value })}
                                                        required="required"
                                                        className={
                                                            "form-control"
                                                        }
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <div className="mb-3 row d-flex select2-container">
                                                <Label className="col-md-2 col-form-label" htmlFor="selectProject">Project*</Label>
                                                <div className="col-md-10">
                                                    <Select
                                                        name="selectProject"
                                                        value={selectedGroupProject}
                                                        onChange={this.handleSelectGroupProject}
                                                        options={this.state.optionGroupProjects}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        {this.state.has_sizes == 0 ? <></> : <>
                                            <Col md="12">
                                                <div className="mb-3 row d-flex select2-container">
                                                    <Label className="col-md-2 col-form-label" htmlFor="selectSizes">Size*</Label>
                                                    <div className="col-md-10">
                                                        <Select
                                                            name="selectSizes"
                                                            value={selectedGroupSizes}
                                                            onChange={this.handleSelectGroupSizes}
                                                            options={this.state.optionGroupSizes}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </>}
                                        <form onSubmit={this.handleSubmit}>
                                            <Card id="payment-type-box">
                                                <CardBody className="border card border-primary">
                                                    <CardBody className="border-bottom">
                                                        <div className="d-flex align-items-center">
                                                            <h5 className="mb-0 card-title flex-grow-1">Add payment type to payment plan</h5>
                                                        </div>
                                                    </CardBody>
                                                    <div className="mb-3 row d-flex select2-container">
                                                        <label className="mt-3 col-md-2 col-form-label">
                                                            Payment Types:
                                                        </label>
                                                        <div className="col-md-10">
                                                            <select required className="mt-3 form-select" name="PaymentType" value={formData.PaymentType} onChange={this.handleChange}>
                                                                <option value="">Select...</option>
                                                                {this.state.optionsArray?.map(option => (
                                                                    <option value={option} key={option}>{option}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="mb-3 row d-flex">
                                                        <label className="col-md-2 col-form-label">
                                                            Occurance:
                                                        </label>
                                                        <div className="col-md-10">
                                                            <input required className="form-control" type="number" name="no_of_recurrences" value={this.state.formData.no_of_recurrences} onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="mb-3 row d-flex">
                                                        <label className="col-md-2 col-form-label">
                                                            Order:
                                                        </label>
                                                        <div className="col-md-10">
                                                            <input required type="number" className="form-control" name="order" value={this.state.formData.order} onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <Col md="12">
                                                        <div className="mb-3 row d-flex select2-container">
                                                            <Label className="col-md-2 col-form-label" htmlFor="currency">Currency Prices</Label>
                                                            {this.state.currencies?.map((d, i) =>
                                                                <div key={d.currency_id} className="col-md-12 d-flex mb-2" style={{ "marginLeft": "1.5rem" }}>
                                                                    <Label className="col-md-2 col-form-label" htmlFor="currency">Currency</Label>
                                                                    <div className="col-md-4">
                                                                        <input
                                                                            name={d.currency_name}
                                                                            value={d.currency_name}
                                                                            disabled
                                                                            placeholder="Price"
                                                                            // value={this.state.price?this.state.price:""}
                                                                            // onChange={(e) => this.setState({ price: e.target.value })}
                                                                            type="text"
                                                                            className={
                                                                                "form-control"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <Label style={{ "marginLeft": "1.5rem" }} className="col-md-2 col-form-label" htmlFor="currency">Price</Label>
                                                                    <div className="col-md-3">
                                                                        <input
                                                                            // name={d.currency_id}
                                                                            id={"price-" + i}
                                                                            name={d.currency_name} value={this.state.formData.currency_name} onChange={this.handleChange}
                                                                            placeholder="0.00"
                                                                            // value={this.state.price?this.state.price:""}
                                                                            type="number"
                                                                            step="0.01"
                                                                            pattern="[0-9]+([\.,][0-9]+)?"
                                                                            style={{ textAlign: 'right' }}
                                                                            required="required"
                                                                            className={"form-control"}
                                                                            onBlur={(e) => {
                                                                                if (e.target.value.trim() === "") {
                                                                                    e.target.value = ""; // Empty value stays empty
                                                                                } else {
                                                                                    const formattedValue = parseFloat(e.target.value).toFixed(2);
                                                                                    e.target.value = formattedValue;
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </CardBody>
                                                {this.state.currencies.length == 0 ? <></> : <>
                                                    <div className='col-md-12 float-end'>
                                                        <button color="primary" disabled={this.state.optionsArray.length == 0} className="btn btn-primary ms-1 mt-2 mb-2" type="submit">Add Payment Type</button>
                                                    </div>
                                                </>}
                                            </Card>
                                        </form>
                                        {this.state.tableHead != '' ? <>
                                            {this.state.tableData.map((data, index) => (
                                                <div key={index}>
                                                    <Card id="payment-type-box">
                                                        <CardBody className="border card border-primary">
                                                            <div className="mb-3 row d-flex select2-container">
                                                                <label className="mt-3 col-md-2 col-form-label">
                                                                    Payment Type:
                                                                </label>
                                                                <div className="mt-3 col-md-10">
                                                                    {/* <select value={selectedOptions[index]} onChange={(event) => handleOptionChange(index, event)}> */}
                                                                    <select required className="form-select test-000" name={"PaymentType-" + index} id={"PaymentType-" + index} value={data.payment_type_name} onChange={(event) => this.handleChangePaymentType(index, event)}>
                                                                        <option disabled value={data.payment_type_name}>{data.payment_type_name}</option>
                                                                        {this.state.optionsArray?.map(option => (
                                                                            <option value={option} key={option}>{option}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="mb-3 row d-flex">
                                                                <label className="col-md-2 col-form-label">
                                                                    Occurances:
                                                                </label>
                                                                <div className="col-md-4">
                                                                    <input
                                                                        name='occurance[]'
                                                                        id={'occurance-' + index}
                                                                        placeholder="0.00"
                                                                        defaultValue={data.no_of_recurrences}
                                                                        // value={this.state.formData.no_of_recurrences} 
                                                                        onChange={(event) => this.handleChangeRecurrences(index, event)}
                                                                        // onChange={(e) => this.handleAmountChange(e, i)}
                                                                        type="number"
                                                                        // pattern="[0-9]+([\.,][0-9]+)?"
                                                                        style={{ textAlign: 'right' }}
                                                                        required="required"
                                                                        className={"form-control"}
                                                                    />
                                                                </div>
                                                                <label style={{ textAlign: 'right' }} className="col-md-1 col-form-label">
                                                                    Order:
                                                                </label>
                                                                <div className="col-md-5">
                                                                    <input
                                                                        name='order'
                                                                        id={'order-' + index}
                                                                        placeholder="0.00"
                                                                        defaultValue={data.order}
                                                                        // onChange={(e) => this.handleAmountChange(e, i)}
                                                                        onChange={(event) => this.handleChangeOrder(index, event)}
                                                                        type="number"
                                                                        // pattern="[0-9]+([\.,][0-9]+)?"
                                                                        style={{ textAlign: 'right' }}
                                                                        required="required"
                                                                        className={"form-control"}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <Col md="12">
                                                                <div className="row d-flex">
                                                                    {data.currencies?.map((c, i) => (
                                                                        <div className="row d-flex mb-3" key={i}>
                                                                            <Label className="col-md-2 col-form-label">{this.state.tableHead[i]}</Label>
                                                                            <div className='col-md-4'>
                                                                                <input
                                                                                    name='amount'
                                                                                    id={'amount-' + index + '-' + i}
                                                                                    placeholder="0.00"
                                                                                    defaultValue={parseFloat(c.amount).toFixed(2)}
                                                                                    // onChange={(e) => this.handleAmountChange(e, i)}
                                                                                    onChange={(event) => this.handleChangeCurrencies(index, i, event)}
                                                                                    type="text"
                                                                                    // pattern="[0-9]+([\.,][0-9]+)?"
                                                                                    style={{ textAlign: 'right', marginLeft: '4px' }}
                                                                                    required="required"
                                                                                    className={"form-control"}
                                                                                    onBlur={(e) => {
                                                                                        if (e.target.value.trim() === "") {
                                                                                            e.target.value = ""; // Empty value stays empty
                                                                                        } else {
                                                                                            const formattedValue = parseFloat(e.target.value).toFixed(2);
                                                                                            e.target.value = formattedValue;
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <Label className="col-md-6 col-form-label">x{" "}&nbsp;&nbsp;{data.no_of_recurrences}{" "}&nbsp;&nbsp; = &nbsp;&nbsp;{this.state.currencies[i].currency_symbol}{" "}{numberWithCommas(data.no_of_recurrences * c.amount)}</Label>
                                                                        </div>
                                                                    ))}
                                                                    <div><button color="danger" className="btn btn-danger ms-1 mt-2 mb-2" onClick={() => this.handleDelete(index)}>Delete</button></div>
                                                                </div>
                                                            </Col>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            ))}
                                        </> : <></>}
                                        <Row>
                                            <Col md="12">
                                                <Card>
                                                    <CardBody>
                                                        <div className='d-flex'>
                                                            {Object.keys(currenciesTotals).map((key, i) => (
                                                                <div key={i}>
                                                                    <div md='12'>
                                                                        <b>Total in {key}: {" "}</b>
                                                                        {numberWithCommas(currenciesTotals[key])} &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        {this.state.tableData.length == 0 ? <></> : <>
                                            <div className='float-end'>
                                                <button color="success" className="btn btn-success ms-1 mt-2 mb-2" onClick={() => this.submitPaymentPlanData()}>Submit</button>
                                            </div>
                                        </>}
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </div >
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container >
            </div >

        );
    }
}
export default AddPaymentPlan;
